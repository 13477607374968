export const stepOneData = {
  firstName: "",
  lastName: "",
  phone: "",
  whatsapp: "",
  languages: [],
  otherLanguages: [],
  mediumshipLevel: "",
  mediumship_level: "",
  mediumship_level_admin: "",
  mediumshipSpecialities: [],
  otherMediumSpecialities: [],
  country: "",
  website: "",
  timezone: "",
  biographicalInfo: "",
  when_do_i_pay: [],
  session_length: 30,
  session_length_30: "",
  session_length_45: "",
  session_length_60: "",
  session_length_45_2: "",
  session_length_60_2: "",
  session_length_30_currency: "",
  session_length_45_currency: "",
  session_length_60_currency: "",
  session_length_45_2_currency: "",
  session_length_60_2_currency: "",
  profile_image: "",
  encoded_image: "",
  zoom: "",
  google: "",
  medium_professional_category: "1",
  recommendation: "",
  encrypted_id: "",
  personal_booking_link: "",
  session_lengths: "",
  session_lengths_currency: "",
  session_currency: "USD",
  is_square_integrated: false,
  is_stripe_integrated: false,
  is_paypal_integrated: false,
  test_mode: 0,
  allow_free_coaching_session: false,
  payitforward_pricing_30: "",
  payitforward_pricing_45: "",
  payitforward_pricing_60: "",
  pay_forward:0


};
export const stepOneDataBackup = {
  firstName: "",
  lastName: "",
  phone: "",
  whatsapp: "",
  languages: [],
  otherLanguages: [],
  mediumshipLevel: "",
  mediumship_level: "",
  mediumship_level_admin: "",
  mediumshipSpecialities: [],
  otherMediumSpecialities: [],
  country: "",
  website: "",
  timezone: "",
  biographicalInfo: "",
  when_do_i_pay: [],
  session_length: 30,
  session_length_30: "",
  session_length_45: "",
  session_length_60: "",
  session_length_45_2: "",
  session_length_60_2: "",
  session_length_30_currency: "",
  session_length_45_currency: "",
  session_length_60_currency: "",
  session_length_45_2_currency: "",
  session_length_60_2_currency: "",
  profile_image: "",
  encoded_image: "",
  zoom: "",
  google: "",
  medium_professional_category: "1",
  recommendation: "",
  encrypted_id: "",
  personal_booking_link: "",
  coaching_pricing_30: '',
  hosting_circle_pricing_30: '',
  hosting_qa_pricing_30: '',

};

export const stepTwoData = {
  mediumship_session_tools: [],
  currentRoles: "",
  screenName: "",
  allowRecordings: "3",
  session_preferences: [],
  wantToDoSessions: [],
  wantToDoWork: "All Countries",
  country_type: "",
  exclusions: [],
  inclusions: [],
  interestedSessionType: [],
  interestedSessionType1: "",
  PaymentMethods: [],
  countries: [],
  opt_in: "",
};

export const stepThreeData = {
  electronicCalendars: [],
  zoomAccountType: "",
  preferredDevice: [],
  preferredBrowser: [],
  zoom_personal_link: "",
  paypal_me_link: "https://",
  personal_booking_link: "",
};

export const stepFourData = {
  legalAndPrivacy: false,
  optIn: false,
  codeOfEthics: false,
  operatingPrinciples: false,
};
