import React from "react";

export default function PrivacyModal() {
  return (
    <div
      class="modal fade bd-example-modal-lg mt-5"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-preview">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {" "}
              We Deeply Care About Your Privacy.
            </h5>
            <button
              type="button"
              class="close about-us-review-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-5">
            <p className="margin-unset line-height-24px font-size-14">
              {" "}
              We use your contact information to reach you with details about
              sessions that you book with us. You will receive your session Zoom
              link and reminders via email (and text if you choose). You will
              also receive a brief survey after your session via email that
              helps us to ensure quality experiences.
            </p>
            <br />
            <p className="margin-unset line-height-24px font-size-14">
              Your medium will have access to your screen name and contact
              information provided at booking. If you choose professional
              services, you will pay the Medium directly and they will receive a
              payment notification from their payment provider.
            </p>
            <br />

            <p className="margin-unset line-height-24px font-size-14">
              Importantly, we don’t use your information for any session
              preparation. You can trust that your mediumship experience is
              completely authentic and you can expect our Mediums to convey
              information that could not otherwise be known.
            </p>
            <br />
            <p className="margin-unset line-height-24px font-size-14">
              We promise we won’t spam you, use your email for promotions, or
              sell your information. If you choose to opt-in, we may let you
              know about upcoming free events that may be of interest to you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
