import React, { useEffect } from "react";
import ConfirmDeleteModal from "../../../../../components/deleteConfirmModal";

export default function Card({
  id,
  tab,
  src,
  title,
  onClick = () => { },
  tbd,
  tabNo,
  googleconnectedemail,
  subcalendarname,
  zoomconnectedemail,
  name,
  buttonType,
  unlinkHnalder,
  str_pub_key,
  str_pri_key,
  paypal_pub_key,
  paypal_pri_key,
  square_location_id,
  squareup_access_token,
  show_pay_forward,
  changeAvailabilityHandler,
  googleavailabilitysync,
  show_payment_gateways
}) {
  const onToggle = () => {
    onClick();
  };
  const getActive = () => {
    return tab ? "active" : "";
  };
  const openConfirmModal = () => {
    window.loadModal2(`#${id}`, "show");
  };
  return (
    <>
      <div className="box-integaction">
        <div className={`icon-inte ${tbd ? "opacity-2" : ""}`}>
          <div className="text-center font-size-22">{name}</div>
          <div className="user-b-l">
            <img src="/integrationimages/avatar.png" alt="avatar" />
          </div>
          <div className="video-b-r">
            <img src={src} alt="icon" />
          </div>
        </div>
        {tbd && (
          <div className="_tb_parent _tbd-to-0 _tbd-z-index-1 _tbd-left ">
            <p className="_to_be_decided">TBD</p>
          </div>
        )}

        <div
          onClick={!buttonType ? onToggle : () => { }}
          className={`link-your first_link_zoom ${getActive()} ${tbd ? "opacity-2" : ""
            }`}
        >
          {tabNo === 2 && (
            <>
              <div className="font-size-12 font-size-13 line-height-20px">
                Connected Email : {googleconnectedemail}
                <br />
                <div className="font-size-12 font-size-13 line-height-20px">
                  Subcalendar :{" "}
                  {googleconnectedemail !== null ? subcalendarname : ""}
                </div>
              </div>
              {/* {!tab && <div>
                <div className="red red-error-payment line-height-unset font-size-12 ">
                  <div className="d-flex">
                    <span className="width-65-percent d-block heigh-35">

                      Display VerySoul availability on my Google calendar as "free" time. NOTE: This may interfere with other booking systems
                    </span>

                    <div className="mt-4">
                      <div class="toggle-button-cover mt-5">
                        <div class="button-cover">
                          <div
                            class="button r"
                            id="button-1"
                            onClick={changeAvailabilityHandler}
                          >
                            <input
                              type="checkbox"
                              checked={googleavailabilitysync}
                              name="toggle"
                              class="checkbox"
                            // onChange={change}
                            />
                            <div class="knobs"></div>
                            <div class="layer"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>} */}
            </>
          )}
          {/* <br/><span className="subcalendar font-size-12">{"abc"}</span></div>} */}
          {tabNo === 1 && <>
            <div className="userstatus font-size-12 font-size-13 line-height-20px">
              Connection Status :{" "}
              {zoomconnectedemail ? (
                <>
                  Connected
                  <div className="userstatus-green inline-block"></div>
                </>
              ) : (
                <>
                  {" "}
                  Disconnected
                  <div className="userstatus-red inline-block"></div>
                </>
              )}
            </div>
            {/* <div className="red red-error-payment line-height-unset font-size-12 red-error-payment-1">
              Note: Please confirm if you checked: Allow this app to use my shared  access permissions in zoom settings
            </div> */}
          </>
          }
          {buttonType ? (
            <div class="unlink-buttton">
              <button onClick={openConfirmModal} class="proced">
                {title}
              </button>
            </div>
          ) : (
            <>            <a className="d-flex align-items-center" href="javascript:void(0)">
              <div className="up-down-errow">
                {tab ? (
                  <img
                    src="/integrationimages/icon-up.png"
                    alt="up"
                    className="up"
                  />
                ) : (
                  <img
                    src="/integrationimages/icon-down.png"
                    alt="down"
                    className="down"
                  />
                )}
              </div>
              <div className="cont-link">
                <span>{title}</span>
                {/* <p>Lorem Ipsum</p> */}
              </div>
            </a>
              <span className="red font-size-13 mt-2 d-block"> {tabNo === 1 && <>Zoom integration required to access the main menu</>}</span>
            </>
          )}
          {tabNo === 3 && (
            <>
                            <div className="mt-2">
                <div className="d-flex flex-wrap align-items-center">
                  <div className="mr-3">
                    Stripe:
                    {(str_pub_key === "" && str_pri_key === "") ||
                      (str_pub_key === null && str_pri_key === null) ? (
                      <span className="ml-1">&nbsp;&nbsp;</span>
                    ) : (
                      <img className="ml-1" src="/localImages/tick.png" alt="tick" />
                    )}
                  </div>
                  <div className="mr-3">
                    Paypal:
                    {(paypal_pub_key === "" && paypal_pri_key === "") ||
                      (paypal_pub_key === null && paypal_pri_key === null) ? (
                      <span className="ml-1">&nbsp;&nbsp;</span>
                    ) : (
                      <img className="ml-1" src="/localImages/tick.png" alt="tick" />
                    )}
                  </div>
                  <div className="mr-3">
                    Square:
                    {(square_location_id === "" && squareup_access_token === "") ||
                      (square_location_id === null && squareup_access_token === null) ? (
                      <span className="ml-1">&nbsp;&nbsp;</span>
                    ) : (
                      <img className="ml-1" src="/localImages/tick.png" alt="tick" />
                    )}
                  </div>
                  <div>
                    Pay it Forward:
                    {show_pay_forward === false ? (
                      <span className="ml-1">&nbsp;&nbsp;</span>
                    ) : (
                      <img className="ml-1" src="/localImages/tick.png" alt="tick" />
                    )}
                  </div>
                </div>
              </div>
              {/* {!tab && <span className="red red-error-payment font-size-12 red-error-payment-3 ">
                *Only applicable if you have qualified and have been <br />{" "}
                invited to do client work wih verysoul
              </span>} */}
            </>
          )}
        </div>
      </div>
      <ConfirmDeleteModal
        deleteUser={unlinkHnalder}
        id={id}
        label={"Sure you want to unlink ?"}
        btnLabel={"Continue"}
      />
    </>
  );
}
