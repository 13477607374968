import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CopyComponent from "../../../../components/copyComponent";
import CsvDownload from "../../../../components/csvdownload";
import Filter from "../../../../components/filter";
import Search from "../../../../components/search";
import Sorting from "../../../../components/sorting";
import UserStatus from "../../../../components/userstatus";
import Pagination from "../../../../components/tablePagination";
import Button from "../../../../components/button";
import { getMediumRole } from "../../../../utils/utils";
import Checkbox from "../../Subcomponents/checkbox";
import { filterList, bulkList } from "../data";
// import PaginatedItems from '../../../../components/pagination';


import { fireFilter } from "../functions";
import { setMediumPage, setMediumrowsPerPage, setPathArray } from "../../../../reducer/adminSlice";

const headers = [
  { label: "Screen Name", key: "screen_name" },
  { label: "Email", key: "email" },
  { label: "Approved Role", key: "approved_role" },
  { label: "Status", key: "status_name" },
  { label: "Online status", key: "is_available" },
];
export default function Medium(props) {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState([]);

  const {
    allMediumCount
  } = useSelector((state) => state.users);
  const {
    mediums,
    updateUser,
    onClickHandler,
    filterListMediums,
    filterReset,
    bulkActions,
    getPaginationData,
    mediumpage,
    mediumrowsPerPage,
    setFilterValues,
    setSearchValues,
    searchValue,
    filterValues,
  } = props;
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');


  useEffect(() => {

    if (page !== null) {

      dispatch(setMediumPage(page));
      
    }
    if (rowperpage !== null) {
      dispatch(setMediumrowsPerPage(rowperpage));
    }
  }, [])
  let info = JSON.parse(localStorage.getItem('pagination'))
  const navigateUser = (id) => {
    window.renderFirst = false
    dispatch(setPathArray(id.id));
    navigate(`/profile-view/${id.id}?view=0&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}`);
  };
  const navigateEdit = (e, id) => {
    window.renderFirst = false
    if (e.target.className == "capitalize") {
      navigate(`/profile-edit/${id.id}?view=0&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}`);
    } else {
    }

  };
  const checkBoxChangeHandler = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let result = mediums.map((a) => a.id);
      setSelected(result);
    } else {
      setSelected([]);
    }
  };

  // const getPaginationData = (item) => {
  //  console.log('paginationdata',item);
  //  getFromToData(item);
  // };

  const changeHandler = (e) => {
    if (e.target.value === "Decline") {
      bulkActions(2, selected);
    }
    if (e.target.value === "Delete") {
      bulkActions(3, selected);
    }
  };

  return (

    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        <Search callApi={filterListMediums} list={filterList} searchValue={searchValue} setSearchValues={setSearchValues} setFilterValues={setFilterValues}

          filterValues={filterValues} isDatepickerShow={1}/>
        <Filter
          name={"Bulk Actions"}
          list={bulkList}
          changeHandler={changeHandler}
        />
         {/* <Button
          status={0} 
           type="button"
           onClick={filterReset}
           className={`resend-btn  width-80px  reset-btn-123`}
           title="Reset"
          /> */}
      </div>
      <CsvDownload
        href={`${process.env.REACT_APP_API_URL_EXPORT}` + 'export-medium-list?user_type=all'}
      // usertype={"all"}
      // data={fireFilter(mediums)}
      // headers={headers}
      // filename={"mediums"}
      />

      <div className="table-box-medium">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th >
                <input
                  onChange={(e) => checkBoxChangeHandler(e)}
                  type="checkbox"
                  id="one"
                />
                <label htmlFor="one"></label>
                &nbsp;
              </th>
              <th>
                {" "}
                <div className="d-flex align-items-center">
                  {" "}
                  <span className="scren-name">Screen Name</span>{" "}
                  <Sorting callApi={filterListMediums} />
                </div>
              </th>

              <th>Email</th>
              <th>Starter Referral Code</th>
              <th>Total Starter Referrals</th>
              <th>Approved Role</th>
              <th>Status</th>
              <th>Online status</th>
              <th className="text-right">Actions</th>
            </tr>
            {mediums.length > 0 ? (
              mediums.map((medium) => (
                <tr
                  onClick={(e) => navigateEdit(e, medium)}
                  className="innr pointer"
                >
                  <td className="">
                  <div className="d-flex-medium-tables">
                    <Checkbox
                      medium={medium}
                      status={checked}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <img
                      src="/images/undraw_profile.svg"
                      className="profile-image-avatar"
                      alt="avatar"
                    />
                    </div>
                  </td>
                  <td className="capitalize">
                    {medium.screen_name ? medium.screen_name : "N/A"}
                    {/* {medium.first_name ? medium.first_name : "N/A"} &nbsp;{" "}
                    {medium.last_name} */}
                  </td>
                  <td>
                    {medium.email}
                    <CopyComponent value={medium.email} />
                  </td>
                  <td>
                    {medium.refer_code ? medium.refer_code : "-"}

                  </td>
                  <td>
                    {medium.number_of_uses}

                  </td>
                  <td className="change">
                    <em>{getMediumRole(medium.current_mediumship_level)}</em>
                    {/* <a href="#">Change</a> */}
                  </td>

                  <td>
                    {parseInt(medium.status_name) === 1
                      ? "Approved"
                      : parseInt(medium.status_name) === 2
                        ? "Declined"
                        : parseInt(medium.status_name) === 4
                          ? "Restricted"
                          : "Pending"}
                  </td>
                  <td>
                    <UserStatus status={medium.is_available} />
                  </td>
                  <td className="dott">
                    <img
                      onClick={(e) => onClickHandler(e, medium.id, "mediums")}
                      src="/images/Unionblck.png"
                      className="padding-10"
                      alt="dots"
                    />
                    {medium.status && (
                      <div className="approve-btn-1 approve-btn z-index-1">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            window.renderFirst = false;
                            navigate(
                              `/services?schedule=schedule&id=${medium.id}&view=0&level=${medium.current_mediumship_level}&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}`
                            );
                          }}
                        >
                          Schedule
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            window.renderFirst = false;
                            navigate(
                              `/availability/${medium.id}?&view=0&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}`
                            );
                          }}
                        >
                          Calendar
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateUser(medium);
                          }}
                        >
                          View
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(
                              medium,
                              parseInt(medium.status_name) === 4 ? 1 : 4,
                              "medium",
                              ""
                            );
                          }}
                        >
                          {parseInt(medium.status_name) === 4
                            ? "UnRestrict"
                            : "Restrict"}
                        </span>
                        {/* <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(medium, 2, "mediums", "");
                          }}
                        >
                          Decline
                        </span> */}

                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(medium, 3, "delete", "");
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </td>
                  {/* <td className="dot-image">
                    <img src="../images/Unionblck.png" />
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Mediums Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <div className="pagination-box">
          <div className="left-pagi">
            <span>Showing 1 to 10 of 18 entries</span>
          </div>
          <div className="right-pagi">
            <ul>
              <li>Previous</li>
              <li className="active">
                <a href="#">1</a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>Next</li>
            </ul>
          </div>
        </div> */}

      </div>
      <Pagination
        rowsPerPageOptions={[5, 10, 25]}
        count={allMediumCount}
        activeTab={"medium"}
        mediumpage={mediumpage}
        mediumrowsPerPage={mediumrowsPerPage}
        getPaginationData={getPaginationData}
      />
      {/* <div ><PaginatedItems itemsPerPage={5}/></div> */}
    </>
  );
}
