import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './homepage.css';

export default function () {
  const navigate = useNavigate();

  const redirectRoute = (item) => {
    navigate(item);
  };
  return (
    <div>


      <div className="header-saction">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="#"><img src="images/Very Soul Logo Black.png" alt="LOGO" /></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse show" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active"><a className="nav-link" href="#">How it Works</a></li>
                <li className="nav-item"><a className="nav-link" target='_blank' href="https://verysoul.com/about-us">Our Story</a></li>
                <li className="nav-item"><a className="nav-link" target='_blank' href="https://verysoul.com/members-map">Global Presence</a></li>
                <li className="nav-item"><a className="nav-link" target='_blank' href="https://verysoul.com/reviews">Review</a></li>

                <li className="nav-item">
                  <Link to={""} data-toggle="modal"
                    data-target="#myModal"
                    className="sign-up">Sign Up</Link>

                  {/* <a className="sign-up">Sign Up</a> */}
                </li>
                <li className="nav-item">
                  <Link to={"/login"} className='login'>Login</Link>
                  {/* <a className="login">Login</a> */}
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div className="banner-saction">
        <div className="container">
          <h2>Everyone deserves connection.</h2>
          <p>If you’d like to connect with people you’ve lost,<br></br> our Mediums can help.</p>
          <a href='#'>Explore Our Offerings</a>
          <img className='w-100' src="images/banner-tow.png" alt='banner' />
        </div>
      </div>
      <div className='how-it-works'>
        <div className="container">
          <h2>How it works</h2>
          {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. <br></br>Lorem Ipsum has been the industry's standard dummy text ever since the</p> */}
          <div className='how-it-works-box'>
            <div className='row flex-wrap'>
              <div className='col-md-4'>
                <div className='how-it-works-inner'>
                  <span>1</span>
                  <h4>Sign Up</h4>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p> */}
                </div>
              </div>
              <div className='col-md-4'>
                <div className='how-it-works-inner'>
                  <span>2</span>
                  <h4>Complete Your Profile</h4>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p> */}
                </div>
              </div>
              <div className='col-md-4'>
                <div className='how-it-works-inner'>
                  <span>3</span>
                  <h4>Select Session Date/Time</h4>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p> */}
                </div>
              </div>
              {/*
              <div className='col-md-3'>
                <div className='how-it-works-inner'>
                  <span>4</span>
                  <h4>Pay and Book a call</h4>
                   <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                </div>
              </div>
              */}
            </div>
          </div>
          <div className='our-story-box'>
            <div className='our-story-box-left'>
              <h3>Our Story</h3>
              <strong>Imagine a compelling force…a force that demands your attention. It shows you a transformational opportunity that must spark action. This is inspiration. And it was inspiration that started VerySoul in early 2021.</strong>
              <p>VerySoul was more than an idea. It was a detailed plan, with goals and funding, that came together to meet a clear need: providing a place where Mediums could help bridge the communication gap between people and their loved ones who had passed.</p>
              <p>Mediums have been helping bridge this gap for centuries by serving as conduits for people who wanted to experience their departed loved ones. Though the need for authentic, evidence-based Mediums is growing, they have historically been hard to find.</p>
            </div>
            <div className='our-story-box-right'>
              <img className='w-100' alt='our story' src="images/our-story.png" />
            </div>
          </div>
        </div>
      </div>


      <div className="our-global-presence">
        <div className="container">
          <span>The Mediums</span>
          <h2>Our Global Presence</h2>
          <div className='row our-global-wrapper'>
            <div className='col-md-12 col-lg-6  col-sm-12'>
              <div className="our-global-presence-iiner">
                <h4>Okay, Let’s see <br></br><i>verysoul</i> in numbers</h4>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and <br></br>typesetting industry. Lorem Ipsum has been.</p> */}
                <ul className='numbar'>
                  <li>
                    <span>300+</span>
                    <i>Using Group</i>
                  </li>
                  <li>
                    <span>20k</span>
                    <i>Active users</i>
                  </li>
                  <li>
                    <span>10k+</span>
                    <i>Subscribers</i>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-12 col-lg-6  col-sm-12'>
              <div className="our-global-presence-inner">
                <img className='w-100' alt='laptop' src="images/laptop.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='get-started-with-verySoul'>
        <div className="container">
          <h2>Get started with VerySoul</h2>

          {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the</p> */}


          <Link to={""} data-toggle="modal"
            data-target="#myModal"
            className='signup-get'>Sign Up</Link>

          {/* <a className="sign-up">Sign Up</a> */}


          <Link to={"/login"} className='login-get'>Login</Link>
          {/* <a className="login">Login</a> */}

          {/* <a href='#' className='signup-get'>Sign Up</a>
          <a href='#' className='login-get'>Login</a> */}
        </div>
      </div>


      <div className='footer-saction-home'>
        <div className="container">
          <div className='footer-logo-home'><img src="images/Union-icon.png" /> </div>
          <p>For greater perspective on life</p>
          <ul>
            <li>
              <Link target='_blank' to={'/terms-conditions'}>Terms & Conditions</Link>
              {/* <a href='#'>Terms& Conditions</a> */}
            </li>
            <li>|</li>
            <li>
              <Link target='_blank' to={'/privacy-policy'}>Privacy Policy</Link>
              {/* <a href='#'>Privacy Policy</a> */}
            </li>
          </ul>
          {/* <span><b>Note: For optimum performance: use Chrome, Safari, or Edge</b></span> */}
          <span>@2023 very Soul LLC. All right reserved.</span>
        </div>
      </div>
      <div className="dont-have-acc">
        <div
          className="modal fade"
          id="myModal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-preview">
            <div className="modal-content">
              <div className="modal-body text-align-unset">
                <div className="icon-pop text-align-center">
                  <img
                    src="images/Union-icon.png"
                    alt="union-icon"
                  />
                </div>
                <ul>
                  <li>
                    <div
                      onClick={() =>
                        redirectRoute("/registration")
                      }
                      className="height-unset"
                      data-dismiss="modal"
                    >
                      <img
                        src="images/medum.png"
                        alt="medium"
                      />
                      <span data-dismiss="modal">
                        Register as <br />
                        Medium
                      </span>
                    </div>
                  </li>
                  <li>
                    <div
                      className="height-unset"
                      onClick={() =>
                        redirectRoute("/register-client")
                      }
                      data-dismiss="modal"
                    >
                      <img
                        src="images/customer.png"
                        alt="register"
                      />
                      <span>
                        Register as <br />
                        Client
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="text-center booking"><b>Note: For optimum performance: use Chrome, Safari, or Edge browser</b></p>
    </div>
  )
}
