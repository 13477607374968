import { FieldArray, Formik, useFormikContext } from "formik";
import React, { useEffect, useState, useRef } from "react";
import "./backtoschedules.css";
import { initialValues } from "./data";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import MultipleDatePicker from "react-multiple-datepicker";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  makeHours,
  updateSingleMediumAvailability,
  addScheduleForm,
  updateScheduleForm,
  setAdvancedAvailability,
  clearScheduleForm,
} from "../functions";
import {
  checkAlreadyExistService,
  getAdvancedPreferences,
  getAdvancedPreferencesFromAdmin,
  setNewTimeZone,
} from "../../../../services/medium";
import {
  filterVisibleTimeZones,
  removeItemOnce,
} from "../../../../utils/helpers";
import {
  capitalizeFirstLetter,
  participantLevelRoles,
  studentLevelRoles,
  getTimeImage,
} from "../../../../utils/utils";
import moment, { duration } from "moment";
import { setTimezoneArray } from "../../../../reducer/uidataSlice";
import { getTimezones } from "../../../../services/uidata";
import { setNewTimeZoneData } from "../../../../reducer/userSlice";
import { slotsArray, case10Array, items } from "../data";
import { setFilteredData } from "../../../../reducer/adminSlice";
import BookingPeriodBar from "../bookingperiodbar";
import ConfirmOffline from "../subcomponent/checkOffline";

import ConfirmTime from "../subcomponent/confirmTime";

import PreviewCheckbox from "../subcomponent/previewcheckbox";

export default function BackToSchedules({
  type = "admin",
  formType,
  openSchedules,
  medium_id,
  countries,
  schedule_id = "",
  profilePreferences = [],
  languages = [],
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectRef = useRef();
  const { timezone, current_mediumship_level } = useSelector((state) => state.user.user);
  const [valueupdated, setValueUpdated] = useState(true);
  const auth = useSelector((state) => state.auth);
  const { timezoneArray, countryArray } = useSelector((state) => state.uidata);
  const { sessionTypes, screenNameDetails } = useSelector((state) => state.users);
  const [collapsed, setCollapsed] = useState("collapse");
  const [newCountryData, setNewCountryData] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [update, setUpdate] = useState({});
  const [values, setValues] = useState(initialValues);
  const [isValueLoded, setisValueLoded] = useState(false);
  const [copydays, setcopydays] = useState([]);
  const [activeCopy, setActiveCopy] = useState("");
  const [mediumRolesStudentList, setmediumRolesStudent] = useState([]);
  const [mediumRolesList, setmediumRoles] = useState([]);
  const [showField, setshowField] = useState(false);
  const [timezoneData, setTimezoneData] = useState([]);
  const [mediumAvailabilityId, setmediumAvailabilityId] = useState("");
  const [slotsList, setSlotsList] = useState([]);
  const [ParticipantLevelStatus, setParticipantLevelStatus] = useState(false);
  //specific dates
  const [testValues, settestValues] = useState([]);
  const [updateType, setUpdateType] = useState("ADD");
  const [updateTypeIndex, setUpdateTypeIndex] = useState(0);

  //blocked dates
  const [blockedDatesValues, setBlockedDatesValues] = useState([]);
  const [blockedupdateType, setUpdateblockedType] = useState("ADD");
  const [blockedupdateTypeIndex, setblockedUpdateTypeIndex] = useState(0);

  //
  const [showSpecificAddDates, setShowSpecificAddDates] = useState(false);
  const [showBlockedAddDates, setShowBlockedAddDates] = useState(false);

  //
  const [blockedfullday, setBlockedFullday] = useState(false);
  const [specificfullday, setspecificFullday] = useState(false);
  const [errorMessages, setErrorMessages] = useState({})

  const [alreadyExist, setAlreadyExist] = useState(false);
  const [active60minNote, setActive60minNote] = useState(false);
  const [alreadyExistService, setAlreadyExistService] = useState('');
  const [unitValue, setUnitValue] = useState('Days')
  const [temporaryStorage, setTemporaryStorage] = useState()

  const [activeSessionLength, setactiveSessionLength] = useState();
  const [searchParams] = useSearchParams();
  const level = type === "admin" ? parseInt(searchParams.get("level")) : parseInt(current_mediumship_level)

  const [showGroupSession, setShowGroupSession] = useState(false);
  const [showGroupNotification, setshowGroupNotification] = useState(true);
  const [showGroup, setshowGroup] = useState(true);

  const [showpreffered, setshowpreffered] = useState(false);
  const [timeDifferenceError, setTimeDifferenceError] = useState(false);


  useEffect(() => {
    setTimeout(() => {
    }, 5000)
    clearScheduleForm(initialValues);
    if (schedule_id === "") {
      initialValues.start_date = moment(new Date()).format("YYYY-MM-DD");
    }
    setSlotsList(
      slotsArray.map((data) => {
        return {
          label: data.time,
          value: data.time,
          image: data.image,
          val: data.val,
          change: data.change,
        };
      })
    );
    if (!timezoneArray.length) updateTimezones();
  }, []);
  useEffect(() => {
    setTimezoneData(filterVisibleTimeZones(timezoneArray));
  }, [timezoneArray]);

  const updateTimezones = () => {
    getTimezones()
      .then((data) => {
        dispatch(setTimezoneArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };
  const changeTimeZoneHandler = (e) => {
    let id = e.target.value;
    let body = {
      timezone_id: id,
    };
    setNewTimeZone(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(setNewTimeZoneData(filterTimeZone(id)));
        }
      })
      .catch(() => { });
  };

  const handleMinDaysChange = (e, setFieldValue) => {
    setFieldValue("min_booking_days", e.target.value);
    setFieldValue("min_booking_hour", "00");
    setFieldValue("min_booking_time", "00");
  }

  const handleMinHoursChange = (e, setFieldValue) => {
    setFieldValue("min_booking_hour", e.target.value);
    setFieldValue("min_booking_days", "00");
    setFieldValue("min_booking_time", "00");
  }

  const handleMinTimeChange = (e, setFieldValue) => {
    setFieldValue("min_booking_time", e.target.value);
    setFieldValue("min_booking_hour", "00");
    setFieldValue("min_booking_days", "00");
  }


  const filterTimeZone = (id) => {
    let zone;
    timezoneData.filter((item) => {
      if (parseInt(id) === parseInt(item.id)) {
        zone = item.front_name;
      }
    });
    return zone;
  };

  useEffect(() => {
    console.log(sessionTypes,sessionTypes)
    if (type === "admin") {
      setSessions(sessionTypes);
    } else {
      setSessions(
        sessionTypes.map((item) => {
          return {
            value: item.sub_session_id,
            label: item.sub_session_name,
            session_type: item.session_type,
            sub_session_id: item.sub_session_id,
            is_group_session: item.is_group_session,
            is_medium_level: item.is_medium_level,
            duration: item.duration
          };
        })
      );
    }

    if (schedule_id !== "") {
      setmediumAvailabilityId(schedule_id);
      updateSingleMediumAvailability(schedule_id, updateForm, level);

      // initialValues.start_date = '2023-01-19';
    }
    //  else {
    //   setisValueLoded(true);
    // }
    if (schedule_id === "") {
      getAdvancedProfile();

      // initialValues.start_date = '2023-01-19';
    }

    setmediumRolesStudent(
      studentLevelRoles.map((item) => {
        return { value: item.id, label: item.name };
      })
    );
    setmediumRoles(
      participantLevelRoles.map((item) => {
        return { value: item.id, label: item.name };
      })
    );

    setNewCountryData(
      countries.map((item) => {
        return {
          ...item,
          value: item.value,
          label: item.label,
        };
      })
    );
  }, [countries]);

  const getAdvancedProfile = async () => {

    if (type === "admin") {
      await getAdvancedPreferencesFromAdmin(medium_id)
        .then((data) => {
          let profilePrefer = data.data.data;
          addNewData(profilePrefer);
          //addScheduleForm(initialValues,profilePreferences);
        })
        .catch((err) => console.log(err));
    }
    else {

      await getAdvancedPreferences()
        .then((data) => {
          let profilePrefer = data.data.data;
          addNewData(profilePrefer);
          //addScheduleForm(initialValues,profilePreferences);
        })
        .catch((err) => console.log(err));
    }
  };

  const addNewData = (profilePrefer) => {

    addScheduleForm(initialValues, profilePrefer, countries);
    setisValueLoded(true);
  };

  const cancelForm = () => {
    clearScheduleForm(initialValues);
    openSchedules();
  };
  const updateForm = (info) => {
    setUpdate(info);
  };

  const submitForm = (body) => {

    // if(body.specificDateSlotsdata === "[]" && )
    // if (body.end_date !== "" && body.end_date !== null) {
    //   let specific = body.specificDateSlotsdata
    //   let block = body.blockedSpecificSlotsData
    //   let end = moment(body.end_date, "YYYY-MM-DD").add(1, "days").format('YYYY-MM-DD')
    //   let specificcheck = JSON.parse(specific).every((data) => {
    //     return moment(end).isAfter(data.date)
    //   })
    //   let blockdate = JSON.parse(block).every((data) => {
    //     return moment(end).isAfter(data.date)
    //   })
    //   if (!specificcheck) {
    //     toast.error("Please Select Specific Dates Between start date and end date ", { position: "top-center" });
    //     return true
    //   }
    //   if (!blockdate) {
    //     toast.error("Please Select blocked Dates Between start date and end date ", { position: "top-center" });
    //     return true
    //   }

    // }
    setAdvancedAvailability(
      body,
      setmediumAvailabilityId,
      dispatch,
      openSchedules
    );
  };
  useEffect(() => {
    if (Object.values(update).length > 0) {
      let countryType = getCountryTypes(
        values.country_type,
        values.exclusions,
        values.inclusions
      );
      updateScheduleForm(
        update,
        initialValues,
        sessions,
        type,
        setValues,
        languages,
        newCountryData,
        settestValues,
        setBlockedDatesValues
        , setUnitValue,
        level,
        setShowGroupSession,
        setShowSpecificAddDates,
        setshowGroupNotification,
        setshowpreffered
      );
      setisValueLoded(true);
      // setValues(initialValues)
    }
  }, [update]);

  const copyChangeHandler = (e) => {
    let data = copydays;
    if (e.target.checked) {
      data.push(e.target.value);
    } else {
      let index = data.indexOf(e.target.value);

      if (index > -1) {
        data.splice(index, 1);
      }
    }
    setcopydays(data);
  };
  const copyHandler = (data, setFieldValue) => {

    let newvalues = data;
    copydays.map((day) => {
      if (activeCopy === "sunday") {
        newvalues.daysunday = true;
        runDaysFunc(day, newvalues, data.scheduleSunday, setFieldValue);
      }
      if (activeCopy === "monday") {
        newvalues.daymonday = true;
        runDaysFunc(day, newvalues, data.scheduleMonday, setFieldValue);
      }
      if (activeCopy === "tuesday") {
        newvalues.daytuesday = true;
        runDaysFunc(day, newvalues, data.scheduleTuesday, setFieldValue);
      }
      if (activeCopy === "wednesday") {
        newvalues.daywednesday = true;
        runDaysFunc(day, newvalues, data.scheduleWednesday, setFieldValue);
      }
      if (activeCopy === "thursday") {
        newvalues.daythursday = true;
        runDaysFunc(day, newvalues, data.scheduleThursday, setFieldValue);
      }
      if (activeCopy === "friday") {
        newvalues.dayfriday = true;
        runDaysFunc(day, newvalues, data.scheduleFriday, setFieldValue);
      }
      if (activeCopy === "saturday") {
        newvalues.daysaturday = true;
        runDaysFunc(day, newvalues, data.scheduleSaturday, setFieldValue);
      }
    });
    setisValueLoded(false);
    setValues(newvalues);
    setCollapsed("collapse show");
    setTimeout(() => {
      setisValueLoded(true);
      setcopydays([]);
    }, 10);
  };

  const runDaysFunc = (day, info, data, setFieldValue) => {
    let newdata = JSON.parse(JSON.stringify(data))
    if (day === "sunday") {
      info.daysunday = true;
      info.scheduleSunday = newdata;
      // setFieldValue('scheduleSunday', data)

    }
    if (day === "monday") {

      info.daymonday = true;
      // setFieldValue('scheduleMonday', data)
      info.scheduleMonday = newdata;
    }
    if (day === "tuesday") {
      info.daytuesday = true;
      // setFieldValue('scheduleTuesday', data)
      info.scheduleTuesday = newdata;
    }
    if (day === "wednesday") {
      info.daywednesday = true;
      // setFieldValue('scheduleWednesday', data)
      info.scheduleWednesday = newdata;
    }
    if (day === "thursday") {
      info.daythursday = true;
      // setFieldValue('scheduleThursday', data)
      info.scheduleThursday = newdata;
    }
    if (day === "friday") {
      info.dayfriday = true;
      // setFieldValue('scheduleFriday', data)
      info.scheduleFriday = newdata;
    }
    if (day === "saturday") {
      info.daysaturday = true;
      // setFieldValue('scheduleSaturday', data)
      info.scheduleSaturday = newdata;
    }
    setcopydays([])
  };
  const changeHandler = (e, setFieldValue, values) => {
    let value = e.target.value;
    let filtered = sessions.filter((item) => item.sub_session_id == value)

    if (type === "admin") {
      if (filtered.length > 0 && (filtered[0].is_group_session == 1 || filtered[0].is_medium_level) == 1) {
        setShowGroupSession(true);
        setShowSpecificAddDates(true)
        setshowGroupNotification(true)
        setshowpreffered(true)
        checkTypeOfMedium(e, setFieldValue, values);


      }
      else {
        setShowGroupSession(false);
        setShowSpecificAddDates(false)
        setshowGroupNotification(false)
        setshowpreffered(false)
      }
    }

    //
    let find_data = sessions.filter((item) => {
      if (parseInt(item.sub_session_id) === parseInt(value)) {
        return item;
      }
    });

    if (parseInt(find_data[0].participant_level) === 1) {
      setParticipantLevelStatus(true);
    } else {
      setParticipantLevelStatus(false);
    }
    if (parseInt(find_data[0].student_level) === 1) {
      setshowField(true);
    } else {
      setshowField(false);
    }


    setFieldValue("session_type", value);
  };
  const removefirstArray = (item, setFieldValue) => {
    setFieldValue(item, [{ from: "", to: "" }]);
  };

  const removefirstArrayDateSLots = (item, setFieldValue) => {
    setFieldValue(item, [
      { date: "", time: [{ from: "", to: "", time_slot_id: "" }] },
    ]);
    if (item === "specificDateSlots") {
      setspecificFullday(false);
    }
    else {
      setBlockedFullday(false);
    }

  };

  // const removefirstArraySpecificslots = (item, setFieldValue) => {
  //   setFieldValue(item,[{date:"",time:[{from: "", to: "",time_slot_id:""}]}]);
  // };
  const handleTime = (time) => {

    let times = time.map((item) => {
      return {
        from:
          item.from !== ""
            ? item.from.change !== "NO"
              ? formatTimeTo24hrs(item.from.value)
              : "24:00"
            : "",
        to:
          item.to !== ""
            ? item.to.change !== "NO"
              ? formatTimeTo24hrs(item.to.value)
              : "24:00"
            : "",
      };
      // removeString()
    });
    let timed = times.map((item) => { return [item.from, item.to] })
    let newTime = timed.sort(([a], [b]) => a < b ? -1 : 1);
    let updatedTime = newTime.map((item) => {
      return {
        from: item[0],
        to: item[1]
      }
    })
    return updatedTime;
  };
  const handleTime1 = (time) => {
    let times = time.map((item) => {
      return {
        from:
          item.from !== ""
            ? item.from.change !== "NO"
              ? formatTimeTo24hrs(item.from.value)
              : "24:00"
            : "",
        to:
          item.to !== ""
            ? item.to.change !== "NO" && item.to.label !== "12:00 AM"
              ? formatTimeTo24hrs(item.to.value)
              : "24:00"
            : "",
      };

      // removeString()
    });
    let timed = times.map((item) => { return [item.from, item.to] })
    let newTime = timed.sort(([a], [b]) => a < b ? -1 : 1);
    let updatedTime = newTime.map((item) => {
      return {
        from: item[0],
        to: item[1]
      }
    })
    return updatedTime;
  };
  // const handleSpecificDateslots=(data)=>{
  //   let dateslot=data.map((item)=>{
  //    return{
  //     date:item.date,
  //     time:item.time.map((dataItem)=>{
  //       return handleTime(dataItem);
  //     })
  // }})
  // return dateslot;
  // }
  const formatTime = (item) => {
    return moment(`${item}:00`, ["hh:mm"]).format("hh:mm A");
  };

  const formatTimeTo24hrs = (item) => {
    return moment(`${item}`, "hh:mm A").format("HH:mm");
  };

  const getCountryTypes = (type, exclusions, inclusions) => {
    let country;
    if (type === "All Countries") {
      country = newCountryData.map((item) => {
        return item.value;
      });
    }
    if (type === "All Countries with Exclusions") {
      let itemArray = [];

      newCountryData.map((item, index) => {
        itemArray.push(item.value);
      });
      exclusions.map((item, index) => {
        itemArray = removeItemOnce(itemArray, item.value);
      });
      country = itemArray;
    }
    if (type === "Selected Countries only") {
      country = inclusions.map((item) => {
        return item.value;
      });
    }

    return country;
  };

  const changeSigleSession = (value, setFieldValue, values) => {
    let filtered = value.filter((item) => {
      if (item.label.includes("60")) {
        return item;
      }
    })
    console.log(filtered, "filtered")
    if (filtered.length) {
      setActive60minNote(true)
    }
    else {
      setActive60minNote(false)
    }

    // return;
    //
    // let valueGroupSession = parseInt(process.env.REACT_APP_GROUP_SESSION_ID);
    // const specifiedValue = valueGroupSession;
    let array = value;
    const hasValue = array.some(obj => obj.is_group_session === 1);
    // Check if there are other values in the array
    const hasDifferentValues = array.some(obj => obj.is_group_session !== 1);
    // If there are other values and value specifiedValue exists, remove objects with value specifiedValue
    if (hasDifferentValues && hasValue) {
      array = array.filter(obj => obj.is_group_session !== 1);
    }

    value = array;
    if (value && value.length > 0) {
      if (value[0].is_group_session == 1 || value[0].is_medium_level == 1) {
        setShowGroupSession(true);
        setShowSpecificAddDates(true)
        setshowGroupNotification(true)
        setshowpreffered(true);
        // setshowGroup(true);
      } else {
        setShowGroupSession(false);
        setShowSpecificAddDates(false)
        setshowGroupNotification(false)
        // setshowGroup(false)
        setshowpreffered(false);
      }
    }
    else {
      setShowGroupSession(false);
      setShowSpecificAddDates(false)
      setshowGroupNotification(true)
      setshowpreffered(false);
      // setshowGroup(true);
    }
    //





    let getId = value[value.length - 1]
    let body = {
      sub_session_id: getId ? getId.sub_session_id : 0
    }
    checkAlreadyExistService(body).then((data) => {
      if (data.data.data === 0) {

        setAlreadyExist(false);
        setFieldValue("session_type", value);
        setAlreadyExistService()
        setactiveSessionLength(value[0].session_type)






        if (value.length > 0) {
          let info = value.filter((item) => parseInt(item.is_group_session) === 1)
          if (info.length > 0) {

            console.log(info, info.length > 0, "valuevalue.length > 0)")

            checkTypeOfMedium(value, setFieldValue, values);

          }
          else {
            setFieldValue('preferred_medium_level', ["1", "2", "3", "4", "5", "6", "7"])
          }
        }

      }
      else {

        setAlreadyExistService(getId.label)
        setAlreadyExist(true);
      }
    }).catch(() => {

    })
    // setFieldValue("session_type", value);
    const checkUsername = (obj) => parseInt(obj.session_type) === 2;
    if (value.some(checkUsername)) {
      setParticipantLevelStatus(true);
    } else {
      setParticipantLevelStatus(false);
    }
  };

  //specific dates
  const updatdeValues = (info, setFieldValue, body) => {
    //     let end = moment(body.end_date, "YYYY-MM-DD").add(1, "days").format('YYYY-MM-DD')

    // if (body.end_date !== "" && body.end_date !== null) {
    let specific = body.specificDateSlots
    let end = moment(body.end_date, "YYYY-MM-DD").add(1, "days").format('YYYY-MM-DD');
    let start = moment(body.start_date, "YYYY-MM-DD").subtract(1, "days").format('YYYY-MM-DD');
    let specificcheck = specific.every((data) => {
      return ((end !== "" && end !== "Invalid date") ? moment(end).isAfter(data.date) : true) && (start !== "" && moment(start).isBefore(data.date))
    })
    if (!specificcheck) {
      toast.error("Please Select Specific Dates Between start date and end date ", { position: "top-center" });
      return true
    }

    // }
    if (updateType === "ADD") {
      let aa = testValues;
      aa.push(info[0]);

      settestValues(aa);
    }
    if (updateType === "EDIT") {
      let aa = testValues;
      aa[updateTypeIndex] = info[0];
      settestValues(aa);
    }
    setUpdateType("ADD");
    setFieldValue("specificDateSlots", [
      { date: "", time: [{ from: "", to: "", time_slot_id: "" }] },
    ]);
    setspecificFullday(false)
  };

  const getCopiedDates = (dates, index, setFieldValue) => {
    let info = testValues;

    dates.map((date) => {
      info.push({
        date: moment(date).format("YYYY-MM-DD"),
        time: testValues[index].time,
      });
    });
    settestValues(info);
    setFieldValue("specificDateSlots", [
      { date: "", time: [{ from: "", to: "", time_slot_id: "" }] },
    ]);
  };
  const onDeleteSpecificValue = (index, setFieldValue) => {
    let array = testValues;
    if (index > -1) {
      array.splice(index, 1);
    }
    settestValues(array);
    setFieldValue("specificDateSlots", [
      { date: "", time: [{ from: "", to: "", time_slot_id: "" }] },
    ]);
  };

  const onEditSpecificValue = (index, setFieldValue) => {
    setUpdateType("EDIT");
    setFieldValue("specificDateSlots", [testValues[index]]);
    setUpdateTypeIndex(index);
    // setblockedUpdateTypeIndex(index);
    setShowSpecificAddDates(true);
  };

  //blocked dates
  const getBlockedCopiedDates = (dates, index, setFieldValue) => {
    let info = blockedDatesValues;

    dates.map((date) => {
      info.push({
        date: moment(date).format("YYYY-MM-DD"),
        time: blockedDatesValues[index].time,
      });
    });
    setBlockedDatesValues(info);
    setFieldValue("blockedDateSlots", [
      { date: "", time: [{ from: "", to: "", time_slot_id: "" }] },
    ]);
  };
  const onEditBlockedValue = (index, setFieldValue) => {
    setUpdateblockedType("EDIT");
    setFieldValue("blockedDateSlots", [blockedDatesValues[index]]);
    // setUpdateTypeIndex(index);
    setblockedUpdateTypeIndex(index);
    setShowBlockedAddDates(true);
  };
  const updatdeBlockedValues = (info, setFieldValue, body) => {

    // if (body.end_date !== "" && body.end_date !== null) {
    let block = body.blockedDateSlots
    let end = moment(body.end_date, "YYYY-MM-DD").add(1, "days").format('YYYY-MM-DD');
    let start = moment(body.start_date, "YYYY-MM-DD").subtract(1, "days").format('YYYY-MM-DD');
    let blockdate = block.every((data) => {
      return ((end !== "" && end !== "Invalid date") ? moment(end).isAfter(data.date) : true) && (start !== "" && moment(start).isBefore(data.date))
    })

    if (!blockdate) {
      toast.error("Please Select blocked Dates Between start date and end date ", { position: "top-center" });
      return true
    }

    // }
    if (blockedupdateType === "ADD") {
      let aa = blockedDatesValues;
      aa.push(info[0]);
      setBlockedDatesValues(aa);
    }
    if (blockedupdateType === "EDIT") {
      let aa = blockedDatesValues;
      aa[blockedupdateTypeIndex] = info[0];
      setBlockedDatesValues(aa);
    }
    setUpdateblockedType("ADD");
    setFieldValue("blockedDateSlots", [
      { date: "", time: [{ from: "", to: "", time_slot_id: "" }] },
    ]);
    setBlockedFullday(false)
  };
  const onDeleteBlockedValue = (index, setFieldValue) => {
    let array = blockedDatesValues;
    if (index > -1) {
      array.splice(index, 1);
    }
    setBlockedDatesValues(array);
    setFieldValue("blockedDateSlots", [
      { date: "", time: [{ from: "", to: "", time_slot_id: "" }] },
    ]);
  };
  function setCharAt(str, index, chr) {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
  }

  const customFilter = (option, searchText) => {
    // const check = (option, searchText) => {

    let searchingtime = option.data.value === "11:59 PM" ? "12:00 AM" : option.data.value;
    let status = true;
    if (searchText !== "") {
      let case1 = searchingtime.indexOf("0") === 0;
      let case2 = searchingtime.indexOf("1") === 0;
      let case10 = searchingtime.indexOf("1") === 0 && searchingtime.indexOf("0") === 1 && case10Array.includes(searchingtime.replaceAll(':', "").replaceAll(" ", ""));

      let newSearchTime = searchText.replaceAll(":", "").replaceAll(" ", "");
      if (newSearchTime[0] === "1" && newSearchTime[1] === "0" && newSearchTime[2] === "1" || newSearchTime[2] === "3" || newSearchTime[2] === "4") {

        // let newSearchTime = searchingtime.replaceAll(":", "").replaceAll(" ", "");
        // if (newSearchTime[2] === "1" || newSearchTime[2] === "3" || newSearchTime[3] === "4") {
        // let newSting1 = setCharAt(searchingtime, 3, "");
        // let newSting = searchText[3] !== "0" ? setCharAt(newSting1, 3, "") : searchingtime;

        status =
          newSearchTime[0] == searchingtime[0] &&
          newSearchTime[1] == searchingtime[1] &&
          newSearchTime[2] == searchingtime[3]

        // !case1
        // newSting
        //   // .replaceAll(":", "")
        //   .replaceAll(" ", "")
        //   .toLowerCase()
        //   .includes(searchText.toLowerCase());
      }
      else {


        if (case1) {
          status =
            (searchingtime
              .replaceAll(":", "")
              .replaceAll("0", "")
              .replaceAll(" ", "")
              .toLowerCase()
              .includes(searchText.toLowerCase()) &&
              searchText[0] === searchingtime[1]) || (searchingtime
                .replaceAll(" ", "")
                .toLowerCase()
                .includes(searchText.toLowerCase()) &&
                searchText[0] === searchingtime[1]);
        } else if (case2 && !case1) {
          let catchstatus = false;
          if (searchingtime[0] === "1" && searchingtime[1] === "0" && searchText[2] !== ":") {
            let newSting1 = setCharAt(searchingtime, 3, "");

            let newSting = setCharAt(newSting1, 3, "");

            catchstatus =
              searchText[0] === searchingtime[0] &&
              searchText[1] === searchingtime[1] &&
              !case1 &&
              newSting
                .replaceAll(":", "")
                .replaceAll(" ", "")
                .toLowerCase()
                .includes(searchText.toLowerCase());
          }
          else if (searchingtime[0] === "1" && searchingtime[1] === "0" && searchingtime[2] === ":") {

            let newSting1 = setCharAt(searchingtime, 3, "");

            let newSting = searchText[3] !== "0" ? setCharAt(newSting1, 3, "") : searchingtime;

            catchstatus =
              searchText[0] === searchingtime[0] &&
              searchText[1] === searchingtime[1] &&
              !case1 &&
              newSting
                // .replaceAll(":", "")
                .replaceAll(" ", "")
                .toLowerCase()
                .includes(searchText.toLowerCase());
          }
          /////


          /////
          // if (option.data.value[0] === '1' && option.data.value[1] === '0' && (option.data.value === '10:00 AM' || option.data.value === '10:00 PM')) {
          //   catchstatus = searchText[0] === option.data.value[0] && searchText[1] === option.data.value[1] && !case1 &&
          //     option.data.value.replaceAll(':', '').replaceAll(' ', '').toLowerCase().includes(searchText.toLowerCase())
          // }
          else if (searchText[0] === searchingtime[0] &&
            searchText[1] === searchingtime[1] &&
            !case1 && searchText[2] !== ":") {
            catchstatus = catchstatus =
              searchText[0] === searchingtime[0] &&
              searchText[1] === searchingtime[1] &&
              !case1 &&
              searchingtime
                .replaceAll(":", "")
                .replaceAll("0", "")
                .replaceAll(" ", "")
                .toLowerCase()
                .includes(searchText.toLowerCase());
          }
          else {
            catchstatus = catchstatus =
              searchText[0] === searchingtime[0] &&
              searchText[1] === searchingtime[1] &&
              !case1 &&
              searchingtime
                // .replaceAll(":", "")
                // .replaceAll("0", "")
                .replaceAll(" ", "")
                .toLowerCase()
                .includes(searchText.toLowerCase());
          }
          status = catchstatus;
          // status = searchText[0] ===  option.data.value[0]
        }
        // else if(case10){
        //   status = true
        // }
        else {
          status = false;
        }
      }
    }
    // return status;
    let case2 =
      searchingtime
        .replaceAll(":", "")
        .replace("0", "")
        .indexOf(searchText.toLowerCase()) === 0;
    // }

    if (
      status
      // option.data.value.toLowerCase().includes(searchText.toLowerCase()) ||
      //   // option.data.val.toLowerCase().includes(searchText.toLowerCase()) ||
      //  option.data.value.replaceAll(':','').replace('0','').indexOf(searchText.toLowerCase()) === 0
      // `${option.data.value.split(':')[0]}AM`.toLowerCase().includes(searchText.toLowerCase()) ||
      // `${option.data.value.split(':')[0]}PM`.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getNewList = (value) => {
    let newArray = [];
    let filternewList = [];
    newArray = slotsList.filter(
      (option, index) =>
        formatTimeTo24hrs(
          option.value
        ) >
        formatTimeTo24hrs(
          value.value
        )
    )
    filternewList = slotsList.filter(
      (option, index) =>
        formatTimeTo24hrs(
          option.value
        ) <
        formatTimeTo24hrs(
          value.value
        )
    )

    let array = filternewList.reverse();
    let arr = newArray.concat(array);
    setSlotsList(arr);
  }

  const checkTimeDifferenceBetweenTime = (values, prevVal, value, dir, day) => {
    let status = true;
    console.log(values, prevVal, value, dir, day)

    if (values.session_type !== "" && dir == "to"  && day !== 'blockedDateSlots') {
      const timeToMinutes = (time) => {
        let [hours, minutes] = time.split(':');
        minutes = parseInt(minutes.slice(0, 2));
        const period = time.slice(-2);
        hours = parseInt(hours);

        if (period === "PM" && hours !== 12) {
          hours += 12;
        } else if (period === "AM" && hours === 12) {
          hours = 0;
        }

        return hours * 60 + minutes;
      };
      const startlabel = prevVal.label;
      const endlabel = value.label
      let array = []
      if (type == "admin") {
        let filter = sessions.filter((row) => parseInt(row.sub_session_id) == parseInt(values.session_type));
        array = filter
      }
      else {
        array = values.session_type
      }
      console.log(values.session_type, "values.session_type", sessions, type)
      const buffer_time = parseInt(values.buffer_time) // in minutes
      array.map((item) => {
        const duration = parseInt(item.duration); // in minutes
        console.log(buffer_time, "buffer_time");
        console.log(duration, "duration")
        const startMinutes = timeToMinutes(startlabel);
        console.log(startMinutes, "startMinutes")
        const endMinutes = timeToMinutes(endlabel);
        console.log(endMinutes, "endMinutes")
        const timeDifference = endMinutes - startMinutes;

        const totalTime = duration + buffer_time;

        const isTimeSufficient = timeDifference >= totalTime;

        console.log(isTimeSufficient, "isTimeSufficient")
        status = isTimeSufficient;
      })



      // Function to convert time from "HH:MM AM/PM" to minutes


    }
    return status;
    // console.log(values.buffer_time, "buffer_time", values)

    // console.log(values.session_type)
  }
  const customChangeHandler
    = (
      index,
      value,
      setFieldValue,
      name,
      values,
      day,
      dir,
      prevVal
    ) => {
      console.log(
        value,

        day,
        dir, prevVal);
      let status = checkTimeDifferenceBetweenTime(values, prevVal, value, dir, day);
      console.log(status, "status", "status")
      if (status == true) {


        if (dir === "to" && prevVal !== undefined && checkIsGroupSessionTitles(values, "group", "normal") == "group") {

          let cu = value.value;
          let prev = prevVal.value;
          console.log(cu, prev)
          var startTime = moment(prev, 'HH:mm a');
          var endTime = moment(cu, 'HH:mm a');

          var duration = moment.duration(endTime.diff(startTime));
          var minutes = duration.asMinutes();
          console.log(minutes, "hours")
          if (minutes > 120) {
            setTimeout(() => {

              window.loadModal('#ConfirmTime', 'show')
            }, 300);
          }
        }
        setFieldValue(name, value);
        // getNewList(value);
        // let currentArrayValue = data

        if (
          (day === "specificDateSlots" || day === "blockedDateSlots") &&
          dir === "to"
        ) {
          // let data = values[day][0].time;

          // let updatedValue = data[index]
          // dir === "from" ? (updatedValue.from = value) : (updatedValue.to = value);
          // let newValues = evaluate(data, setFieldValue, day);

          // let update = [{ date: values[day][0].date, time: newValues }];

          // setFieldValue(day, update);

        }
        console.log(day,"day day")
        if (
          dir === "to" &&
          (day !== "specificDateSlots" && day !== "blockedDateSlots")
        ) {
          let data = values[day];

          let updatedValue = data[index]
          dir === "from" ? (updatedValue.from = value) : (updatedValue.to = value);
          // makeloop(data, updatedValue, setFieldValue, day)
          let newValues = evaluate(data, setFieldValue, day);
          setFieldValue(day, newValues);
          // compareObjects(data, updatedValue, setFieldValue, day);
        }
      }
      else {
        setTimeDifferenceError(true);

        setTimeout(() => {
          setTimeDifferenceError(false);
        }, 3500);
      }
    };

  const onMenuOpen = () => {
    setTimeout(() => {
      // const focusedOptionRef = document.getElementsByClassName("css-9gakcf-option")[0];
      const cala = document.getElementsByClassName("css-4ljt47-MenuList")[0];


      // focusedOptionRef.scrollIntoView({block: "start", inline: "nearest",behavior:});
      // focusedOptionRef.parentNode.scrollTop = focusedOptionRef.offsetTop - focusedOptionRef.parentNode.offsetTop;    to to back 
    }, 2000);
  };

  // const scrollMenuToTarget = () => {


  //   setTimeout(() => {

  //     // const { focusedOptionRef } = selectRef.current;
  //   const focusedOptionRef = document.getElementsByClassName("css-yt9ioa-option")[25];

  //     focusedOptionRef &&
  //       focusedOptionRef.scrollIntoView({ behavior: "smooth" });
  //   }
  //   , 1);
  // };

  const scrollMenuToTarget = (dir, day) => {
    if (dir === "from") {
      setTimeout(() => {
        // const cala = document.getElementsByClassName("css-11unzgr")[0]; //to be back
        const cala = document.getElementsByClassName("css-4ljt47-MenuList")[0];
        //  to be back

        // let elemHeight = cala.offsetHeight;


        //  const selectedValue=document.getElementsByClassName("css-1n7v3ny-option");
        //  let valueHeight=selectedValue.offsetHeight;



        // document.querySelector(".output").textContent
        //     = elemHeight + "px";
        // css-4ljt47-MenuList

        // css-11unzgr"
        if (window.innerWidth < 500) {
          if (day !== undefined) {
            cala.scrollTop = 930;
          }
          else {

            cala.scrollTop = 1140;
          }
        } else {
          if (day !== undefined) {
            cala.scrollTop = 1500;
          }
          else {

            cala.scrollTop = 1140;
          }
        }
      }, 200);
    }
    if (dir === "spec_block_date") {
      setTimeout(() => {
        // const cala = document.getElementsByClassName("css-11unzgr")[0]; // to be back
        const cala = document.getElementsByClassName("css-4ljt47-MenuList")[0];
        // to be back
        if (window.innerWidth < 500) {
          if (day !== undefined) {
            cala.scrollTop = 1200;
          }
          else {

            cala.scrollTop = 1140;
          }
        } else {
          if (day !== undefined) {
            cala.scrollTop = 1138;
          }
          else {

            cala.scrollTop = 1140;
          }
        }
      }, 200);
    }
  };
  const makeloop = (mainArray) => {
    const check = (time) => {
      return moment(time, "hh:mm:a").format("X");
    };
    mainArray.forEach((outerValue, index) => {
      let currentFromValue = check(outerValue.from.value);
      let currentToValue = check(outerValue.to.value);
      mainArray.forEach((innerValue, innerIndex) => {
        let loopFromValue = check(innerValue.from.value);
        let loopToValue = check(innerValue.to.value);

        if (
          currentFromValue < loopFromValue &&
          currentToValue < loopFromValue &&
          currentToValue < loopToValue
        ) {
          console.log("Index TO update", index);
          console.log("case 2");

          mainArray[index].from = outerValue.from;
        } else if (
          currentFromValue < loopFromValue &&
          currentToValue > loopToValue
        ) {
          mainArray[index].from = outerValue.from;
          mainArray[index].to = outerValue.to;
          console.log("Index TO update", index);
          console.log("case 3");
        } else if (
          currentFromValue < loopFromValue &&
          currentFromValue < loopToValue &&
          currentToValue < loopFromValue &&
          currentToValue < loopToValue
        ) {
          //Nothing
          console.log("Index TO update", index);
          console.log("case 4");
        } else if (
          currentFromValue < loopFromValue &&
          currentFromValue < loopToValue &&
          currentToValue > loopToValue
        ) {
          mainArray[index].to = outerValue.to;
          console.log("Index TO update", index);
          console.log("case 5");
        } else if (
          currentFromValue > loopFromValue &&
          currentToValue > loopFromValue &&
          currentToValue < loopToValue
        ) {
          //Nothing
          console.log("Index TO update", index);
          console.log("case 6");
          console.log(mainArray);
          return 0;
        } else if (
          currentFromValue > loopFromValue &&
          currentFromValue < loopToValue &&
          currentToValue > loopToValue
        ) {
          mainArray[index].to = outerValue.to;
          console.log("Index TO update", index);
          console.log("case 7");
        } else if (
          currentFromValue < loopFromValue &&
          currentToValue > loopFromValue &&
          currentToValue < loopToValue
        ) {
          mainArray[index].from = outerValue.from;
          console.log("Index TO update", index);
          console.log("case 8");
        }
      });
    });
  };
  const consolidateArray = (index, childIndex, array, updatedValue, dir) => {
    if (index !== childIndex) {
      array[index][dir] = updatedValue;
    }
  };

  const filterMoment = (time) => {
    return moment(moment(time, ["h:mm A"]).format("HH:mm"), "HH:mm");
  };
  const evaluate = (state, setFieldValue, day) => {
    //filter state

    const filteredState = [];
    for (let i in [...state]) {
      const entry = { ...state[i] };
      const from = moment(entry.from.value, ["h:mm a"]).format("HHmm");
      const to = moment(entry.to.value, ["h:mm a"]).format("HHmm");

      let isExist = false;

      for (let key in state) {
        const s = state[key];

        if (filteredState.length === 0) {
          isExist = false;
          break;
        }

        if (i !== key) {
          const from1 = moment(s.from.value, ["h:mm a"]).format("HHmm");
          const to1 = moment(s.to.value, ["h:mm a"]).format("HHmm");

          if (
            parseInt(from) === parseInt(from1) &&
            parseInt(to) === parseInt(to1)
          ) {
            isExist = true;
            break;
          } else {
            isExist = false;
            continue;
          }
        }
      }
      if (!isExist) {
        filteredState.push(entry);
      }
    }

    let currentValues = [...filteredState];
    let newValues = [...filteredState];

    for (let i in currentValues) {
      const d = currentValues[i];
      for (let ind in newValues) {
        const e = newValues[ind];
        if (i !== ind) {
          if (
            moment(filterMoment(d.from.value)).isBetween(
              filterMoment(e.from.value),
              filterMoment(e.to.value)
            ) &&
            moment(filterMoment(d.to.value)).isBetween(
              filterMoment(e.from.value),
              filterMoment(e.to.value)
            )
          ) {
            newValues[i]["disabled"] = true;
            break;
          }

          // if to date is after
          if (
            (moment(filterMoment(d.from.value)).isBetween(
              filterMoment(e.from.value),
              filterMoment(e.to.value)
            ) ||
              moment(filterMoment(d.from.value)).isSame(
                filterMoment(e.from.value)
              )) &&
            (moment(filterMoment(d.to.value)).isAfter(
              filterMoment(e.to.value)
            ) ||
              moment(filterMoment(d.to.value)).isSame(filterMoment(e.to.value)))
          ) {
            console.log('line 1043')
            newValues[ind].to = d.to;
            newValues[ind]["disabled"] = false;
            newValues[i]["disabled"] = true;
            break;
          }

          // if from date is smaller
          if (
            (moment(filterMoment(d.to.value)).isBetween(
              filterMoment(e.from.value),
              filterMoment(e.to.value)
            ) ||
              moment(filterMoment(d.to.value)).isSame(
                filterMoment(e.to.value)
              )) &&
            (moment(filterMoment(d.from.value)).isBefore(
              filterMoment(e.from.value)
            ) ||
              moment(filterMoment(d.from.value)).isSame(
                filterMoment(e.from.value)
              ))
          ) {
            console.log('line 1067')
            newValues[ind].from = d.from;
            newValues[ind]["disabled"] = false;
            newValues[i]["disabled"] = true;
            break;
          }
          if (
            moment(filterMoment(d.to.value)).isSame(filterMoment(e.from.value))
            && d.to.change === 'YES') {
            newValues[i].to = e.to;
            break;
            // d.to.value = e.from.value
            // console.log(d.from.value, "d.from.value")
            // console.log(d.to.value, "d.to.value")
            // console.log(e.to.value, "e.to.calue");
            // console.log(e.from.value, "e.from.valie")
          }
        }
      }
    }

    newValues = newValues.filter((v) => !v.disabled);

    return newValues;
  };
  const onChangeFulldayHandler = (e, type, values, setFieldValue) => {
    let checked = e.target.checked;
    if (type === "blocked") {
      setBlockedFullday(!blockedfullday)
    }
    if (type === "specific") {
      setspecificFullday(!specificfullday)
    }
    let first = slotsList[0];
    let last = slotsList[slotsList.length - 1];
    if (type === "specific") {
      let value = values.specificDateSlots[0];
      if (!specificfullday) {
        let time = value.time[0];
        time.from = first;
        time.to = last;
      } else {
        let value = values.specificDateSlots[0];
        let time = value.time[0];
        time.from = "";
        time.to = "";
      }
      setFieldValue("specificDateSlots", [value]);
    } else if (type === "blocked") {
      let value = values.blockedDateSlots[0];
      if (!blockedfullday) {
        let time = value.time[0];
        time.from = first;
        time.to = last;
      } else {
        let value = values.blockedDateSlots[0];
        let time = value.time[0];
        time.from = "";
        time.to = "";
      }
      setFieldValue("blockedDateSlots", [value]);
    }
  };
  const onCustomdateChangeHandler = (e, type, name, setFieldValue) => {
    if (type === 'specific') {
      let check = testValues.some((data => data.date === e.target.value))
      if (!check) { setFieldValue(name, e.target.value) } else {
        toast.error("Date Already exist", { position: "top-center" });
      }
    }
    if (type === 'blocked') {
      let check = blockedDatesValues.some((data => data.date === e.target.value))
      if (!check) { setFieldValue(name, e.target.value) } else {
        toast.error("Date Already exist", { position: "top-center" });
      }
    }
  }


  const unitChangeHandler = (e, setFieldValue) => {
    let value = e.target.value
    setUnitValue(e.target.value);
    setTimeout(() => {


      if (value === "Days") {
        setFieldValue('min_booking_days', '01');
        setFieldValue('min_booking_hour', '');
        setFieldValue('min_booking_time', '');
      }
      if (value === "Hours") {
        setFieldValue('min_booking_days', '');
        setFieldValue('min_booking_hour', '01');
        setFieldValue('min_booking_time', '');
      }
      if (value === "Minutes") {
        setFieldValue('min_booking_days', '');
        setFieldValue('min_booking_hour', '');
        setFieldValue('min_booking_time', '15');
      }

    }, 100)
  }
  const checkUnitType = (value) => {
    if (value.min_booking_days !== "00" && value.min_booking_days !== "") {
      setUnitValue('Days')
    }
    if (value.min_booking_time !== "00" && value.min_booking_time !== "") {
      setUnitValue('Minutes')
    }
    if (value.min_booking_hour !== "00" && value.min_booking_hour !== "") {
      setUnitValue('Hours')
    }
  }
  const checkOfflineStatus = (body) => {
    console.log(body, "bodeu");
    let session = body.session_type[0];
    if (session.sub_session_id === 78) {
      window.loadModal('#ConfirmOffline', 'show');
      setTemporaryStorage(body)
    }
    else {
      console.log(body.session_type.length)

      // if (body.session_type.length === 1) {
      //   localStorage.setItem('showpreviewValue', body.session_type[0].sub_session_id)
      //   setTemporaryStorage(body)
      //   window.loadModal2('#PreviewCheckbox', 'show');
      // }
      // else {
      submitForm(body)
      // }


    }

    // submitForm(body);
  }
  const confirmFormRequest = () => {
    console.log(temporaryStorage, "temporaryStorage")
    submitForm(temporaryStorage)
  }
  const closePopup = () => {
    window.loadModal('#ConfirmOffline', 'hide');
  }

  const checkTypeOfMedium = (e, setFieldValue, values) => {

    // { id: 1, name: "Novice I" },
    // { id: 2, name: "Intermediate I" },
    // { id: 3, name: "Professional I" },
    // { id: 4, name: "Novice II" },
    // { id: 5, name: "Intermediate II" },
    // { id: 6, name: " Professional II" },
    // { id: 7, name: "Professional III" },
    let value = []
    console.log('')

    // setFieldValue('preferred_medium_level', [])
    // value.push(level.toString())

    values.preferred_medium_level = value
    // console.log(newInfo)
    // console.log(initialValues,"initialValues")
    // if (level === 1 || level === 4) {
    //   initialValues.preferred_medium_level = ["1"]
    //   value = ["1",]
    // }
    // else if (level === 2 || level === 5) {
    //   initialValues.preferred_medium_level = ["2"]
    //   value = ["2",]

    // }
    // else if (level === 3 || level === 6 || level === 7) {
    //   initialValues.preferred_medium_level = ["3"]
    //   value = ["3"]


    // }
    // if (window.valueupdated) {
    //   window.valueupdated = false;
    // setValueUpdated(false);
    console.log(value)
    console.log(values)
    setFieldValue('preferred_medium_level', value)

    // }
    // setValues(initialValues);

  }
  const checkPreferedType = (type, values, setFieldValue, setshowpreffered) => {
    // console.log(num === 3 && (level === 3 || level === 6 || level === 7), num, "num")
    let status = true;

    if (type === "admin") {
      let filtered = sessions.filter((item) => item.sub_session_id == values.session_type)
      if (filtered.length > 0 && filtered[0].is_group_session == 1) {
        // checkTypeOfMedium(setFieldValue)

        status = false
        // setshowpreffered(true);

      }
      else if (filtered[0].is_medium_level == 1) {
        status = false
        // setshowpreffered(true);

      }
      else {
        status = true
        // setshowpreffered(false);
      }
    }
    else {

      if (typeof values.session_type === "object" && values.session_type.length > 0) {
        let info = values.session_type.filter((item) => parseInt(item.is_group_session) === 1)
        let info1 = values.session_type.filter((item) => parseInt(item.is_medium_level) == 1)
        console.log(info1, "info1", values)
        if (info.length > 0) {
          // checkTypeOfMedium(setFieldValue)

          status = false
          // setshowpreffered(true);

        }
        else if (info1.length > 0) {
          status = false
          // setshowpreffered(true);
        }
        else {
          status = true
          // setshowpreffered(false);
        }
      }
    }
    // type === "admin" ? values.session_type !== "64" : values.session_type == "" ? true : values.session_type.length > 0 && values.session_type.filter((item) => item.value === 64)[0].value !== 64)

    return status;

  }

  const checkPreferedMediums = (values) => {


    // let newItem = items.filter((item) => { if (item.checked === true) return { ...item.value } })
    // let newArray = newItem.map((item) => item.value)

    let partner = values.preferred_medium_level;
    if (partner.includes("1")) {
      if (!partner.includes("4")) {
        partner.push("4")
      }
    }
    else {
      let index = partner.indexOf('4');
      if (index !== -1) {
        partner.splice(index, 1);
      }
    }
    if (partner.includes("2")) {
      if (!partner.includes("5")) {
        partner.push("5")

      }
    }
    else {
      let index = partner.indexOf('5');
      if (index !== -1) {
        partner.splice(index, 1);
      }
    }
    if (partner.includes("3")) {
      if (!partner.includes("6")) {
        partner.push("6")
      }
      if (!partner.includes("7")) {
        partner.push("7")
      }

    }
    else {
      let index1 = partner.indexOf('6');
      let index2 = partner.indexOf('7');
      if (index1 !== -1) {
        partner.splice(index1, 1);
      }
      if (index2 !== -1) {
        partner.splice(index2, 1);
      }
    }
    return partner;
  }

  const changeHna = (e, setFieldValue) => {
    let value = e.target.value;
    let newVal = value.replace(/\D+/g, '')
    console.log(newVal, "newVal")
    if (newVal < 0 || newVal > 100) {
      return;
    }
    else {
      setFieldValue('max_attendees', newVal);
      // setUpMarkupValu(newVal);
    }
  }
  const checkIsGroupSessionTitles = (values, groupTitle, OtherTitle) => {
    if (type !== "admin") {
      if (values.session_type.length > 0 && values.session_type.some((item) => parseInt(item.is_group_session) === 1)) {
        return groupTitle
      }
      else {
        return OtherTitle
      }
    }
    else {
      let items = sessions.length && sessions.filter((item) => parseInt(item.sub_session_id) === parseInt(values.session_type))
      console.log(items)
      if (items.length && items[0].is_group_session === 1) {
        return groupTitle
      }
      else {
        return OtherTitle
      }
    }


  }

  const closePreviewCheckbox = () => {
    window.loadModal2('#PreviewCheckbox', 'hide');
    submitForm(temporaryStorage)

  }

  const openPreviewCheckbox = () => {
    localStorage.setItem('showpreview', 'showpreview')
    submitForm(temporaryStorage)

  }
  return !isValueLoded ? (

    <div className="d-flex align-items-center justify-content-center h-100">
      Loading...
    </div>
  ) : (
    <>
      {timeDifferenceError && <div className="time-difference-popup">
        Increase your set available hours to account for Session Duration and Buffer Time together.
      </div>}
      <div className="add-availability-window-box margin-botton-200px margin-botton-50px">
        <div className="hadding-window">
          <span onClick={cancelForm} className="pointer">
            <span>
              <img src="images/arrow-back.png" />
              Back to Schedules
            </span>
          </span>
        </div>
        {auth.role === "medium" && <div className="middle-screen-name time-zone-area font-size-22 mt-2 mb-2">
          {" "}
          <select
            onChange={changeTimeZoneHandler}
            className="select-box-timezone pointer"
          >
            <option disabled selected value="">
              Change Timezone
            </option>
            {timezoneData.map((item) => (
              <option value={item.id}>
                {item.front_name}
              </option>
            ))}
          </select>
          <b>Timezone: {timezone !== undefined && timezone}&nbsp;</b>
        </div>}
        {auth.role === "admin" && <div className="middle-screen-name time-zone-area font-size-22 mt-2 mb-2">
          {" "}

          <b>Medium's Timezone: {screenNameDetails !== null && screenNameDetails.time_zone}&nbsp;</b>
        </div>}
        <Formik
          initialValues={values}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
          validate={(values) => {
            const errors = {};
            if (values.session_type.length <= 0)
              errors.session_type = "Required";
            if (!values.start_date) errors.start_date = "Required";
            if (values.country_type === "All Countries with Exclusions" && values.exclusions.length <= 0) errors.exclusions = "Required";
            if (values.country_type === "Selected Countries only" && values.inclusions.length <= 0) errors.inclusions = "Required";
            if (type !== "admin" && values.session_type.filter((item) => { return item.session_type === 1 }).length > 0) {
              if (!values.preferred_medium_level.length) {
                errors.preferred_medium_level = "Required"
              }
            } else {
              console.log(errors);
            }

            setErrorMessages(errors)
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            console.log(errors)
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            // let preferred_medium = checkPreferedMediums(values).sort();
            // console.log(preferred_medium, "preferred_medium")
            // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            let getSessionType = sessions.filter((item) => {
              if (schedule_id === "" ?
                parseInt(item.sub_session_id) === parseInt(values.session_type) :
                parseInt(item.sub_session_id) === parseInt(values.session_type)
              ) {
                return {
                  session_type: item.session_type,
                  sub_session_id: item.sub_session_id,
                };
              }
            });
            let countryType = getCountryTypes(
              values.country_type,
              values.exclusions,
              values.inclusions
            );
            let flag_hours =
              parseInt(
                values.min_booking_hour !== "" ? values.min_booking_hour : 0
              ) +
              parseInt(
                parseInt(
                  values.min_booking_days !== "" ? values.min_booking_days : 0
                ) * 24
              );

            let flag_hoursmax = parseInt(
              parseInt(
                values.max_booking_days !== "" ? values.max_booking_days : 0
              ) * 24
            );
            let getdATA =
              values.min_booking_time !== ""
                ? `${flag_hours}.${values.min_booking_time}`
                : flag_hours;
            let getdATAMax =
              values.max_booking_days !== "" &&
                values.max_booking_days !== null &&
                values.max_booking_days !== NaN
                ? parseInt(values.max_booking_days) * 24
                : 1440;
            let body = {
              ...values,
              preferred_medium_level: JSON.stringify(values.preferred_medium_level),
              medium_availability_id: mediumAvailabilityId,
              //
              //
              //

              session_type:
                type === "admin" ? getSessionType : values.session_type,
              meeting_preferences:
                values.meeting_preferences_meeting.length > 0 &&
                values.meeting_preferences_meeting.map((item) => {
                  return item.value;
                }),

              recording: values.recording,
              min_book_time: getdATA,
              max_book_time: getdATAMax,
              medium_id: medium_id,
              participant_preferences: countryType,
              specificDateSlotsdata:
                testValues.length &&
                  testValues[0].date !== "" &&
                  testValues[0].time.length > 0
                  ? JSON.stringify(
                    testValues.map((item) => {
                      return { date: item.date, time: handleTime1(item.time) };
                    })
                  )
                  : JSON.stringify([]),

              blockedSpecificSlotsData:
                blockedDatesValues.length &&
                  blockedDatesValues[0].date !== "" &&
                  blockedDatesValues[0].time.length > 0
                  ? JSON.stringify(
                    blockedDatesValues.map((item) => {
                      return { date: item.date, time: handleTime1(item.time) };
                    })
                  )
                  : JSON.stringify([]),
              schedule: JSON.stringify({
                sunday: handleTime(values.scheduleSunday),
                monday: handleTime(values.scheduleMonday),
                tuesday: handleTime(values.scheduleTuesday),
                wednesday: handleTime(values.scheduleWednesday),
                thursday: handleTime(values.scheduleThursday),
                friday: handleTime(values.scheduleFriday),
                saturday: handleTime(values.scheduleSaturday),
              }),
              receive_email: values.receive_email ? 1 : 0,
              cancellation_email: values.cancellation_email ? 1 : 0
            };


            checkOfflineStatus(body)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>

              <div className="chosse-name-selectde">
                <label>
                  Choose Session Name<i>*</i>
                </label>
                <div class="select-dropdown">
                  {type === "admin" ? (
                    <div>
                      <select
                        name="session_type"
                        onChange={(e) => changeHandler(e, setFieldValue, values)}
                        id="session_type"
                        // disabled={schedule_id === "" ?false:true}
                        defaultValue={
                          values.session_type !== ""
                            ? values.session_type
                            : setFieldValue(
                              "session_type",
                              sessions.filter(
                                (option) =>
                                  option.sub_session_name === "Offline"
                              )[0].sub_session_id
                            )
                        }
                        value={values.session_type}
                        className={`${errors.session_type &&
                          touched.session_type &&
                          errors.session_type
                          ? "error-boundry"
                          : ""
                          }`}
                      >
                        <option selected disabled value="">
                          session type
                        </option>

                        {sessions.map((item) => (
                          <option value={item.sub_session_id}>
                            {item.sub_session_name}
                          </option>
                        ))}
                      </select>
                      <span className="error">
                        {errors.session_type &&
                          touched.session_type &&
                          errors.session_type
                          ? "Please Choose Session Name"
                          : ""}
                      </span>

                    </div>
                  ) : (
                    <>
                      <div>
                        <Select
                          id="session_type"
                          // className="assign-level-select"
                          // isDisabled={schedule_id === "" ?false:true}
                          options={sessions}
                          isMulti
                          onChange={(value) =>
                            changeSigleSession(value, setFieldValue, values)
                          }
                          placeholder={'Add services here'}
                          value={values.session_type

                          }

                          closeMenuOnSelect={true}
                          closeMenuOnScroll={false}
                          className={`${errors.session_type &&
                            touched.session_type &&
                            errors.session_type
                            ? "error-boundry"
                            : ""
                            }`}
                        />
                        <span className="error">
                          {errors.session_type &&
                            touched.session_type &&
                            errors.session_type
                            ? "Please Choose Session Name"
                            : ""}
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="red font-size-14 mt-2">{alreadyExist && `*Schedule already added for the ${alreadyExistService} service with no end date. Please add an end date to the existing schedule to add a schedule for a later period of time.`}</div>
                {active60minNote && <div className="red font-size-14 mt-2">  <i>*</i>&nbsp;Note: We recommend having a paid Zoom account for any sessions that are 45 minutes or longer.
                  <br />
                  &nbsp; If you have a free Zoom account, expect your session to be cut off after 40 minutes into the session. You can leave and reenter the same link to continue your session.</div>}
              </div>

              {/****************** */}

              {showGroupNotification && (
                <div className="calendar-box-new">
                  <div id="accordion">
                    <div class="card">
                      <div class="card-header" id={"heading-0"}>
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            href={"#collapse-0"}
                            aria-expanded="false"
                            aria-controls={"collapse-0"}
                          >
                            {""}
                            {"Notification Settings"}{" "}
                          </a>
                        </h5>
                        {/* <div className="scheduling-preferences-saction">
                <h3>Scheduling Preferences</h3> */}
                        <div
                          id={"collapse-0"}
                          class="collapse"
                          data-parent="#accordion"
                          aria-labelledby={"heading-0"}
                        >
                          <div class="card-body pt-4">
                            <div class="form-group d-flex align-items-center">
                              <input
                                onChange={handleChange}
                                type="checkbox"
                                // value={1}
                                name="receive_email"
                                id="receive_email"
                                className="notofication-setting-box"
                                checked={values.receive_email}
                              />
                              <label
                                className="mr-3 margin-unset"
                                for="receive_email"
                              >
                                Receive email whenever a new medium books a group session
                              </label>
                            </div>
                            <div class="form-group d-flex align-items-center">
                              <input
                                onChange={handleChange}
                                type="checkbox"
                                value={"cancellation_email"}
                                name="cancellation_email"
                                id="cancellation_email"
                                className="notofication-setting-box"
                                checked={values.cancellation_email}
                              />
                              <label
                                className="mr-3 margin-unset"
                                for="cancellation_email"
                              >
                                Cancellation emails for group sessions
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              )}


              {/***************** */}
              {/***************Appointment setting start */}
              <div className="calendar-box-new">
                <div id="accordion">
                  <div class="card">
                    <div class="card-header" id={"heading-1"}>
                      <h5 class="mb-0">
                        <a
                          class="collapsed"
                          role="button"
                          data-toggle="collapse"
                          href={"#collapse-1"}
                          aria-expanded="false"
                          aria-controls={"collapse-1"}
                        >
                          {""}
                          {"Appointment Settings"}{" "}
                        </a>
                      </h5>

                      {/* <div className="scheduling-preferences-saction">
                <h3>Scheduling Preferences</h3> */}
                      <div
                        id={"collapse-1"}
                        class="collapse"
                        data-parent="#accordion"
                        aria-labelledby={"heading-1"}
                      >
                        <div class="card-body">
                          <div className="scheduling-preferencesfilde">
                            {!showGroupSession &&
                              <div className="preferencesfilde-left">
                                {" "}
                                <span className="strclint golden-icon min-booking-tooltip tooltip-1-focus ">Buffer Time</span>
                                <div className="tooltip-1 buffer-tooltip-1">
                                  Buffer time applied only to appointments made through VerySoul, not to other appointments on your personal calendar or to Blocked Hours you add in "My Availability"
                                </div>
                                <div class="select-dropdown">

                                  <select
                                    value={values.buffer_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name={`buffer_time`}
                                  >
                                    <option disabled selected value="">
                                      Buffer Time
                                    </option>
                                    <option value={0}>0 Minutes</option>
                                    <option value={15}>15 Minutes</option>
                                    <option value={30}>30 Minutes</option>
                                    <option value={45}>45 Minutes</option>
                                    <option value={60}>60 Minutes</option>
                                    <option value={75}>75 Minutes</option>
                                    <option value={90}>90 Minutes</option>
                                  </select>
                                </div>
                              </div>
                            }
                            {!showGroupSession && <div className={showGroupSession ? "preferencesfilde-left" : "preferencesfilde-right"}>
                              {" "}
                              <span>Number Of Sessions Per Day</span>
                              <div class="select-dropdown">
                                <select
                                  value={values.number_of_session_per_day}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name={`number_of_session_per_day`}
                                  defaultValue={1}
                                >
                                  <option disabled selected value="">
                                    Number Of Sessions
                                  </option>
                                  <option value={100}>Unlimited</option>
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                  <option value={3}>3</option>
                                  <option value={4}>4</option>
                                  <option value={5}>5</option>
                                  <option value={6}>6</option>
                                  <option value={7}>7</option>
                                  <option value={8}>8</option>
                                  <option value={9}>9</option>
                                  <option value={10}>10</option>
                                </select>
                              </div>
                            </div>}

                            {/*************** */}
                            {showGroupSession && (
                              <div className="scheduling-preferencesfilde">
                                <div className="preferencesfilde-left">
                                  {" "}
                                  <span>Number Of Attendees (Check your Zoom attendance limit before setting.)</span>
                                  <div class="select-dropdown">
                                    <input onChange={(e) => { changeHna(e, setFieldValue) }} value={values.max_attendees} name="max_attendees" type="text" className="input-in-availbilities" />
                                  </div>
                                </div>
                                <div className="preferencesfilde-right">
                                  {" "}

                                </div>


                                <div className="preferencesfilde-right">
                                  {" "}
                                  <span>Price For Attendees <b>(TBD)</b></span>
                                  <div class="select-dropdown">
                                    <input
                                      //  onChange={(e) => { changeHna(e, setFieldValue) }}
                                      //  value={values.max_attendees} name="max_attendees"
                                      type="text" className="input-in-availbilities" />
                                  </div>
                                </div>
                                <div className="preferencesfilde-right">
                                  {" "}

                                </div>


                              </div>
                            )}

                            {/************** */}
                          </div>

                          <div className="minimum-booking-time-row">
                            <div className="minimum-booking-time-row-left">
                              <label className="strclint golden-icon min-booking-tooltip tooltip-1-focus ">
                                Minimum Booking Time
                              </label>
                              {/***tooltip here */}
                              <div className="tooltip-1 min-booking-tooltip-1">
                                Minimum advance notice you must receive on new
                                bookings
                              </div>
                              <div className="new-three-bx">
                                {/************ */}
                                <div className="minimum-inner-row-left">
                                  {" "}
                                  <span>Unit</span>
                                  <div class="select-dropdown">
                                    <select
                                      value={unitValue}
                                      name="minunitchange"
                                      onChange={(e) => unitChangeHandler(e, setFieldValue)}
                                    >
                                      <option value="Days">
                                        Days
                                      </option>
                                      <option value="Hours">
                                        Hours
                                      </option>
                                      <option value="Minutes">
                                        Minutes
                                      </option>

                                    </select>
                                  </div>
                                </div>
                                {/*********** */}
                                {unitValue === 'Days' && <div className="minimum-inner-row-left">
                                  {" "}
                                  <span>Days</span>
                                  <div class="select-dropdown">
                                    <select
                                      value={values.min_booking_days}
                                      name="min_booking_days"
                                      onChange={(e) => handleMinDaysChange(e, setFieldValue)}
                                      onBlur={handleBlur}
                                    >
                                      <option disabled selected value="">
                                        Days
                                      </option>
                                      <option value={0}>00</option>
                                      {makeHours(31).map((item) => (
                                        <option selected={item === '01' ? true : false} value={item}>{item}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>}
                                {unitValue === 'Hours' && <div className="minimum-inner-row-left clear-both mt-2">
                                  {" "}
                                  <span>Hours</span>
                                  <div class="select-dropdown">
                                    <select
                                      value={values.min_booking_hour}
                                      name="min_booking_hour"
                                      onChange={(e) => handleMinHoursChange(e, setFieldValue)}
                                      onBlur={handleBlur}
                                    >
                                      <option disabled selected value="">
                                        Hours
                                      </option>
                                      <option value={0}>00</option>
                                      {makeHours(24).map((item) => (
                                        <option selected={item === '01' ? true : false} value={item}>{item}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>}
                                {unitValue === 'Minutes' && <div className="minimum-inner-row-left clear-both mt-2">
                                  {" "}
                                  <span>Min</span>
                                  <div class="select-dropdown">
                                    <select
                                      value={values.min_booking_time}
                                      name="min_booking_time"
                                      onChange={(e) => handleMinTimeChange(e, setFieldValue)}
                                      onBlur={handleBlur}
                                    >
                                      <option disabled selected value="">
                                        Minutes
                                      </option>
                                      <option value={0}>00</option>
                                      <option value="15" selected>15</option>
                                      <option value="30">30</option>
                                      <option value="45">45</option>
                                      {/* {makeHours(59).map((item) => (
                                      <option value={`${item}`}>{item}</option>
                                    ))} */}
                                      {/* {console.log(makeHours(59),"makeHours(59)")} */}
                                    </select>
                                  </div>
                                </div>}
                              </div>
                            </div>
                            {/* {!showGroupSession && ( */}
                            <div className="minimum-booking-time-row-right">
                              <label className="strclint  golden-icon min-booking-tooltip tooltip-2-focus">
                                Maximum Booking Time
                              </label>
                              <div className="tooltip-2 min-booking-tooltip-1">
                                Limit on how far in advance new bookings can be
                                made
                              </div>
                              <div className="minimum-inner-row-left">
                                {" "}
                                <span>Days</span>
                                <div class="select-dropdown">
                                  <select
                                    value={values.max_booking_days}
                                    name="max_booking_days"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option disabled selected value="">
                                      Days
                                    </option>
                                    <option value={360}>Unlimited</option>
                                    {makeHours(31).map((item) => (
                                      <option value={item}>{item}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                          {!showGroupSession && (
                            <BookingPeriodBar values={values} />
                          )}

                          <div className="meeting-preferences-box-row">
                            <h4>Meeting Preferences</h4>
                            <div className="language-box--row">
                              {" "}
                              <span>Language Requirement</span>
                              {/* <span>Language</span> */}
                              <div class="select-dropdown">
                                <Select
                                  id="meeting_preferences_meeting"
                                  className="assign-level-select width-530-px"
                                  options={languages}
                                  isMulti
                                  onChange={(value) =>
                                    setFieldValue(
                                      "meeting_preferences_meeting",
                                      value
                                    )
                                  }
                                  // onBlur={handleBlur}
                                  placeholder=""
                                  value={values.meeting_preferences_meeting}
                                  closeMenuOnSelect={false}
                                  closeMenuOnScroll={false}
                                />
                              </div>
                            </div>
                            <div className="recording-box--row">
                              {" "}
                              <div class="select-dropdown margin-top-recording">
                                <span>Recording</span>
                                <select
                                  name="recording"
                                  value={values.recording}
                                  onChange={handleChange}
                                  onBlur={handleBlur}

                                >
                                  {/* <option disabled selected value="">
                          Recording
                        </option> */}
                                  <option value={1}>Yes</option>
                                  <option value={0}>No</option>
                                </select>
                              </div>
                            </div>
                            {/* {type === "admin" && (
                    <div className="group-limit-box--row">
                      {" "}
                      <span>Group Limit</span>
                      <div class="select-dropdown">
                        <select
                          name="group_limit"
                          value={values.group_limit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option disabled selected value="">
                            Group Limit
                          </option>

                          <option value={500}>500</option>
                          <option value={100}>100</option>
                          <option value={50}>50</option>
                          <option value={10}>10</option>
                        </select>
                      </div>
                    </div>
                  )} */}
                          </div>
                          <div className="meeting-preferences-box-row  mt-top clear-both">
                            <h4>Participants Preferences</h4>
                            <div className="language-box--row clear-both d-flex">
                              {" "}
                              <span>Countries</span>
                              <div className="ml-3">
                                <div className="check-field">
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value="All Countries"
                                    id="allcountries"
                                    defaultChecked={
                                      values.country_type === "All Countries"
                                        ? true
                                        : false
                                    }
                                    name="country_type"
                                    type="radio"
                                  />
                                  <label htmlFor="my-check">
                                    All Countries
                                  </label>
                                </div>
                                <div className="check-field">
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value="All Countries with Exclusions"
                                    id="exclusioncountries"
                                    name="country_type"
                                    defaultChecked={
                                      values.country_type ===
                                        "All Countries with Exclusions"
                                        ? true
                                        : false
                                    }
                                    type="radio"
                                  />
                                  <label htmlFor="my-checktow">
                                    All Countries with Exclusions
                                  </label>
                                </div>
                                <div className="check-field">
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value="Selected Countries only"
                                    defaultChecked={
                                      values.country_type ===
                                        "Selected Countries only"
                                        ? true
                                        : false
                                    }
                                    id="selectedcountries"
                                    name="country_type"
                                    type="radio"
                                  />
                                  <label htmlFor="my-checktow">
                                    Selected Countries only
                                  </label>
                                </div>
                              </div>
                            </div>
                            {values.country_type !== "All Countries" &&
                              values.country_type !== "" && (
                                <div className="row-one-step student-lavel">
                                  <div className="full-fild">
                                    <label>
                                      {values.country_type ===
                                        "All Countries with Exclusions"
                                        ? "Select exclusions"
                                        : "Select Countries"}
                                    </label>
                                    <Select
                                      id="country"
                                      className="selection-box"
                                      options={newCountryData}
                                      multi={true}
                                      isMulti
                                      onChange={
                                        values.country_type ===
                                          "All Countries with Exclusions"
                                          ? (value) =>
                                            setFieldValue("exclusions", value)
                                          : (value) =>
                                            setFieldValue("inclusions", value)
                                      }
                                      onBlur={handleBlur}
                                      placeholder={
                                        values.country_type ===
                                          "All Countries with Exclusions"
                                          ? "Select exclusion"
                                          : "Select Country"
                                      }
                                      value={
                                        values.country_type ===
                                          "All Countries with Exclusions"
                                          ? values.exclusions
                                          : values.inclusions
                                      }
                                      closeMenuOnSelect={false}
                                      closeMenuOnScroll={false}

                                    />
                                    <br />
                                    <p className="error">
                                      {values.country_type === "Selected Countries only" && errors.inclusions &&
                                        touched.inclusions &&
                                        errors.inclusions ||
                                        values.country_type === "All Countries with Exclusions" && errors.exclusions &&
                                        touched.exclusions &&
                                        errors.exclusions ? "Please Select Countries" : ""}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {/* <div class="select-dropdown">
                                <Select
                                  id="meeting_preferences_participants"
                                  className="assign-level-select width-530-px"
                                  options={countries}
                                  isMulti
                                  onChange={(value) =>
                                    setFieldValue(
                                      "meeting_preferences_participants",
                                      value
                                    )
                                  }
                                  // onBlur={handleBlur}
                                  placeholder=""
                                  value={
                                    values.meeting_preferences_participants
                                  }
                                  closeMenuOnSelect={false}
                                  closeMenuOnScroll={false}
                                />
                              </div> */}

                            {/* <div className="recording-box--row">
                    {" "}
                    <span>Recording</span>
                    <div class="select-dropdown">
                      <select
                        name="recording_participants"
                        value={values.recording_participants}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option disabled selected value="">
                          Recording
                        </option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </select>
                    </div>
                  </div> */}
                          </div>
                          {/*********** */}

                          {showpreffered &&
                            <div className="meeting-preferences-box-row clear-both">
                              { }
                              <h4>Preferred Medium Level



                                {checkPreferedType(type, values, setFieldValue, setshowpreffered) && <p className="font-size-11 d-inline">*feature coming soon</p>}
                              </h4>

                              <div className="check-field">

                                <div className={`check-field mt-3 ${checkPreferedType(type, values, setFieldValue, setshowpreffered) ? 'select_partner_level' : ''}`}>
                                  <input
                                    // onChange={(e) => checkPreferedMediums(e, setFieldValue, values)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={1}
                                    id="novice"
                                    disabled={checkPreferedType(type, values, setFieldValue, setshowpreffered)}
                                    // selected={item.status}

                                    checked={values.preferred_medium_level.includes("1") ? "checked" : ""}
                                    type="checkbox"
                                    name="preferred_medium_level"
                                  />

                                  <label htmlFor="">Novice I</label>
                                </div>
                                <div className={`check-field mt-3 ${checkPreferedType(type, values, setFieldValue, setshowpreffered) ? 'select_partner_level' : ''}`}>
                                  <input
                                    // onChange={(e) => checkPreferedMediums(e, setFieldValue, values)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={4}
                                    id="novice2"
                                    disabled={checkPreferedType(type, values, setFieldValue, setshowpreffered)}
                                    // selected={item.status}

                                    checked={values.preferred_medium_level.includes("4") ? "checked" : ""}
                                    type="checkbox"
                                    name="preferred_medium_level"
                                  />

                                  <label htmlFor="novice2">Novice II</label>
                                </div>
                                <div className={`check-field mt-3 ${checkPreferedType(type, values, setFieldValue, setshowpreffered) ? 'select_partner_level' : ''}`}>
                                  <input
                                    // onChange={(e) => checkPreferedMediums(e, setFieldValue, values)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={2}
                                    disabled={checkPreferedType(type, values, setFieldValue, setshowpreffered)}
                                    id="Intermediate"
                                    // selected={item.status}

                                    checked={values.preferred_medium_level.includes("2") ? "checked" : ""}
                                    type="checkbox"
                                    name="preferred_medium_level"
                                  />
                                  {/* {console.log(item.status,"approvedPrefrences")} */}
                                  <label htmlFor="">Intermediate I</label>
                                </div>
                                <div className={`check-field mt-3 ${checkPreferedType(type, values, setFieldValue, setshowpreffered) ? 'select_partner_level' : ''}`}>
                                  <input
                                    // onChange={(e) => checkPreferedMediums(e, setFieldValue, values)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={5}
                                    disabled={checkPreferedType(type, values, setFieldValue, setshowpreffered)}
                                    id="Intermediate2"
                                    // selected={item.status}

                                    checked={values.preferred_medium_level.includes("5") ? "checked" : ""}
                                    type="checkbox"
                                    name="preferred_medium_level"
                                  />
                                  {/* {console.log(item.status,"approvedPrefrences")} */}
                                  <label htmlFor="">Intermediate II</label>
                                </div>
                                <div className={`check-field mt-3 ${checkPreferedType(type, values, setFieldValue, setshowpreffered) ? 'select_partner_level' : ''}`}>
                                  {/* {console.log(checkPreferedType(type, values), "checkPrefer,setshowprefferededType(type, values, 3)")} */}
                                  <input
                                    // onChange={(e) => checkPreferedMediums(e, setFieldValue, values)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={3}
                                    disabled={checkPreferedType(type, values, setFieldValue, setshowpreffered)}
                                    id="Professional3"
                                    // selected={item.status}
                                    checked={values.preferred_medium_level.includes("3") ? "checked" : ""}

                                    type="checkbox"
                                    name="preferred_medium_level"
                                  />
                                  {/* {console.log(item.status,"approvedPrefrences")} */}
                                  <label htmlFor="">Professional I</label>
                                </div>

                                <div className={`check-field mt-3 ${checkPreferedType(type, values, setFieldValue, setshowpreffered) ? 'select_partner_level' : ''}`}>
                                  {/* {console.log(checkPreferedType(type, values), "checkPrefer,setshowprefferededType(type, values, 3)")} */}
                                  <input
                                    // onChange={(e) => checkPreferedMediums(e, setFieldValue, values)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={6}
                                    disabled={checkPreferedType(type, values, setFieldValue, setshowpreffered)}
                                    id="Professional3"
                                    // selected={item.status}
                                    checked={values.preferred_medium_level.includes("6") ? "checked" : ""}

                                    type="checkbox"
                                    name="preferred_medium_level"
                                  />
                                  {/* {console.log(item.status,"approvedPrefrences")} */}
                                  <label htmlFor="">Professional II</label>
                                </div>
                                <div className={`check-field mt-3 ${checkPreferedType(type, values, setFieldValue, setshowpreffered) ? 'select_partner_level' : ''}`}>
                                  {/* {console.log(checkPreferedType(type, values), "checkPrefer,setshowprefferededType(type, values, 3)")} */}
                                  <input
                                    // onChange={(e) => checkPreferedMediums(e, setFieldValue, values)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={7}
                                    disabled={checkPreferedType(type, values, setFieldValue, setshowpreffered)}
                                    id="Professional3"
                                    // selected={item.status}
                                    checked={values.preferred_medium_level.includes("7") ? "checked" : ""}

                                    type="checkbox"
                                    name="preferred_medium_level"
                                  />
                                  {/* {console.log(item.status,"approvedPrefrences")} */}
                                  <label htmlFor="">Professional III</label>
                                </div>
                              </div>
                              <p className="error">
                                {errors.preferred_medium_level}
                              </p>
                            </div>
                          }
                          {/*********** */}
                          <div className="meeting-preferences-box-row">
                            {showField && (
                              <div className="language-box--row clear-both mb-5">
                                {" "}
                                <span>Student Level</span>
                                <div class="select-dropdown">
                                  <Select
                                    id="student_level"
                                    className="assign-level-select width-530-px mb-2"
                                    options={mediumRolesStudentList}
                                    isMulti
                                    onChange={(value) =>
                                      setFieldValue("student_level", value)
                                    }
                                    // onBlur={handleBlur}
                                    placeholder=""
                                    value={values.student_level}
                                    closeMenuOnSelect={false}
                                    closeMenuOnScroll={false}
                                  />
                                </div>
                                {/* <div class="select-dropdown">
                      {console.log(values, "valuesvalues")}
                      <select
                        name="student_level"
                        value={values.student_level}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option disabled selected value="">
                          Student Level
                        </option>
                        {mediumRolesStudent.map((data) => (
                          <option value={data.id}>{data.name}</option>
                        ))}
                      </select>
                    </div> */}
                              </div>
                            )}
                            {/* {ParticipantLevelStatus && (
                              <div className="language-box--row clear-both mt-5">
                                {" "}
                                <span>Participant Level</span>
                                <div class="select-dropdown">
                                  <Select
                                    id="partner_level"
                                    className="assign-level-select width-530-px mt-2"
                                    options={mediumRolesList}
                                    isMulti
                                    onChange={(value) =>
                                      setFieldValue("partner_level", value)
                                    }
                          
                                    placeholder=""
                                    value={values.partner_level}
                                    closeMenuOnSelect={false}
                                    closeMenuOnScroll={false}
                                  />
                                </div>
                               
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*******Appointment setting end */}


              {showGroup && (
                <div className="calendar-box-new">
                  <div id="accordion">
                    <div class="card">
                      <div class="card-header" id={"heading-1"}>
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            role="button"
                            data-toggle="collapse"
                            href={"#collapse-4"}
                            aria-expanded={collapsed === "collapse" ? "false" : "true"}
                            aria-controls={"collapse-4"}
                          >
                            {""}
                            {checkIsGroupSessionTitles(values, " Weekly Group Events", 'Add Weekly Hours')}{" "}
                            {/* {"Add Weekly Hours"}{" "} */}
                          </a>
                        </h5>


                        {/* <div className="scheduling-preferences-saction">
                <h3>Scheduling Preferences</h3> */}
                        <div
                          id={"collapse-4"}
                          class={collapsed}
                          data-parent="#accordion"
                          aria-labelledby={"heading-1"}
                        >
                          <div class="card-body">
                            <div className="availability-window-table">
                              <table>
                                <tbody>
                                  <tr>
                                    <th>{checkIsGroupSessionTitles(values, "Every day at", 'Days')}
                                      {/* Days */}
                                    </th>
                                    <th>{checkIsGroupSessionTitles(values, "Start Time", 'Start at')}
                                      {/* Start at */}
                                    </th>
                                    <th className="position-relative">{checkIsGroupSessionTitles(values, "End Time", 'End at')}
                                      {checkIsGroupSessionTitles(values, "End Time", 'End at') === "End Time" && <span className="ml-1">
                                        <span className="strclint golden-icon min-booking-tooltip tooltip-1-focus "></span>
                                        <div className="tooltip-1  end-time-tooltip-1 end-timetool-text">
                                          Create sessions with customized durations. Remember that you must have a paid Zoom account to host Group Sessions.
                                        </div>
                                      </span>}
                                      {/* End at */}
                                    </th>
                                    <th></th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div class="form-group">
                                        <input
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value="daysunday"
                                          checked={
                                            values.daysunday ||
                                            values.scheduleSunday[0].from !== ""
                                          }
                                          type="checkbox"
                                          name="daysunday"
                                          id="daysunday"
                                        />
                                        <label for="daysunday">Sunday</label>
                                      </div>
                                      <div className="box-valid-from-three">
                                        <div id="copy-modal" class="modal">
                                          <div class="modal__content">
                                            {" "}
                                            <a href="#" class="modal__close">
                                              &times;
                                            </a>
                                            <div className="header-popup-specifc text-center">
                                              <span>Copy To Days</span>
                                            </div>
                                            <div className="mt-3">
                                              {" "}
                                              Selected Day :
                                              <em className="dbb38f">
                                                {" "}
                                                {capitalizeFirstLetter(
                                                  activeCopy
                                                )}
                                              </em>{" "}
                                            </div>
                                            <div className="header-popup-specifc-body copy-container mt-4 d-flex align-items-center ">
                                              {activeCopy !== "sunday" && (
                                                <div class="form-group">
                                                  <input
                                                    onChange={copyChangeHandler}
                                                    type="checkbox"
                                                    value={"sunday"}
                                                    name="copydays"
                                                    id="sunday"
                                                  />
                                                  <label
                                                    className="mr-3"
                                                    for="sunday"
                                                  >
                                                    Sunday
                                                  </label>
                                                </div>
                                              )}
                                              {activeCopy !== "monday" && (
                                                <div class="form-group">
                                                  <input
                                                    onChange={copyChangeHandler}
                                                    value={"monday"}
                                                    type="checkbox"
                                                    id="monday"
                                                    name="copydays"
                                                  />
                                                  <label
                                                    className="mr-3"
                                                    for="monday"
                                                  >
                                                    Monday
                                                  </label>
                                                </div>
                                              )}
                                              {activeCopy !== "tuesday" && (
                                                <div class="form-group">
                                                  <input
                                                    onChange={copyChangeHandler}
                                                    value={"tuesday"}
                                                    type="checkbox"
                                                    id="tuesday"
                                                    name="copydays"
                                                  />
                                                  <label
                                                    className="mr-3"
                                                    for="tuesday"
                                                  >
                                                    Tuesday
                                                  </label>
                                                </div>
                                              )}
                                              {activeCopy !== "wednesday" && (
                                                <div class="form-group">
                                                  <input
                                                    onChange={copyChangeHandler}
                                                    value={"wednesday"}
                                                    type="checkbox"
                                                    id="wednesday"
                                                    name="copydays"
                                                  />
                                                  <label
                                                    className="mr-3"
                                                    for="wednesday"
                                                  >
                                                    Wednesday
                                                  </label>
                                                </div>
                                              )}
                                              {activeCopy !== "thursday" && (
                                                <div class="form-group">
                                                  <input
                                                    onChange={copyChangeHandler}
                                                    value={"thursday"}
                                                    type="checkbox"
                                                    id="thursday"
                                                    name="copydays"
                                                  />
                                                  <label
                                                    className="mr-3"
                                                    for="thursday"
                                                  >
                                                    Thursday
                                                  </label>
                                                </div>
                                              )}
                                              {activeCopy !== "friday" && (
                                                <div class="form-group">
                                                  <input
                                                    onChange={copyChangeHandler}
                                                    value={"friday"}
                                                    type="checkbox"
                                                    id="friday"
                                                    name="copydays"
                                                  />
                                                  <label
                                                    className="mr-3"
                                                    for="friday"
                                                  >
                                                    Friday
                                                  </label>
                                                </div>
                                              )}
                                              {activeCopy !== "saturday" && (
                                                <div class="form-group">
                                                  <input
                                                    onChange={copyChangeHandler}
                                                    value={"saturday"}
                                                    type="checkbox"
                                                    id="saturday"
                                                    name="copydays"
                                                  />
                                                  <label
                                                    className="mr-3"
                                                    for="saturday"
                                                  >
                                                    Saturday
                                                  </label>
                                                </div>
                                              )}
                                            </div>
                                            <div className="text-center mt-3">
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  copyHandler(values, setFieldValue)
                                                }
                                                className="copy-btn-days"
                                              >
                                                Copy
                                              </button>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </td>
                                    <FieldArray name="scheduleSunday">
                                      {({ insert, remove, push }) => (
                                        <td colspan="3">
                                          <div className="d-flex">
                                            <div className="">
                                              {values.scheduleSunday.length > 0 &&
                                                values.scheduleSunday.map(
                                                  (schedule, index) => (
                                                    <div className="d-flex justify-content-between width-530-px mb-2">
                                                      {/* <td> */}
                                                      <div class="select-dropdown">
                                                        {/* <label for="hours">Hours</label> */}
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`scheduleSunday.${index}.from`}

                                                          //  menuIsOpen
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "from", "day"
                                                          //   )
                                                          // }
                                                          //  ref={selectRef}
                                                          onMenuOpen={values.scheduleSunday[index].from === "" ? () =>
                                                            scrollMenuToTarget(
                                                              "from", "day"
                                                            ) : onMenuOpen}
                                                          onChange={(value) =>
                                                            customChangeHandler(
                                                              index,
                                                              value,
                                                              setFieldValue,
                                                              `scheduleSunday.${index}.from`,
                                                              values,
                                                              "scheduleSunday",
                                                              "from"
                                                            )
                                                          }
                                                          onBlur={handleBlur}
                                                          // closeMenuOnSelect={false}
                                                          // closeMenuOnScroll={false}
                                                          options={slotsList}
                                                          // menuPortalTarget={startPoint}
                                                          placeholder="Hours"
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          value={schedule.from}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />
                                                        {/* <input
                                            list="hours"
                                            id={`scheduleSunday.${index}.from`}
                                            value={schedule.from}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name={`scheduleSunday.${index}.from`}
                                            placeholder="Hours"
                                          />

                                          <datalist id="hours">
                                            {slotsArray.map((item) => (
                                              <option value={item.time}>
                                        
                                              </option>
                                            ))}
                                          </datalist> */}
                                                      </div>
                                                      {/* </td> */}
                                                      {/* <td> */}
                                                      <div class="select-dropdown d-flex">
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`scheduleSunday.${index}.to`}
                                                          onChange={(value) =>
                                                            customChangeHandler(
                                                              index,
                                                              value,
                                                              setFieldValue,
                                                              `scheduleSunday.${index}.to`,
                                                              values,
                                                              "scheduleSunday",
                                                              "to",
                                                              values.scheduleSunday[index].from
                                                            )
                                                          }
                                                          onBlur={handleBlur}

                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          // options={slotsList}
                                                          options={slotsList.filter(
                                                            (option, index) =>
                                                              formatTimeTo24hrs(
                                                                option.value
                                                              ) >
                                                              formatTimeTo24hrs(
                                                                schedule.from
                                                                  .value
                                                              )
                                                          )}
                                                          // ref={selectRef}
                                                          onMenuOpen={onMenuOpen}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "to", "day"
                                                          //   )
                                                          // }
                                                          placeholder="Hours"
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          value={schedule.to}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />

                                                        <div className="buttion-add-box onclick-button mt-2">
                                                          {values.scheduleSunday.length > 1 ? (
                                                            <img
                                                              onClick={() =>
                                                                remove(index)
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : values
                                                            .scheduleSunday[0]
                                                            .from !== "" ? (
                                                            <img
                                                              onClick={() =>
                                                                // remove(index)
                                                                removefirstArray(
                                                                  "scheduleSunday",
                                                                  setFieldValue
                                                                )
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : (
                                                            <img
                                                              className="hidden-elem"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                            <div className="w-100 d-flex justify-content-around align-items-center">
                                              <span
                                                onClick={() =>
                                                  push({ from: "", to: "" })
                                                }
                                                className="span-tag pointer"
                                              >
                                                {checkIsGroupSessionTitles(values, "Add Event", 'Add Hours')}
                                              </span>
                                              <a
                                                href="#copy-modal"
                                                onClick={() =>
                                                  setActiveCopy("sunday")
                                                }
                                                className="span-tag pointer"
                                              >
                                                copy
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                    </FieldArray>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div class="form-group">
                                        <input
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value="daymonday"
                                          checked={
                                            values.daymonday ||
                                            values.scheduleMonday[0].from !== ""
                                          }
                                          type="checkbox"
                                          name="daymonday"
                                          id="daymonday"
                                        />
                                        <label for="daymonday">Monday</label>
                                      </div>
                                    </td>
                                    <FieldArray name="scheduleMonday">
                                      {({ insert, remove, push }) => (
                                        <td colspan="3">
                                          <div className="d-flex">
                                            <div className="">
                                              {values.scheduleMonday.length > 0 &&
                                                values.scheduleMonday.map(
                                                  (schedule, index) => (
                                                    <div className="d-flex justify-content-between width-530-px mb-2">
                                                      {/* <td> */}
                                                      <div class="select-dropdown">
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`scheduleMonday.${index}.from`}
                                                          onChange={(value) =>
                                                            customChangeHandler(
                                                              index,
                                                              value,
                                                              setFieldValue,
                                                              `scheduleMonday.${index}.from`,
                                                              values,
                                                              "scheduleMonday",
                                                              "from"
                                                            )
                                                          }
                                                          onBlur={handleBlur}

                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          options={slotsList}
                                                          onMenuOpen={values.scheduleMonday[index].from === "" ? () =>
                                                            scrollMenuToTarget(
                                                              "from", "day"
                                                            ) : onMenuOpen}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "from", "day"
                                                          //   )
                                                          // }
                                                          placeholder="Hours"
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          value={schedule.from}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />

                                                        {/* <input
                                            list="hours"
                                            value={schedule.from}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Hours"
                                            name={`scheduleMonday.${index}.from`}
                                          />
                                          <datalist id="hours">
                                            {slotsArray.map((item) => (
                                              <option value={item.time}>
                              
                                              </option>
                                            ))}
                                          </datalist> */}
                                                      </div>
                                                      {/* </td> */}
                                                      {/* <td> */}
                                                      <div class="select-dropdown  d-flex">
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`scheduleMonday.${index}.to`}
                                                          onChange={(value) =>
                                                            customChangeHandler(
                                                              index,
                                                              value,
                                                              setFieldValue,
                                                              `scheduleMonday.${index}.to`,
                                                              values,
                                                              "scheduleMonday",
                                                              "to",
                                                              values.scheduleMonday[index].from
                                                            )
                                                          }
                                                          onBlur={handleBlur}

                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          // options={slotsList}
                                                          options={slotsList.filter(
                                                            (option, index) =>
                                                              formatTimeTo24hrs(
                                                                option.value
                                                              ) >
                                                              formatTimeTo24hrs(
                                                                schedule.from
                                                                  .value
                                                              )
                                                          )}
                                                          onMenuOpen={onMenuOpen}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "to", "day"
                                                          //   )
                                                          // }
                                                          placeholder="Hours"
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          value={schedule.to}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />
                                                        <div className="buttion-add-box onclick-button mt-2">
                                                          {values.scheduleMonday.length > 1 ? (
                                                            <img
                                                              onClick={() =>
                                                                remove(index)
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : values
                                                            .scheduleMonday[0]
                                                            .from !== "" ? (
                                                            <img
                                                              onClick={() =>
                                                                removefirstArray(
                                                                  "scheduleMonday",
                                                                  setFieldValue
                                                                )
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : (
                                                            <img
                                                              className="hidden-elem"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          )}
                                                        </div>
                                                      </div>
                                                      {/* </td> */}
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                            {/* <td> */}
                                            <div className="w-100 d-flex justify-content-around align-items-center">
                                              <span
                                                onClick={() =>
                                                  push({ from: "", to: "" })
                                                }
                                                className="span-tag pointer"
                                              >
                                                {checkIsGroupSessionTitles(values, "Add Event", 'Add Hours')}
                                              </span>
                                              <a
                                                href="#copy-modal"
                                                // data-toggle="modal"
                                                // data-target="#copy-modal"
                                                onClick={() =>
                                                  setActiveCopy("monday")
                                                }
                                                className="span-tag pointer"
                                              >
                                                copy
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                        // </td>
                                      )}
                                    </FieldArray>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div class="form-group">
                                        <input
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value="daytuesday"
                                          checked={
                                            values.daytuesday ||
                                            values.scheduleTuesday[0].from !== ""
                                          }
                                          type="checkbox"
                                          name="daytuesday"
                                          id="daytuesday"
                                        />
                                        <label for="daytuesday">Tuesday</label>
                                      </div>
                                    </td>
                                    <FieldArray name="scheduleTuesday">
                                      {({ insert, remove, push }) => (
                                        <td colspan="3">
                                          <div className="d-flex">
                                            <div className="">
                                              {values.scheduleTuesday.length >
                                                0 &&
                                                values.scheduleTuesday.map(
                                                  (schedule, index) => (
                                                    <>
                                                      <div className="d-flex justify-content-between width-530-px mb-2">
                                                        {/* <td> */}
                                                        <div class="select-dropdown">
                                                          <Select
                                                            className="day-schedule-input font-size-17 "
                                                            id={`scheduleTuesday.${index}.from`}
                                                            onChange={(value) =>
                                                              customChangeHandler(
                                                                index,
                                                                value,
                                                                setFieldValue,
                                                                `scheduleTuesday.${index}.from`,
                                                                values,
                                                                "scheduleTuesday",
                                                                "from"
                                                              )
                                                            }
                                                            onBlur={handleBlur}
                                                            closeMenuOnScroll={
                                                              false
                                                            }
                                                            options={slotsList}
                                                            // onMenuOpen={() =>
                                                            //   scrollMenuToTarget(
                                                            //     "from", "day"
                                                            //   )
                                                            // }
                                                            onMenuOpen={values.scheduleTuesday[index].from === "" ? () =>
                                                              scrollMenuToTarget(
                                                                "from", "day"
                                                              ) : onMenuOpen}
                                                            placeholder="Hours"
                                                            filterOption={
                                                              customFilter
                                                            }
                                                            value={schedule.from}
                                                            formatOptionLabel={(
                                                              slotsList
                                                            ) =>
                                                              getTimeImage(
                                                                slotsList.value
                                                              ) ? (
                                                                <div className="time-option">
                                                                  <span>
                                                                    {slotsList.value ==
                                                                      "11:59 PM"
                                                                      ? "12:00 AM"
                                                                      : slotsList.value}
                                                                  </span>
                                                                  &nbsp;&nbsp;
                                                                  <img
                                                                    src="localImages/moon.png"
                                                                    className="star-imh-moon"
                                                                    alt="time-image"
                                                                  />
                                                                </div>
                                                              ) : (
                                                                <div className="time-option">
                                                                  <span>
                                                                    {slotsList.value ==
                                                                      "11:59 PM"
                                                                      ? "12:00 AM"
                                                                      : slotsList.value}
                                                                  </span>
                                                                </div>
                                                              )
                                                            }
                                                          />
                                                          {/* <input
                                              list="hours"
                                              ̀̀value={schedule.from}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              placeholder="Hours"
                                              name={`scheduleTuesday.${index}.from`}
                                            />

                                            <datalist id="hours">
                                              {slotsArray.map((item) => (
                                                <option value={item.time}>
                                
                                                </option>
                                              ))}
                                            </datalist> */}
                                                        </div>
                                                        {/* </td> */}
                                                        {/* <td> */}
                                                        <div class="select-dropdown  d-flex">
                                                          <Select
                                                            className="day-schedule-input font-size-17 "
                                                            id={`scheduleTuesday.${index}.to`}
                                                            onChange={(value) =>
                                                              customChangeHandler(
                                                                index,
                                                                value,
                                                                setFieldValue,
                                                                `scheduleTuesday.${index}.to`,
                                                                values,
                                                                "scheduleTuesday",
                                                                "to",
                                                                values.scheduleTuesday[index].from
                                                              )
                                                            }
                                                            onBlur={handleBlur}
                                                            closeMenuOnScroll={
                                                              false
                                                            }
                                                            // options={slotsList}
                                                            // onMenuOpen={() =>
                                                            //   scrollMenuToTarget(
                                                            //     "to", "day"
                                                            //   )
                                                            // }
                                                            onMenuOpen={onMenuOpen}
                                                            options={slotsList.filter(
                                                              (option, index) =>
                                                                formatTimeTo24hrs(
                                                                  option.value
                                                                ) >
                                                                formatTimeTo24hrs(
                                                                  schedule.from
                                                                    .value
                                                                )
                                                            )}
                                                            placeholder="Hours"
                                                            filterOption={
                                                              customFilter
                                                            }
                                                            value={schedule.to}
                                                            formatOptionLabel={(
                                                              slotsList
                                                            ) =>
                                                              getTimeImage(
                                                                slotsList.value
                                                              ) ? (
                                                                <div className="time-option">
                                                                  <span>
                                                                    {slotsList.value ==
                                                                      "11:59 PM"
                                                                      ? "12:00 AM"
                                                                      : slotsList.value}
                                                                  </span>
                                                                  &nbsp;&nbsp;
                                                                  <img
                                                                    src="localImages/moon.png"
                                                                    className="star-imh-moon"
                                                                    alt="time-image"
                                                                  />
                                                                </div>
                                                              ) : (
                                                                <div className="time-option">
                                                                  <span>
                                                                    {slotsList.value ==
                                                                      "11:59 PM"
                                                                      ? "12:00 AM"
                                                                      : slotsList.value}
                                                                  </span>
                                                                </div>
                                                              )
                                                            }
                                                          />

                                                          <div className="buttion-add-box onclick-button mt-2">
                                                            {values.scheduleTuesday.length > 1 ? (
                                                              <img
                                                                onClick={() =>
                                                                  remove(index)
                                                                }
                                                                className="pointer"
                                                                src="../images/icon-b2.png"
                                                                alt="delete"
                                                              />
                                                            ) : values
                                                              .scheduleTuesday[0]
                                                              .from !== "" ? (
                                                              <img
                                                                onClick={() =>
                                                                  removefirstArray(
                                                                    "scheduleTuesday",
                                                                    setFieldValue
                                                                  )
                                                                }
                                                                className="pointer"
                                                                src="../images/icon-b2.png"
                                                                alt="delete"
                                                              />
                                                            ) : (
                                                              <img
                                                                className="hidden-elem"
                                                                src="../images/icon-b2.png"
                                                                alt="delete"
                                                              />
                                                            )}
                                                          </div>
                                                        </div>
                                                        {/* </td> */}
                                                      </div>
                                                    </>
                                                  )
                                                )}
                                            </div>
                                            {/* <td> */}
                                            <div className="w-100 d-flex justify-content-around align-items-center">
                                              <span
                                                onClick={() =>
                                                  push({ from: "", to: "" })
                                                }
                                                className="span-tag pointer"
                                              >
                                                {checkIsGroupSessionTitles(values, "Add Event", 'Add Hours')}
                                              </span>
                                              <a
                                                href="#copy-modal"
                                                // data-toggle="modal"
                                                // data-target="#copy-modal"
                                                onClick={() =>
                                                  setActiveCopy("tuesday")
                                                }
                                                className="span-tag pointer"
                                              >
                                                copy
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                        // </td>
                                      )}
                                    </FieldArray>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div class="form-group">
                                        <input
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value="daywednesday"
                                          checked={
                                            values.daywednesday ||
                                            values.scheduleWednesday[0].from !==
                                            ""
                                          }
                                          type="checkbox"
                                          name="daywednesday"
                                          id="daywednesday"
                                        />
                                        <label for="daywednesday">
                                          Wednesday
                                        </label>
                                      </div>
                                    </td>
                                    <FieldArray name="scheduleWednesday">
                                      {({ insert, remove, push }) => (
                                        <td colspan="3">
                                          <div className="d-flex">
                                            <div className="">
                                              {values.scheduleWednesday.length >
                                                0 &&
                                                values.scheduleWednesday.map(
                                                  (schedule, index) => (
                                                    <div className="d-flex justify-content-between width-530-px mb-2">
                                                      {/* <td> */}
                                                      <div class="select-dropdown">
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`scheduleWednesday.${index}.from`}
                                                          onChange={(value) =>
                                                            customChangeHandler(
                                                              index,
                                                              value,
                                                              setFieldValue,
                                                              `scheduleWednesday.${index}.from`,
                                                              values,
                                                              "scheduleWednesday",
                                                              "from"
                                                            )
                                                          }
                                                          onBlur={handleBlur}

                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          options={slotsList}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "from", "day"
                                                          //   )
                                                          // }
                                                          onMenuOpen={values.scheduleWednesday[index].from === "" ? () =>
                                                            scrollMenuToTarget(
                                                              "from", "day"
                                                            ) : onMenuOpen}
                                                          placeholder="Hours"
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          value={schedule.from}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />
                                                        {/* <input
                                            list="hours"
                                            value={schedule.from}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Hours"
                                            name={`scheduleWednesday.${index}.from`}
                                          />
                                          <datalist id="hours">
                                            {slotsArray.map((item) => (
                                              <option
                                                value={item.time}
                                              ></option>
                                            ))}
                                          </datalist> */}
                                                      </div>
                                                      {/* </td> */}
                                                      {/* <td> */}
                                                      <div class="select-dropdown  d-flex">
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`scheduleWednesday.${index}.to`}
                                                          onChange={(value) =>
                                                            customChangeHandler(
                                                              index,
                                                              value,
                                                              setFieldValue,
                                                              `scheduleWednesday.${index}.to`,
                                                              values,
                                                              "scheduleWednesday",
                                                              "to",
                                                              values.scheduleWednesday[index].from
                                                            )
                                                          }
                                                          onBlur={handleBlur}

                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          // options={slotsList}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "to", "day"
                                                          //   )
                                                          // }
                                                          onMenuOpen={onMenuOpen}
                                                          options={slotsList.filter(
                                                            (option, index) =>
                                                              formatTimeTo24hrs(
                                                                option.value
                                                              ) >
                                                              formatTimeTo24hrs(
                                                                schedule.from
                                                                  .value
                                                              )
                                                          )}
                                                          placeholder="Hours"
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          value={schedule.to}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />
                                                        {/* <input
                                            list="hours"
                                            value={schedule.to}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Hours"
                                            name={`scheduleWednesday.${index}.to`}
                                          />
                                          <datalist id="hours">
                                            {slotsArray.map((item) => (
                                              <option
                                                value={item.time}
                                              ></option>
                                            ))}
                                          </datalist> */}
                                                        <div className="buttion-add-box onclick-button mt-2">
                                                          {values.scheduleWednesday.length > 1 ? (
                                                            <img
                                                              onClick={() =>
                                                                remove(index)
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : values
                                                            .scheduleWednesday[0]
                                                            .from !== "" ? (
                                                            <img
                                                              onClick={() =>
                                                                removefirstArray(
                                                                  "scheduleWednesday",
                                                                  setFieldValue
                                                                )
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : (
                                                            <img
                                                              className="hidden-elem"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          )}
                                                        </div>
                                                      </div>
                                                      {/* </td> */}
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                            {/* <td> */}
                                            <div className="w-100 d-flex justify-content-around align-items-center">
                                              <span
                                                onClick={() =>
                                                  push({ from: "", to: "" })
                                                }
                                                className="span-tag pointer"
                                              >
                                                {checkIsGroupSessionTitles(values, "Add Event", 'Add Hours')}
                                              </span>
                                              <a
                                                href="#copy-modal"
                                                // data-toggle="modal"
                                                // data-target="#copy-modal"
                                                onClick={() =>
                                                  setActiveCopy("wednesday")
                                                }
                                                className="span-tag pointer"
                                              >
                                                copy
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                        // </td>
                                      )}
                                    </FieldArray>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div class="form-group">
                                        <input
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value="daythursday"
                                          checked={
                                            values.daythursday ||
                                            values.scheduleThursday[0].from !== ""
                                          }
                                          type="checkbox"
                                          name="daythursday"
                                          id="daythursday"
                                        />
                                        <label for="daythursday">Thursday</label>
                                      </div>
                                    </td>
                                    <FieldArray name="scheduleThursday">
                                      {({ insert, remove, push }) => (
                                        <td colspan="3">
                                          <div className="d-flex">
                                            <div className="">
                                              {values.scheduleThursday.length >
                                                0 &&
                                                values.scheduleThursday.map(
                                                  (schedule, index) => (
                                                    <div className="d-flex justify-content-between width-530-px mb-2">
                                                      {/* <td> */}
                                                      <div class="select-dropdown">
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`scheduleThursday.${index}.from`}
                                                          onChange={(value) =>
                                                            customChangeHandler(
                                                              index,
                                                              value,
                                                              setFieldValue,
                                                              `scheduleThursday.${index}.from`,
                                                              values,
                                                              "scheduleThursday",
                                                              "from"
                                                            )
                                                          }
                                                          onBlur={handleBlur}

                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          options={slotsList}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "from", "day"
                                                          //   )
                                                          // }
                                                          onMenuOpen={values.scheduleThursday[index].from === "" ? () =>
                                                            scrollMenuToTarget(
                                                              "from", "day"
                                                            ) : onMenuOpen}
                                                          placeholder="Hours"
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          value={schedule.from}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />
                                                        {/* <input
                                            list="hours"
                                            value={schedule.from}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Hours"
                                            name={`scheduleThursday.${index}.from`}
                                          />
                                          <datalist id="hours">
                                            {slotsArray.map((item) => (
                                              <option
                                                value={item.time}
                                              ></option>
                                            ))}
                                          </datalist> */}
                                                      </div>
                                                      {/* </td> */}
                                                      {/* <td> */}
                                                      <div class="select-dropdown  d-flex">
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`scheduleThursday.${index}.to`}
                                                          onChange={(value) =>
                                                            customChangeHandler(
                                                              index,
                                                              value,
                                                              setFieldValue,
                                                              `scheduleThursday.${index}.to
                                                `,
                                                              values,
                                                              "scheduleThursday",
                                                              "to",
                                                              values.scheduleThursday[index].from
                                                            )
                                                          }
                                                          onBlur={handleBlur}

                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          options={slotsList.filter(
                                                            (option, index) =>
                                                              formatTimeTo24hrs(
                                                                option.value
                                                              ) >
                                                              formatTimeTo24hrs(
                                                                schedule.from
                                                                  .value
                                                              )
                                                          )}
                                                          // options={slotsList}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "to", "day"
                                                          //   )
                                                          // }
                                                          onMenuOpen={onMenuOpen}
                                                          placeholder="Hours"
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          value={schedule.to}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />
                                                        {/* <input
                                            list="hours"
                                            value={schedule.to}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Hours"
                                            name={`scheduleThursday.${index}.to`}
                                          />
                                          <datalist id="hours">
                                            {slotsArray.map((item) => (
                                              <option
                                                value={item.time}
                                              ></option>
                                            ))}
                                          </datalist> */}
                                                        <div className="buttion-add-box onclick-button mt-2">
                                                          {values.scheduleThursday.length > 1 ? (
                                                            <img
                                                              onClick={() =>
                                                                remove(index)
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : values
                                                            .scheduleThursday[0]
                                                            .from !== "" ? (
                                                            <img
                                                              onClick={() =>
                                                                removefirstArray(
                                                                  "scheduleThursday",
                                                                  setFieldValue
                                                                )
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : (
                                                            <img
                                                              className="hidden-elem"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          )}
                                                        </div>
                                                      </div>
                                                      {/* </td> */}
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                            {/* <td> */}
                                            <div className="w-100 d-flex justify-content-around align-items-center">
                                              <span
                                                onClick={() =>
                                                  push({ from: "", to: "" })
                                                }
                                                className="span-tag pointer"
                                              >
                                                {checkIsGroupSessionTitles(values, "Add Event", 'Add Hours')}
                                              </span>
                                              <a
                                                href="#copy-modal"
                                                // data-toggle="modal"
                                                // data-target="#copy-modal"
                                                onClick={() =>
                                                  setActiveCopy("thursday")
                                                }
                                                className="span-tag pointer"
                                              >
                                                copy
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                        // </td>
                                      )}
                                    </FieldArray>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div class="form-group">
                                        <input
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value="dayfriday"
                                          checked={
                                            values.dayfriday ||
                                            values.scheduleFriday[0].from !== ""
                                          }
                                          type="checkbox"
                                          name="dayfriday"
                                          id="dayfriday"
                                        />
                                        <label for="dayfriday">Friday</label>
                                      </div>
                                    </td>
                                    <FieldArray name="scheduleFriday">
                                      {({ insert, remove, push }) => (
                                        <td colspan="3">
                                          <div className="d-flex">
                                            <div className="">
                                              {values.scheduleFriday.length > 0 &&
                                                values.scheduleFriday.map(
                                                  (schedule, index) => (
                                                    <div className="d-flex justify-content-between width-530-px mb-2">
                                                      {/* <td> */}
                                                      <div class="select-dropdown">
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`scheduleFriday.${index}.from`}
                                                          onChange={(value) =>
                                                            customChangeHandler(
                                                              index,
                                                              value,
                                                              setFieldValue,
                                                              `scheduleFriday.${index}.from`,
                                                              values,
                                                              "scheduleFriday",
                                                              "from"
                                                            )
                                                          }
                                                          onBlur={handleBlur}

                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          options={slotsList}
                                                          onMenuOpen={values.scheduleFriday[index].from === "" ? () =>
                                                            scrollMenuToTarget(
                                                              "from", "day"
                                                            ) : onMenuOpen}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "from", "day"
                                                          //   )
                                                          // }
                                                          placeholder="Hours"
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          value={schedule.from}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />
                                                        {/* <input
                                            list="hours"
                                            value={schedule.from}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder="Hours"
                                            name={`scheduleFriday.${index}.from`}
                                          />
                                          <datalist id="hours">
                                            {slotsArray.map((item) => (
                                              <option
                                                value={item.time}
                                              ></option>
                                            ))}
                                          </datalist> */}
                                                      </div>
                                                      {/* </td> */}
                                                      {/* <td> */}
                                                      <div class="select-dropdown  d-flex">
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`scheduleFriday.${index}.to`}
                                                          onChange={(value) =>
                                                            customChangeHandler(
                                                              index,
                                                              value,
                                                              setFieldValue,
                                                              `scheduleFriday.${index}.to`,
                                                              values,
                                                              "scheduleFriday",
                                                              "to",
                                                              values.scheduleFriday[index].from
                                                            )
                                                          }
                                                          onBlur={handleBlur}

                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          options={slotsList.filter(
                                                            (option, index) =>
                                                              formatTimeTo24hrs(
                                                                option.value
                                                              ) >
                                                              formatTimeTo24hrs(
                                                                schedule.from
                                                                  .value
                                                              )
                                                          )}
                                                          // options={slotsList}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "to", "day"
                                                          //   )
                                                          // }
                                                          onMenuOpen={onMenuOpen}
                                                          placeholder="Hours"
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          value={schedule.to}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />
                                                        {/* <input
                                            list="hours"
                                            value={schedule.to}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Hours"
                                            name={`scheduleFriday.${index}.to`}
                                          />
                                          <datalist id="hours">
                                            {slotsArray.map((item) => (
                                              <option
                                                value={item.time}
                                              ></option>
                                            ))}
                                          </datalist> */}
                                                        <div className="buttion-add-box onclick-button mt-2">
                                                          {values.scheduleFriday.length > 1 ? (
                                                            <img
                                                              onClick={() =>
                                                                remove(index)
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : values
                                                            .scheduleFriday[0]
                                                            .from !== "" ? (
                                                            <img
                                                              onClick={() =>
                                                                removefirstArray(
                                                                  "scheduleFriday",
                                                                  setFieldValue
                                                                )
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : (
                                                            <img
                                                              className="hidden-elem"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          )}
                                                        </div>
                                                      </div>
                                                      {/* </td> */}
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                            {/* <td> */}
                                            <div className="w-100 d-flex justify-content-around align-items-center">
                                              <span
                                                onClick={() =>
                                                  push({ from: "", to: "" })
                                                }
                                                className="span-tag pointer"
                                              >
                                                {checkIsGroupSessionTitles(values, "Add Event", 'Add Hours')}
                                              </span>
                                              <a
                                                href="#copy-modal"
                                                // data-toggle="modal"
                                                // data-target="#copy-modal"
                                                onClick={() =>
                                                  setActiveCopy("friday")
                                                }
                                                className="span-tag pointer"
                                              >
                                                copy
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                        // </td>
                                      )}
                                    </FieldArray>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div class="form-group">
                                        <input
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value="daysaturday"
                                          checked={
                                            values.daysaturday ||
                                            values.scheduleSaturday[0].from !== ""
                                          }
                                          type="checkbox"
                                          name="daysaturday"
                                          id="daysaturday"
                                        />
                                        <label for="daysaturday">Saturday</label>
                                      </div>
                                    </td>
                                    <FieldArray name="scheduleSaturday">
                                      {({ insert, remove, push }) => (
                                        <td colspan="3">
                                          <div className="d-flex">
                                            <div className="">
                                              {values.scheduleSaturday.length >
                                                0 &&
                                                values.scheduleSaturday.map(
                                                  (schedule, index) => (
                                                    <div className="d-flex justify-content-between width-530-px mb-2">
                                                      {/* <td> */}
                                                      <div class="select-dropdown">
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`scheduleSaturday.${index}.from`}
                                                          onChange={(value) =>
                                                            customChangeHandler(
                                                              index,
                                                              value,
                                                              setFieldValue,
                                                              `scheduleSaturday.${index}.from`,
                                                              values,
                                                              "scheduleSaturday",
                                                              "from"
                                                            )
                                                          }
                                                          onBlur={handleBlur}

                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          options={slotsList}
                                                          onMenuOpen={values.scheduleSaturday[index].from === "" ? () =>
                                                            scrollMenuToTarget(
                                                              "from", "day"
                                                            ) : onMenuOpen}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "from", "day"
                                                          //   )
                                                          // }
                                                          placeholder="Hours"
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          value={schedule.from}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />
                                                        {/* <input
                                            list="hours"
                                            value={schedule.from}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Hours"
                                            name={`scheduleSaturday.${index}.from`}
                                          />
                                          <datalist id="hours">
                                            {slotsArray.map((item) => (
                                              <option
                                                value={item.time}
                                              ></option>
                                            ))}
                                          </datalist> */}
                                                      </div>
                                                      {/* </td> */}
                                                      {/* <td> */}
                                                      <div class="select-dropdown  d-flex">
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`scheduleSaturday.${index}.to`}
                                                          onChange={(value) =>
                                                            customChangeHandler(
                                                              index,
                                                              value,
                                                              setFieldValue,
                                                              `scheduleSaturday.${index}.to`,
                                                              values,
                                                              "scheduleSaturday",
                                                              "to",
                                                              values.scheduleSaturday[index].from
                                                            )
                                                          }
                                                          onBlur={handleBlur}

                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          options={slotsList.filter(
                                                            (option, index) =>
                                                              formatTimeTo24hrs(
                                                                option.value
                                                              ) >
                                                              formatTimeTo24hrs(
                                                                schedule.from
                                                                  .value
                                                              )
                                                          )}
                                                          // options={slotsList}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "to", "day"
                                                          //   )
                                                          // }
                                                          onMenuOpen={onMenuOpen}
                                                          placeholder="Hours"
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          value={schedule.to}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />
                                                        {/* <input
                                            list="hours"
                                            value={schedule.to}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Hours"
                                            name={`scheduleSaturday.${index}.to`}
                                          />
                                          <datalist id="hours">
                                            {makeHours(24).map((item) => (
                                              <option
                                                value={formatTime(item)}
                                              ></option>
                                            ))}
                                          </datalist> */}
                                                        <div className="buttion-add-box onclick-button mt-2">
                                                          {values.scheduleSaturday.length > 1 ? (
                                                            <img
                                                              onClick={() =>
                                                                remove(index)
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : values
                                                            .scheduleSaturday[0]
                                                            .from !== "" ? (
                                                            <img
                                                              onClick={() =>
                                                                removefirstArray(
                                                                  "scheduleSaturday",
                                                                  setFieldValue
                                                                )
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : (
                                                            <img
                                                              className="hidden-elem"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          )}
                                                        </div>
                                                      </div>
                                                      {/* </td> */}
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                            {/* <td> */}
                                            <div className="w-100 d-flex justify-content-around align-items-center">
                                              <span
                                                onClick={() =>
                                                  push({ from: "", to: "" })
                                                }
                                                className="span-tag pointer"
                                              >
                                                {checkIsGroupSessionTitles(values, "Add Event", 'Add Hours')}
                                              </span>
                                              <a
                                                href="#copy-modal"
                                                // data-toggle="modal"
                                                // data-target="#copy-modal"
                                                onClick={() =>
                                                  setActiveCopy("saturday")
                                                }
                                                className="span-tag pointer"
                                              >
                                                copy
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                        // </td>
                                      )}
                                    </FieldArray>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* adding specific dates accordion */}
              {/* {schedule_id !== "" && ( */}

              <div className="calendar-box-new">
                <div id="accordion">
                  <div class="card-modify">
                    <div class="card-header" id={"heading-1"}>
                      <h5 class="mb-0">
                        <a
                          class="collapsed"
                          role="button"
                          data-toggle="collapse"
                          href={"#collapse-2"}
                          aria-expanded="false"
                          aria-controls={"collapse-2"}
                        >
                          {""}
                          {checkIsGroupSessionTitles(values, "Add Events for specific date", 'Add Hours for Specific Dates')}{" "}
                          {/* {"Add Hours for Specific Dates"}{" "} */}
                        </a>
                      </h5>

                      <div
                        id={"collapse-2"}
                        class="collapse"
                        data-parent="#accordion"
                        aria-labelledby={"heading-1"}
                      >
                        <div class="card-body">
                          {!showSpecificAddDates && (
                            <div className="right-last-td nonediv  ml-5">
                              <button
                                className="pointer"
                                type="reset"
                                onClick={() => setShowSpecificAddDates(true)}
                              >
                                Add New Date
                              </button>
                            </div>
                          )}
                          {showSpecificAddDates &&
                            values.specificDateSlots.length > 0 &&
                            values.specificDateSlots.map((schedule, index) => (
                              <div className="mt-4 mb-4 specific-specific-blocked-date">
                                <FieldArray name="specificDateSlots">
                                  {({ insert, remove, push }) => (
                                    <>
                                      <div className="addspecifce">
                                        <div className="clander-popup">
                                          <div className="box-valid-from-two">
                                            <div id="filterDate2">
                                              <div className="tab2-row">
                                                <div
                                                  class="input-group date newadddate"
                                                  data-date-format="dd.mm.yyyy"
                                                >
                                                  {/* {console.log(
                                                        "date",
                                                        schedule
                                                      )} */}
                                                  <input
                                                    type="date"
                                                    class="form-control"
                                                    // placeholder="dd.mm.yyyy"
                                                    value={schedule.date}
                                                    onChange={(e) => onCustomdateChangeHandler(e, 'specific', `specificDateSlots.${index}.date`, setFieldValue)}
                                                    onBlur={handleBlur}
                                                    name={`specificDateSlots.${index}.date`}
                                                    max={values.end_date}
                                                    min={moment().format('YYYY-MM-DD')}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <FieldArray
                                        name={`specificDateSlots.${index}.time`}
                                      >
                                        {({ insert, remove, push }) => (
                                          <div className="d-flex">
                                            <div className="">
                                              {schedule.time.length > 0 &&
                                                schedule.time.map(
                                                  (time, index1) => (
                                                    <div className="d-flex justify-content-between width-354-px mb-2 ml-3">
                                                      <div class="select-dropdown time-claas">
                                                        <span class="start_time">Start Time</span>
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`specificDateSlots.${index}.time.${index1}.from`}
                                                          onChange={(value) =>
                                                            // setFieldValue(
                                                            //   `specificDateSlots.${index}.time.${index1}.from`,
                                                            //   value
                                                            // )
                                                            customChangeHandler(
                                                              index1,
                                                              value,
                                                              setFieldValue,
                                                              `specificDateSlots.${index}.time.${index1}.from`,

                                                              values,
                                                              "specificDateSlots",
                                                              "from"
                                                            )
                                                          }
                                                          onBlur={handleBlur}
                                                          closeMenuOnSelecttrue={
                                                            false
                                                          }
                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          options={slotsList}
                                                          onMenuOpen={values.specificDateSlots[index].time[index1].from == "" ? () =>
                                                            scrollMenuToTarget(
                                                              "spec_block_date", "day"
                                                            ) : onMenuOpen}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "from"
                                                          //   )
                                                          // }
                                                          placeholder="Hours"
                                                          value={time.from}
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {
                                                                    slotsList.value
                                                                  }
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />
                                                      </div>

                                                      <div class="select-dropdown time-claas">
                                                        <span class="end_time">End Time</span>
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`specificDateSlots.${index}.time.${index1}.to`}
                                                          onChange={(value) =>
                                                            // setFieldValue(
                                                            //   `specificDateSlots.${index}.time.${index1}.to`,
                                                            //   value
                                                            // )
                                                            customChangeHandler(
                                                              index1,
                                                              value,
                                                              setFieldValue,
                                                              `specificDateSlots.${index}.time.${index1}.to`,

                                                              values,
                                                              "specificDateSlots",
                                                              "to",
                                                              values.specificDateSlots[index].time[index1].from
                                                            )
                                                          }
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          onBlur={handleBlur}
                                                          closeMenuOnSelecttrue={
                                                            false
                                                          }
                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          options={slotsList.filter(
                                                            (option, index) =>
                                                              formatTimeTo24hrs(
                                                                option.value
                                                              ) >
                                                              formatTimeTo24hrs(
                                                                time.from.value
                                                              )
                                                          )}
                                                          // options={slotsList}
                                                          onMenuOpen={onMenuOpen}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "to"
                                                          //   )
                                                          // }
                                                          placeholder="Hours"
                                                          value={time.to}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />

                                                        <div className="buttion-add-box onclick-button mt-2">
                                                          {schedule.time.length > 1 ? (
                                                            <img
                                                              onClick={() =>
                                                                remove(index1)
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : values
                                                            .specificDateSlots[
                                                            index
                                                          ].time[0].from !==
                                                            "" ? (
                                                            <img
                                                              onClick={() =>
                                                                removefirstArray(
                                                                  `specificDateSlots.${index}.time`,
                                                                  setFieldValue
                                                                )
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : (
                                                            <img
                                                              className="hidden-elem"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                            {!showGroupSession && (
                                              <div className="fullDay-checkbox">
                                                <input
                                                  onChange={(e) =>
                                                    onChangeFulldayHandler(
                                                      e,
                                                      "specific",
                                                      values,
                                                      setFieldValue
                                                    )
                                                  }
                                                  checked={specificfullday ? true : false}
                                                  type="checkbox"
                                                  value={"fullday"}
                                                  name="fullday"
                                                  id="fullday"
                                                />
                                                <label
                                                  className="mr-3"
                                                  for="fullday"
                                                >
                                                  Full day
                                                </label>
                                              </div>
                                            )}
                                            <div className="w-100 d-flex justify-content-around align-items-center ml-5">

                                              <span

                                                onClick={!specificfullday ? () =>
                                                  push({ from: "", to: "" }) : () => { }
                                                }
                                                className="span-tag pointer margin-unset padding-left-hours"
                                              >
                                                {checkIsGroupSessionTitles(values, "Add Event", 'Add Hours')}
                                              </span>
                                            </div>

                                          </div>
                                        )}
                                      </FieldArray>
                                      <div className="ml-5 action-btn-blocked-specific">
                                        <div className="right-last-td">
                                          {index > 0 ? (
                                            <button
                                              onClick={() => remove(index)}
                                              type="button"
                                              className="pointer"
                                            >
                                              <img src="images/close.png"></img>
                                              Remove
                                            </button>
                                          ) : values.specificDateSlots[0].date !==
                                            "" ||
                                            values.specificDateSlots[0].time[0]
                                              .from !== "" ? (
                                            <button
                                              onClick={() =>
                                                removefirstArrayDateSLots(
                                                  `specificDateSlots`,
                                                  setFieldValue
                                                )
                                              }
                                              className="pointer"
                                              type="button"
                                            >
                                              <img src="images/close.png"></img>
                                              Remove
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              className="hidden-elem"
                                            >
                                              <img src="images/close.png"></img>
                                              Remove
                                            </button>
                                          )}
                                        </div>

                                        <div className="right-last-td">
                                          <button
                                            className="pointer  bg-theme-d5a67b"
                                            type="button"
                                            // type="reset"
                                            onClick={
                                              values.specificDateSlots[0].date !==
                                                "" &&
                                                values.specificDateSlots[0].time[0]
                                                  .from !== "" &&
                                                values.specificDateSlots[0].time[0]
                                                  .to !== ""
                                                ? () =>
                                                  updatdeValues(
                                                    values.specificDateSlots,
                                                    setFieldValue,
                                                    values,
                                                  )
                                                : ""
                                            }
                                          >
                                            {updateType === "ADD"
                                              ? "Save Date"
                                              : "Update Date"}
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                            ))}

                          <div>
                            {testValues.length > 0 &&
                              testValues.map((item, index) => (
                                <div className="specific-dated-text-box">
                                  <div>{item.date}</div>
                                  <div>
                                    {item.time.map((time) => (
                                      <div className="line-height-30px">
                                        <span className="mr-2">
                                          {" "}
                                          {time.from.value}
                                          {getTimeImage(time.from.value) ? <img
                                            src="localImages/moon.png"
                                            className="star-imh-moon ml-1"
                                            alt="time-image"
                                          /> : ''}
                                        </span>{" "}
                                        -{""}
                                        <span className="ml-2">
                                          {time.to.value === "11:59 PM" ? "12:00 AM" : time.to.value}
                                          {getTimeImage(time.to.value) ? <img
                                            src="localImages/moon.png"
                                            className="star-imh-moon ml-1"
                                            alt="time-image"
                                          /> : ''}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                  <div>
                                    {" "}
                                    <div className="multiple-datepicker">
                                      <MultipleDatePicker
                                        value={"Copy"}
                                        onSubmit={(dates) =>
                                          getCopiedDates(
                                            dates,
                                            index,
                                            setFieldValue
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="right-last-td">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        onEditSpecificValue(
                                          index,
                                          setFieldValue
                                        )
                                      }
                                      className="pointer"
                                    >
                                      Edit
                                    </button>
                                    &nbsp;
                                    <button
                                      type="button"
                                      onClick={() =>
                                        onDeleteSpecificValue(
                                          index,
                                          setFieldValue
                                        )
                                      }
                                      className="pointer"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* )} */}
              {/********
               *
               * blocked dates
               *
               *
               *
               *
               *
               *
               *
               */}
              {/* {!showGroupSession && ( */}
              <div className="calendar-box-new">
                <div id="accordion">
                  <div class="card-modify">
                    <div class="card-header" id={"heading-1"}>
                      <h5 class="mb-0">
                        <a
                          class="collapsed"
                          role="button"
                          data-toggle="collapse"
                          href={"#collapse-3"}
                          aria-expanded="false"
                          aria-controls={"collapse-3"}
                        >
                          {""}
                          {"Block Hours for Specific Dates"}{" "}
                        </a>
                      </h5>

                      <div
                        id={"collapse-3"}
                        class="collapse"
                        data-parent="#accordion"
                        aria-labelledby={"heading-1"}
                      >
                        <div class="card-body">
                          {!showBlockedAddDates && (
                            <div className="right-last-td nonediv  ml-5">
                              <button
                                className="pointer"
                                type="reset"
                                onClick={() => setShowBlockedAddDates(true)}
                              >
                                Add New Date
                              </button>
                            </div>
                          )}

                          {showBlockedAddDates &&
                            values.blockedDateSlots.length > 0 &&
                            values.blockedDateSlots.map((schedule, index) => (
                              <div className="mt-4 mb-4 specific-specific-blocked-date">
                                <FieldArray name="blockedDateSlots">
                                  {({ insert, remove, push }) => (
                                    <>
                                      <div className="addspecifce">
                                        <div className="clander-popup">
                                          <div className="box-valid-from-two">
                                            <div id="filterDate2">
                                              <div className="tab2-row">
                                                <div
                                                  class="input-group date newadddate"
                                                  data-date-format="dd.mm.yyyy"
                                                >
                                                  <input
                                                    type="date"
                                                    class="form-control"
                                                    placeholder="dd.mm.yyyy"
                                                    value={schedule.date}
                                                    onChange={(e) => onCustomdateChangeHandler(e, 'blocked', `blockedDateSlots.${index}.date`, setFieldValue)}
                                                    onBlur={handleBlur}
                                                    name={`blockedDateSlots.${index}.date`}
                                                    min={moment().format('YYYY-MM-DD')}
                                                    max={values.end_date}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <FieldArray
                                        name={`blockedDateSlots.${index}.time`}
                                      >
                                        {({ insert, remove, push }) => (
                                          <div className="d-flex">
                                            <div className="">
                                              {schedule.time.length > 0 &&
                                                schedule.time.map(
                                                  (time, index1) => (
                                                    <div className="d-flex justify-content-between width-354-px mb-2 ml-3">
                                                      <div class="select-dropdown time-claas">
                                                        <span className="start_time">Start Time</span>
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`blockedDateSlots.${index}.time.${index1}.from`}
                                                          onChange={(value) =>
                                                            // setFieldValue(
                                                            //   `blockedDateSlots.${index}.time.${index1}.from`,
                                                            //   value
                                                            // )

                                                            customChangeHandler(
                                                              index1,
                                                              value,
                                                              setFieldValue,
                                                              `blockedDateSlots.${index}.time.${index1}.from`,

                                                              values,
                                                              "blockedDateSlots",
                                                              "from"
                                                            )
                                                          }
                                                          onBlur={handleBlur}
                                                          closeMenuOnSelecttrue={
                                                            false
                                                          }
                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          options={slotsList}
                                                          onMenuOpen={values.blockedDateSlots[index].time[index1].from === "" ? () =>
                                                            scrollMenuToTarget(
                                                              "spec_block_date", "day"
                                                            ) : onMenuOpen}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "from"
                                                          //   )
                                                          // }
                                                          placeholder="Hours"
                                                          value={time.from}
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />
                                                      </div>

                                                      <div class="select-dropdown time-claas">
                                                        <span className="end_time">End Time</span>
                                                        <Select
                                                          className="day-schedule-input font-size-17 "
                                                          id={`blockedDateSlots.${index}.time.${index1}.to`}
                                                          onChange={(value) =>
                                                            // setFieldValue(
                                                            //   `blockedDateSlots.${index}.time.${index1}.to`,
                                                            //   value
                                                            // )
                                                            customChangeHandler(
                                                              index1,
                                                              value,
                                                              setFieldValue,
                                                              `blockedDateSlots.${index}.time.${index1}.to`,

                                                              values,
                                                              "blockedDateSlots",
                                                              "to",
                                                              values.blockedDateSlots[index].time[index1].from
                                                            )
                                                          }
                                                          filterOption={
                                                            customFilter
                                                          }
                                                          onBlur={handleBlur}
                                                          closeMenuOnSelecttrue={
                                                            false
                                                          }
                                                          closeMenuOnScroll={
                                                            false
                                                          }
                                                          options={slotsList.filter(
                                                            (option, index) =>
                                                              formatTimeTo24hrs(
                                                                option.value
                                                              ) >
                                                              formatTimeTo24hrs(
                                                                time.from
                                                                  .value
                                                              )
                                                          )}
                                                          // options={slotsList}
                                                          // onMenuOpen={() =>
                                                          //   scrollMenuToTarget(
                                                          //     "to"
                                                          //   )
                                                          // }
                                                          onMenuOpen={onMenuOpen}
                                                          placeholder="Hours"
                                                          value={time.to}
                                                          formatOptionLabel={(
                                                            slotsList
                                                          ) =>
                                                            getTimeImage(
                                                              slotsList.value
                                                            ) ? (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <img
                                                                  src="localImages/moon.png"
                                                                  className="star-imh-moon"
                                                                  alt="time-image"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="time-option">
                                                                <span>
                                                                  {slotsList.value ==
                                                                    "11:59 PM"
                                                                    ? "12:00 AM"
                                                                    : slotsList.value}
                                                                </span>
                                                              </div>
                                                            )
                                                          }
                                                        />

                                                        <div className="buttion-add-box onclick-button mt-2">
                                                          {schedule.time.length > 1 ? (
                                                            <img
                                                              onClick={() =>
                                                                remove(index1)
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : values
                                                            .blockedDateSlots[
                                                            index
                                                          ].time[0].from !==
                                                            "" ? (
                                                            <img
                                                              onClick={() =>
                                                                removefirstArray(
                                                                  `blockedDateSlots.${index}.time`,
                                                                  setFieldValue
                                                                )
                                                              }
                                                              className="pointer"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          ) : (
                                                            <img
                                                              className="hidden-elem"
                                                              src="../images/icon-b2.png"
                                                              alt="delete"
                                                            />
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                            </div>


                                            <div className="fullDay-checkbox">
                                              <input
                                                onChange={(e) =>
                                                  onChangeFulldayHandler(
                                                    e,
                                                    "blocked",
                                                    values,
                                                    setFieldValue
                                                  )
                                                }
                                                checked={blockedfullday ? true : false}
                                                type="checkbox"
                                                value={"fulldayblock"}
                                                name="fulldayblock"
                                                id="fulldayblock"
                                              />
                                              <label
                                                className="mr-3"
                                                for="fulldayblock"
                                              >
                                                Full day
                                              </label>
                                            </div>
                                            <div className="w-100 d-flex justify-content-around align-items-center ml-5">
                                              <span
                                                onClick={!blockedfullday ? () =>
                                                  push({ from: "", to: "" }) : () => { }
                                                }
                                                className="span-tag pointer margin-unset padding-left-hours"
                                              >
                                                {checkIsGroupSessionTitles(values, "Add Event", 'Add Hours')}
                                              </span>

                                            </div>
                                          </div>
                                        )}
                                      </FieldArray>
                                      <div className="ml-5 action-btn-blocked-specific">
                                        <div className="right-last-td">
                                          {index > 0 ? (
                                            <button
                                              onClick={() => remove(index)}
                                              type="button"
                                              className="pointer"
                                            >
                                              <img src="images/close.png"></img>
                                              Remove
                                            </button>
                                          ) : values.blockedDateSlots[0]
                                            .date !== "" ||
                                            values.blockedDateSlots[0].time[0]
                                              .from !== "" ? (
                                            <button
                                              onClick={() =>
                                                removefirstArrayDateSLots(
                                                  `blockedDateSlots`,
                                                  setFieldValue
                                                )
                                              }
                                              className="pointer"
                                              type="button"
                                            >
                                              <img src="images/close.png"></img>
                                              Remove
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              className="hidden-elem"
                                            >
                                              <img src="images/close.png"></img>
                                              Remove
                                            </button>
                                          )}

                                        </div>

                                        <div className="right-last-td">
                                          <button
                                            className="pointer bg-theme-d5a67b"
                                            type="button"
                                            // type="reset"

                                            onClick={
                                              values.blockedDateSlots[0]
                                                .date !== "" &&
                                                values.blockedDateSlots[0].time[0]
                                                  .from !== "" &&
                                                values.blockedDateSlots[0].time[0]
                                                  .to !== ""
                                                ? () =>
                                                  updatdeBlockedValues(
                                                    values.blockedDateSlots,
                                                    setFieldValue,
                                                    values,
                                                  )
                                                : ""
                                            }
                                          >
                                            {/* <img src="images/close.png"></img> */}
                                            {blockedupdateType === "ADD"
                                              ? "Save Date"
                                              : "Update Date"}
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                            ))}

                          {blockedDatesValues.length > 0 &&
                            blockedDatesValues.map((item, index) => (
                              <div className="specific-dated-text-box">
                                <div>{item.date}</div>
                                <div>
                                  {item.time.map((time) => (
                                    <div className="line-height-30px">
                                      <span className="mr-2">
                                        {" "}
                                        {time.from.value}
                                        {getTimeImage(time.from.value) ? <img
                                          src="localImages/moon.png"
                                          className="star-imh-moon ml-1"
                                          alt="time-image"
                                        /> : ''}
                                      </span>{" "}
                                      -{""}
                                      <span className="ml-2">
                                        {time.to.value === "11:59 PM" ? "12:00 AM" : time.to.value}
                                        {getTimeImage(time.to.value) ? <img
                                          src="localImages/moon.png"
                                          className="star-imh-moon ml-1"
                                          alt="time-image"
                                        /> : ''}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                                <div className="multiple-datee">
                                  {" "}
                                  <div className="multiple-datepicker">
                                    <MultipleDatePicker
                                      value={"Copy"}
                                      onSubmit={(dates) =>
                                        getBlockedCopiedDates(
                                          dates,
                                          index,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="right-last-td">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      onEditBlockedValue(index, setFieldValue)
                                    }
                                    className="pointer"
                                  >
                                    Edit
                                  </button>
                                  &nbsp;
                                  <button
                                    type="button"
                                    onClick={() =>
                                      onDeleteBlockedValue(index, setFieldValue)
                                    }
                                    className="pointer"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* )} */}
              {/********
               *
               *
               * blocked dated
               * *
               *
               *
               *
               *
               */}

              {/*** start date and end date  start*/}
              {/* {!showGroupSession && ( */}
              <div className="row-valid-from">
                <div className="box-valid-from-one">
                  <span className="these-span">These hours are valid from</span>
                </div>
                <div className="box-valid-from-two">
                  <div id="filterDate2">
                    <div class="input-group date" >
                      <input
                        type="date"
                        class="form-control"
                        placeholder="YYYY-MM-DD"
                        data-date-format="YYYY-MM-DD"
                        value={values.start_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`start_date`}
                        min={schedule_id === "" ? moment().format('YYYY-MM-DD') : ''}
                        className={`number-input form-control ${errors.start_date &&
                          touched.start_date &&
                          errors.start_date
                          ? "error-boundry"
                          : ""
                          }`}
                      />
                    </div>
                  </div>
                  <em>to</em>
                  <div id="filterDate2">
                    <div class="input-group date" >
                      <input
                        type="date"
                        class="form-control"
                        placeholder="YYYY-MM-DD"
                        data-date-format="YYYY-MM-DD"
                        value={values.end_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`end_date`}
                        min={moment().format('YYYY-MM-DD')}
                      />
                    </div>
                  </div>
                  <span>(Optional)</span>{" "}
                </div>
              </div>
              {/* )} */}
              {/*** start date and end date end */}
              {/* adding Accordion */}

              {/* <div className="advancesettings-connect-meeting">
                <h4>Payment Setup</h4>
                <div className="payment-setup-boxx">
                  <p>
                    <input
                      type="radio"
                      id="test2"
                      name="payment_type"
                      checked={
                        parseInt(values.payment_type) === 0 ? true : false
                      }
                      value={0}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label for="test2">Paypal</label>
                  </p>
                </div>
                {parseInt(values.payment_type) === 0 && (
                  <div className="paypaluser-filde">
                    <label>PayPal Username</label>
                    <input
                      name="payment_id"
                      value={values.payment_id}
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></input>
                  </div>
                )}
              </div> */}
              <div className="back-to-schudle-btn floating-button-back-to-schudle">

                <button type="submit" className="updatebtn updatebtn-green">
                  Update
                </button>
                <button
                  type="button"
                  onClick={cancelForm}
                  className="pay-cnale"
                >
                  Cancel
                </button>
              </div>
              {/* </div> */}
            </form>
          )}
        </Formik>
        <div></div>
      </div>
      <div className="backtoschedules-modal">

        <ConfirmOffline deleteUser={confirmFormRequest} id={'ConfirmOffline'} cancelRequest={closePopup} />
        <ConfirmTime id={'ConfirmTime'} deleteUser={() => window.loadModal('#ConfirmTime', 'hide')} />
        {/* <PreviewCheckbox id={'PreviewCheckbox'} cancelRequest={closePreviewCheckbox} deleteUser={openPreviewCheckbox} /> */}
      </div>
    </>
  );
}