export const list = [
  { id: 1, name: "All Medium", count: 0 },
  { id: 2, name: "Novice", count: 0 },
  { id: 3, name: "Intermediate", count: 0 },
  { id: 4, name: "Professional", count: 0 },
  { id: 5, name: "Pending Approvals", count: 0 },
  { id: 6, name: "Unverified Email", count: 0 },
  { id: 7, name: "Declined", count: 0 },
];

export const filterList = [
  {
    id: 1,
    name: "Email",
    value: "email",
  },
  {
    id: 2,
    name: "Screen Name",
    value: "screen_name",
  },
  {
    id: 3,
    name: "Register Date",
    value: "created_at",
  },
];
export const filterListpendin = [
  {
    id: 1,
    name: "Email",
    value: "email",
  },
  {
    id: 2,
    name: "Screen Name",
    value: "screen_name",
  },

];

export const bulkList = [
  {
    id: 1,
    name: "Decline",
    value: "Decline",
  },
  {
    id: 2,
    name: "Delete",
    value: "Delete",
  },
];

export const bulkListApproval = [
  {
    id: 1,
    name: "Approve",
    value: "Approve",
  },
  {
    id: 2,
    name: "Decline",
    value: "Decline",
  },
  {
    id: 3,
    name: "Delete",
    value: "Delete",
  },
];
export const bulkListDeclined = [
  {
    id: 1,
    name: "Approve",
    value: "Approve",
  },
  {
    id: 2,
    name: "Delete",
    value: "Delete",
  },
];
