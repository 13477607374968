import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
//services
import { Link } from "react-router-dom";
import { updateClientsMeta } from "../../../services/profile";
import {
  logout,
  sendVerificationCode,
  updateUserEmail,
  updateZoomVerificationCod,
} from "../../../services/auth";
import { setLoading } from "../../../reducer/auth/auth";
import Button from "../../../components/button";
import TickMark from "../../../components/tickmark";
import { updateUser } from "../../../reducer/userSlice";
import { es } from "date-fns/locale";
import './steps.css';
//
const StepTwo = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { stepTwoData } = props;
  // const { email } = useSelector((state) => state.user.user);
  const { is_guest_client } = useSelector(
    (state) => state.user.user
  );
  const [verified, setVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verifiedTitle, setVerifiedTitle] = useState("Verify");
  const [verifiedError, setVerifiedError] = useState(false);
  const [verifiedMessage, setVerifiedMessage] = useState("");
  const [resend, setResend] = useState(0);

  const [emailValue, setEmailValue] = useState("");
  const [emailTitle, setEmailTitle] = useState("Request Change");
  const [emailMessage, setEmailMessage] = useState("");
  const [emailError, setEmailError] = useState("");

  const [emailDisabled, setEmailDisabled] = useState(true);
  const [changeEmailRequest, setChangeEmailRequest] = useState(true);
  const [timeDisplay, setTimeDisplay] = useState(true);
  const [time, setTime] = useState(0);
  const [showResend, setshowResend] = useState(false)
  const [showEmailField, setShowEmailField] = useState(true);
  const [secondsLeft, setSecondsLeft] = useState(30);
  const [zoomverificationCode, setZoomVerificationCode] = useState("");

  // useEffect(() => {
  //   setEmailValue(email);
  // }, []);
  useEffect(() => {
    const startTimestamp = localStorage.getItem('emailVerificationStart');
    if (startTimestamp) {
      const elapsedTime = Math.floor((Date.now() - parseInt(startTimestamp, 10)) / 1000);
      const newSecondsLeft = Math.max(30 - elapsedTime, 0);
      setSecondsLeft(newSecondsLeft);
      if (newSecondsLeft === 0) {
        setShowEmailField(true);
        setTimeDisplay(false);
      }
    } else {
      localStorage.setItem('emailVerificationStart', Date.now().toString());
    }
  }, []);

  useEffect(() => {
    if (secondsLeft > 0) {
      const timerId = setTimeout(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    } else {
      setShowEmailField(true);
      setTimeDisplay(false);
      setResend(1);
    }
  }, [secondsLeft]);
  const RunFunction = (formData) => {
    dispatch(setLoading(true));
    const { opt_in_out, agree_to_legal_terms_and_conditionss } = formData;
    let item = {
      agree_to_legal_terms_and_conditionss: agree_to_legal_terms_and_conditionss
        ? 1
        : 0,
      opt_in_out: opt_in_out ? 1 : 0,
    };
    updateClientsMeta(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {

          if (parseInt(is_guest_client) === 0) {
            localStorage.removeItem("guest_id");
            navigate("/registration-completed?from=registration");
            localStorage.setItem('registration-from', "registration")
          }
          else {
            let guest_id = localStorage.getItem('guest_id');
            navigate(`/guest-booking/${guest_id}`);
          }

          dispatch(updateUser());
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const logoutUser = () => {
    let token = localStorage.getItem("access_token");
    logout(token)
      .then((data) => {
        if (data.data.status) {
        }
      })
      .catch((err) => console.log(err));
  };
  const customHandleChange = (errors) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const reSendVerificationCodeHandler = () => {
    setVerificationCode("");
    let val = "";
    sendVerificationCodeHandler(val);
    setResend(2);
    setSecondsLeft(30);
    setShowEmailField(true);
    setTimeDisplay(true);
    localStorage.setItem('emailVerificationStart', Date.now().toString());
    setTimeout(() => {
      setResend(0);
    }, 4000);
  };
  const sendVerificationCodeHandler = (value) => {
    setTime(1);
    let body = {
      verification_code: value,
    };
    sendVerificationCode(body)
      .then((data) => {
        if (
          !data.data.status &&
          data.data.message === "OTP sent Successfully"
        ) {
          let email = data.data.data.email;
          setTimeout(() => {
            // setResend(1);
          }, 30000);

          setVerifiedTitle("Verify");
          setVerifiedMessage(`We send your code to ${email}`);
          setVerifiedError(false);
          setChangeEmailRequest(true);
        }
        if (!data.data.status && data.data.message === "Invalid OTP") {
          setVerifiedTitle("Verify");
          setVerifiedMessage("Code is incorrect. Please try reentering your code.");
          setVerifiedError(true);
          setChangeEmailRequest(false);
        }
        if (data.data.status && data.data.message === "Verified") {
          setSecondsLeft(0);
          localStorage.removeItem('emailVerificationStart');
          setVerifiedTitle("Verified");
          setVerifiedMessage(data.data.message);
          setVerifiedError(false);
          setVerified(true);
          setResend(0);
          setShowEmailField(true);
          setTimeDisplay(true);
          setChangeEmailRequest(false);
        }
      })
      .catch((data) => {
        setVerifiedMessage("Please Try Again");
        setVerifiedError(true);
      });
  };
  const handleChangeVerification = (e) => {
    setTime(0);
    const { value } = e.target;
    setVerificationCode(value);
    if (value.length == 6) {
      sendVerificationCodeHandler(value);
    }

  };
  const handleChangeEmail = (e) => {
    setEmailValue(e.target.value);
    if (e.target.value === "") {
      setEmailError("*Required");
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)) {
      setEmailError("*Invalid Email");
    } else {
      setEmailError("");
    }
  };
  const onChangeEmailHandler = (e) => {
    setEmailDisabled(false);
    setEmailTitle("Submit");
    if (emailTitle === "Submit" && emailError === "" && emailValue !== "") {
      let body = {
        email: emailValue,
      };
      updateUserEmail(body)
        .then((data) => {
          if (data.data.status) {
            setshowResend(true);
            setResend(1);
            setEmailMessage("Email successfully Updated");
            setChangeEmailRequest(false);
            setVerifiedTitle("Verify");
            setTimeout(() => {
              setVerifiedMessage("");
              setEmailDisabled(true);
              setEmailTitle("Request Change Email");
              setEmailMessage("");
              reSendVerificationCodeHandler();

            }, 3000);
          } else if (!data.data.status) {
            console.log(data.data.error.email[0]);
            if (data.data.error && data.data.error.email[0])
              setEmailError(data.data.error.email[0]);
          } else {
            setEmailError("Please Try Again");
          }
          setTimeout(() => {
            setEmailError("");
          }, 3000);
        })
        .catch(() => { });
    }
    //
  };

  const onChangesetZoomVerificationCode =(e) =>{
    let value = e.target.value;
    setZoomVerificationCode(value);
    if(value ==="5630"){
      updateZoomVerificationCod().then(()=>{}).catch(()=>{})
    }

  }
  return (
    <Formik
      initialValues={stepTwoData}
      validate={(values) => {
        const errors = {};
        if (!values.agree_to_legal_terms_and_conditionss)
          errors.agree_to_legal_terms_and_conditionss = "Required";
        if (!values.opt_in_out) errors.optIn = "Required";

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        RunFunction(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="from-inner-box">
            <div className="full-fild">
              <label>
                Legal and Privacy <i>*</i>
              </label>
              <span className="error marg-top-55">
                {errors.agree_to_legal_terms_and_conditionss &&
                  touched.agree_to_legal_terms_and_conditionss &&
                  errors.agree_to_legal_terms_and_conditionss}
              </span>
              <div className="prvacy-box">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="I have read and agree to the terms and conditions"
                  id="termsandconditions"
                  type="checkbox"
                  name="agree_to_legal_terms_and_conditionss"
                  defaultChecked={values.agree_to_legal_terms_and_conditionss}
                />
                <label htmlFor="termsandconditions" className="width-unset">
                  I have read and agree to the terms and conditions.
                </label>
                <div className="tr-pr">
                  <Link to="/terms-conditions" target="_blank">
                    Terms &amp; Conditions
                  </Link>
                  <Link to="/privacy-policy" target="_blank">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="from-inner-box radeo check-box-input payment-saction">
            <div className="full-fild">
              <label>
                Opt-In? <i>*</i>
              </label>
              <h4>
                I would like to receive notices of offers (for free events,
                opportunities, etc.)
              </h4>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="Yes"
                  id="opt_in"
                  type="radio"
                  name="opt_in_out"
                  checked={values.opt_in_out === "Yes" ? true : false}
                />
                <label htmlFor="optin">Yes</label>
              </div>
              <div className="check-field">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="No"
                  id="opt_out"
                  type="radio"
                  name="opt_in_out"
                  checked={values.opt_in_out === "No" ? true : false}
                />
                <label htmlFor="optout">No</label>
              </div>
            </div>
            <span className="error marg-top-55">
              {errors.opt_in_out && touched.opt_in_out && errors.opt_in_out}
            </span>
          </div>
          {/*********starte */}
          {/* <div className="from-inner-box">
            <div className="verification-code-section">
              <label htmlFor="changeemail">
                Email Address<i>*</i>
              </label>
              <div className="d-flex">
                <input
                  onChange={handleChangeEmail}
                  onBlur={handleBlur}
                  className={`height-45 margin-right-30 ${
                    emailDisabled ? "background-beige" : ""
                  }`}
                  value={emailValue}
                  type="text"
                  id="changeemail"
                  name="changeemail"
                  placeholder="Email"
                  disabled={emailDisabled}
                />
                <span className="error marg-top-55">{emailError}</span>
                <div className="mobile-div">
                  <Button
                    status={0}
                    type="button"
                    className={`resend-btn w-unset height-45 min-width-130px`}
                    onClick={onChangeEmailHandler}
                    title={emailTitle}
                  />
                </div>
              </div>

              <div className={`text-left green`}>
                <span>{emailMessage}</span>
              </div>
            </div>
          </div> */}

          {/*********end */}

          <div className="from-inner-box">
            <div className="verification-code-section">
              <label>
                Let’s confirm your ability to connect to Zoom. Connect now and retrieve your 4-digit validation code.
                <a className="font-size-14 mt-1 pointer learn-more-btn" href="https://us05web.zoom.us/j/81367634087?pwd=JKuymCKOxmdb0s39ssxjbEw9cvseDE.1" target="_blank">Get Zoom Code</a>
              </label>
              <div className="d-flex">



                <input
                  onChange={(e) => onChangesetZoomVerificationCode(e)}
                  onBlur={handleBlur}
                  className="height-45 margin-right-30"
                  value={zoomverificationCode}
                  type="text"
                  id="codeverification"
                  name="codeverification"
                  placeholder="Enter Zoom verification Code"
                />
                {zoomverificationCode === "5630" && <TickMark />}
              </div>
              <div className="d-flex">
                <ul className="text-left mt-3">
                  <li className="line-height-20px">

                    <div className="hover-container">
                      <span>  Why does this matter?</span> <span className="hover-target pointer red " tabindex="1">Learn More</span>
                      <aside className="hover-popup hover-popup-1">
                      
                        <p>We use Zoom video conferencing to connect you with your Medium.</p>
                      </aside>
                    </div>



                  </li>
                  <li className="line-height-20px mt-2">
                    <div className="hover-container">
                      <span> Not working?</span> <span className="hover-target pointer red" tabindex="1">Click Here</span>
                      <aside class="hover-popup hover-popup-2">
                        <b className="text-underline">Instruction :</b> Download Zoom app to your device then try above link again. (You do NOT need to open an account or sign into Zoom.)
                        <a className="font-size-14 mt-1 pointer width-245px learn-more-btn" target="_blank" href="https://zoom.us/download" >Download Zoom app here</a>
                      </aside>
                    </div>


                  </li>
                </ul>
              </div>

            </div>

          </div>
          <div className="from-inner-box">
            <div className="verification-code-section">
              <label>
                Let’s confirm your email.<i></i>
              </label>

              <div className="d-flex">
                {showEmailField ? (
                  <>
                    {verifiedTitle !== "Send Verification Code" && (
                      <input
                        onChange={handleChangeVerification}
                        onBlur={handleBlur}
                        className="height-45 margin-right-30"
                        value={verificationCode}
                        type="text"
                        id="codeverification"
                        name="codeverification"
                        placeholder="Enter verification code we send to you here"
                      />
                    )}
                    {/* <Button
                      status={0}
                      type="button"
                      className={`resend-btn w-unset height-45 min-width-130px ${verifiedTitle === "Verify" ? "bg-green" : ""}`}
                      onClick={time === 0 ? sendVerificationCodeHandler : () => { }}
                      title={verifiedTitle}
                    /> */}
                  </>
                ) : (
                  ""
                )}

                {(verifiedMessage !== "Verified" || showResend) && (
                  <>
                    {resend === 1 && (
                      <Button
                        status={0}
                        type="button"
                        className={`resend-btn w-unset height-45 min-width-130px ml-2`}
                        onClick={reSendVerificationCodeHandler}
                        title={"Resend"}
                      />
                    )}
                    {resend === 2 && <TickMark />}
                  </>
                )}
              </div>
              {verifiedTitle === "Verify" && (
                <>
                  {" "}
                  <br />
                  <div className="text-left mt-1">
                    {" "}
                    {timeDisplay ? (
                      <> <i>*</i>Delivery may take up to {secondsLeft} second.
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-left mt-1">
                    <i>*</i><div class="hover-container"><span>Can’t find the code? </span>
                      <span class="hover-target red" tabindex="1">Click here</span>
                      <aside class="hover-popup">
                        <h2></h2>
                        <p>My email is correct, but can’t find code.: <br /><br />1. Check your spam/junk folders <br />
                          2. Search your email for “verysoul” <br />3.Expect significant delays with Yahoo. <br /> 4. Try resending the code by clicking the Resend button <br />5. Request support help here <a class="user-menu-link" href="https://support.verysoul.com/portal/en/newticket" target="_blank">Help &amp; Support</a></p>
                      </aside>
                    </div>
                  </div>
                  {/* <div className="red text-left mt-1">
                    <i>*</i>Please expect significant mail delivery delays if using Yahoo
                    Mail.
                  </div> */}
                </>
              )}
              {verifiedTitle === "Verified" && (
                <div className="text-left font-size-11">
                  {" "}
                  <i>*</i>Email Succesfully Verfied
                </div>
              )}
              <div className={`text-left mt-1 ${verifiedError ? "red" : "green"}`}>
                {verifiedMessage}
                {changeEmailRequest && (
                  <>
                   <span className="color-555">Wrong Email Address?</span>
                 <span
                    onClick={onChangeEmailHandler}
                    className="ml-1 change-email-request pointer"
                  >
                    Change Email
                  </span>
                  </>
                )}
              </div>
            </div>
            {/*********starte */}

            {!emailDisabled && (
              <div className="verification-code-section mt-3">
                <div className="d-flex">
                  {emailTitle === "Submit" && (
                    <>
                      {" "}
                      <input
                        onChange={handleChangeEmail}
                        onBlur={handleBlur}
                        className={`height-45 margin-right-30 ${emailDisabled ? "background-beige" : ""
                          }`}
                        value={emailValue}
                        type="text"
                        id="changeemail"
                        name="changeemail"
                        placeholder="Email"
                        disabled={emailDisabled}
                      />
                      <span className="error marg-top-55">{emailError}</span>
                    </>
                  )}

                  <div className="mobile-div">
                    <Button
                      status={0}
                      type="button"
                      className={`resend-btn w-unset height-45 min-width-130px`}
                      onClick={onChangeEmailHandler}
                      title={emailTitle}
                    />
                  </div>
                </div>

                <div className={`text-left green`}>
                  <span>{emailMessage}</span>
                </div>

              </div>
            )}
            {/*********end */}
          </div>
          <div className="thanku-popup">
            <button
              onClick={() => customHandleChange(errors)}
              type="submit"
              className="save-btn-clinet-registration width-unset"
              disabled={!verified || zoomverificationCode !== "5630"}
            >
              <div className="d-flex align-items-center">
                <img id="calendar-image-1" src="/images/calendar1.png" />
                <img id="calendar-image-2" src="/images/calendar2.png" />
                <span className="ml-2 mt-2">

                  Save & Proceed to
                  Choose Your Appointment
                </span>
              </div>

            </button>
            <div
              className="modal fade"
              id="myModal"
              role="dialog"
              data-backdrop="static"
            >
              <div className="modal-dialog modal-dialog-preview">
                <div className="modal-content">
                  {/* <!--<button type="button" className="close" data-dismiss="modal">&times;</button>--> */}
                  <div className="modal-body">
                    <div className="icon-pop">
                      <img src="images/Union-icon.png" alt="Union-icon" />
                    </div>
                    <p>
                      Thank you for applying to become a medium with VerySoul.
                      All new membership requests are generally reviewed within
                      24 hours and you will be notified by email. If you don’t
                      hear from us after 24 hours, please check your spam and
                      junk folders, search for{" "}
                      <a className="pointer" href="mailto:welcome@verysoul.com">
                        welcome@verysoul.com
                      </a>
                      , or just come back to log in at{" "}
                      <a className="pointer" href="app.verysoul.com">
                        app.verysoul.com
                      </a>{" "}
                      .
                    </p>
                    <div className="closebt">
                      <button
                        onClick={logoutUser}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default StepTwo;
