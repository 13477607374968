const stepOneData = {
  first_name: "",
  last_name: "",
  phone: "",
  whatsapp: "",
  country: "",
  languages: [],
  timezone: "",
  screen_name: "",

};

const stepTwoData = {
  agree_to_legal_terms_and_conditionss: false,
  //   agree_to_starter_client_guidelines: false,
  opt_in_out: false,
};
export { stepOneData, stepTwoData };
