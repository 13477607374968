import React, { useEffect, useState } from "react";

const Checkbox = ({ medium, status, selected, setSelected }) => {
  const [checked, setChecked] = useState(false);

  const checkBoxChangeHandler = (e) => {


    const array = selected;
    console.log(e.target.checked,"e.target.checked");
    if (e.target.checked) {
      array.push(medium.id);
    } else {
      const index = array.findIndex((num) => {
        console.log(num);
        console.log(medium);

        return num === medium.id;
      });
      array.splice(index, 1);
      console.log(index)
    }
    setSelected(array);
    setChecked(e.target.checked);
  };
  useEffect(() => {
    setChecked(status);
  }, [status]);
  return (
    <>
      <input
        onChange={checkBoxChangeHandler}
        checked={checked}
        value={medium.id}
        type="checkbox"
        id={`checkbox${medium.id}`}
        name={`checkbox${medium.id}`}
      />
      <label htmlFor={`checkbox${medium.id}`}></label>
    </>
  );
};

export default Checkbox;
