export const list = [
  { id: 0, name: "Outreach Clients", count: 0 },
  { id: 1,name: "Starter Clients", count: 0 },
  { id: 2,name: "Restricted Clients", count: 0 },
  { id: 3,name: "Potential Clients", count: 0 },
  { id: 4,name: "Unverified Email", count: 0 },
];

export const filterList = [
  {
    id: 1,
    name: "Email",
    value: "email",
  },
  {
    id: 2,
    name: "Client name",
    value: "screen_name",
  },
  {
    id: 3,
    name: "Register Date",
    value: "created_at",
  },
  {
    id: 4,
    name: "Referral Code",
    value:"referal_code"
  }
];
