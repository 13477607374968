import React, { useEffect, useState } from "react";
import { items, slotsArray } from "../../calendar/data";
import Select from "react-select";
import ReactSelect from "react-select";
import {
  createAppointmentAdmin,
  getClientMediumTimeZone,
} from "../../../../services/admin";
import { da } from "date-fns/locale";
import { toast } from "react-toastify";
import { getTimeImage } from "../../../../utils/utils";
export default function CreateNewAppointment({
  mediums = [],
  clients = [],
  getMediumList,
  getAllFreeClients,
  getAllAppointmentsList,
  activeTab,
  getSubsessions,
  subsessionsList,
  setAllMediums,
  setAllClients,
}) {
  const [payType, setPayType] = useState(7);
  const [mediumList, setMediumList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [mediumValue, setMediumVale] = useState("");
  const [clientValue, setClientVale] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [timer, setTimer] = useState(null);
  const [allowButton, setAllowButton] = useState(false);
  const [appointmentType, setAppointmentType] = useState("");
  const [duration, setDuration] = useState("");
  const [errorType, setErrorType] = useState([]);
  const [colorType, setColorType] = useState("red");

  const [sessionList, setSessionList] = useState([]);
  const [activeSubSessionId, setActiveSubSessionId] = useState("");
  const [clientTimeZone, setClientTimeZone] = useState("");
  const [MediumTimeZone, setMediumTimeZone] = useState("");

  const [mediumdate, setMediumDate] = useState("");
  const [clienttimer, setClientTimer] = useState("");

  const [warningMessage, setWarningMessage] = useState("");

  useEffect(() => {
    setClientList(
      clients.map((item) => {
        return {
          ...item,
          value: item.id,
          label:
            item.first_name + " " + item.last_name + " " + `(${item.email})`,
        };
      })
    );
  }, [clients]);
  useEffect(() => {
    setMediumList(
      mediums.map((item) => {
        return {
          ...item,
          value: item.id,
          label:
            item.first_name + " " + item.last_name + " " + `(${item.email})`,
        };
      })
    );
  }, [mediums]);

  useEffect(() => {
    setSessionList(
      subsessionsList.map((item) => {
        return {
          ...item,
        };
      })
    );
  }, [subsessionsList]);

  const changeHandler = (name, item) => {
    clearTimeout(timer);

    if (name === "client") {
      let data = {
        status: "",
        clientType: "outreach",
        paginationdata: {
          page: 1,
          rowsPerPage: 15,
        },
        client_name: item,
        email: "",
        created_at: "",
        new_appt:true,
      };
      const newTimer = setTimeout(() => {
        getAllFreeClients(data);
      }, 500);
      setTimer(newTimer);
    }
    if (name === "medium") {
      const data = {
        page: 1,
        rowsPerPage: 10,
        screen_name: item,
        email: "",
        created_at: "",
      };
      const newTimer = setTimeout(() => {
        getMediumList(data);
      }, 500);
      setTimer(newTimer);
    }
  };

  const changeHandlerItem = (name, item) => {
    if (name === "client") {
      setClientVale(item);
      // checkClientMediumTime("client",  mediumValue.id ,clientValue.id)
    } else {
      setMediumVale(item);
      checkClientMediumTime("medium", item.id, clientValue.id);
    }
  };
  const onInputChange = (e) => {
    setErrorType([]);
    let name = e.target.name;
    let value = e.target.value;
    if (name === "date") {
      setDate(value);
    }
    if (name === "time") {
      setTime(value);
      createAppointment(value, true);
    }
    if (name === "duration") {
      setDuration(value);
      if (appointmentType !== "" && appointmentType == 7) {
        console.log(appointmentType);
        if (value === "30") {
          setActiveSubSessionId(41);
        }
        if (value === "45") {
          setActiveSubSessionId(42);
        }
        if (value === "60") {
          setActiveSubSessionId(43);
        }
      }
      if (appointmentType !== "" && appointmentType != 7) {
        console.log(appointmentType);
        if (value === "30") {
          setActiveSubSessionId(38);
        }
        if (value === "45") {
          setActiveSubSessionId(39);
        }
      }
      console.log("activeSubSessionId", activeSubSessionId);
    }

    // if(time !==""){
    // }
  };
  const checkDisbaled = () => {
    if (clientValue !== "" && mediumValue !== "") {
      return false;
    } else {
      return true;
    }
  };
  const createAppointment = (value, check) => {
    let sub = sessionList.filter((info) => info.duration === duration);
    console.log(sub);
    let body = {
      client_id: clientValue.value,
      medium_id: mediumValue.value,
      date: date,
      time: value,
      session_type: appointmentType,
      duration: duration,
      subsession_id: sub[0].id,
      check: check,
      // subsession_id:activeSubSessionId
    };
    createAppointmentAdmin(body)
      .then((data) => {
        console.log(data.data);
        if (data.data.status) {
          let info = [];
          if (data.data.message === "Slot Booked Successfully") {
            setPayType(7);
            setMediumVale("");
            setClientVale("");
            setTime("");
            setDate("");
            setAppointmentType("");
            setDuration("");
            setColorType("red");
            setErrorType([]);
            setAllowButton(false);
            setMediumTimeZone("");
            setClientTimeZone("");
            setWarningMessage("");
            setMediumDate("");
            setClientTimer("");
            setClientList([]);
            setMediumList([]);
            setAllMediums([]);
            setAllClients([]);
          }

          // info.push(data.data.message)
          toast.success(data.data.message, { position: "top-center" });
          const dat = {
            page: 1,
            rowsPerPage: 10,
          };
          getAllAppointmentsList(dat, activeTab);
          window.loadModal2("#CreateNewAppointment", "hide");
          // setErrorType(info)
        } else {
          let info = [];
          console.log(data.data.error, "data.data.error");
          if (data.data.error === "warning") {
            console.log(data.data.message, "data.data.message");
            setWarningMessage(data.data.message);
          } else if (data.data.error === "Slot is Available") {
            setAllowButton(true);
            info.push("Slot is Available");
            setColorType("bg-green");
            setWarningMessage("");
            console.log(data.data.data, ":cedl,");
            setMediumDate(data.data.data.medium.date);
            setClientTimer(data.data.data.medium.time);
            // setMediumTimeZone(data.data.data.medium_time);
            // setClientTimeZone(data.data.data.client_time)
          } else {
            typeof data.data.error !== "string"
              ? Object.entries(data.data.error).map(([key, value]) =>
                  info.push(value[0])
                )
              : info.push(data.data.error);
            setColorType("");
            setWarningMessage("");
          }
          setErrorType(info);
        }
      })
      .catch(() => {});
  };

  const cancelRequest = () => {
    console.log("cekdmkd");
    setPayType(7);
    setMediumVale("");
    setClientVale("");
    setTime("");
    setDate("");
    setAppointmentType("");
    setDuration("");
    setColorType("red");
    setErrorType([]);
    setAllowButton(false);
    setMediumTimeZone("");
    setClientTimeZone("");
    setWarningMessage("");
    setMediumDate("");
    setClientTimer("");
    setClientList([]);
    setMediumList([]);
    setAllMediums([]);
    setAllClients([]);

    window.loadModal2("#CreateNewAppointment", "hide");
  };

  const checkClientMediumTime = (name, mediumID, clientId) => {
    let body;
    if (name === "client") {
      body = {
        client_id: clientId,
        medium_id: mediumID,
      };
    } else {
      body = {
        client_id: clientId,
        medium_id: mediumID,
      };
    }
    console.log(body, "body");
    getClientMediumTimeZone(body)
      .then((data) => {
        console.log(data.data, "ewhcbhd");
        // if(data.data.status){
        setMediumTimeZone(data.data.data.medium_timezone);
        setClientTimeZone(data.data.data.client_timezone);
        // }
      })
      .catch(() => {});
  };
  return (
    <div className="register-medium">
      <div
        className="modal fade "
        id="CreateNewAppointment"
        data-backdrop="static"
        role="dialog"
      >
        <div className="width-unset  modal-dialog advanceConfirmbooking modal-lg  modal-dialog-preview height-unset">
          <div className="modal-content p-5">
            <div className="modal-body">
              <div className="">
                <div className="schedule-popup">
                  <h3 className="mb-4 mt-2 text-center mt-2">
                    Create New Appointment
                  </h3>
                  {warningMessage !== "" && (
                    <div className={`eroorbox warning-color`}>
                      {warningMessage}
                    </div>
                  )}
                  {errorType.map((item) => (
                    <div className={`eroorbox ${colorType}`}>{item}</div>
                  ))}
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="from-fild mt-2 mb-4 text-left ">
                        <label className="font-size-17" htmlFor="firstName">
                          Client Name
                        </label>
                        <div className="d-flex align-items-center justify-content-center ">
                          <ReactSelect
                            onInputChange={(item) =>
                              changeHandler("client", item)
                            }
                            options={clientList}
                            onChange={(item) =>
                              changeHandlerItem("client", item)
                            }
                            noOptionsMessage={() => "Type to search"}
                            placeholder="Search Client Name"
                            isSearchable={true}
                            isClearable={true}
                            closeMenuOnSelect={true}
                            closeMenuOnScroll={false}
                            className="CreateNewAppointment"
                            value={clientValue}
                          />
                          {/* <select className='p-2'>
                                                        <option selected disabled> Client Name</option>
                                                        <option>Client 1</option>
                                                        <option>Client 2</option>
                                                        <option>Client 3</option>
                                                        <option>Client 4</option>

                                                    </select> */}
                        </div>
                      </div>
                      <div className="from-fild mt-2 mb-4 text-left ">
                        <label className="font-size-17" htmlFor="firstName">
                          Medium Name
                        </label>
                        <div className="d-flex align-items-center justify-content-center ">
                          <Select
                            onInputChange={(item) =>
                              changeHandler("medium", item)
                            }
                            className="CreateNewAppointment"
                            options={mediumList}
                            onChange={(item) =>
                              changeHandlerItem("medium", item)
                            }
                            placeholder="Search Medium Name"
                            isSearchable={true}
                            isClearable={true}
                            closeMenuOnSelect={true}
                            closeMenuOnScroll={false}
                            value={mediumValue}
                            noOptionsMessage={() => "Type to search"}
                          />
                          {/* <select className='p-2'>
                                                        <option selected disabled> Medium Name</option>
                                                        <option>Medium 1</option>
                                                        <option>Medium 2</option>
                                                        <option>Medium 3</option>
                                                        <option>Medium 4</option>

                                                    </select> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="from-fild mt-2 mb-4 text-left ">
                        <label className="font-size-17" htmlFor="firstName">
                          Appointment Type
                        </label>
                        <div className="d-flex align-items-center justify-content-center  width-335px">
                          <select
                            onChange={(e) => {
                              setAppointmentType(e.target.value);
                              getSubsessions(e.target.value);
                            }}
                            className="p-2 w-100"
                            value={appointmentType}
                          >
                            <option value={""} selected disabled>
                              Type
                            </option>
                            <option value={3}>Starter</option>
                            <option value={5}>Outreach</option>
                            <option value={7}>Professional</option>
                          </select>
                        </div>
                      </div>
                      {appointmentType != 7 && (
                        <div className="from-fild mt-2 mb-4 text-left ">
                          <label className="font-size-17" htmlFor="firstName">
                            Duration
                          </label>
                          <div className="d-flex align-items-center justify-content-center width-335px">
                            <select
                              onChange={onInputChange}
                              name="duration"
                              value={duration}
                              className="p-2 w-100"
                            >
                              <option value={""} selected disabled>
                                Duration
                              </option>
                              {sessionList.map((info) => (
                                <option value={info.duration}>
                                  {info.sub_session_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                      {appointmentType == 7 && (
                        // <div className="d-flex align-items-center justify-content-between">
                        <div className="from-fild mt-2 mb-4 text-left">
                          <label className="font-size-17" htmlFor="firstName">
                            Payment Type
                          </label>
                          <div className="d-flex align-items-center justify-content-center width-335px">
                            <select value={payType} className="p-2 w-100">
                              <option value={""} selected disabled>
                                Payment Type
                              </option>
                              {/* <option>Pre Pay</option> */}
                              <option value={7}>Pay After</option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {appointmentType == 7 && (
                    <div className="from-fild mt-2 mb-4 text-left ">
                      <label className="font-size-17" htmlFor="firstName">
                        Duration
                      </label>
                      <div className="d-flex align-items-center justify-content-center">
                        <select
                          onChange={onInputChange}
                          name="duration"
                          value={duration}
                          className="p-2 w-100"
                        >
                          <option value={""} selected disabled>
                            Duration
                          </option>
                          {sessionList.map((info) => (
                            <option value={info.duration}>
                              {info.sub_session_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  <div>
                    <div className="d-flex align-items-center ">
                      <div className="from-fild mt-2 mb-4 text-left width-70px">
                        <span className="d5a67b">Client</span>
                      </div>
                      <div className="from-fild mt-2 mb-4 text-left width-150px max-width-150px  mr-3">
                        <label className="font-size-17" htmlFor="firstName">
                          Date
                        </label>
                        <div className="d-flex align-items-center justify-content-center">
                          <input
                            disabled={checkDisbaled()}
                            onChange={onInputChange}
                            name="date"
                            type="date"
                            className="d-block p-2"
                            value={date}
                          />
                        </div>
                      </div>
                      <div className="from-fild mt-2 mb-4 text-left width-150px max-width-150px">
                        <label className="font-size-17" htmlFor="firstName">
                          Time
                        </label>
                        <div className="d-flex align-items-center justify-content-center">
                          <select
                            disabled={checkDisbaled()}
                            onChange={onInputChange}
                            name="time"
                            className="p-2"
                            value={time}
                          >
                            <option value={""} selected disabled>
                              {" "}
                              Time
                            </option>
                            {slotsArray.map((time) => (
                              <option
                                className="moon-create-app star-imh-moon"
                                value={time.time}
                              >
                                {time.change == "NO" ? "12:00 AM" : time.time}{" "}
                                {getTimeImage(time.time) ? (
                                  <span>🌙</span>
                                ) : (
                                  <></>
                                )}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="from-fild ml-2 text-left">
                        <span className="font-size-14">{clientTimeZone}</span>
                        {/* {clientTimeZone !== "" && <div className="d-flex align-items-center justify-content-center">
                                        <div className="from-fild mt-2 mb-4 text-left">
                                            <span className="text-center d-block mb-1">
                                                {" "}
                                                Client Timezone:
                                            </span>{" "}
                                            {clientTimeZone}
                                        </div>

                                    </div>
                                    } */}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex align-items-center ">
                      <div className="from-fild mt-2 mb-4 text-left width-70px">
                        <span className="d5a67b">Medium</span>
                      </div>
                      <div className="from-fild mt-2 mb-4 text-left width-150px max-width-150px mr-3">
                        <div className="d-flex align-items-center justify-content-center">
                          <input
                            disabled={true}
                            // onChange={onInputChange}
                            // name="date"
                            type="date"
                            className="d-block p-2"
                            value={mediumdate}
                          />
                        </div>
                      </div>
                      <div className="from-fild mt-2 mb-4 text-left width-150px max-width-150px">
                        {/* <label className="font-size-17" htmlFor="firstName">
                                                    Time
                                                </label> */}
                        <div className="d-flex align-items-center justify-content-center">
                          <select
                            disabled={true}
                            // onChange={onInputChange}
                            name="time"
                            className="p-2"
                            value={clienttimer}
                          >
                            <option value={""} selected disabled>
                              {" "}
                              Time
                            </option>
                            {slotsArray.map((time) => (
                              <option
                                className="moon-create-app star-imh-moon"
                                value={time.time}
                              >
                                {time.change == "NO" ? "12:00 AM" : time.time}{" "}
                                {getTimeImage(time.time) ? (
                                  <span>🌙</span>
                                ) : (
                                  <></>
                                )}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="from-fild ml-2 text-left">
                        <span className="font-size-14">{MediumTimeZone}</span>
                        {/* {clientTimeZone !== "" && <div className="d-flex align-items-center justify-content-center">
                                        <div className="from-fild mt-2 mb-4 text-left">
                                            <span className="text-center d-block mb-1">
                                                {" "}
                                                Client Timezone:
                                            </span>{" "}
                                            {clientTimeZone}
                                        </div>

                                    </div>
                                    } */}
                      </div>
                    </div>
                  </div>
                  {/* {MediumTimeZone !== "" && <div className="d-flex align-items-center justify-content-center">
                                        <div className="from-fild mt-2 mb-4 text-left">
                                            <span className="text-center d-block mb-1">
                                                {" "}
                                                Medium Timezone:
                                            </span>{" "}
                                            {MediumTimeZone}
                                        </div>

                                    </div>} */}

                  {/* <div className="d-flex justify-content-between">
                                    <div
                                        className="advance-tag pointer text-center width-150px max-width-150px"
                                        onClick={onCancelHandler}
                                    >
                                        Cancel
                                    </div>
                                    <div
                                        className="advance-tag pointer text-center width-150px max-width-150px ml-2"
                                        onClick={updateHandler}
                                    >
                                        Update
                                    </div>
                                </div> */}
                </div>
                <div className="confirm-delete-wrapper ">
                  <div className="confirm-btn-box">
                    <div
                      className="register-medium-confirnation"
                      onClick={cancelRequest}
                      // data-dismiss="modal"
                    >
                      Cancel
                    </div>
                    <div
                      className="register-medium-confirnation"
                      // data-dismiss="modal"
                      onClick={
                        allowButton
                          ? () => createAppointment(time, false)
                          : () => {}
                      }
                    >
                      Confirm
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
