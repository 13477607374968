
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Tabs from "../../../components/tabs";
import TabPanel from "../../../components/tabs/tabpanel";
import FreeClients from "./freeclients";
import UnverifiedEmails from "./unverifiedemails";
// import CsvDownloadAll from "../../../components/csvdownloadAll";
import { list } from "./data";

//
import {
  setAllClients,
  setAllStarterClients,
  setAllOutreachClientsCount,
  setAllStarterClientsCount,
  setAllRestrictedClients,
  setAllRestrictedClientsCount,
  setAllVolunteerClients,
  setAllVolunteerClientsCount,
  setAllUnverifiedEmails,
  setAllUnverifiedEmailsCount,
  setAllPendingApprovalsPage,
  setAllPendingApprovalsrowsPerPage,
  setAllUnverifiedEmailsPage,
  setAllUnverifiedEmailsrowsPerPage,
  setAllOutreachClientsPage,
  setAllOutreachClientsrowsPerPage,

  setAllStarterClientsPage,
  setAllStarterClientsrowsPerPage,
  setAllRestrictedClientsPage,
  setAllRestrictedClientsrowsPerPage,

  setAllVolunteerClientsPage,
  setAllVolunteerClientsrowsPerPage,

} from "../../../reducer/adminSlice";

//service
import {
  approveUser,
  filterAllClients,
  getAllClients,
  resendActivationMail,
  getUnverfiedEmailsClients
} from "../../../services/admin";

import "./client.css";
import ConfirmDelete from "../Subcomponents/deleteconfirm";
import StarterClient from "./paidclients";
import RestrictedClients from "./restrictedClients";
import VolunteerClients from "./volunteerClients";
import { useLocation, useSearchParams } from "react-router-dom";

export default function Clients() {
  const dispatch = useDispatch();
  const {
    allClients = [],
    allStarterClients = [],
    allOutreachClientsCount,
    allStarterClientsCount,
    allRestrictedClients,
    allRestrictedClientsCount,
    allVolunteerClients,
    allVolunteerClientsCount,
    allunverifiedEmails,
    allUnverifiedEmailsCount,

    allPendingApprovalsPage,
    allPendingApprovalsrowsPerPage,
    allUnverifiedEmailsPage,
    allUnverifiedEmailsrowsPerPage,
    allOutreachClientsPage,
    allOutreachClientsrowsPerPage,

    allStarterClientsPage,
    allStarterClientsrowsPerPage,
    allRestrictedClientsPage,
    allRestrictedClientsrowsPerPage,

    allVolunteerClientsPage,
    allVolunteerClientsrowsPerPage,
    globalRowsPerPage,
  } = useSelector((state) => state.users);


  const [listArray, setListArray] = useState(list);
  const [activeTab, setActiveTab] = useState(0);
  const [clients, setClients] = useState([]);
  const [starterclients, setStarterClients] = useState([]);
  const [restrictedclients, setRestrictedClients] = useState([]);
  const [volunteerclients, setVolunteerClients] = useState([]);
  const [unverifiedEmails, setUnverifiedEmails] = useState([]);
  const [backupclients, setbackupClients] = useState([]);
  const [restructedbackupclients, setbackupRestrictedClients] = useState([]);
  const [volunteerbackupclients, setbackupVolunteerClients] = useState([]);
  const [backupStarterclients, setStarterbackupClients] = useState([]);
  const [backupUnverfiedEmails, setbackupUnverifiedEmails] = useState([]);

  const [sortingNameOrderBy, setSortingNameOrderBy] = useState('');
  // const [sortingNameCreated, setSortingNameCreated] = useState('');
  const [sortingTtabType, setSortingTabType] = useState('');

  const [searchParams] = useSearchParams();
  const status = searchParams.get("view");
  const searchVal = searchParams.get("search");
  const searchName = searchParams.get("searchName");
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');
  const pagintion = searchParams.get('pagintion');


  const location = useLocation();
  const [deleteInfo, setDeletInfo] = useState({});
  // const [filterStatus ,setfilterStatus] = useState(false)
  const [filterValues, setFilterValues] = useState('screen_name');
  const [searchValue, setSearchValues] = useState('');
  useEffect(() => {
    localStorage.setItem('pagintionTYPE', pagintion)
    if (searchVal !== null) {
      setFilterValues(searchVal);
    }
    if (searchName !== null) {
      setSearchValues({ [searchVal]: searchName })
    }
    if (page !== null) {

    }
    setTimeout(() => {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete("view");
      newUrl.searchParams.delete("search");
      newUrl.searchParams.delete("searchName");
      newUrl.searchParams.delete("page");
      newUrl.searchParams.delete("rowperpage");
      newUrl.searchParams.delete("pagintion");
      // setQueryValue(newUrl.searchParams.delete("view"))
      window.history.replaceState({}, document.title, newUrl.href);
    }, 2000)

    console.log(location.search, "location.search location.search ")
    if (location.pathname === "/clients" && location.search === "") {
      launcApi();

    }
    if (location.pathname === "/clients" && location.search === "?view=0") {
      setClients(backupclients)

    }
    if (location.pathname === "/clients" && location.search === "?view=1") {
      setStarterClients(backupStarterclients)

    }
    if (location.pathname === "/clients" && location.search === "?view=2") {
      setRestrictedClients(restructedbackupclients)

    }
    if (location.pathname === "/clients" && location.search === "?view=3") {
      setVolunteerClients(volunteerbackupclients)

    }
    if (location.pathname === "/clients" && location.search === "?view=4") {
      setUnverifiedEmails(backupUnverfiedEmails)

    }
    if (status === "all" || status === "4") setActiveTab(4);
    if (status === "1") setActiveTab(1);
    if (status === "2") setActiveTab(2);
    if (status === "3") setActiveTab(3);
    if (status === "5") setActiveTab(5);
  }, []);


  const setListedArray = (item, set) => {
    const update = item.map((resp) => {
      return {
        ...resp,
        client_status: false,
        resent_status: 0,
      };
    });

    set(update)
    setListArray(
      list.map((item) => {
        if (item.name === "Outreach Clients") {
          return {
            ...item,
            ...(item.count = allOutreachClientsCount),
          };
        }
        if (item.name === "Starter Clients") {
          return {
            ...item,
            ...(item.count = allStarterClientsCount),
          };

        }
        if (item.name === "Restricted Clients") {
          return {
            ...item,
            ...(item.count = allRestrictedClientsCount),
          };
        }
        if (item.name === "Potential Clients") {
          return {
            ...item,
            ...(item.count = allVolunteerClientsCount),
          };
        }
        if (item.name === "Unverified Email") {
          return {
            ...item,
            ...(item.count = allUnverifiedEmailsCount),
          };
        }
      })
    );
  }

  useEffect(() => {
    setListedArray(allClients, setClients);
  }, [allClients]);

  useEffect(() => {
    setListedArray(allStarterClients, setStarterClients);
  }, [allStarterClients]);
  useEffect(() => {
    setListedArray(allRestrictedClients, setRestrictedClients);
  }, [allRestrictedClients]);

  useEffect(() => {
    setListedArray(allVolunteerClients, setVolunteerClients);
  }, [allVolunteerClients]);

  useEffect(() => {
    setListedArray(allunverifiedEmails, setUnverifiedEmails);
  }, [allunverifiedEmails]);



  useEffect(() => {
    if (window.renderFirst) {
      const data = {
        page: 1,
        rowsPerPage: 10,
      };

      if (activeTab === 0) {
        // setFilterValues('screen_name')
        // setSearchValues('')
        getAllStarterClients(data, 'tab');
        getAllRestrictedClients(data, 'tab');
        getAllVolunteerClients(data, 'tab');
        getAllUnVerifiedEmails(data, 'tab');


        getAllFreeClients(data);
      }

      if (activeTab === 1) {
        // setFilterValues('screen_name')
        // setSearchValues('')

        getAllFreeClients(data, 'tab');
        getAllRestrictedClients(data, 'tab');
        getAllVolunteerClients(data, 'tab');
        getAllUnVerifiedEmails(data, 'tab');

        getAllStarterClients(data);
      }
      if (activeTab === 2) {
        // setFilterValues('screen_name')
        // setSearchValues('')
        getAllFreeClients(data, 'tab');


        getAllStarterClients(data, 'tab');
        getAllVolunteerClients(data, 'tab');
        getAllUnVerifiedEmails(data, 'tab');

        getAllRestrictedClients(data);
      }
      if (activeTab === 3) {
        // setFilterValues('screen_name')
        // setSearchValues('')
        getAllFreeClients(data, 'tab');


        getAllStarterClients(data, 'tab');
        getAllRestrictedClients(data, 'tab');
        getAllUnVerifiedEmails(data, 'tab');

        getAllVolunteerClients(data)

      }
      if (activeTab === 4) {
        // setFilterValues('screen_name')
        // setSearchValues('')
        getAllFreeClients(data, 'tab');


        getAllStarterClients(data, 'tab');
        getAllRestrictedClients(data, 'tab');
        getAllVolunteerClients(data, 'tab');

        getAllUnVerifiedEmails(data);

      }
    }
    else {
      window.renderFirst = true;
    }
  }, [activeTab]);

  //volunteer client

  //restricted clients
  const getAllRestrictedClients = (paginationdata, info) => {
    let data = {
      clientType: "",
      status: 4,
      paginationdata: paginationdata,
      client_name: info === "tab" ? '' : searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '',
      email: info === "tab" ? '' : searchValue !== undefined && searchValue.email !== undefined ? searchValue.email : '',
      created_at: info === "tab" ? '' : searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      created_at_order_by: paginationdata.created_at_order_by !== undefined ? paginationdata.created_at_order_by : '',
      orderBy: paginationdata.orderBy !== undefined ? paginationdata.orderBy : ''
    };
    getAllClients(data)
      // getAllClients("outreach")
      .then((data) => {
        if (data.data.status) {
          dispatch(setAllRestrictedClientsCount(data.data.data.total));

          dispatch(setAllRestrictedClients(data.data.data.data));
          setbackupRestrictedClients(JSON.parse(JSON.stringify(data.data.data.data)));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllFreeClients = (paginationdata, info) => {
    let data = {
      status: "",
      clientType: "outreach",
      paginationdata: paginationdata,
      client_name: info === "tab" ? '' : searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '',
      email: info === "tab" ? '' : searchValue !== undefined && searchValue.email !== undefined ? searchValue.email : '',
      created_at: info === "tab" ? '' : searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      created_at_order_by: paginationdata.created_at_order_by !== undefined ? paginationdata.created_at_order_by : '',
      orderBy: paginationdata.orderBy !== undefined ? paginationdata.orderBy : ''

    };
    getAllClients(data)
      // getAllClients("outreach")
      .then((data) => {
        if (data.data.status) {
          dispatch(setAllOutreachClientsCount(data.data.data.total));

          dispatch(setAllClients(data.data.data.data));
          setbackupClients(JSON.parse(JSON.stringify(data.data.data.data)));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllStarterClients = (paginationdata, info) => {
    let data = {
      status: "",
      clientType: "starter",
      paginationdata: paginationdata,
      client_name: info === "tab" ? '' : searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '',
      email: info === "tab" ? '' : searchValue !== undefined && searchValue.email !== undefined ? searchValue.email : '',
      created_at: info === "tab" ? '' : searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      created_at_order_by: paginationdata.created_at_order_by !== undefined ? paginationdata.created_at_order_by : '',
      orderBy: paginationdata.orderBy !== undefined ? paginationdata.orderBy : ''
    };
    getAllClients(data)
      .then((data) => {
        if (data.data.status) {
          dispatch(setAllStarterClientsCount(data.data.data.total));

          dispatch(setAllStarterClients(data.data.data.data));
          setStarterbackupClients(
            JSON.parse(JSON.stringify(data.data.data.data))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllVolunteerClients = (paginationdata, info) => {

    let data = {
      status: "",
      clientType: "volunteer",
      paginationdata: paginationdata,
      client_name: info === "tab" ? '' : searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '',
      email: info === "tab" ? '' : searchValue !== undefined && searchValue.email !== undefined ? searchValue.email : '',
      created_at: info === "tab" ? '' : searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      created_at_order_by: paginationdata.created_at_order_by !== undefined ? paginationdata.created_at_order_by : '',
      orderBy: paginationdata.orderBy !== undefined ? paginationdata.orderBy : ''
    };
    getAllClients(data)
      .then((data) => {
        if (data.data.status) {
          dispatch(setAllVolunteerClientsCount(data.data.data.total));

          dispatch(setAllVolunteerClients(data.data.data.data));
          setbackupVolunteerClients(
            JSON.parse(JSON.stringify(data.data.data.data))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const launcApi = () => {
    const data = {
      page: 1,
      rowsPerPage: 10,
    };
    getAllFreeClients(data);
    getAllStarterClients(data);
    getAllRestrictedClients(data);
    getAllVolunteerClients(data);
    getAllUnVerifiedEmails(data);
  };

  const getPaginationData = (data, activeTab) => {

    if (activeTab + 1 === parseInt(sortingTtabType)) {
      Object.assign(data, sortingNameOrderBy)
    }
    if (activeTab === 0) {

      getAllFreeClients(data);
    }

    if (activeTab === 1) {
      getAllStarterClients(data);
    }
    if (activeTab === 2) {
      getAllRestrictedClients(data);
    }
    if (activeTab === 3) {
      getAllVolunteerClients(data);
    }
    if (activeTab === 4) {
      getAllUnVerifiedEmails(data);
    }
  };

  const getAllUnVerifiedEmails = (paginationdata, info) => {

    let data = {
      status: "",
      clientType: "unverified",
      paginationdata: paginationdata,
      client_name: info === "tab" ? '' : searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '',
      email: info === "tab" ? '' : searchValue !== undefined && searchValue.email !== undefined ? searchValue.email : '',
      created_at: info === "tab" ? '' : searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      created_at_order_by: paginationdata.created_at_order_by !== undefined ? paginationdata.created_at_order_by : '',
      orderBy: paginationdata.orderBy !== undefined ? paginationdata.orderBy : ''
    };
    getAllClients(data)
      .then((data) => {
        if (data.data.status) {


          dispatch(setAllUnverifiedEmailsCount(data.data.data.total))
          dispatch(setAllUnverifiedEmails(data.data.data.data));
          setbackupUnverifiedEmails(JSON.parse(JSON.stringify(data.data.data.data)));

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const getAllUnVerifiedEmails = async (data) => {
  //   // dispatch(setLoading(true));
  //   const response = await getUnverfiedEmailsClients(data)
  //     .then((data) => {
  //       // dispatch(setLoading(false));
  //       const update = data.data.data.data.map((resp) => {
  //         return {
  //           ...resp,
  //           status: false,
  //         };
  //       });
  //       dispatch(setAllUnverifiedEmailsCount(data.data.data.total))
  //       dispatch(setAllUnverifiedEmails(update));
  //       setbackupUnverifiedEmails(JSON.parse(JSON.stringify(data.data.data.data)));
  //     })
  //     .catch((err) => {
  //       // dispatch(setLoading(false));
  //       console.log(err);
  //     });

  //   return response;
  // };

  const onClickHandler = (e, id, name) => {
    e.stopPropagation();
    if (name === "outreach") {
      let data = JSON.parse(JSON.stringify(clients));
      const response = data.map((user) => {
        if (user.id === id) {
          return Object.assign(user, { client_status: !user.client_status });
        } else {
          return Object.assign(user, { client_status: false });
        }
      });
      setClients(response);
    }
    if (name === "starter") {

      let data = JSON.parse(JSON.stringify(starterclients));
      const response = data.map((user) => {
        if (user.id === id) {
          return Object.assign(user, { client_status: !user.client_status });
        } else {
          return Object.assign(user, { client_status: false });
        }
      });
      setStarterClients(response);
    }
    if (name === "restricted") {

      let data = JSON.parse(JSON.stringify(restrictedclients));
      const response = data.map((user) => {
        if (user.id === id) {
          return Object.assign(user, { client_status: !user.client_status });
        } else {
          return Object.assign(user, { client_status: false });
        }
      });
      setRestrictedClients(response);
    }
    if (name === "volunteer") {

      let data = JSON.parse(JSON.stringify(volunteerclients));
      const response = data.map((user) => {
        if (user.id === id) {
          return Object.assign(user, { client_status: !user.client_status });
        } else {
          return Object.assign(user, { client_status: false });
        }
      });
      setVolunteerClients(response);
    }
    if (name === "unverifiedemails") {

      let data = JSON.parse(JSON.stringify(unverifiedEmails));
      const response = data.map((user) => {
        if (user.id === id) {
          return Object.assign(user, { client_status: !user.client_status });
        } else {
          return Object.assign(user, { client_status: false });
        }
      });
      setUnverifiedEmails(response);
    }
  };

  const updateUser = async (user, status, name, item) => {
    if (name === "delete") {
      window.loadModal2("#deleteConfirmation", "show");
      let data = { user, status, name, item };
      setDeletInfo(data);
    } else {
      deleteUser(user, status, name, item);
    }
  };
  const deleteUser = async (user, status, name, item) => {
    let body = {
      user_id: status !== undefined ? user.id : deleteInfo.user.id,
      medium_status:
        status !== undefined
          ? user.medium_status
          : deleteInfo.user.medium_status,
      status: status !== undefined ? status : deleteInfo.status,
    };
    await approveUser(body)
      .then((data) => {
        if (data.data.status) {
          const data = {
            page: 1,
            rowsPerPage: 10,
          };
          getAllFreeClients(data);
          getAllStarterClients(data);
          getAllRestrictedClients(data);
          getAllUnVerifiedEmails(data);
        }
      })
      .catch(() => { });
  };

  const resendEmail = (e, id, name) => {
    // const activeClient = name === "outreach" ? clients : starterclients;
    let activeClient;
    let activeSetData;
    if (name === "outreach") {
      activeClient = clients;
    } else if (name === "starter") {
      activeClient = starterclients;
    } else if (name === "unverifiedEmails") {
      activeClient = unverifiedEmails
    }
    if (name === "outreach") {
      activeSetData = setClients;
    } else if (name === "starter") {
      activeSetData = setStarterClients;
    } else if (name === "unverifiedEmails") {
      activeSetData = setUnverifiedEmails
    }
    // const activeSetData = name === "outreach" ? setClients : setStarterClients;

    e.stopPropagation();
    resendActivationMail(id, name)
      .then((data) => {
        if (data.data.status) {
          let response = activeClient.map((item) => {
            if (parseInt(item.id) === parseInt(id)) {
              return {
                ...item,
                ...(item.resent_status = 1),
              };
            } else {
              return { ...item };
            }
          });
          activeSetData(response);
        }
        if (!data.data.status) {
          let response = activeClient.map((item) => {
            if (parseInt(item.id) === parseInt(id)) {
              return {
                ...item,
                ...(item.resent_status = 2),
              };
            } else {
              return { ...item };
            }
          });
          activeSetData(response);
        }
      })
      .catch(() => { });

    setTimeout(() => {
      let response = activeClient.map((item) => {
        return {
          ...item,
          ...(item.resent_status = 0),
        };
      });
      activeSetData(response);
    }, 2000);
  };
  const filterListClients = (constants) => {
    // console.log(constants);
    if (Boolean(constants.created_at_order_by)) {
      var { email, screen_name, created_at, created_at_order_by, orderBy, referal_code } = constants;
    } else {
      var { email, screen_name, created_at, created_at_order_by, orderBy = "asc", referal_code } = constants;
    }

    let client_name = screen_name == undefined ? searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '' : screen_name;
    let emails = email == undefined ? searchValue !== undefined && searchValue.email !== undefined ? searchValue.email : '' : email;
    let created_att = created_at == undefined ? searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '' : created_at;
    let body = {
      email: emails,
      screen_name: client_name,
      created_at: created_att,
      created_at_order_by,
      orderBy,
      referal_code
    };
    let type = "";
    if (activeTab === 0) {
      type = "outreach"
    }
    if (activeTab === 1) {
      type = "starter"
    }
    if (activeTab === 2) {
      type = "restricted"
    }

    if (activeTab === 3) {
      type = "volunteer"
    }
    if (activeTab === 4) {
      type = "unverified"
    }


    // let type = activeTab === 1 ? "starter" : "outreach";
    filterAllClients(body, type)
      .then((data) => {
        if (data.data.status) {

          // dispatch(setAllStarterClientsCount(data.data.data.total));
          // dispatch(setAllOutreachClientsCount(data.data.data.total));
          // dispatch(setAllVolunteerClientsCount(data.data.data.total));
          if (activeTab === 0) {
            dispatch(setAllOutreachClientsCount(data.data.data.total));
            dispatch(setAllClients(data.data.data.data))

            dispatch(setAllOutreachClientsPage(allOutreachClientsPage + 1));
            dispatch(setAllOutreachClientsrowsPerPage(globalRowsPerPage));

            setbackupClients(JSON.parse(JSON.stringify(data.data.data.data)))

          }
          if (activeTab === 1) {

            dispatch(setAllStarterClientsCount(data.data.data.total));
            dispatch(setAllStarterClients(data.data.data.data))

            dispatch(setAllStarterClientsPage(allStarterClientsPage + 1));
            dispatch(setAllStarterClientsrowsPerPage(globalRowsPerPage));
            setStarterbackupClients(JSON.parse(JSON.stringify(data.data.data.data)))

          }
          if (activeTab === 2) {


            dispatch(setAllRestrictedClientsCount(data.data.data.total));
            dispatch(setAllRestrictedClients(data.data.data.data))
            
            dispatch(setAllRestrictedClientsPage(allRestrictedClientsPage + 1));
            dispatch(setAllRestrictedClientsrowsPerPage(globalRowsPerPage));
            setbackupRestrictedClients(JSON.parse(JSON.stringify(data.data.data.data)))

          }
          if (activeTab === 3) {
           




            dispatch(setAllVolunteerClientsCount(data.data.data.total));
            dispatch(setAllVolunteerClients(data.data.data.data))
            dispatch(setAllVolunteerClientsPage(allVolunteerClientsPage + 1));
            dispatch(setAllVolunteerClientsrowsPerPage(globalRowsPerPage));
            setbackupVolunteerClients(JSON.parse(JSON.stringify(data.data.data.data)))

          }
          if (activeTab === 4) {
            dispatch(setAllUnverifiedEmailsCount(data.data.data.total));
            dispatch(setAllUnverifiedEmails(data.data.data.data))

            dispatch(setAllUnverifiedEmailsPage(allUnverifiedEmailsPage + 1));
            dispatch(setAllUnverifiedEmailsrowsPerPage(globalRowsPerPage));
            setbackupUnverifiedEmails(JSON.parse(JSON.stringify(data.data.data.data)))

          }
          // dispatch(
          //   activeTab === 1
          //     ? setAllStarterClients(data.data.data.data)
          //     : setAllClients(data.data.data.data)
          // );
        }
      })
      .catch(() => { });

    // let type = activeTab === 1 ? "starter" : "outreach";
    // filterAllClients(body, type)
    //   .then((data) => {
    //     if (data.data.status) {
    //       dispatch(setAllOutreachClientsCount(data.data.data.total));
    //       dispatch(
    //         activeTab === 1
    //           ? setAllStarterClients(data.data.data.data)
    //           : setAllClients(data.data.data.data)
    //       );
    //     }
    //   })
    //   .catch(() => { });

    // }
  };
  const filterReset = () => {
    setFilterValues('screen_name');
    setSearchValues("");
    let dataVal = {
      "email": "",
      "client_name": "",
      "created_at": "",
      "orderBy": "asc",
      "referal_code": "",
      "created_at_order_by": ""
    }
    filterListClients(dataVal);
  }


  return (
    <div className="all-medium-page">
      <Tabs active={activeTab} list={listArray} setActiveTab={setActiveTab} />

      <ul className="tabs-content">
        <TabPanel id={0} activeTab={activeTab}>
          <FreeClients
            resendEmail={resendEmail}
            updateUser={updateUser}
            filterListClients={filterListClients}
            filterReset={filterReset}
            clients={clients}
            onClickHandler={onClickHandler}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            allOutreachClientsPage={allOutreachClientsPage}
            allOutreachClientsrowsPerPage={allOutreachClientsrowsPerPage}


          />
        </TabPanel>
        <TabPanel id={1} activeTab={activeTab}>
          <StarterClient
            resendEmail={resendEmail}
            updateUser={updateUser}
            filterListClients={filterListClients}
            filterReset={filterReset}
            clients={starterclients}
            onClickHandler={onClickHandler}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            allStarterClientsPage={allStarterClientsPage}
            allStarterClientsrowsPerPage={allStarterClientsrowsPerPage}


          />
        </TabPanel>
        <TabPanel id={2} activeTab={activeTab}>
          <RestrictedClients
            resendEmail={resendEmail}
            updateUser={updateUser}
            filterListClients={filterListClients}
            filterReset={filterReset}
            clients={restrictedclients}
            onClickHandler={onClickHandler}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}

            allRestrictedClientsPage={allRestrictedClientsPage}
            allRestrictedClientsrowsPerPage={allRestrictedClientsrowsPerPage}

          />
        </TabPanel>
        <TabPanel id={3} activeTab={activeTab}>
          <VolunteerClients
            resendEmail={resendEmail}
            updateUser={updateUser}
            filterListClients={filterListClients}
            filterReset={filterReset}
            clients={volunteerclients}
            onClickHandler={onClickHandler}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}


            allVolunteerClientsPage={allVolunteerClientsPage}
            allVolunteerClientsrowsPerPage={allVolunteerClientsrowsPerPage}
          />
        </TabPanel>
        <TabPanel id={4} activeTab={activeTab}>
          <UnverifiedEmails
            resendEmail={resendEmail}
            updateUser={updateUser}
            filterListClients={filterListClients}
            filterReset={filterReset}
            unverifiedEmails={unverifiedEmails}
            onClickHandler={onClickHandler}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            allUnverifiedEmailsPage={allUnverifiedEmailsPage}
            allUnverifiedEmailsrowsPerPage={allUnverifiedEmailsrowsPerPage}
          />
        </TabPanel>
      </ul>

      <ConfirmDelete deleteUser={deleteUser} id="deleteConfirmation" />
    </div>
  );
}