import React, { useEffect, useState } from "react";
import Filter from "../filter";
import "./search.css";
import Button from "../button";
import MultiDatePicker from 'react-multi-date-picker';

export default function Search({ callApi, list, setFilterValues,
  setSearchValues, searchValue, filterValues, isDatepickerShow }) {
  const [inputValue, setInputValue] = useState(searchValue !== null && searchValue !== undefined ? Object.values(searchValue)[0] : '');
  const [timer, setTimer] = useState(null);
  const [filterValue, setFilterValue] = useState(filterValues == undefined ? '' : filterValues);

  const [values, setValues] = useState([]);



  useEffect(() => {
    // console.log(searchValue, "searchValue");

    setInputValue(searchValue !== null && searchValue !== undefined && searchValue !== "" ? Object.values(searchValue)[0] : '');
    setValues(searchValue !== null && searchValue !== undefined && searchValue !== "" ? values : '');
    

  }, [searchValue])
  // useEffect(() => {
  //   // console.log(searchValue, "searchValue");
  //   setInputValue('');

  // }, [])
  const getValue = (obj) => {
    // Object.values(obj)[0];
    return obj !== null && obj !== undefined ? Object.values(obj)[0] : ''
  }
  const inputChanged = (e) => {
    setInputValue(e.target.value);

    let name = {};

    if (filterValue === "") {
      name["screen_name"] = e.target.value;
    } else {
      name[filterValue] = e.target.value;
    }
    clearTimeout(timer);
    setSearchValues(name);
    const newTimer = setTimeout(() => {
      if (e.target.value.length > 2 || e.target.value.length === 0) {
        callApi(name);
      }
    }, 2000);

    setTimer(newTimer);
  };
  const changeHandler = (e) => {
    setFilterValue(e.target.value);
    // setInputValue("");
    setFilterValues(e.target.value);
  };
  const handleValueChange = (newValues) => {
    // This function will be called when the date picker's value changes
    if (newValues) {
      setValues(newValues);
      let name = {};
      if(newValues.length === 2){
        const startDate = newValues[0].format("YYYY-MM-DD");
        const endDate = newValues[1].format("YYYY-MM-DD");
        name["created_at"] = `${startDate},${endDate}`; // Fix the assignment
      }else{
        const startDate = newValues[0].format("YYYY-MM-DD");
        name["created_at"] = `${startDate}`; // Fix the assignment
      }
     

      clearTimeout(timer);
      setSearchValues(name);
      const newTimer = setTimeout(() => {

        console.log(name);
        callApi(name);

      }, 2000);
      setTimer(newTimer);
    }
  };
  return (
    <div className="filter-wrapper">
      {isDatepickerShow && isDatepickerShow === 1 ? (
        filterValues === "created_at" ? (
          <MultiDatePicker
            style={{ height: "45px" }}
            className="search-input"
            value={values}
            onChange={handleValueChange}
            range
            placeholder="Select date range"
            maxDate={new Date()}
          />
        ) : (
          <div className="search-box search-container">
            <input
              value={inputValue}
              onChange={inputChanged}
              className="search-input"
              type={filterValues === "created_at" ? "date" : "text"}
              placeholder="Search"
            />
          </div>
        )
      ) : (
        <div className="search-box search-container">
          <input
            value={inputValue}
            onChange={inputChanged}
            className="search-input"
            type={filterValues === "created_at" ? "date" : "text"}
            placeholder="Search"
          />
        </div>
      )}

      <Filter list={list} changeHandler={changeHandler} filterValue={filterValues} name={'Filter By'} />
    </div>
  );
}