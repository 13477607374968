import React, { useState } from "react";
import { copyToClipboard } from "../../utils/helpers";

export default function CopyComponent({value}) {
  const [copy, setCopy] = useState(false);
  const copyText = (e, value) => {
    setCopy(true);
    e.stopPropagation();
    copyToClipboard(value);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  };
  return (
    <div className="d-inline">
      {copy ? (
        <span className="green unset-float ml-2">Copied</span>
      ) : (
        <img
          title="Copy"
          onClick={(e) => copyText(e, value)}
          className="googl-link-copy-img copy-image-animate"
          alt="copy"
          src="images/copy.png"
        />
      )}
    </div>
  );
}
