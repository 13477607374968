import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminDashboard from "../pages/admin/dashboard/dashboard";
import Mediums from "../pages/admin/mediums/mediums";
import MediumView from "../pages/admin/mediumprofileview";
import ClientView from "../pages/admin/clientprofileview";
import Appointments from "../pages/admin/appointments";
import Clients from "../pages/admin/clients";
import Feedback from "../pages/admin/feedback";
import InspirationStories from "../pages/admin/inspirationstories";
// import TermsAndConditions from "../pages/termsandcondition";
import MediumEdit from "../pages/admin/mediumprofiledit";
import ClientEdit from "../pages/admin/clientprofiledit";
import Requests from "../pages/admin/requests";
import Support from "../pages/admin/support";
import Calendar from '../pages/admin/calendar';
import PractitionerInfo from "../pages/admin/professionalPublicView";
import Survey from "../pages/admin/survey";
import FeedBackRequests from "../pages/admin/feedbackrequests";
import Transaction from "../pages/admin/transactions";
import Availability from "../pages/availability/availability";
import ReferralCodes from "../pages/admin/referrals";
import PayMyMedium from '../pages/paymymedium';
import VerifyEmail from "../pages/admin/calendar/verifyemail";
// import Comingsoon from "../pages/comingsoon";
// import Privacy from "../pages/privacy";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/dashboard" element={<AdminDashboard />} />
      <Route path="/mediums" element={<Mediums />} />
      <Route path="/public-view/:id" element={<PractitionerInfo show={false} />} />
      <Route path="/profile-view/:id" element={<MediumView />} />
      <Route path="/profile-edit/:id" element={<MediumEdit />} />
      <Route path="/view/:id" element={<ClientView />} />
      <Route path="/edit/:id" element={<ClientEdit />} />
      <Route path="/appointments" element={<Appointments />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/feedbacks" element={<Feedback />} />
      <Route path="/inspiration-stories" element={<InspirationStories />} />
      <Route path="/requests" element={<Requests />} />
      <Route path="/system-updates" element={<Support />} />
      <Route path="/services" element={<Calendar />} />
      <Route path="/survey" element={<Survey />} />
      <Route path="/feedback-requests" element={<FeedBackRequests />} />
      <Route path="/transactions" element={<Transaction />} />
      <Route path="/availability/:id" element={<Availability />} />
      <Route path="/referrals" element={<ReferralCodes />} />
      <Route path="/paymymedium/:id" element={<PayMyMedium />} />
      <Route path="/verifyemail" element={<VerifyEmail />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default AdminRoutes;
