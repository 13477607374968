import React, { useState } from "react";
// import moment from "moment";
import { filterList } from "../data";
import { useDispatch, useSelector } from "react-redux";
import Search from "../../../../components/search";
import Sorting from "../../../../components/sorting";
import Pagination from "../../../../components/tablePagination";
import { fireFilter } from "../functions";
import CsvDownload from "../../../../components/csvdownload";
import ConfirmCancel from "../subcomponents/cancelpopup";
import { emptyAllSelectBox, session_id_beg, session_id_end } from "../../../../utils/helpers";
import { resendSurveyEmail } from "../../../../services/medium";
import { toast } from "react-toastify";
import Button from "../../../../components/button";
import { noshowGroupAppointments, noshowRemoveGroupAppointments, sendRequestAdmin, settlePaymentOutside } from "../../../../services/admin";
import { setLoading } from "../../../../reducer/auth/auth";
import Filter from "../../../../components/filter";
import NoshowBox from "../subcomponents/noshowbox";
import UpdateAppointments from "../../Subcomponents/updateappt";

const headers = [
  { label: "Medium Name", key: "medium_name" },
  { label: "Client Name", key: "client_name" },
  { label: "Date", key: "date" },
  // { label: "Time", key: "time" },
  { label: "Session Name", key: "session_type" },
  { label: "Medium Email", key: "medium_emails" },
  { label: "Client Email", key: "client_emails" },
  // { label: "Meeting Link", key: "meeting_link" },
  { label: "Feedback Links", key: "" },
  // { label: 'Date and Time Cancelled', key: 'date_time_canceled' },
  // { label: "Cancelled By", key: 'userby' },
  // { label: "No Show By", key: 'userby' },
];

export default function Completed(props) {
  const {
    completedAppointments,
    changeHandler,
    filterListAppointments,
    filterReset,
    getPaginationData,
    setFilterValues,
    setSearchValues,
    getAllAppointmentsList,
    activeTab,
    completedAppointmentsPage,
    completedAppointmentsrowsPerPage,
    searchValue,
    filterValues,
    serviceTypeList,
    changeHandlerFilter,
    serviceFilterValue,
    setSortingNameOrderBy,
    setSortingTabType,
  } = props;

  // const copyMeetingLink = (url) => {
  //   navigator.clipboard.writeText(url);
  // };
  const { allCompletedCount } = useSelector((state) => state.users);

  const [active, setActive] = useState({});
  const [attendeesList, setattendeesList] = useState([])
  const [noshowFrom, setNoShowFrom] = useState('');
  const [updateItem, setUpdateItem] = useState({});
  const [noShowActive, setNoShowActive] = useState();
  const dispatch = useDispatch()

  const changeHandlerLocal = (e, type, id) => {
    let value = e.target.value;
    console.log('id', e)
    if (value === '') {
      setActive({ e: e, type: 6, id: id });
    } else {
      setActive({ e: e, type: 2, id: id });
    }
    window.loadModal2("#ConfirmCancelAdminCompleted", "show");
  };

  const cancelRequest = () => {
    window.loadModal2("#ConfirmCancelAdminCompleted", "hide");
    emptyAllSelectBox()
  };
  const confirmRequest = () => {
    let { e, type, id } = active;
    changeHandler(e, type, id);
  };
  const resendSurveyLink = (id) => {
    let body = {
      appointment_id: id
      // survey_link
    };
    resendSurveyEmail(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Email Sent Succesfully", {
            position: "top-center",
          });
          // getLink();
        }
        else {
          toast.error("Something went wrong", {
            position: "top-center",
          });
        }
      })
      .catch(() => { });
  }
  const sendRequet = (id) => {
    let body = {
      appointment_id: id
    }
    sendRequestAdmin(body).then((data) => {
      if (data.data.status) {
        toast.success(data.data.message, {
          position: "top-center",
        });
      }
      else {
        toast.error(data.data.message, {
          position: "top-center",
        });
      }

    }).catch(() => { });



  }
  const settlePayment = (id) => {
    dispatch(setLoading(true))
    settlePaymentOutside(id).then((data) => {
      dispatch(setLoading(false))
      if (data.data.status) {

        toast.success("Appointment Updated Successfully!!", {
          position: "top-center",
        });
        const info = {
          page: completedAppointmentsPage,
          rowsPerPage: completedAppointmentsrowsPerPage,
        };
        getAllAppointmentsList(info, activeTab)
      }
      else {
        toast.error(data.data.message, {
          position: "top-center",
        });
      }
    })
  }

  const openNoShowBox = (list, from,item) => {
    setNoShowActive(item)
    setNoShowFrom(from);
    setattendeesList(list)
    window.loadModal('#NoshowBox', 'show');
  }

  const noshowGroupAppointment = (item) => {
    console.log(item);
    let body = {
      appointment_id: item.newappointment_id,
      user_id: item.user_id
    }
    noshowGroupAppointments(body).then((data) => {
      if (data.data.status) {
        toast.success("Status updated succesfully", {
          position: "top-center",
        });
        const data = {
          page: 1,
          rowsPerPage: 10,
        };
        getAllAppointmentsList(data, activeTab,);
      }
      else {
        toast.error("Something went wrong", { position: "top-center" });
      }
    })
  }
  const noshowRemoveGroupAppointment = (item) => {
    console.log(item);
    let body = {
      appointment_id: item.newappointment_id,
      user_id: item.user_id
    }
    noshowRemoveGroupAppointments(body).then((data) => {
      if (data.data.status) {
        toast.success("Status updated succesfully", {
          position: "top-center",
        });
        const data = {
          page: 1,
          rowsPerPage: 10,
        };
        getAllAppointmentsList(data, activeTab,);
      }
      else {
        toast.error("Something went wrong", { position: "top-center" });
      }
    })
  }
  const openUpdateappointmentBox = (item) => {
    setUpdateItem(item)
    window.loadModal2('#UpdateAppointments', 'show')
  }

  const getAppointmentsNewList = () => {

    const info = {
      page: completedAppointmentsPage,
      rowsPerPage: completedAppointmentsrowsPerPage,
    };
    getAllAppointmentsList(info, activeTab)
    window.loadModal2('#UpdateAppointments', 'hide')
  }
  const checkAppId = (appoinment) => {
    if (appoinment.is_group_session === 0) {
      return appoinment.id
    }
    else if (appoinment.is_group_session === 1) {
      if (appoinment.attendees == undefined) {
        if (appoinment.parent_id == null) {
          return appoinment.id
        }
        else {
          return appoinment.parent_id 
        }

      }
      else {
        return appoinment.id
      }

    }
    else{
      return appoinment.id
    }

  }
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        <Search
          callApi={filterListAppointments}
          list={filterList}
          setSearchValues={setSearchValues}
          setFilterValues={setFilterValues}
          searchValue={searchValue}
          filterValues={filterValues}
        />
        <Filter
          list={serviceTypeList}
          changeHandler={(e) => changeHandlerFilter(e, 'completely')}
          filterValue={serviceFilterValue}
          name={'Service Type'} />
        <Button
          status={0}
          type="button"
          onClick={filterReset}
          className={`resend-btn  width-80px  reset-btn-123`}
          title="Reset"
        />
      </div>
      <CsvDownload
        href={
          `${process.env.REACT_APP_API_URL_EXPORT}` +
          "export-appoitment-list?appoitment_type=completed"
        }
      // usertype={"completed"}
      //  data={fireFilter(completedAppointments)} headers={headers} filename={'completed'}
      />
      <div className="table-box-medium admin-appoi overflow-scroll">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th className="min-width-100">Session #</th>
              <th>
                {/* <input type="checkbox" id="one" />
                <label htmlFor="one"></label> */}
                Medium Name
              </th>
              <th>Attendees</th>
              <th>
                {" "}
                <div className="d-flex align-items-center">
                  {" "}
                  <span>Date</span> <Sorting tab={2} callApi={filterListAppointments} view={'admin'} types={'date'} setSortingNameOrderBy={setSortingNameOrderBy}
                    setSortingTabType={setSortingTabType} />
                </div>
              </th>
              <th>
                {" "}
                <div className="d-flex align-items-center">
                  {" "}
                  <span>Time</span> <Sorting tab={2} callApi={filterListAppointments} view={'admin'} types={'time'} setSortingNameOrderBy={setSortingNameOrderBy}
                    setSortingTabType={setSortingTabType} />
                </div>
              </th>
              <th>Session Name</th>
              <th>Feedback Status</th>
              <th>Feedback Links</th>
              <th>Payment Request</th>
              <th>Emails</th>
              {/* <th>Meeting Link</th> */}
              <th>No Show / Give Add'l</th>
              <th>Status</th>
              <th><div className="d-inline-flex align-items-center align-items-center">
                <span> Created at</span>{" "}
                <Sorting tab={2} callApi={filterListAppointments} view={'admin'} types={'created_at'} setSortingNameOrderBy={setSortingNameOrderBy}
                  setSortingTabType={setSortingTabType} />
              </div>
              </th>
              {/* <th>Contact</th> */}
              {/* <th>Action</th> */}
            </tr>
            {completedAppointments.length > 0 ? (
              completedAppointments.map((appoinment) => (
                <tr className="innr">
                  <td>{session_id_beg + checkAppId(appoinment) + session_id_end}{appoinment.give_add1_highlight ? <img className="ml-1 star-app-hightlight" src="../images/star.png" /> : ''}</td>
                  <td>
                    {/* <input type="checkbox" id="two" />
                    <label htmlFor="two"></label> */}
                    <span className="capitalize">{appoinment.medium_name}</span>
                  </td>
                  <td>
                    {appoinment.is_group_session !== 1 ?
                      <span className="capitalize">{
                        appoinment.client_name}</span>
                      :
                      <span className="text-underline  pointer p-1" onClick={() => openNoShowBox(appoinment.attendees ,"",appoinment)}>{appoinment.attendees.length}&nbsp;Attendee(s)</span>
                    }
                  </td>
                  <td>
                    {appoinment.date}
                    {/* {moment.utc(appoinment.date).local().format("LL")} */}
                  </td>
                  <td>
                    {appoinment.time}
                    {/* {moment.utc(appoinment.date).local().format("LL")} */}
                  </td>
                  <td className="width-190px ">{appoinment.session_name}</td>
                  <td>
                    {appoinment.is_group_session !== 1 ?
                      <div className="color-d5a67b justify-content-center d-flex align-items-center height-45 capitalize">{appoinment.survey_status}</div>
                      : 'N/A'
                    }

                  </td>
                  <td>
                    {appoinment.is_group_session !== 1 ?

                      <>
                        {appoinment.survey_status !== "pending" && appoinment.survey_status !== "partner pending" ? (
                          <div className="color-d5a67b d-flex align-items-center height-45 capitalize"> {appoinment.survey_status}</div>
                        ) : (
                          <Button
                            status={0}
                            onClick={(e) => resendSurveyLink(appoinment.id)}
                            type="button"
                            className={`resend-btn w-130`}
                            title="Send Email"
                          />
                        )}
                      </>
                      :
                      'N/A'
                    }
                  </td>
                  <td >

                    {appoinment.payment_request_button === "Send Request" ? <Button
                      status={0}
                      onClick={(e) => sendRequet(appoinment.id)}
                      type="button"
                      className={`resend-btn height-35 w-140`}
                      title="Send Request"
                    /> : <div className="color-d5a67b text-center">{appoinment.payment_request_button}</div>
                    }
                    {appoinment.payment_request_button === "Send Request" && appoinment.session_type === "7" && <Button
                      status={0}
                      onClick={(e) => settlePayment(appoinment.id)}
                      type="button"
                      className={`resend-btn height-35 w-140 mt-2`}
                      title="Settle Outside"
                    />
                    }
                  </td>
                  <td>
                    <div>
                      <p className="d-flex">
                        <div> Client:</div>{" "}
                        <div className="d5a67b ml-1">
                          {appoinment.client_email}{" "}
                        </div>
                      </p>
                      <p className="d-flex">
                        {" "}
                        <div>Medium:</div>{" "}
                        <div className="d5a67b ml-1">
                          {appoinment.medium_email}
                        </div>
                      </p>
                    </div>
                  </td>
                  {/* <td>Practice - 60 Min (30 Min each)</td> */}
                  {/* <td className="googl-link">
                    <a
                      className="appointment-links"
                      href={appoinment.meeting_link}
                      target="_blank"
                    >
                      {appoinment.meeting_link}
                    </a>
                    <img
                      onClick={() => copyMeetingLink(appoinment.meeting_link)}
                      className="googl-link-copy-img "
                      alt="copy"
                      src="images/copy.png"
                    />
                  </td> */}
                  <td>
                    {appoinment.is_group_session !== 1 ?
                      <select
                        //  value={appoinment.statusValue}
                        onChange={(e) =>
                          changeHandlerLocal(e, 2, appoinment.appointmentId)
                          // changeHandler(e, 2, appoinment.appointmentId)
                        }
                        className="appointment-select-box"
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        {Object.entries(appoinment.userArray).map((show) => (
                          <option value={show[0]}>{'NS-' + show[1]}</option>
                        ))}
                        {appoinment.session_type === "5" ? <option value={""}>{"Give Add’l"}</option> : ''}
                      </select>
                      :
                      <>
                        <Button
                          status={0}
                          onClick={() => openNoShowBox(appoinment.attendees)}
                          type="button"
                          className={`resend-btn w-130`}
                          title="No Show"
                        />
                      </>
                    }
                  </td>
                  <td>

                    <Button
                      status={0}
                      onClick={(e) => openUpdateappointmentBox(appoinment)}
                      type="button"
                      className={`resend-btn w-130`}
                      title="Change Status"
                    />

                  </td>
                  <td>{appoinment.createdDate}</td>
                  {/* <td className="change">
                    <a href="#">
                      <img src="../images/email-blu.png" />
                      Email
                    </a>
                  </td> */}
                  {/* <td className="dot-image">
                    <img src="../images/Unionblck.png" />
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Appointments Found
                </td>
              </tr>
            )}

          </tbody>
        </table>
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          count={allCompletedCount}
          activeTab={"completedAdmin"}
          getPaginationData={getPaginationData}
          completedAppointmentsPage={completedAppointmentsPage}
          completedAppointmentsrowsPerPage={completedAppointmentsrowsPerPage}
        />
        <ConfirmCancel
          deleteUser={confirmRequest}
          cancelRequest={cancelRequest}
          id={'ConfirmCancelAdminCompleted'} />
        <NoshowBox
          noShowActive={noShowActive}
          list={attendeesList}
          noShowRequest={noshowGroupAppointment}
          noshowRemoveGroupAppointment={noshowRemoveGroupAppointment}
          noshowFrom={noshowFrom}
          />
        <UpdateAppointments
          updateItem={updateItem}
          appType={['Cancel', 'No Show', 'Restrict','Ended Early']}
          getAppointmentsNewList={getAppointmentsNewList}
        />
      </div>
    </>
  );
}
