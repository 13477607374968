import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Topbar from "../../../components/topbar/topbar";
import { setPathArray } from "../../../reducer/adminSlice";
import { setLoading } from "../../../reducer/auth/auth";

import { getClientProfileDetails } from "../../../services/admin";
import { removeQueryParams, setQueryParams } from "../../../utils/helpers";

import "../mediumprofileview/profile.css";

export default function ClientView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [profile, setProfile] = useState(null);
  const params = useParams();

  //query params
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("view");
  const searchVal = searchParams.get("search");
  const searchName = searchParams.get("searchName");
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');
  
  //query params

  //query state
  const [queryValue, setQueryValue] = useState('');
  const [query1, setQuery1] = useState('');
  const [query2, setQuery2] = useState('');
  const [query3, setQuery3] = useState('');
  const [query4, setQuery4] = useState('');

  useEffect(() => {

    setQueryParams(setQueryValue, setQuery1, setQuery2,setQuery3,setQuery4, status, searchVal, searchName,page ,rowperpage)
    setTimeout(() => {
      removeQueryParams()

    }, 2000);
    dispatch(setPathArray(params.id));
    getProfile(params.id);



    return () => {
      dispatch(setPathArray(null));
    };
  }, []);
  const getProfile = (id) => {
    dispatch(setLoading(true));
    getClientProfileDetails(id)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          setProfile(data.data.data);
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <div className="sinle-profile">
      <Topbar />

      <div className="single-profile-saction">
        <div className="container">
          <div className="back-btn-profile">
            <span className="back-handler pointer" onClick={() => status === null ? navigate(-1) : navigate(`/clients?view=${queryValue}&search=${query1}&searchName=${query2}&page=${query3}&rowperpage=${query4}&pagintion=${'change'}`)}>
              <img src="../images/arrow-back.png" alt="back arrow" />
              Go Back
            </span>
          </div>
          <div className="profile-all-box">
            <div className="banner-img-profile">
              <img src="../images/profile-cover.png" alt="profile" />
            </div>
            <div className="profile-info">
              <div className="profile-image">
                <img src="../images/user-dummy.png" alt="profile" />
              </div>

              <div className="profile-name">
                <div className="nam-prof">
                  {profile !== null && profile.general.first_name}
                </div>
                <a href="#">
                  @{profile !== null && profile.preferences.screen_name}
                </a>
                <div className="star-rating">
                  <span>0</span>{" "}
                  <img src="../localImages/hollowstar.svg" alt="white start" />
                  <img src="../localImages/hollowstar.svg" alt="white start" />
                  <img src="../localImages/hollowstar.svg" alt="white start" />
                  <img src="../localImages/hollowstar.svg" alt="white start" />
                  <img src="../localImages/hollowstar.svg" alt="white start" />
                  <em>0 Ratings</em>
                </div>
              </div>
            </div>
            <div className="profile-details">
              <ul>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="web.png" />
                  </div>
                  <em>Screen Name :</em>
                  <a href="javascipt:void(0)">
                    {profile !== null && profile.preferences.screen_name}
                  </a>
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/chate.png" alt="profile" />
                  </div>
                  <em>Languages Available :</em>
                  {profile !== null &&
                    profile.general.languages.map((item) => (
                      <span>{item.lanugage_name}</span>
                    ))}
                </li>
                {/* <li>
                  <div className="icon-detls">
                    <img src="../images/present.png" />
                  </div>
                  <em>Mediumship Specialities:</em>
                  {profile !== null &&
                    profile.general.specialities.map((item) => (
                      <span>{item.specialty_name}</span>
                    ))}
                </li> */}
                <li>
                  <div className="icon-detls">
                    <img src="../images/word.png" alt="country" />
                  </div>
                  <em>Country:</em>

                  <a href="#">{profile !== null && profile.general.country}</a>
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="legal terms" />
                  </div>
                  <em>Agree to legal terms and conditions :</em>

                  {profile !== null &&
                    Object.keys(profile.general).length > 0 && (
                      <a href="javascipt:void(0)">
                        {parseInt(profile.general.agree_to_legal_terms_and_conditionss) === 1
                          ? "Checked"
                          : "Not Checked"}
                      </a>
                    )}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="guidelines" />
                  </div>
                  <em>Agree to starter client guidelines :</em>

                  {profile !== null &&
                    Object.keys(profile.general).length > 0 && (
                      <a href="javascipt:void(0)">
                        {parseInt(
                          profile.general.agree_to_starter_client_guidelines
                        ) === 1
                          ? "Checked"
                          : "Not Checked"}
                      </a>
                    )}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="opt-in-out" />
                  </div>
                  <em>opt-in/out :</em>

                  {profile !== null &&
                    Object.keys(profile.general).length > 0 && (
                      <a href="javascipt:void(0)">
                        {parseInt(profile.general.opt_in_out) === 1
                          ? "Checked"
                          : "Not Checked"}
                      </a>
                    )}
                </li>
                <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" alt="opt-in-out" />
                  </div>
                  <em>Created :</em>

                  {profile !== null &&
                    Object.keys(profile.general).length > 0 && (
                      <a href="javascipt:void(0)">
                        {profile.general.created_at !== "" ? moment(profile.general.created_at).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                      </a>
                    )}
                </li>
                {/* <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" />
                  </div>
                  <em>Special Tools:</em>
                  {profile !== null && profile.preferences.length &&
                    profile.preferences.mediumship_session_tools.map((item) => (
                      <span>{item.tools_name}</span>
                    ))}
                </li> */}
                {/* <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" />
                  </div>
                  <em>Screen Name :</em>
                  <a href="javascipt:void(0)">
                    {profile !== null && profile.preferences.screen_name}
                  </a>
                </li> */}
                {/* <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" />
                  </div>
                  <em>Session Preferences :</em>
                  {profile !== null && profile.preferences.length &&
                    profile.preferences.session_preferences.map((item) => (
                      <span>{item}</span>
                    ))}
                </li> */}
                {/* <li>
                  <div className="icon-detls">
                    <img src="../images/web.png" />
                  </div>
                  <em>Payment methods :</em>
                  {profile !== null  && profile.preferences.length &&
                    profile.preferences.payment_methods.map((item) => (
                      <span>{item.payment_name}</span>
                    ))}
                </li> */}
              </ul>
            </div>
            {/* <div className="profile-bio">
              <h2>About Me</h2>
              <p>{profile !== null  && profile.general.biographical_info}</p>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
