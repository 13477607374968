import React, { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getMediumRole } from "../../../../utils/utils";
import Filter from "../../../../components/filter";
import { useNavigate } from "react-router-dom";
import { bulkListDeclined } from "../data";
import Checkbox from "../../Subcomponents/checkbox";
// import Button from "../../../../components/button";
import { fireFilter } from "../functions";
import CsvDownload from "../../../../components/csvdownload";
import CopyComponent from "../../../../components/copyComponent";
import Pagination from "../../../../components/tablePagination";
import { setPathArray } from "../../../../reducer/adminSlice";

const headers = [
  { label: "Screen Name", key: "screen_name" },
  { label: "Email", key: "email" },
  { label: "Created", key: "created_at" },
  { label: "Approved Role", key: "approved_role" },

];

export default function Declined(props) {
  const { pendingApprovals, updateUser, onClickHandler, bulkActions,getPaginationData, declinedPage,
  declinedrowsPerPage} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const {
    allDeclinedCount
  } = useSelector((state) => state.users);

  const navigateUser = (id) => {
    window.renderFirst = false
    dispatch(setPathArray(id.id));
    navigate(`/profile-view/${id.id}?view=5`);
  };
  const navigateEdit = (e, id) => {
    window.renderFirst = false
    if (e.target.className == "capitalize") {
      navigate(`/profile-edit/${id.id}?view=5`);
   }else{
   }
  };
  const checkBoxChangeHandler = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let result = pendingApprovals.map((a) => a.id);
      setSelected(result);
    } else {
      setSelected([]);
    }
  };

  // const getPaginationData = (item) => {
  //   console.log('paginationdata',item.from);
  //   getFromToData(item)
  //  };


  const changeHandler = (e) => {
    if (e.target.value === "Delete") {
      bulkActions(3, selected);
    }
    if (e.target.value === "Approve") {
      bulkActions(1, selected);
    }
    e.target.value = ""
  };
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        <Filter
          name={"Bulk Actions"}
          list={bulkListDeclined}
          changeHandler={changeHandler}
        />
      </div>
      <CsvDownload 
       href={`${process.env.REACT_APP_API_URL_EXPORT}`+'export-medium-list?user_type=declined'}
        // usertype={"declined"}
      // data={fireFilter(pendingApprovals)} headers={headers} filename={'declined'} 
      />
      <div className="table-box-medium">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th>
                <input
                  onChange={(e) => checkBoxChangeHandler(e)}
                  type="checkbox"
                  id="one"
                />
                <label htmlFor="one"></label>
                &nbsp;
              </th>
              <th>Screen Name</th>
              <th>Email</th>
              <th>Starter Referral Code</th>
              <th>Total Starter Referrals</th>
              <th>Created</th>
              <th>Approved Role</th>
              {/* <th>Member Type</th> */}
              <th>Action</th>
            </tr>
            {pendingApprovals.length > 0 ? (
              pendingApprovals.map((user) => (
                <tr onClick={(e) => navigateEdit(e, user)} className="innr pointer">
                 <td className="d-flex-medium-tables">
                    <Checkbox
                      medium={user}
                      status={checked}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <img src="/images/undraw_profile.svg" className="profile-image-avatar" alt="avatar" />
                  </td>
                  <td className="capitalize">
                    {" "}
                    {user.screen_name ? user.screen_name : "N/A"}
                    {/* {user.first_name} &nbsp; {user.last_name} */}
                  </td>
                  <td>{user.email} <CopyComponent value={user.email} /></td>
                  <td>{user.refer_code ? user.refer_code : "-"}</td>
                  <td>{user.number_of_uses}</td>
                  <td>
                   {moment(user.created_at).format("YYYY-MM-DD HH:mm:ss")}
                  </td>
                  <td>{getMediumRole(user.current_mediumship_level)}</td>
                  {/* <td>Medium</td> */}
                  <td className="dot-image dott">
                    <img
                      className="padding-10"
                      onClick={(e) =>
                        onClickHandler(e, user.id, "declined")
                      }
                      src="../images/Unionblck.png"
                      alt="dots"
                    />
                    {user.status && (
                      <div className="approve-btn-1 approve-btn z-index-1">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(
                              user,
                              1,
                              "pendingApprovals",
                              "pendingApprovals"
                            );
                          }}
                        >
                          Approve
                        </span>
                        {/* <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(user, 2, "pendingApprovals", "");
                          }}
                        >
                          Decline
                        </span> */}

                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(user, 3, "delete", "pendingApprovals");
                          }}
                        >
                          Delete
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateUser(user);
                          }}
                        >
                          View
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Declined Mediums
                </td>
              </tr>
            )}
            {/* <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr> */}
          </tbody>
        </table>
        {/* <div className="pagination-box">
        <div className="left-pagi">
          <span>Showing 1 to 10 of 18 entries</span>
        </div>
        <div className="right-pagi">
          <ul>
            <li>Previous</li>
            <li className="active">
              <a href="#">1</a>
            </li>
            <li>
              <a href="#">2</a>
            </li>
            <li>
              <a href="#">3</a>
            </li>
            <li>Next</li>
          </ul>
        </div>
      </div> */}
      <Pagination  rowsPerPageOptions={[5, 10, 25]}  count={allDeclinedCount}  activeTab={"declined"} declinedPage={declinedPage}
            declinedrowsPerPage={declinedrowsPerPage} getPaginationData={getPaginationData} />
      </div>
    </>
  );
}
