import React from "react";
// import moment from "moment";
import { filterList } from "../data";
import { useSelector } from "react-redux";
import Search from "../../../../components/search";
// import Button from "../../../../components/button";
import { session_id_beg, session_id_end } from "../../../../utils/helpers";
import Sorting from "../../../../components/sorting";
import Pagination from "../../../../components/tablePagination";
import Button from "../../../../components/button";

import { fireFilter } from "../functions";
import CsvDownload from "../../../../components/csvdownload";
import Filter from "../../../../components/filter";
const headers = [
  { label: "Medium Name", key: "medium_name" },
  { label: "Client Name", key: "client_name" },
  { label: "Date", key: "date" },
  { label: "Time", key: "time" },
  { label: "Session Name", key: "session_type" },
  { label: "Emails", key: "emails" },
  { label: "Meeting Link", key: "meeting_link" },
  // { label: 'Feedback Links', key: "" },
  // { label: 'Date and Time Cancelled', key: 'date_time_canceled' },
  // { label: "Cancelled By", key: 'userby' },
  // { label: "No Show By", key: 'userby' },
];

export default function Ongoing(props) {
  const {
    showOngoingAppointments,
    // changeHandler,
    filterListAppointments,
    getPaginationData,
    setFilterValues,
    setSearchValues,
    filterReset,
    ongoingAppointmentsPage,
    ongoingAppointmentsrowsPerPage,
    searchValue,
    serviceTypeList,
    changeHandlerFilter,
    serviceFilterValue,
    setSortingNameOrderBy,
    setSortingTabType,
  } = props;

  const { allOngoingCount } = useSelector((state) => state.users);
  // const copyMeetingLink = (url) => {
  //   navigator.clipboard.writeText(url);
  // };

  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        <Search
          callApi={filterListAppointments}
          list={filterList}
          setSearchValues={setSearchValues}
          setFilterValues={setFilterValues}
          searchValue={ searchValue}
        />
         {/* <Button
          status={0} 
           type="button"
           onClick={filterReset}
           className={`resend-btn  width-80px  reset-btn-123` }
           title="Reset"
          /> */}
         {/* <Filter
          list={serviceTypeList}
          changeHandler={(e)=>changeHandlerFilter(e,'ongoing')}
          filterValue={serviceFilterValue}
          name={'Service Type'} /> */}
      </div>
      <CsvDownload
        href={
          `${process.env.REACT_APP_API_URL_EXPORT}` +
          "export-appoitment-list?appoitment_type=ongoing"
        }
        // usertype={"ongoing"}
        //  data={fireFilter(showOngoingAppointments)} headers={headers} filename={'ongoing'}
      />
      <div className="table-box-medium admin-appoi overflow-scroll">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th className="min-width-100">Session #</th>
              <th>
                {/* <input type="checkbox" id="one" />
                <label htmlFor="one"></label> */}
                Medium Name
              </th>
              <th>Client Name</th>
              <th>
                {" "}
                <div className="d-inline-flex align-items-center align-items-center">
                  {" "}
                  <span>Date</span> <Sorting  tab={7} callApi={filterListAppointments}  view={'admin'} types={'date'}  setSortingNameOrderBy={setSortingNameOrderBy}
                setSortingTabType={setSortingTabType} />
                </div>
              </th>
              <th>
                <div className="d-inline-flex align-items-center align-items-center">
                  <span> Time</span>{" "}
                  <Sorting tab={7}  callApi={filterListAppointments} view={'admin'} type={'time'}  setSortingNameOrderBy={setSortingNameOrderBy}
                setSortingTabType={setSortingTabType} />
                </div>
              </th>
              <th>Session Name</th>
              <th>Emails</th>
              <th>Meeting Link</th>
            </tr>
            {showOngoingAppointments.length > 0 ? (
              showOngoingAppointments.map((appoinment) => (
                <tr className="innr">
                  <td>{session_id_beg + appoinment.id + session_id_end}</td>
                  <td>
                    {/* <input type="checkbox" id="two" />
                    <label htmlFor="two"></label> */}
                    <span className="capitalize">{appoinment.medium_name}</span>
                  </td>
                  <td>
                    <span className="capitalize">{appoinment.client_name}</span>
                  </td>
                  <td>
                    {appoinment.date}
                    {/* {moment
                      .utc(appoinment.date)
                      .local()
                      .format("LL")} */}
                  </td>
                  <td>
                    {" "}
                    {appoinment.time}
                    {/* {moment
                      .utc(appoinment.time, ["h:mm A"])
                      .local()
                      .format("hh:mm a")} */}
                  </td>
                  <td className="width-190px ">{appoinment.session_name}</td>
                  <td>
                    <div>
                      <p className="d-flex">
                        <div> Client:</div>{" "}
                        <div className="d5a67b ml-1">
                          {appoinment.client_email}{" "}
                        </div>
                      </p>
                      <p className="d-flex">
                        {" "}
                        <div>Medium:</div>{" "}
                        <div className="d5a67b ml-1">
                          {appoinment.medium_email}
                        </div>
                      </p>
                    </div>
                  </td>
                  <td className="googl-link">
                    <a
                      target={"_blank"}
                      className={`d-flex align-items-center justify-content-center resend-btn new-btn-click-here height-35 min-width-130px`}
                      href={appoinment.meeting_link}
                    >
                      Click Here
                    </a>
                    {/* <Button
                      className={`resend-btn w-unset height-35 min-width-130px`}
                      title="Click Here"
                      status={0}
                      onClick={() =>}
                    /> */}
                    {/* <a
                      className="appointment-links"
                      href={appoinment.meeting_link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {appoinment.meeting_link}
                    </a>
                    <img
                      onClick={() => copyMeetingLink(appoinment.meeting_link)}
                      className="googl-link-copy-img "
                      src="images/copy.png"
                      alt="copy"
                    /> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Appointments Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <div className="pagination-box">
          <div className="left-pagi">
            <span>Showing 1 to 10 of 18 entries</span>
          </div>
          <div className="right-pagi">
            <ul>
              <li>Previous</li>
              <li className="active">
                <a href="#">1</a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>Next</li>
            </ul>
          </div>
        </div> */}
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          count={allOngoingCount}
          activeTab={"ongoingAdmin"}
          getPaginationData={getPaginationData}
          ongoingAppointmentsPage={ongoingAppointmentsPage}
          ongoingAppointmentsrowsPerPage={ongoingAppointmentsrowsPerPage}
        />
      </div>
    </>
  );
}
