import React, { useRef ,useState ,useEffect} from "react";
import "./waitingtimer.css";

export default function WaitingTimerPopup() {
  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("03:30");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    console.log(total);
    if (total >= 0) {
      setTimer(
        // (hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
    if (total === 0) {
      clearInterval(Ref.current);
    //   hideCountdownTimer();
    localStorage.removeItem('offset');
    window.location.reload(false);
    }
  };

  const clearTimer = (e) => {
    setTimer("05:00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 300);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);
  return (
    <div
      className="modal fade"
      id="WaitingTimerPopup"
      role="dialog"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg modal-dialog-preview">
        <div className="modal-content margin-top-300 border-waiting-time">
          <div className="modal-body modal-body-waiting-time">
            <div className="profile-video"></div>
            <h3 className="text-center">
              Complete Your Payment Before{" "}
            </h3>
            <div className="modal-body-waiting-time-wrapper">
              <div className="payment-new-wrapper">
                <div className="payment-new-container">
                  <p className="font-size-40">{timer}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
