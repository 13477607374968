import React from "react";

export default function ConfirmCancel({ deleteUser,cancelRequest, id }) {
  return (
    <div className="confirm-delete-wrapper register-medium">
      <div data-backdrop="static" className="modal fade" id={id} role="dialog">
        <div className="modal-dialog padding-unset modal-dialog-preview">
          <div className="modal-content">
            <div className="modal-body">
              <div className="icon-pop">
                <img src="images/Union-icon.png" alt="Union-icon" />
              </div>
              <h3>Are you sure?</h3>
              <div className="confirm-btn-box">
                <div
                  className="register-medium-confirnation"
                  onClick={cancelRequest}
                  data-dismiss="modal"
                >
                  Cancel
                </div>
                <div
                  className="register-medium-confirnation"
                  data-dismiss="modal"
                  onClick={deleteUser}
                >
                  Confirm
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
