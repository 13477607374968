import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "../../../components/tabs";
import TabPanel from "../../../components/tabs/tabpanel";
import Medium from "./medium";
import Novice from "./novice";
import PendingApprovals from "./pendingapprovals";
import UnverifiedEmails from "./unverifiedemails";
import Professional from "./professional";
import { useLocation } from 'react-router-dom';

// import Pagination from "../../../components/tablePagination";

import {
  setAllMediums,
  setAllProfessionals,
  setAllIntermediate,
  setAllNovice,
  setUsers,
  setAllUnverifiedEmails,
  setAllDeclined,
  setDashboardCount,
  setAllMediumsCount,
  setAllDeclinedCount,
  setAllNoviceCount,
  setAllProfessionalsCount,
  setAllIntermediateCount,
  setAllPendingApprovalsCount,
  setAllUnverifiedEmailsCount,
  setMediumrowsPerPage,
  setMediumPage,
  setNovicePage,
  setNovicerowsPerPage,
  setIntermediatePage,
  setIntermediaterowsPerPage,
  setProfessionalPage,
  setProfessionalrowsPerPage,
  setPendingApprovalsPage,
  setPendingApprovalsrowsPerPage,
  setUnverifiedEmailsPage,
  setUnverifiedEmailsrowsPerPage,
  setDeclinedPage,
  setDeclinedrowsPerPage
} from "../../../reducer/adminSlice";

//service
import {
  getAllMediums,
  getAllProfessionals,
  getAllIntermediate,
  getAllDeclinedMediums,
  getAllNovice,
  getUsers,
  approveUser,
  filterAllmediums,
  filterAllNovice,
  filterAllIntermediate,
  filterAllProfessionals,
  performBulkActions,
  getAdminAppointmentCount,
  resendActivationMail,
  resendActivationUnverifiedMail,
  filterAllPendingApprovals,
  filterAllUnverifiedEmails,
  getUnverfiedEmailsMediums
} from "../../../services/admin";

import { list } from "./data";

import "./mediums.css";
import Intermediate from "./intermediate";
import { useSearchParams } from "react-router-dom";
import ConfirmDelete from "../Subcomponents/deleteconfirm";
import { setLoading } from "../../../reducer/auth/auth";
import Declined from "./declined";
import { toast } from "react-toastify";

export default function Mediums() {
  const {
    users,
    allMediums,
    allProfessionals,
    allNovice,
    allIntermediate,
    allDeclined,
    allunverifiedEmails,
    selectedTab,
    allMediumCount,
    allProfessionalsCount,
    allNoviceCount,
    allIntermediateCount,
    allDeclinedCount,
    allPendingApprovalsCount,
    allUnverifiedEmailsCount,
    mediumpage,
    mediumrowsPerPage,
    novicepage,
    novicerowsPerPage,
    intermediatePage,
    intermediaterowsPerPage,
    professionalPage,
    professionalrowsPerPage,
    pendingapprovalsPage,
    pendingapprovalsrowsPerPage,
    unverifiedEmailsPage,
    unverifiedEmailsrowsPerPage,
    declinedPage,
    declinedrowsPerPage,
    globalRowsPerPage

  } = useSelector((state) => state.users);

  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("view");
  const searchVal = searchParams.get("search");
  const searchName = searchParams.get("searchName");
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');
  const pagintion= searchParams.get('pagintion');


  const [listArray, setListArray] = useState(list);
  const [mediums, setMediums] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [intermediate, setIntermediate] = useState([]);
  const [novice, setNovice] = useState([]);
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [unverifiedEmails, setUnverifiedEmails] = useState([]);
  const [declinedMediums, setDeclinedMediums] = useState([]);
  const [deleteInfo, setDeletInfo] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [backupmediums, setbackupMediums] = useState([]);
  const [backupprofessionals, setbackupProfessionals] = useState([]);
  const [backupPending, setbackupPending] = useState([]);
  const [backupUnverfiedEmails, setbackupUnverifiedEmails] = useState([]);
  const [backupintermediate, setbackupIntermediate] = useState([]);
  const [backupDeclined, setbackupDeclined] = useState([]);
  // const [mediumdata,setmediumdata]=useState({page:1,rowsPerPage:10});
  const [backupnovice, setbackupNovice] = useState([]);
  const [bulkInfo, setBulkInfo] = useState({});

  const [filterValues, setFilterValues] = useState('screen_name');
  const [searchValue, setSearchValues] = useState('');
  const [queryValue, setQueryValue] = useState('');
  const queryParams = new URLSearchParams(location.search);


  useEffect(() => {
   
    localStorage.setItem('pagintionTYPE',pagintion)

    if (searchVal !== null) {
      setFilterValues(searchVal);
    }
    if (searchName !== null) {
      setSearchValues({ [searchVal]: searchName })
    }
    setTimeout(() => {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete("view");
      newUrl.searchParams.delete("search");
      newUrl.searchParams.delete("searchName");
      newUrl.searchParams.delete("page");
      newUrl.searchParams.delete("rowperpage");
      newUrl.searchParams.delete("pagintion");
      
      setQueryValue(newUrl.searchParams.delete("view"))
      window.history.replaceState({}, document.title, newUrl.href);
    }, 2000)
    if ((location.pathname === "/mediums" && location.search === "") || (location.pathname === "/mediums" && location.search === "?view=allmediums")) {
      launcApi();
      const data = {
        page: 1,
        rowsPerPage: 10,
        screen_name: searchValue !== undefined ? searchValue.screen_name !== undefined ? searchValue.screen_name : '' : '',
        email: searchValue !== undefined ? searchValue.email !== undefined ? searchValue.email : '' : '',
        created_at: searchValue !== undefined ? searchValue.created_at !== undefined ? searchValue.created_at : '' : '',

      }

      callUsers(data);
    }
    // }
    if (location.pathname === "/mediums" && location.search === "?view=0") {
      setAllMediums(backupmediums)
      // if (!backupmediums.length) { launcApi()  } else { setAllMediums(backupmediums);}
      // getPathFromUrl();
    }
    if (location.pathname === "/mediums" && location.search === "?view=1") {
      setAllNovice(backupnovice);
      console.log(backupnovice);
      // getPathFromUrl();
    }
    if (location.pathname === "/mediums" && location.search === "?view=2") {
      setAllIntermediate(backupintermediate);
      // getPathFromUrl();
    }
    if (location.pathname === "/mediums" && location.search === "?view=3") {
      console.log(allProfessionals, "backupprofessionals");
      setAllProfessionals(backupprofessionals);
    }
    if (location.pathname === "/mediums" && location.search === "?view=4") {
      console.log(backupPending, "backupPending");
      setUsers(backupPending);
    }
    if (location.pathname === "/mediums" && location.search === "?view=5") {
      setAllUnverifiedEmails(backupUnverfiedEmails);
      // getPathFromUrl();
    }
    if (status === "all" || status === "4") setActiveTab(4);
    if (status === "1") setActiveTab(1);
    if (status === "2") setActiveTab(2);
    if (status === "3") setActiveTab(3);
    if (status === "5") setActiveTab(5);
    if (status === "6") setActiveTab(6);
  }, []);

  function getPathFromUrl(url) {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete("view");
    window.history.replaceState({}, document.title, newUrl.href);
  }
  const setListedArray = (item, set) => {
    const intermediates = item.map((resp) => {
      return {
        ...resp,
        status: false,
        resent_status: 0,
      };
    });

    set(intermediates);

    setListArray(
      list.map((item) => {
        if (item.name === "All Medium") {
          return {
            ...item,
            ...(item.count = allMediumCount),
          };
        }
        if (item.name === "Professional") {
          return {
            ...item,
            ...(item.count = allProfessionalsCount),
          };
        }

        if (item.name === "Pending Approvals") {
          return {
            ...item,
            ...(item.count = allPendingApprovalsCount),
          };
        }
        if (item.name === "Unverified Email") {
          return {
            ...item,
            ...(item.count = allUnverifiedEmailsCount),
          };
        }

        if (item.name === "Novice") {
          return {
            ...item,
            ...(item.count = allNoviceCount),
          };
        }
        if (item.name === "Intermediate") {
          return {
            ...item,
            ...(item.count = allIntermediateCount),
          };
        }
        if (item.name === "Declined") {
          return {
            ...item,
            ...(item.count = allDeclinedCount),
          };
        } else {
          return {
            ...item,
          };
        }
      })
    );
  };
  useEffect(() => {
    setListedArray(allMediums, setMediums);
  }, [allMediums]);
  useEffect(() => {
    setListedArray(allProfessionals, setProfessionals);
  }, [allProfessionals]);
  useEffect(() => {
    setListedArray(allIntermediate, setIntermediate);
  }, [allIntermediate]);
  useEffect(() => {
    setListedArray(allNovice, setNovice);
  }, [allNovice]);
  useEffect(() => {
    setListedArray(users, setPendingApprovals);
  }, [users]);
  useEffect(() => {
    setListedArray(allunverifiedEmails, setUnverifiedEmails);
  }, [allunverifiedEmails]);
  useEffect(() => {
    setListedArray(allDeclined, setDeclinedMediums);
  }, [allDeclined]);

  useEffect(() => {

    if (window.renderFirst) {


      // if (location.pathname === "/mediums" && location.search !== "") {
      const data = {
        page: 1,
        rowsPerPage: 10,
        screen_name: '',
        email: '',
        created_at: '',

      }
      if (activeTab === 0) {
        setFilterValues('screen_name')
        setSearchValues('')
        getAllNoviceList(data, 'tab');
        getAllIntermediateList(data, 'tab');
        getAllProfessionalsList(data, 'tab');
        getAllUnVerifiedEmails(data, 'tab');
        callUsers(data, 'tab');
        getAllDeclinedList(data, 'tab');
        // dispatch(setMediumPage(data.page));
        // dispatch(setMediumrowsPerPage(data.rowsPerPage));
      }


      if (activeTab === 1) {
        setFilterValues('screen_name')
        setSearchValues('')

        getMediumList(data, 'tab');

        getAllIntermediateList(data, 'tab');
        getAllProfessionalsList(data, 'tab');
        getAllUnVerifiedEmails(data, 'tab');
        callUsers(data, 'tab');
        getAllDeclinedList(data, 'tab');
        // dispatch(setNovicePage(data.page));
        // dispatch(setNovicerowsPerPage(data.rowsPerPage));
      }
      if (activeTab === 2) {
        setFilterValues('screen_name')
        setSearchValues('')

        getMediumList(data, 'tab');
        getAllNoviceList(data, 'tab');
        getAllProfessionalsList(data, 'tab');
        getAllUnVerifiedEmails(data, 'tab');
        callUsers(data, 'tab');
        getAllDeclinedList(data, 'tab');
        // dispatch(setIntermediatePage(data.page));
        // dispatch(setIntermediaterowsPerPage(data.rowsPerPage));
      }
      if (activeTab === 3) {
        setFilterValues('screen_name')
        setSearchValues('')
        getMediumList(data, 'tab');
        getAllNoviceList(data, 'tab');
        getAllIntermediateList(data, 'tab');
        getAllUnVerifiedEmails(data, 'tab');
        callUsers(data, 'tab');
        getAllDeclinedList(data, 'tab');
        // dispatch(setProfessionalPage(data.page));
        // dispatch(setProfessionalrowsPerPage(data.rowsPerPage));
      }
      if (activeTab === 4) {
        setFilterValues('screen_name')
        setSearchValues('')
        getMediumList(data, 'tab');
        getAllNoviceList(data, 'tab');
        getAllIntermediateList(data, 'tab');
        getAllProfessionalsList(data, 'tab');
        getAllUnVerifiedEmails(data, 'tab');
        getAllDeclinedList(data, 'tab');
        // dispatch(setPendingApprovalsPage(data.page));
        // dispatch(setPendingApprovalsrowsPerPage(data.rowsPerPage));
      }
      if (activeTab === 6) {
        setFilterValues('screen_name')
        setSearchValues('')
        getMediumList(data, 'tab');
        getAllNoviceList(data, 'tab');
        getAllIntermediateList(data, 'tab');
        getAllProfessionalsList(data, 'tab');
        getAllUnVerifiedEmails(data, 'tab');
        callUsers(data, 'tab');

        // dispatch(setDeclinedPage(data.page));
        // dispatch(setDeclinedrowsPerPage(data.rowsPerPage));
      }
      if (activeTab === 5) {
        setFilterValues('screen_name')
        setSearchValues('')
        getMediumList(data, 'tab');
        getAllNoviceList(data, 'tab');
        getAllIntermediateList(data, 'tab');
        getAllProfessionalsList(data, 'tab');
        callUsers(data, 'tab');
        getAllDeclinedList(data, 'tab');
        // dispatch(setUnverifiedEmailsPage(data.page));
        // dispatch(setUnverifiedEmailsrowsPerPage(data.rowsPerPage));
      }

      //   // //   // dispatch(setAllMediums(backupmediums));
      //   // //   // dispatch(setAllNovice(backupnovice));
      //   // //   // dispatch(setAllIntermediate(backupintermediate));
      //   // //   // dispatch(setAllProfessionals(backupprofessionals));
      //   // //   // dispatch(setSelectedTab(activeTab))
      // }
    } else {
      window.renderFirst = true;
    }
  }, [activeTab]);

  const getMediumList = (item, info) => {
    getAllMediums(item)
      .then((data) => {
        if (data.data.status) {
          dispatch(setAllMediumsCount(data.data.data.total))
          dispatch(setAllMediums(data.data.data.data));
          setbackupMediums(JSON.parse(JSON.stringify(data.data.data.data)));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getAllProfessionalsList = (data, info) => {
    getAllProfessionals(data)
      .then((data) => {
        if (data.data.status) {
          // dispatch(setAllProfessionals(data.data.data.data));
          dispatch(setAllProfessionalsCount(data.data.data.total))
          setbackupProfessionals(JSON.parse(JSON.stringify(data.data.data.data)));
          dispatch(setAllProfessionals(data.data.data.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllNoviceList = (data, info) => {
    getAllNovice(data)
      .then((data) => {
        // if (data.data.status) dispatch(setAllNovice(data.data.data.data));
        if (data.data.status) {
          dispatch(setAllNoviceCount(data.data.data.total))
          dispatch(setAllNovice(data.data.data.data));
          setbackupNovice(JSON.parse(JSON.stringify(data.data.data.data)));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllIntermediateList = (data, info) => {
    getAllIntermediate(data)
      .then((data) => {
        if (data.data.status) {
          // dispatch(setAllIntermediate(data.data.data.data));
          dispatch(setAllIntermediateCount(data.data.data.total))
          dispatch(setAllIntermediate(data.data.data.data));
          setbackupIntermediate(JSON.parse(JSON.stringify(data.data.data.data)));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllUnVerifiedEmails = async (data) => {
    // dispatch(setLoading(true));
    const response = await getUnverfiedEmailsMediums(data)
      .then((data) => {
        // dispatch(setLoading(false));
        const update = data.data.data.data.map((resp) => {
          return {
            ...resp,
            status: false,
          };
        });
        dispatch(setAllUnverifiedEmailsCount(data.data.data.total))
        dispatch(setAllUnverifiedEmails(update));
        setbackupUnverifiedEmails(JSON.parse(JSON.stringify(data.data.data.data)));
      })
      .catch((err) => {
        // dispatch(setLoading(false));
        console.log(err);
      });

    return response;
  };


  const getAllDeclinedList = (data, info) => {
    getAllDeclinedMediums(data)
      .then((data) => {
        if (data.data.status) {
          // dispatch(setAllIntermediate(data.data.data.data));
          dispatch(setAllDeclinedCount(data.data.data.total))
          dispatch(setAllDeclined(data.data.data.data));
          setbackupDeclined(JSON.parse(JSON.stringify(data.data.data.data)));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const launcApi = () => {
    const data = {
      page: 1,
      rowsPerPage: 10,
      screen_name: searchValue !== undefined ? searchValue.screen_name !== undefined ? searchValue.screen_name : '' : '',
      email: searchValue !== undefined ? searchValue.email !== undefined ? searchValue.email : '' : '',
      created_at: searchValue !== undefined ? searchValue.created_at !== undefined ? searchValue.created_at : '' : '',
    }
    // if (!allMediums.length > 0) {
    getMediumList(data);
    // } else {
    // console.log(allMediums,"allMediumsallMediumsallMediums")
    // setListedArray(allMediums, setMediums);
    // }
    // if (!allProfessionals.length > 0) {
    getAllProfessionalsList(data);
    // } else {
    //   setListedArray(allProfessionals, setProfessionals);
    // }
    // if (!allIntermediate.length > 0) {
    getAllIntermediateList(data);
    getAllDeclinedList(data);
    getAllUnVerifiedEmails(data);
    // } else {
    // setListedArray(allIntermediate, setIntermediate);
    // }
    // if (!allNovice.length > 0) {
    getAllNoviceList(data);
    // } else {
    // setListedArray(allNovice, setNovice);
    // }
  };

  const callUsers = async (data) => {
    // dispatch(setLoading(true));
    const response = await getUsers(data)
      .then((data) => {
        // dispatch(setLoading(false));
        const update = data.data.data.data.map((resp) => {
          return {
            ...resp,
            status: false,
          };
        });
        dispatch(setAllPendingApprovalsCount(data.data.data.total))
        setbackupPending(JSON.parse(JSON.stringify(update)));
        dispatch(setUsers(update));
      })
      .catch((err) => {
        // dispatch(setLoading(false));
        console.log(err);
      });

    return response;
  };

  const updateUser = async (user, status, name, item) => {
    if (name === "delete") {
      window.loadModal2("#deleteConfirmation", "show");
      let data = { user, status, name, item };
      setDeletInfo(data);
    } else {
      deleteUser(user, status, name, item);
    }
  };
  const deleteUser = async (user, status, name, item) => {
    let body = {
      user_id: status !== undefined ? user.id : deleteInfo.user.id,
      medium_status:
        status !== undefined
          ? user.medium_status
          : deleteInfo.user.medium_status,
      status: status !== undefined ? status : deleteInfo.status,
    };
    await approveUser(body)
      .then((data) => {
        if (data.data.status) {
          toast.success('Status Updated', { position: 'top-center' })
          const data = {
            page: 1,
            rowsPerPage: 10,
            screen_name: searchValue !== undefined ? searchValue.screen_name !== undefined ? searchValue.screen_name : '' : '',
            email: searchValue !== undefined ? searchValue.email !== undefined ? searchValue.email : '' : '',
            created_at: searchValue !== undefined ? searchValue.created_at !== undefined ? searchValue.created_at : '' : '',
          }
          getCount();
          getAllIntermediateList(data);
          getAllDeclinedList(data);
          getAllNoviceList(data);
          getAllProfessionalsList(data);
          getMediumList(data);
          getAllUnVerifiedEmails(data);
          callUsers();
        }
        // if (deleteInfo.name == "mediums" || deleteInfo.name == "delete")
        //   getMediumList();
        // if (deleteInfo.name == "intermediate" || deleteInfo.name == "delete")
        //   getAllIntermediateList();
        // if (deleteInfo.name == "novice" || deleteInfo.name == "delete")
        //   getAllNoviceList();
        // if (deleteInfo.name == "professional" || deleteInfo.name == "delete")
        //   getAllProfessionalsList();
        // if (deleteInfo.item == "pendingApprovals"|| item == "pendingApprovals")  callUsers();
      })
      .catch(() => { });
  };
  const getCount = async () => {
    await getAdminAppointmentCount()
      .then((data) => {
        // dispatch(setLoading(false));
        if (data.data.status) {
          dispatch(setDashboardCount(data.data.data));
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };



  const getPaginationData = (data, activeTab) => {
    let info = {
      ...data,
      screen_name: searchValue !== undefined ? searchValue.screen_name !== undefined ? searchValue.screen_name : '' : '',
      email: searchValue !== undefined ? searchValue.email !== undefined ? searchValue.email : '' : '',
      created_at: searchValue !== undefined ? searchValue.created_at !== undefined ? searchValue.created_at : '' : '',
    }
    if (activeTab === 0) {
      getMediumList(info);
      // dispatch(setMediumPage(info.page));
      // dispatch(setMediumrowsPerPage(info.rowsPerPage));
    }

    if (activeTab === 1) {
      getAllNoviceList(info);
      // dispatch(setNovicePage(info.page));
      // dispatch(setNovicerowsPerPage(info.rowsPerPage));
    }
    if (activeTab === 2) {
      getAllIntermediateList(info);
      // dispatch(setIntermediatePage(info.page));
      // dispatch(setIntermediaterowsPerPage(info.rowsPerPage));
    }
    if (activeTab === 3) {
      getAllProfessionalsList(info);
      // dispatch(setProfessionalPage(info.page));
      // dispatch(setProfessionalrowsPerPage(info.rowsPerPage));
    }
    if (activeTab === 4) {
      callUsers(info);
      // dispatch(setPendingApprovalsPage(info.page));
      // dispatch(setPendingApprovalsrowsPerPage(info.rowsPerPage));
    }
    if (activeTab === 6) {
      getAllDeclinedList(info);
      // dispatch(setDeclinedPage(info.page));
      // dispatch(setDeclinedrowsPerPage(info.rowsPerPage));
    }
    if (activeTab === 5) {
      getAllUnVerifiedEmails(info);
      // dispatch(setUnverifiedEmailsPage(info.page));
      // dispatch(setUnverifiedEmailsrowsPerPage(info.rowsPerPage));
    }

  };


  const onClickHandler = (e, id, name) => {
    e.stopPropagation();
    if (name === "mediums") {
      const response = mediums.map((user) => {
        if (user.id === id) {
          user.status = !user.status;
        } else {
          user.status = false;
        }
        return user;
      });
      setMediums(response);
    }
    if (name === "intermediate") {
      const response = intermediate.map((user) => {
        if (user.id === id) {
          user.status = !user.status;
        } else {
          user.status = false;
        }
        return user;
      });
      setIntermediate(response);
    }
    if (name === "novice") {
      const response = novice.map((user) => {
        if (user.id === id) {
          user.status = !user.status;
        } else {
          user.status = false;
        }
        return user;
      });
      setNovice(response);
    }
    if (name === "professional") {
      const response = professionals.map((user) => {
        if (user.id === id) {
          user.status = !user.status;
        } else {
          user.status = false;
        }
        return user;
      });
      setProfessionals(response);
    }
    if (name === "pendingAprrovals") {
      const response = pendingApprovals.map((user) => {
        if (user.id === id) {
          user.status = !user.status;
        } else {
          user.status = false;
        }
        return user;
      });
      setPendingApprovals(response);
    }
    if (name === "unverifiedemails") {
      const response = unverifiedEmails.map((user) => {
        if (user.id === id) {
          user.status = !user.status;
        } else {
          user.status = false;
        }
        return user;
      });
      setUnverifiedEmails(response);
    }
    if (name === "declined") {
      const response = declinedMediums.map((user) => {
        if (user.id === id) {
          user.status = !user.status;
        } else {
          user.status = false;
        }
        return user;
      });
      setDeclinedMediums(response);
    }
  };
  const filterListMediums = (constants) => {
    const { email, screen_name, created_at, orderBy = "asc" } = constants;
    let body = {
      email,
      screen_name,
      created_at,
      orderBy,
    };
    if (parseInt(activeTab) === 0) {
      filterAllmediums(body)
        .then((data) => {
          if (data.data.status) {
            dispatch(setAllMediumsCount(data.data.data.total))
            dispatch(setAllMediums(data.data.data.data));
            dispatch(setMediumPage(mediumpage + 1));
            dispatch(setMediumrowsPerPage(globalRowsPerPage));
            setbackupMediums(JSON.parse(JSON.stringify(data.data.data.data)));
          }
        })
        .catch(() => { });
    }

    if (parseInt(activeTab) === 1) {
      filterAllNovice(body)
        .then((data) => {
          if (data.data.status) {
            dispatch(setAllNoviceCount(data.data.data.total))
            dispatch(setAllNovice(data.data.data.data));
            dispatch(setNovicePage(novicepage + 1));
            dispatch(setNovicerowsPerPage(globalRowsPerPage));
            setbackupNovice(JSON.parse(JSON.stringify(data.data.data.data)));
          }
        })
        .catch(() => { });
    }
    if (parseInt(activeTab) === 2) {
      filterAllIntermediate(body)
        .then((data) => {
          if (data.data.status) {
            dispatch(setAllIntermediateCount(data.data.data.total))
            dispatch(setAllIntermediate(data.data.data.data));
            dispatch(setIntermediatePage(intermediatePage + 1));
            dispatch(setIntermediaterowsPerPage(globalRowsPerPage));
            setbackupIntermediate(JSON.parse(JSON.stringify(data.data.data.data)));
          }
        })
        .catch(() => { });
    }
    if (parseInt(activeTab) === 3) {
      filterAllProfessionals(body)
        .then((data) => {
          if (data.data.status) {
            dispatch(setAllProfessionalsCount(data.data.data.total))
            dispatch(setAllProfessionals(data.data.data.data));
            dispatch(setProfessionalPage(professionalPage + 1));
            dispatch(setProfessionalrowsPerPage(globalRowsPerPage));
            setbackupProfessionals(JSON.parse(JSON.stringify(data.data.data.data)));
          }
        })
        .catch(() => { });
    }
    if (parseInt(activeTab) === 4) {
      filterAllPendingApprovals(body)
        .then((data) => {
          if (data.data.status) {
            dispatch(setAllPendingApprovalsCount(data.data.data.total))
            dispatch(setUsers(data.data.data.data));
            dispatch(setPendingApprovalsPage(pendingapprovalsPage + 1));
            dispatch(setPendingApprovalsrowsPerPage(globalRowsPerPage));
            setbackupPending(JSON.parse(JSON.stringify(data.data.data.data)));
          }
        })
        .catch(() => { });
    }
    if (parseInt(activeTab) === 5) {
      filterAllUnverifiedEmails(body)
        .then((data) => {
          if (data.data.status) {
            dispatch(setAllUnverifiedEmailsCount(data.data.data.total))
            dispatch(setAllUnverifiedEmails(data.data.data.data));
            dispatch(setUnverifiedEmailsPage(unverifiedEmailsPage + 1));
            dispatch(setUnverifiedEmailsrowsPerPage(globalRowsPerPage));
            setbackupUnverifiedEmails(JSON.parse(JSON.stringify(data.data.data.data)));
          }
        })
        .catch(() => { });
    }
  };
  const bulkActions = (status, selected) => {
    let body = { status, selected };
    setBulkInfo(body);
    if (parseInt(status) === 3) {
      window.loadModal2("#deleteConfirmation1", "show");
    } else {
      bulkActionsHanlder(body);
    }
  };
  const bulkActionsHanlder = (info) => {
    let body;
    if (bulkInfo.status !== undefined) {
      body = {
        status: bulkInfo.status,
        users: bulkInfo.selected,
      };
    } else {
      body = {
        status: info.status,
        users: info.selected,
      };
    }

    performBulkActions(body)
      .then((data) => {
        if (data.data.status) {
          getMediumList();
          getAllProfessionalsList();
          getAllDeclinedList();
          getAllIntermediateList();
          getAllNoviceList();
          getAllUnVerifiedEmails();
          callUsers();

        }
      })
      .catch(() => { });
  };
  const resendUnverifiedEmail = (e, id) => {
    e.stopPropagation();
    resendActivationUnverifiedMail(id, "unverified")
      .then((data) => {
        if (data.data.status) {
          let response = unverifiedEmails.map((item) => {
            if (parseInt(item.id) === parseInt(id)) {
              return {
                ...item,
                ...(item.resent_status = 1),
              };
            } else {
              return { ...item };
            }
          });
          setUnverifiedEmails(response);
        }
        if (!data.data.status) {
          let response = unverifiedEmails.map((item) => {
            if (parseInt(item.id) === parseInt(id)) {
              return {
                ...item,
                ...(item.resent_status = 2),
              };
            } else {
              return { ...item };
            }
          });
          setUnverifiedEmails(response);
        }
      })
      .catch(() => { });

    setTimeout(() => {
      let response = unverifiedEmails.map((item) => {
        return {
          ...item,
          ...(item.resent_status = 0),
        };
      });
      setUnverifiedEmails(response);
    }, 6000);
  };

  const resendEmail = (e, id) => {
    e.stopPropagation();
    resendActivationMail(id)
      .then((data) => {
        if (data.data.status) {
          let response = pendingApprovals.map((item) => {
            if (parseInt(item.id) === parseInt(id)) {
              return {
                ...item,
                ...(item.resent_status = 1),
              };
            } else {
              return { ...item };
            }
          });
          setPendingApprovals(response);
        }
        if (!data.data.status) {
          let response = pendingApprovals.map((item) => {
            if (parseInt(item.id) === parseInt(id)) {
              return {
                ...item,
                ...(item.resent_status = 2),
              };
            } else {
              return { ...item };
            }
          });
          setPendingApprovals(response);
        }
      })
      .catch(() => { });

    setTimeout(() => {
      let response = pendingApprovals.map((item) => {
        return {
          ...item,
          ...(item.resent_status = 0),
        };
      });
      setPendingApprovals(response);
    }, 6000);
  };
  const cancelRequest = () => {
    setDeletInfo({});
    setBulkInfo({});
  };
  const filterReset = () => {
    setFilterValues('screen_name'); 
    setSearchValues("");
    let dataVal = {
      "email": "",
      "client_name": "",
      "created_at": "",
      "orderBy": "asc",
      "referal_code": "",
      "created_at_order_by": ""
    }
    filterListMediums(dataVal);
  }
  return (
    <div className="all-medium-page medium-tab-pad">
      <Tabs active={activeTab} list={listArray} setActiveTab={setActiveTab} />
      <ul className="tabs-content">
        <TabPanel id={0} activeTab={activeTab}>
          <Medium
            mediums={mediums}
            updateUser={updateUser}
            filterReset={filterReset}
            onClickHandler={onClickHandler}
            filterListMediums={filterListMediums}
            activeTab={activeTab}
            bulkActions={bulkActions}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            getMediumList={getMediumList}
            mediumpage={mediumpage}
            mediumrowsPerPage={mediumrowsPerPage}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}

          />
        </TabPanel>
        <TabPanel id={1} activeTab={activeTab}>
          <Novice
            novices={novice}
            updateUser={updateUser}
            onClickHandler={onClickHandler}
            filterListMediums={filterListMediums}
            filterReset={filterReset}
            bulkActions={bulkActions}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            novicepage={novicepage}
            novicerowsPerPage={novicerowsPerPage}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
          />
        </TabPanel>
        <TabPanel id={2} activeTab={activeTab}>
          <Intermediate
            intermediates={intermediate}
            updateUser={updateUser}
            onClickHandler={onClickHandler}
            filterListMediums={filterListMediums}
            bulkActions={bulkActions}
            filterReset={filterReset}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            intermediatePage={intermediatePage}
            intermediaterowsPerPage={intermediaterowsPerPage}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
          />
        </TabPanel>
        <TabPanel id={3} activeTab={activeTab}>
          <Professional
            professionals={professionals}
            updateUser={updateUser}
            onClickHandler={onClickHandler}
            filterListMediums={filterListMediums}
            bulkActions={bulkActions}
            filterReset={filterReset}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            professionalPage={professionalPage}
            professionalrowsPerPage={professionalrowsPerPage}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
          />
        </TabPanel>

        <TabPanel id={4} activeTab={activeTab}>
          <PendingApprovals
            updateUser={updateUser}
            pendingApprovals={pendingApprovals}
            onClickHandler={onClickHandler}
            bulkActions={bulkActions}
            resendEmail={resendEmail}
            filterListMediums={filterListMediums}
            filterReset={filterReset}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            pendingapprovalsPage={pendingapprovalsPage}
            pendingapprovalsrowsPerPage={pendingapprovalsrowsPerPage}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
          />
        </TabPanel>
      
        <TabPanel id={5} activeTab={activeTab}>
          <UnverifiedEmails
            updateUser={updateUser}
            unverifiedEmails={unverifiedEmails}
            onClickHandler={onClickHandler}
            // bulkActions={bulkActions}
            filterReset={filterReset}
            resendUnverifiedEmail={resendUnverifiedEmail}
            filterListMediums={filterListMediums}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            unverifiedEmailsPage={unverifiedEmailsPage}
            unverifiedEmailsrowsPerPage={unverifiedEmailsrowsPerPage}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
          />
        </TabPanel>
        <TabPanel id={6} activeTab={activeTab}>
          <Declined
            updateUser={updateUser}
            pendingApprovals={declinedMediums}
            onClickHandler={onClickHandler}
            filterReset={filterReset}
            bulkActions={bulkActions}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            declinedPage={declinedPage}
            declinedrowsPerPage={declinedrowsPerPage}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
          />
        </TabPanel>
      </ul>
      <ConfirmDelete
        cancelRequest={cancelRequest}
        deleteUser={deleteUser}
        id="deleteConfirmation"
      />
      <ConfirmDelete
        cancelRequest={cancelRequest}
        deleteUser={bulkActionsHanlder}
        id="deleteConfirmation1"
      />
      {/* <Pagination  rowsPerPageOptions={[5, 10, 25]}  count={100} getPaginationData={getPaginationData} /> */}
    </div>
  );
}
