import React, { useEffect, useState } from "react";
import { updateFeedbackByAdmin } from "../../../../../services/admin";
import { toast } from "react-toastify";

export default function PublishFeedback({ editFeedbackDetail, callBack }) {
    const [additionalFeedback, setAdditionalFeedback] = useState("");
    const [meaningfulExperience, setMeaningfulExperience] = useState("");
    const [overallImpression, setOverallImpression] = useState("");
    const [
        recognizedMyLovedOneFromEvidence,
        setrecognizedMyLovedOneFromEvidence,
    ] = useState("");
    const [recommendTOthers, setRecommendToOthers] = useState("");

    ///
    const [additionalFeedbackPublish, setAdditionalFeedbackPublish] = useState("");
    const [meaningfulExperiencePublish, setMeaningfulExperiencePublish] = useState("");
    const [overallImpressionPublish, setOverallImpressionPublish] = useState("");
    const [
        recognizedMyLovedOneFromEvidencePublish,
        setrecognizedMyLovedOneFromEvidencePublish,
    ] = useState("");
    const [recommendTOthersPublish, setRecommendToOthersPublish] = useState("");

    const [feedbackId, setFeedbackId] = useState('')

    //effects
    const clearStates = () => {
        setAdditionalFeedback("");
        setMeaningfulExperience("");
        setOverallImpression("");
        setrecognizedMyLovedOneFromEvidence("");
        setRecommendToOthers("");
        /// publisheabele
        setAdditionalFeedbackPublish("");
        setMeaningfulExperiencePublish("");
        setOverallImpressionPublish("");
        setrecognizedMyLovedOneFromEvidencePublish("");
        setRecommendToOthersPublish("");

        setFeedbackId("")
    }
    useEffect(() => {

        if (editFeedbackDetail !== undefined) {
            let feedback;
            let id;
            let publish;
            if ((Object.keys(editFeedbackDetail).length) === 1) {

                if (Object.values(editFeedbackDetail)[0].edited_feedback === null) {
                    publish = Object.values(editFeedbackDetail)[0].feedback;
                }
                else {
                    publish = Object.values(editFeedbackDetail)[0].edited_feedback;
                }
                feedback = Object.values(editFeedbackDetail)[0].feedback;


                id = Object.values(editFeedbackDetail)[0].feedback_id
            }
            else {

                if (Object.values(editFeedbackDetail)[1].edited_feedback === null) {
                    publish = Object.values(editFeedbackDetail)[1].feedback;
                }
                else {
                    publish = Object.values(editFeedbackDetail)[1].edited_feedback;
                }


                feedback = Object.values(editFeedbackDetail)[1].feedback;
                id = Object.values(editFeedbackDetail)[1].feedback_id
            }
            setAdditionalFeedback(feedback.additional_feedback);
            setMeaningfulExperience(feedback.meaningful_experience);
            setOverallImpression(feedback.overall_impression);
            setrecognizedMyLovedOneFromEvidence(
                feedback.recognized_my_loved_one_from_evidence
            );
            setRecommendToOthers(feedback.recommend_To_others);
            /// publisheabele
            setAdditionalFeedbackPublish(publish.additional_feedback);
            setMeaningfulExperiencePublish(publish.meaningful_experience);
            setOverallImpressionPublish(publish.overall_impression);
            setrecognizedMyLovedOneFromEvidencePublish(
                publish.recognized_my_loved_one_from_evidence
            );
            setRecommendToOthersPublish(publish.recommend_To_others);

            setFeedbackId(id)
        }
    }, [editFeedbackDetail]);

    const onChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let check = (value > 0 && value < 6 || value === "")
        if (name === "additionalFeedbackPublish") {

            setAdditionalFeedbackPublish(value);

        }
        if (name === "meaningfulExperiencePublish" && check) {
            setMeaningfulExperiencePublish(value);
        }
        if (name === "overallImpressionPublish") {
            setOverallImpression(value);
        }
        if (name === "recognizedMyLovedOneFromEvidencePublish" && check) {
            setrecognizedMyLovedOneFromEvidencePublish(value);
        }
        if (name === "recommendTOthersPublish" && check) {
            setRecommendToOthersPublish(value);
        }
    };

    const onCancelHandler = () => {
        // window.loadModal2("#editFeedback", "hide");
        clearStates()
        window.loadModal2("#publishFeedback", "hide");
    };

    const updateHandler = () => {
        let body = {
            feedback_id: feedbackId,
            recognized_my_loved_one_from_evidence: recognizedMyLovedOneFromEvidencePublish,
            meaningful_experience: meaningfulExperiencePublish,
            recommend_To_others: recommendTOthersPublish,
            overall_impression: overallImpressionPublish,
            additional_feedback: additionalFeedbackPublish,
        }

        updateFeedbackByAdmin(body)
            .then((data) => {
                if (data.data.status) {
                    toast.success('Feedback updated succesfully', { position: 'top-center' })
                    callBack()
                    // window.loadModal2('#editFeedback', 'hide');
                    clearStates()
                    window.loadModal2("#publishFeedback", "hide");
                }
            })
            .catch(() => {
                toast.error('Error', { position: 'top-center' })
            });
    };
    return (
        <div
            className="modal fade "
            id="publishFeedback"
            // data-backdrop="static"
            role="dialog"
        >
            <div className="modal-dialog modal-lg  modal-dialog-preview">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="editable-form-feedback">
                            <div className="schedule-popup">
                                {/* <img src="../images/new-cll.png" alt="user" /> */}

                                <h3 className="mb-4 text-center mt-2">Client Feedback</h3>
                                <div>
                                    <div className="from-fild mt-2 mb-4">
                                        <label htmlFor="firstName">
                                            Recognized My Loved One From Evidence
                                        </label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <input
                                                onChange={onChangeHandler}
                                                value={recognizedMyLovedOneFromEvidence}
                                                type="number"
                                                id="recognizedMyLovedOneFromEvidence"
                                                name="recognizedMyLovedOneFromEvidence"
                                                placeholder="Recognized My Loved One From Evidence"
                                                min={1}
                                                max={5}
                                            />
                                        </div>
                                    </div>
                                    <div className="from-fild mt-2 mb-4">
                                        <label htmlFor="firstName">Meaningful Experience</label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <input
                                                onChange={onChangeHandler}
                                                value={meaningfulExperience}
                                                min={1}
                                                max={5}
                                                type="number"
                                                id="meaningfulExperience"
                                                name="meaningfulExperience"
                                                placeholder="Meaningful Experience"
                                            />
                                        </div>
                                    </div>


                                    <div className="from-fild mt-2 mb-4">
                                        <label htmlFor="firstName">Recommend To Others</label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <input
                                                onChange={onChangeHandler}
                                                value={recommendTOthers}
                                                min={1}
                                                max={5}
                                                type="number"
                                                id="recommendTOthers"
                                                name="recommendTOthers"
                                                placeholder="Recommend To Others"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="from-fild mt-2 mb-4">
                                    <label htmlFor="firstName">Overall Impression</label>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <input
                                            onChange={onChangeHandler}
                                            value={overallImpression}
                                            type="text"
                                            id="overallImpression"
                                            name="overallImpression"
                                            placeholder="Overall Impression"
                                        />
                                    </div>
                                </div>
                                <div className="from-fild mt-2 mb-4">
                                    <label htmlFor="firstName">Additional Feedback</label>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <textarea
                                            className="feedback-text-area"
                                            rows={6}
                                            onChange={onChangeHandler}
                                            value={additionalFeedback}
                                            type="text"
                                            id="additionalFeedback"
                                            name="additionalFeedback"
                                            placeholder="Additional Feedback"
                                        />
                                    </div>
                                </div>
                                {/* <div className="d-flex justify-content-between">
                                    <div
                                        className="advance-tag pointer text-center width-150px"
                                        onClick={onCancelHandler}
                                    >
                                        Cancel
                                    </div>
                                    <div
                                        className="advance-tag pointer text-center width-150px ml-2"
                                        onClick={updateHandler}
                                    >
                                        Update
                                    </div>
                                </div> */}
                            </div>
                            <div className="schedule-popup">
                                {/* <img src="../images/new-cll.png" alt="user" /> */}

                                <h3 className="mb-4 text-center mt-2">Publishable Feedback</h3>
                                <div>
                                    <div className="from-fild mt-2 mb-4">
                                        <label htmlFor="firstName">
                                            Recognized My Loved One From Evidence
                                        </label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <input
                                                onChange={onChangeHandler}
                                                value={recognizedMyLovedOneFromEvidencePublish}
                                                type="number"
                                                id="recognizedMyLovedOneFromEvidencePublish"
                                                name="recognizedMyLovedOneFromEvidencePublish"
                                                placeholder="Recognized My Loved One From Evidence"
                                                min={1}
                                                max={5}
                                            />
                                        </div>
                                    </div>
                                    <div className="from-fild mt-2 mb-4">
                                        <label htmlFor="firstName">Meaningful Experience</label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <input
                                                onChange={onChangeHandler}
                                                value={meaningfulExperiencePublish}
                                                min={1}
                                                max={5}
                                                type="number"
                                                id="meaningfulExperiencePublish"
                                                name="meaningfulExperiencePublish"
                                                placeholder="Meaningful Experience"
                                            />
                                        </div>
                                    </div>


                                    <div className="from-fild mt-2 mb-4">
                                        <label htmlFor="firstName">Recommend To Others</label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <input
                                                onChange={onChangeHandler}
                                                value={recommendTOthersPublish}
                                                min={1}
                                                max={5}
                                                type="number"
                                                id="recommendTOthersPublish"
                                                name="recommendTOthersPublish"
                                                placeholder="Recommend To Others"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="from-fild mt-2 mb-4">
                                    <label htmlFor="firstName">Overall Impression</label>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <input
                                            onChange={onChangeHandler}
                                            value={overallImpressionPublish}
                                            type="text"
                                            id="overallImpressionPublish"
                                            name="overallImpressionPublish"
                                            placeholder="Overall Impression"
                                        />
                                    </div>
                                </div>
                                <div className="from-fild mt-2 mb-4">
                                    <label htmlFor="firstName">Additional Feedback</label>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <textarea
                                            rows={6}
                                            className="feedback-text-area"
                                            onChange={onChangeHandler}
                                            value={additionalFeedbackPublish}
                                            type="text"
                                            id="additionalFeedbackPublish"
                                            name="additionalFeedbackPublish"
                                            placeholder="Additional Feedback"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div
                                        className="advance-tag pointer text-center width-150px"
                                        onClick={onCancelHandler}
                                    >
                                        Cancel
                                    </div>
                                    <div
                                        className="advance-tag pointer text-center width-150px ml-2"
                                        onClick={updateHandler}
                                    >
                                        Update
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
