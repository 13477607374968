import React, { useEffect, useState } from "react";
import BookingCalendar from "./calendar";
import moment from "moment-timezone";
import { getTimezones } from "../../services/uidata";
import { getDateCount, getTimeSlotsByDate } from "../../services/client";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../reducer/auth/auth";
import { toast } from "react-toastify";
import { filterVisibleTimeZones } from "../../utils/helpers";

export default function BookingPage({ openForm, setBookingDate, setBookingTime, setTimeZoneInfo, setsession_id,
    setsubsession_id, setTimeZoneName, setPrice,
    setDuration, setPaymentInfo, setCurrency, price,
    currency }) {
    const params = useParams();
    const dispatch = useDispatch();

    const [pageType, setPageType] = useState('');
    const [timezoneData, setTimezoneData] = useState([]);
    const [timezone, setTimezone] = useState("");
    const [timeZoneValue, setTimeZoneValue] = useState("");
    const [appointmentTyepList, setAppointmentTyepList] = useState([]);
    const [dateCount, setDateCount] = useState({});
    const [activeSessionId, setActiveSessionId] = useState("");
    const [activeSubSessionId, setActiveSubSessionId] = useState("");

    //
    const [slots, setSlots] = useState([]);
    const [slotsupdated, setSlotsUpdated] = useState(false);
    const [active, setActive] = useState(false);
    const [count, setCount] = useState(0);
    const [noSlotsFound, setnoSlotsFound] = useState(false);
    const [month_empty, setmonth_empty] = useState(false);



    useEffect(() => {

        setTimezone(moment.tz.guess());
        dispatch(setLoading(true))
    }, []);
    useEffect(() => {
        if (timezone !== "") {
            updateTimezones(timezone);
        }
    }, [timezone]);

    const onChangeSessionHandler = (e) => {
        setSlotsUpdated(false);
        setActive(false);

        let value = e.target.value;
        console.log(value)
        console.log(appointmentTyepList, "appointmentTyepList")
        let item = appointmentTyepList.filter(
            (info) => parseInt(info.subsession_id) === parseInt(value)
        );
        console.log(item, "appointmentTyepList")
        setPageType('selection');
        setActiveSubSessionId(item[0].subsession_id);
        setActiveSessionId(item[0].session_id);
        //
        setsession_id(item[0].session_id);
        setPrice(item[0].amount);
        setDuration(item[0].duration);
        setCurrency(item[0].currency)
        setsubsession_id(item[0].subsession_id);

    };
    const changeTimeZoneHandler = (e) => {
        let id = e.target.value;
        console.log(id, "id");
        let info = timezoneData.filter((time) => {
            return time.id === parseInt(id);
        });
        console.log(timezoneData, "timezoneData")
        console.log(info, 'timezoneData info');

        setTimeZoneName(info[0].front_name);
        setTimeZoneValue(id);
        setTimeZoneInfo(id);
        setSlotsUpdated(false);
        setActive(false);
    };
    const updateTimezones = (timezone) => {
        console.log(timezone, "timezone")
        getTimezones()
            .then((data) => {
                setTimezoneData(filterVisibleTimeZones(data.data.data));

                // let info = data.data.data.filter((time) => {
                //     return time.name === timezone;
                // });

                let item = data.data.data.filter((time) => time.name === timezone);

                if (item.length && item[0].status === 1) {

                    setTimeZoneName(item[0].front_name);
                    setTimeZoneValue(item[0].id);
                    setTimeZoneInfo(item[0].id)
                }
                else {
                    let info = getNextMatchingTimezone(data.data.data, timezone);
                    console.log(data.data.data, "data.data.data")
                    console.log(info)
                    setTimeZoneName(info.front_name);
                    setTimeZoneValue(info.id);
                    setTimeZoneInfo(info.id)
                }

            })
            .catch((err) => console.log(err));
    };





    const getNextMatchingTimezone = (timezoneData, newtime) => {
        const array = timezoneData;
        const currentValue = newtime;

        const currentIndex = array.findIndex(obj => obj.name === currentValue);
        const nextMatchingIndex = array.findIndex((obj, index) => index > currentIndex && obj.status === 1);
        const nextMatchingObject = nextMatchingIndex !== -1 ? array[nextMatchingIndex] : null;

        return nextMatchingObject;

    }
    const getDatesCount = (dates) => {
        dispatch(setLoading(true))

        let body = {
            medium_id: params.id,
            startDate: dates.start,
            endDate: dates.end,
            timezone: timeZoneValue,
            session_id: activeSessionId,
            subsession_id: activeSubSessionId,
        };
        getDateCount(body)
            .then((data) => {

                dispatch(setLoading(false))
                if (data.data.status) {
                    setAppointmentTyepList(data.data.externalInfo.available_sessions);
                    setPaymentInfo(data.data.externalInfo.payment_methods);
                    setmonth_empty(data.data.externalInfo.month_empty)
                    if (data.data.externalInfo.month_empty) {
                        window.scrollTo({
                            left: 0,
                            top: 700,
                            behavior: "smooth",
                        });
                    }
                    if (count === 0) {


                        // setsubsession_id(data.data.externalInfo.available_sessions[0].subsession_id);
                        // setsession_id(data.data.externalInfo.available_sessions[0].session_id);
                        // setPrice(data.data.externalInfo.available_sessions[0].price);
                        // setDuration(data.data.externalInfo.available_sessions[0].duration);
                    }
                    setCount(count + 1)
                    // setActiveSubSessionId(data.data.externalInfo[0].subsession_id);
                    // setActiveSessionId(data.data.externalInfo[0].session_id)
                    setDateCount(data.data.data);
                }
                else {
                    setmonth_empty(false)
                    toast.error(data.data.message, {
                        position: "top-center",
                    });
                }
            })
            .catch(() => { dispatch(setLoading(false)) });
    };

    const getTimeSlots = (date) => {
        dispatch(setLoading(true))
        setSlotsUpdated(false);
        setActive(false);
        setBookingDate(date);
        let body = {
            medium_id: params.id,
            date: date,
            timezone: timeZoneValue,
            session_id:
                activeSessionId !== ""
                    ? activeSessionId
                    : appointmentTyepList[0].session_id,
            subsession_id:
                activeSubSessionId !== ""
                    ? activeSubSessionId
                    : appointmentTyepList[0].subsession_id,
        };
        getTimeSlotsByDate(body)
            .then((data) => {

                dispatch(setLoading(false))
                if (data.data.status && !data.data.data.slots.length > 0) {
                    setnoSlotsFound(true);
                    window.scrollTo({
                        left: 0,
                        top: 700,
                        behavior: "smooth",
                    });

                    // window.loadModal("#noslotsfound", "open");
                    return;
                }
                else if (data.data.status) {
                    setnoSlotsFound(false);
                    setSlotsUpdated(true);
                    setActive(true);
                    console.log(data.data.data.slots, "data.data.data.slots");
                    window.scrollTo({
                        left: 0,
                        top: 700,
                        behavior: "smooth",
                    });
                    setSlots(
                        data.data.data.slots.map((value) => {
                            return { datetime: value, selectedStatus: false, status: false };
                        })
                    );
                }

                else {
                    toast.error(data.data.message, {
                        position: "top-center",
                    });
                }
            })
            .catch(() => { });
    };
    const openCalendar = () => {
        setPageType('calendar');
    }
    return (
        <>

            <div className="container mt-5">

                {/* <div>
                    <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2  text-center">

                        <select
                            value={timeZoneValue}
                            onChange={changeTimeZoneHandler}
                            className="select-box-timezone pointer width-440px"
                        >
                            <option disabled selected value="">
                                Change Timezone
                            </option>
                            {timezoneData.map((item) => (
                                <option value={item.id}>{item.front_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2  text-center">
                        <select
                            onChange={onChangeSessionHandler}
                            className="subsession-box"
              
                        >
                            <option disabled value={""}>
                                Appoinment Type
                            </option>

                            {appointmentTyepList.map((item, index) => (
                                <option data-item={item} value={item.subsession_id}>
                                    {" "}
                                    {item.name}
                                </option>
                            ))}
                        </select>
                        <span className="d-inline-block p-2"> &nbsp;Price :${activeAmount}&nbsp;{activeCurrency}</span>
                    </div>
                </div> */}

            </div>

            <div>


                <div className="container mt-5">
                    <div>
                        <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2  text-center">
                            {/* <b className="mr-5">
                            Timezone: {timezone !== undefined && timezone}&nbsp;
                        </b> */}
                            <select
                                value={timeZoneValue}
                                onChange={changeTimeZoneHandler}
                                className="select-box-timezone pointer width-440px"
                            >
                                <option disabled selected value="">
                                    Change Timezone
                                </option>
                                {timezoneData.map((item) => (
                                    <option value={item.id}>{item.front_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2  text-center">
                            <select
                                onChange={onChangeSessionHandler}
                                className="subsession-box"
                                value={activeSubSessionId}
                            >
                                <option disabled value={""}>
                                    Appointment Type
                                </option>

                                {appointmentTyepList.map((item, index) => (
                                    <option selected={item.subsession_id === activeSubSessionId} data-item={item} value={item.subsession_id}>
                                        {" "}
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            <span className="d-inline-block p-2"> &nbsp;Price :${price}&nbsp;{currency}</span>
                        </div>
                    </div>
                    <h4 className='text-center'>Please select service type from dropdown above to get started.</h4>
                </div>
                <div className={`${pageType === "selection" ? 'd-none' : 'd-block'}`}>
                    <div className="p-5 m-5">

                    </div>
                    <div className="p-5 m-5">

                    </div>
                    <div className="p-5 m-5">

                    </div>
                </div>
                <div className={`${pageType === "selection" ? 'd-block' : 'd-none'}`}>
                    <div className="schedule-advance-saction">
                        <div className="container">
                            <div className="row">

                                <BookingCalendar
                                    getDatesCount={getDatesCount}
                                    timeZoneValue={timeZoneValue}
                                    dateCount={dateCount}
                                    activeSubSessionId={activeSubSessionId}
                                    getTimeSlots={getTimeSlots}
                                    slots={slots}
                                    setSlots={setSlots}
                                    setBookingDate={setBookingDate}
                                    setBookingTime={setBookingTime}
                                    slotsupdated={slotsupdated}
                                    setSlotsUpdated={setSlotsUpdated}
                                    active={active}
                                    noSlotsFound={noSlotsFound}
                                    setnoSlotsFound={setnoSlotsFound}
                                    month_empty={month_empty}
                                />
                            </div>
                        </div>
                    </div>
                    {/**********progress bar */}
                    <div className="progress-saction">
                        <div className="container">
                            <ul>
                                <li
                                    // onClick={changeRender}
                                    className="back"
                                >
                                    <Link to={'/'}>
                                        <img src="../images/black-erow.png" alt="back" />
                                        Back</Link>

                                </li>

                                <li className="continue">
                                    <div className="dont-have-acc">
                                        {/* sessionType==="prof"?  () =>"": */}
                                        <button
                                            onClick={openForm}
                                            type="button"
                                            className="btn btn-info btn-lg width-unset-img"
                                        // data-toggle="modal"
                                        // data-target="#myModal"
                                        >
                                            Book Appointment
                                            <img
                                                className="width-unset-img"
                                                src="../images/wight-erow.png"
                                                alt="book appointment"
                                            />
                                        </button>
                                        {/* <ConfirAdvanceModal /> */}
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {/* <div style={{ width: `${loaderLine}%` }} className="prgress-baar-new">
      {loaderLine !== 0 && (
            <span className="loaderline"> {loaderLine}%</span>
          )}
      </div> */}
                    </div>
                </div>
            </div>
            <div className="dont-have-acc gust-ppop">
                <div className="modal fade" id="noslotsfound" role="dialog">
                    <div className="modal-dialog modal-dialog-preview">
                        <button
                            type="button"
                            className="close pointer"
                            data-dismiss="modal"
                        >
                            &times;
                        </button>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="guest-popup">
                                    {/* <img src="images/sadu.png" /> */}
                                    <h3>
                                        We are sorry
                                        <br />
                                        there are no slots available at the moment.
                                        <br />
                                        Try the next month for more slots.
                                    </h3>
                                    <a
                                        data-dismiss="modal"

                                        href="javascript:void(0);"
                                    >
                                        Cancel
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
