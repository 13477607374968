import React from "react";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import ClientDashboard from "../pages/client/dashboard/dashboard";
import ClientAppointments from "../pages/client/appointments/appointments";
import ClientAppointment from "../pages/client/appointment/appointment";
import Topbar from "../components/topbar/topbar";
import Comingsoon from "../pages/comingsoon";
import MyProfile from "../pages/client/myprofile";
import ThankyouPopup from "../pages/client/appointment/premiumsessions/practitonerInfo/payment/thankyou";
import ShortCuts from "../pages/client/shortcuts";
import ClientVideo from "../pages/client/clientvideo";
import PayMyMedium from '../pages/paymymedium';
import AppointmentConfirm from "../pages/client/appointmentconfirm";
import GuestBooking from "../pages/guestbooking";
import VerifyEmail from "../pages/admin/calendar/verifyemail";
import RegistrationCompleted from "../pages/admin/registrationcompleted";
import Footer from "../pages/client/appointment/footer";

// import Survey from "../pages/surveyform";
const LoggedInRoutes = ({ is_first_appointment }) => {
  let from = localStorage.getItem('registration-from');
  return (
    <Routes>
      <Route path="/" element={<Navigate to={localStorage.getItem('guest_id') ? `/guest-booking/${localStorage.getItem('guest_id')}` : from == "registration" ? '/registration-completed' : is_first_appointment ? "/shortcuts" : "/appointment"} />} />
      <Route path="/registration-completed" element={
        <div
          className="new-appointment new-appointment-listt"
        >
          <Topbar />
          <RegistrationCompleted />
       
        </div>
      } />
      {/* <Route path="/dashboard" element={<ClientDashboard />} /> */}
      <Route path="/appointments" element={<ClientAppointments />} />
      <Route path="/guest-booking/:id" element={<GuestBooking />} />
      {/* <Route path="/appointment-confirmation/:id" element={<AppointmentConfirm />} /> */}
      <Route path="/appointment-confirmation/:id" element={<AppointmentConfirm />} />
      <Route path="/verifyemail" element={<VerifyEmail />} />
      <Route
        path="/appointment"
        element={
          <div
            className="new-appointment new-appointment-listt"
          >
            <Topbar />
            <ClientAppointment />
          </div>
        }
      />
      <Route path="/shortcuts" element={<ShortCuts />} />
      <Route path="/feedback" element={<Comingsoon />} />
      <Route path="/my-profile" element={<MyProfile />} />
      <Route path="/thankyou" element={<ThankyouPopup />} />
      <Route path="/paymymedium/:id" element={<PayMyMedium />} />
      <Route path="intro-video" element={
        <div
          className="new-appointment new-appointment-listt"
        >
          <Topbar />
          <ClientVideo />
        </div>
      } />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default LoggedInRoutes;
