import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { updatePricing } from "../../../../services/profile";
import { toast } from "react-toastify";
import { setLoading } from "../../../../reducer/auth/auth";

export default function Coaching(props) {
  const dispatch = useDispatch();
  const { stepOneData, id } = props;
  const { coaching_pricing_30, session_currency } = stepOneData || {};
  useEffect(() => {
    console.log(stepOneData, "data");
  }, []);
  const updateCoachingPricing = (formData) => {
    dispatch(setLoading(true));
    const { coaching_pricing_30, session_currency } = formData;
    let item = {
      userId: id,
      coaching_pricing:
        coaching_pricing_30 === "" ||
        coaching_pricing_30 === null ||
        parseInt(coaching_pricing_30) === 0
          ? null
          : JSON.stringify({
              30: coaching_pricing_30,
            }),
      session_currency: JSON.stringify({
        30: session_currency,
        45: session_currency,
        60: session_currency,
        452: session_currency,
        602: session_currency,
      }),
    };
    updatePricing(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          stepOneData.session_currency = session_currency
          toast.success("Coaching Pricing Updated", { position: "top-center" });
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  return (
    <div className="row-one-step min-height-80vh">
      <Formik
        initialValues={{
          coaching_pricing_30: coaching_pricing_30 || "",
          session_currency: session_currency || "",
        }}
        onSubmit={(values, actions) => {
          updateCoachingPricing(values);
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
        }) => (
          <Form>
            <div className="full-side">
              <label>
                Please enter pricing as per the session length below
              </label>
            </div>
            <div className="mt-2 ml-5">
              <label>30 Minutes</label>
              <Field
                className="w-25 p-2 amount_input"
                id="coaching_pricing_30"
                name="coaching_pricing_30"
                type="number"
                placeholder="Amount"
              />
              <ErrorMessage
                name="session_length_30"
                component="div"
                className="error"
              />
            </div>
            <>
              <div className="row-one-step p-2">
                <div className="full-side">
                  <label>Session Currency</label>

                  {/* stepOneData.mediumship_level */}
                  <div className="full-fild ml-5">
                    <>
                      <div className="check-field">
                        <input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="radio"
                          name="session_currency"
                          id="session_currency_usd"
                          value={"USD"}
                          checked={
                            values.session_currency === "USD" ? true : false
                          }
                          // checked={
                          //   parseInt(values.when_do_i_pay) === 1 ? true : false
                          // }
                        />
                        <label className=" w-unset" htmlFor="when_do_i_pay_1">
                          USD
                        </label>
                      </div>
                      <div className="check-field">
                        <input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="radio"
                          name="session_currency"
                          id="session_currency_cad"
                          value={"CAD"}
                          checked={
                            values.session_currency === "CAD" ? true : false
                          }
                          // checked={
                          //   parseInt(values.when_do_i_pay) === 1 ? true : false
                          // }
                        />
                        <label className=" w-unset" htmlFor="when_do_i_pay_1">
                          CAD
                        </label>
                      </div>
                      <div className="check-field">
                        <input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="radio"
                          name="session_currency"
                          id="session_currency_aud"
                          value={"AUD"}
                          checked={
                            values.session_currency === "AUD" ? true : false
                          }
                          // checked={
                          //   parseInt(values.when_do_i_pay) === 1 ? true : false
                          // }
                        />
                        <label className=" w-unset" htmlFor="when_do_i_pay_1">
                          AUD
                        </label>
                      </div>
                    </>
                  </div>
                </div>
              </div>
              <div className="row-one-step ml-5">
                <div className="full-side">
                  <span className="error text-left">
                    {errors.session_currency &&
                      touched.session_currency &&
                      errors.session_currency}
                  </span>
                </div>
              </div>
            </>
            {/* Add button for form submission */}
            <div className="mt-2 ml-5">
              <button type="submit" className="resend-btn">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
