import React, { useEffect, useState } from "react";
import moment from "moment";
//services
import {
  createSession,
  getMatchedAvailabilites,
  getPractitionars,
  getPractitionarInfo,
  getPractitionarsFilter,
  saveDirectAdvanceBooking,
  saveSessionTerms,
  getSessionLimit,
  getMediumServiceDetails
} from "../../../services/client";

import { Link, useNavigate } from "react-router-dom";
import Loader from "./loader/loader";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setLoadingContent,
  setLoadingText,
} from "../../../reducer/auth/auth";
import { setMediumData, setSaveTermsHanlder, setSaveTermsHanlderStarter } from "../../../reducer/userSlice";

import {
  setmediumListItems,
  setbookingBtnStatus,
  setbookedAppointmentData,
  setClientPathArray,
  setBookingTime,
  setBookingDate,
  setWindowObject,
  setRenderTypeInfo,
  setBookingTypeInfo,
} from "../../../reducer/clientsSlice";
import Pusher from "pusher-js";

//component
import Progress from "./Progress";
import AdvanceAppointment from "./advanceappointment";
import Practitioners from "./premiumsessions/practitioners";
import PractitionerInfo from "./premiumsessions/practitonerInfo";
import Footer from "./footer";
import OpenUrlPopUp from "../../../layout/openUrlPopUp";
import { changeOnlineStatusFunc } from "../../../commonfunctions";
import Questions from "./questions";
import { getLanguages, getSpeciality } from "../../../services/uidata";
import StepOne from "./bookasessionlaterquestions/stepone";
import StepTwo from "./bookasessionlaterquestions/steptwo";
import { getBannerPage } from "../../../services/client";
import Button from "../../../components/button";
import { toast } from "react-toastify";
import { getCreateAppointmentSubseesions } from "../../../services/client";

export default function ClientAppointment() {
  const auth = useSelector((state) => state.user);
  const {
    preferences,
    bookingdate,
    bookingtime,
    bookingIds,
    windowObject,
    mediumsToBeMatch,
    renderTypeInfo,
    bookingTypeInfo,
  } = useSelector((state) => state.client);
  const { promotion_code, is_starter, test_mode } = useSelector(
    (state) => state.user.user
  );
  const { is_guest_client } = useSelector(
    (state) => state.user.user
  );
  // const { appointment_count } = useSelector((state) => state.user.user);
  // const {languages ,specialities ,countries } = preferences
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [renderType, setRenderType] = useState("loader");
  // const [appointmentType, setAppointmentType] = useState("");
  // const [mediumStatus, checkMediumStatus] = useState(false);
  // const [loaderLine, setLoaderLine] = useState(0);
  const [saveterms, setSaveTerms] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [time, setTime] = useState(0);
  const [practitioners, setPractitioners] = useState([]);
  const [practitionerInfo, setPractitionerInfo] = useState(null);
  const [step, setStep] = useState(0);
  const [limit, setLimit] = useState(0);
  const [loaderType, setLoaderType] = useState("image");
  const [redirelUrl, setRedirelUrl] = useState(null);
  // const [stopTime, setstopTime] = useState(false);
  const [bookedId, setbookedId] = useState("");
  const [howManyTime, setHowManyTime] = useState(0);
  const [formLanguages, setFormLanguages] = useState([]);
  const [specialtyData, setSpecialityData] = useState([]);
  const [showBtn, setShowBtn] = useState(false);
  const [bookInfo, setBookInfo] = useState("");
  const [sessionType, setSessionType] = useState("prof");
  const [bannerLimit, setBannerLimit] = useState(0);
  const [bannerDesc, setBannerDesc] = useState("");
  const [checkOriganalType, setcheckOriganalType] = useState("original");
  const [bookingType, setBookingType] = useState("");
  const [showSessionDone, setshowSessionDone] = useState(false);

  const [appointmentTyepList, setAppointmentTyepList] = useState([]);
  const [mediumSessionInfo, setMediumSessionInfo] = useState();
  const [activeSessionId, setActiveSessionId] = useState('');
  const [activeSubSessionId, setActiveSubSessionId] = useState('');//
  const [activeDurationId, setActiveDurationId] = useState('');
  const [activeAmount, setActiveAmount] = useState('');
  const [activeCurrency, setActiveCurrency] = useState('');
  const [activesession_id, setactivesession_id] = useState('5');//
  const [appointment_count, setappointment_count] = useState(true);




  useEffect(() => {
    dispatch(setLoading(true))
    getSessionLimit().then((data) => {

      if (data.data.status) {
        setappointment_count(data.data.data.appointment_count);
      }
      setTimeout(() => {
        dispatch(setLoading(false))

      }, 300)
    }).catch(() => { dispatch(setLoading(false)) })

    if (localStorage.getItem('type') === "newappointment") {
      setActiveSubSessionId(localStorage.getItem('subsession_id'));
      setActiveDurationId(localStorage.getItem('duration'));
      setActiveAmount(localStorage.getItem('amount'));
      setActiveCurrency(localStorage.getItem('activeCurrency'));
      setRenderType("newappointment");
      setBookingType("outreach");
      setactivesession_id(localStorage.getItem('activesession_id'));
    }
    else {
      setRenderType("select");
    }
    dispatch(setWindowObject(true));
    // setRenderType("newappointment");

    if (formLanguages.length === 0) updateLanguage();
    if (specialtyData.length === 0) updateSpeciality();
    getBanner();
    // window.loadModal2("#OpenUrlPopUp", "show");
    // callProfile();
    dispatch(setClientPathArray("appointment"));

    if (renderTypeInfo === "startBookaSessionLaterQuestions" && bookingTypeInfo === "outreach") {
      setRenderType('startBookaSessionLaterQuestions')
      setBookingType('outreach')
      setcheckOriganalType('dummy')
      // dispatch(setRenderTypeInfo(''))
      // dispatch(setBookingTypeInfo(''))
    }
    if (renderTypeInfo === "book-specific-professinal" && bookingTypeInfo === "professional") {
      setRenderType('book-specific-professinal')
      setBookingType('professional')
      // dispatch(setRenderTypeInfo(''))
      // dispatch(setBookingTypeInfo(''))
    }
    return () => {
      (setClientPathArray(null));
    };


  }, []);

  useEffect(() => {
    dispatch(setBookingTypeInfo(bookingType))
    dispatch(setRenderTypeInfo(renderType))
  }, [renderType])

  const sessionLimitReachedHandler = () => {
    setshowSessionDone(true);
  };
  const getBanner = () => {
    getBannerPage()
      .then((data) => {
        if (data.data.status) {
          let info = data.data.data[0];
          setBannerLimit(parseInt(info.status));
          setBannerDesc(info.description);
        }
      })
      .catch(() => { });
  };
  const updateLanguage = () => {
    getLanguages()
      .then((data) => {
        setFormLanguages(data.data.data);
      })
      .catch((err) => console.log(err));
  };
  const updateSpeciality = () => {
    getSpeciality()
      .then((data) => {
        setSpecialityData(data.data.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    Pusher.logToConsole = false;
    var pusher = new Pusher("823ab72ed6ecd563b8c8", {
      cluster: "ap2",
    });

    var channel = pusher.subscribe("notify-clients");
    channel.bind("App\\Events\\NotifyClients", function (data) {
      // checkMediumStatus(!data.medium ? true : false);
      if (!data.medium) {
        if (parseInt(bookedId) === parseInt(data.appointment_id)) {
          // setstopTime(true);
          changeOnlineStatusFunc(0, dispatch);
          setHowManyTime(0);
          setRenderType("loader");
          setLoaderType("video");
          setRedirelUrl(data.link);
          handleReset();
          setIsActive(false);
        } else {
          console.log("inside else");
        }
      } else {
        console.log("main else");
      }
    });
  }, [bookedId]);

  useEffect(() => {
    if (howManyTime === 4) {
      if (renderType === "loader") {
        setRenderType("select");
        handleReset();
        setIsActive(false);
        window.openModal2();
      }
    }
  }, [howManyTime]);
  const setAppointments = (type) => {
    setRenderType(type);
    startSession(type);
  };
  // const selectAppointmentType = (type) => {
  //   setAppointmentType(type);
  // };
  const startSession = (type) => {
    updateSession(type);
  };

  const updateSession = (type) => {
    let support_date = new Date();
    // let lang = item.languages.map((item) => {
    //   return item.id;
    // });
    let body = {
      time: moment
        .utc()
        .add(4, "minutes")
        .format("HH:mm"),
      date: moment.utc(support_date).format("YYYY-MM-DD HH:mm"),
      ondemand: "no",
      offset: step,
      limit: limit === 0 ? 5 : limit,
      id: bookedId,
    };
    createSession(body)
      .then((data) => {
        if (data.data.status) {
          setbookedId(data.data.data.id);

          let lim = limit;
          // let steps = step;
          let tim = howManyTime;

          setHowManyTime(tim + 1);
          setLimit(lim === 0 ? 5 : lim + 5);
          setStep(lim === 5 ? 10 : 5);
          setTimeout(() => {
            if (type === "loader") {
              handleReset();
              // window.openModal2();
            }
          }, 900);
          dispatch(setMediumData(data.data.data));
        }
        if (!data.data.status) {
          handleReset();
          setIsActive(false);
          window.openModal2();
        }
      })
      .catch((err) => console.log(err));
  };
  const changeRender = () => {
    localStorage.removeItem('type');
    localStorage.removeItem('subsession_id');
    localStorage.removeItem('duration');
    localStorage.removeItem('amount');
    localStorage.removeItem('activeCurrency');
    localStorage.removeItem('activesession_id');
    if (bookingType === "professional") {
      setRenderType("premium-session");
    } else {
      setRenderType("select");
    }
    dispatch(setBookingTime(""));
    dispatch(setBookingDate(""));
    localStorage.setItem("settingAppointment", "No");
    // dispatch(setAppointment("No"));

    // navigate("/appointments");
  };

  const bookNewAppointment = () => {
    // changeRender()
    setRenderType("select");
    window.openModal2();
  };
const startBookingAgain = () => {
  startLoaderNow()
  window.openModal2();
}
  const getSessionTerms = (data) => {
    dispatch(setLoading(true));
    let body;

    if (activesession_id === "3") {
      body = {
        terms_and_condition_starter: JSON.stringify(data)
      }
    }
    else {
      body = {
        terms_and_condition_json: JSON.stringify(data),
      };
    }
    saveSessionTerms(body)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          if (activesession_id === "3") {
            dispatch(setSaveTermsHanlderStarter())

          }
          else {
            dispatch(setSaveTermsHanlder());
          }
          console.log("success");
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const getAvailabilites = () => {

    dispatch(setLoadingContent(`<div>Searching for availability... we'll have the results shortly! </div>`));
    dispatch(setLoadingText(true));
    let body = {
      date: preferences.bookingdate,
      time: preferences.bookingtime,
    };

    getMatchedAvailabilites(body)
      .then((data) => {
        dispatch(setLoadingContent(``));
        dispatch(setLoadingText(false));
        if (data.data.status) {
          dispatch(setmediumListItems(data.data.data));
          dispatch(setbookingBtnStatus(true));
        }
        if (!data.data.status) {
          dispatch(setmediumListItems(data.data.data));
        }
        if (!data.data.status && data.data.message === "No Time Slots found") {
          dispatch(setmediumListItems(data.data.data));
          window.loadModal("#noslotsfound", "open");
        }
      })
      .catch((err) => {
        dispatch(setLoadingContent(``));
        dispatch(setLoadingText(false));
        console.log(err);
      });
  };
  const bookAppointment = () => {
    let amount = practitionerInfo !== null ? practitionerInfo.general.session_length !== undefined && practitionerInfo.general.session_length !== "" && practitionerInfo.general.session_length !== null ? Object.values(
      JSON.parse(practitionerInfo.general.session_length)
    )[0] : 20 : 0
    let currency = practitionerInfo !== null ? practitionerInfo.general.session_currency !== undefined && practitionerInfo.general.session_currency !== "" && practitionerInfo.general.session_currency !== null ? Object.values(
      JSON.parse(practitionerInfo.general.session_currency)
    )[0] : 'CAD' : ''
    let medium_id =
      practitionerInfo !== null && practitionerInfo !== undefined
        ? practitionerInfo.general.medium_id
        : "";
    // dispatch(setLoading(true));
    let data = activesession_id;
    // let medium = selectedMedium.id;
    // getZoom(medium)
    //   .then((data) => {
    //     dispatch(setLoading(false));
    //     if (data.data.status) {
    if (practitionerInfo !== null && practitionerInfo !== undefined) {
      if (practitionerInfo.general.payStatus !== undefined) {
        if (practitionerInfo.general.payStatus === "before") {
          data = "8";
        }
        else {
          data = "7";
        }
      }
      else {
        if (
          (practitionerInfo.general.payType.includes(1) &&
            practitionerInfo.general.payType.includes(2)) ||
          (practitionerInfo.general.payType.length === 1 &&
            practitionerInfo.general.payType.includes(1)) ||
          practitionerInfo.general.payType.length === 0
        ) {
          data = "8";
        } else if (
          practitionerInfo.general.payType.length === 1 &&
          practitionerInfo.general.payType.includes(2)
        ) {
          data = "7";
        }
      }
    }
    startAdvanceBooking(medium_id, data, amount, currency);
    //   }
    // })
    // .catch(() => {
    //   dispatch(setLoading(false));
    // });
  };
  const startAdvanceBooking = (medium_id, data, amount, currency) => {

    console.log(activeSubSessionId, "activeSubSessionId");
    let body = {
      date: bookingdate,
      time: bookingtime,
      session_type: data,
      medium_id: medium_id,
      mediums: mediumsToBeMatch,
      amount: activeAmount === null ? "" : activeAmount,
      currency: activeCurrency === null ? "" : activeCurrency,
      duration: activeDurationId === null ? "" : activeDurationId,
      subsession_id: activeSubSessionId === null ? "" : activeSubSessionId
    };
    if (bookingdate === "" || bookingtime === "") {
      toast.error("Please select both date and time slot", {
        position: "top-center",
      });
      dispatch(setLoading(false));
      return;
    }
    dispatch(
      setLoadingContent(" <div>We are finding the best Medium for you</div>")
    );
    dispatch(setLoadingText(true));
    saveDirectAdvanceBooking(body)
      .then((data) => {
        dispatch(setLoadingText(false));
        dispatch(setLoadingContent(""));
        if (data.data.status) {
          // localStorage.removeItem('type');
          dispatch(setbookedAppointmentData(data.data.data));
          window.loadModal("#advanceConfirmbooking", "open");
          localStorage.removeItem('subsession_id');
          localStorage.removeItem('duration');
          localStorage.removeItem('amount');
          localStorage.removeItem('activeCurrency');
          localStorage.removeItem('activesession_id');
        }
        // if (
        //   !data.data.status &&
        //   data.data.message !== "Please hit the api again"
        // ) {
        //   window.loadModal("#noslotsfound", "open");
        // }
        // if (
        //   !data.data.status &&
        //   data.data.message === "Please hit the api again"
        // ) {
        //   startAdvanceBooking();
        // }
        if (!data.data.status) {
          let date = bookingdate;
          dispatch(setBookingDate(''));
          dispatch(setBookingDate(date));
          console.log(date)
          toast.error(data.data.message, {
            position: "top-center",
          });
        }
      })
      .catch(() => {
        dispatch(setLoadingText(false));
        dispatch(setLoadingContent(""));
      });
  };
  const startLoader = () => {
    setRenderType("questions");
  };
  const startLoaderNow = () => {
    changeOnlineStatusFunc(1, dispatch);
    setAppointments("loader");
    handleStart();
  };
  // const stopTimer = (interval) => {
  //   clearInterval(setLoaderLine(0));
  // };
  useEffect(() => {

    if (parseInt(time) === 117 && parseInt(howManyTime) === 3) {
      let tim = howManyTime;
      setHowManyTime(tim + 1);
    }
    if (parseInt(time) === 60 && howManyTime === 1) {
      handleReset();
      updateSession("loader");
    }
    if (parseInt(time) === 120) {
      handleReset();
      updateSession("loader");
    }
  }, [time]);
  useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  // const handlePauseResume = () => {
  //   setIsPaused(!isPaused);
  // };

  const handleReset = () => {
    // setIsActive(false);
    setTime(0);
  };
  const getPractitionarsList = () => {
    // setBookingType("professional");
    // setSessionType("prof");
    setRenderType("premium-session");
    getPractitionars()
      .then((data) => {
        if (data.data.status) {
          setPractitioners(data.data.data);
        }
        if (!data.data.status) {
          setPractitioners([]);
          // window.openModal2();
        }
      })
      .catch(() => { });
  };
  const getPractitionarFiltersList = (body) => {
    // setRenderType("premium-session");
    getPractitionarsFilter(body)
      .then((data) => {
        if (data.data.status) {
          setPractitioners(data.data.data);
        }
        if (!data.data.status) {
          setPractitioners([]);
          // window.openModal2();
        }
      })
      .catch(() => { });
  };
  const bookSession = (id) => {

    getPractitionarInfo(id)
      .then((data) => {
        if (data.data.status) {
          setPractitionerInfo(data.data.data);
          setRenderType("book-a-session");
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        } else {
          toast.error("Please Try again Error while fetching data", {
            position: "top-center",
          });
        }
      })
      .catch(() => { });
  }
  const onVideoEnd = () => {
    setTimeout(() => {
      // window.open(redirelUrl);
      window.loadModal2("#OpenUrlPopUp", "show");
      setTimeout(() => {
        window.loadModal2("#OpenUrlPopUp", "hide");
        navigate("/appointments");
        window.location.reload();
      }, 240000);
      // window.location.href = redirelUrl;
      // window.open(redirelUrl, "_blank");
    }, 2000);
  };

  const backHandler = () => {
    setRenderType("premium-session");
    setRenderType("select")
  };

  const onCancelQuestions = () => {
    setRenderType("select");
  };
  //new
  const startBookaSessionLater = (stat) => {
    setActiveSessionId('5');
    setactivesession_id('5');
    setActiveSubSessionId('');
    localStorage.setItem('activesession_id', '5')
    // getSubsessions(5)
    setBookingType("outreach");
    if (stat === "dummy") {
      setPractitionerInfo(null);
    }
    setcheckOriganalType(stat);
    if (auth.user.terms_session_status === 1) {
      setSaveTerms(auth.user.terms_session_status);
      setRenderType("startBookaSessionLaterQuestions");
    } else {
      setSessionType("later");
      setRenderType("startBookaSessionLater");
    }
  };
  const startBookaSessionLaterVideo = () => {
    // setRenderType("startBookaSessionLaterQuestions");
    setShowBtn(true);
    setRenderType("startBookaSessionLaterloader");
    setLoaderType("video");
  };
  const startBookaSessionLaterQuestions = (prof) => {

    if (sessionType === "prof") {
      setRenderType("book-specific-professinal");
    } else {
      // setShowBtn(true);
      setRenderType("startBookaSessionLaterQuestions");
    }
    // setShowBtn(true);
    // setLoaderType("image");
  };
  const refreshpage = () => {
    navigate("/appointments");
  };
  const getSubsessions = (id, sub_session_id, duration, amount, currency) => {
    console.log(id, "id");
    let info = id.map((item) => {
      return {

        duration
          : item.duration,
        id
          : item.subsession_id,
        pricing_type
          :
          "free",
        sub_session_name: item.name,
        price: item.price,
        currency: item.currency
      }
    })
    // getCreateAppointmentSubseesions(7)
    //   .then((data) => {
    // if (data.data.status) {
    console.log(info)
    // setActiveSubSessionId
    // if (data.data.data.length) {

    setActiveSubSessionId(sub_session_id !== undefined ? sub_session_id : '');
    setActiveDurationId(duration !== undefined ? duration : '');
    setActiveAmount(amount !== undefined ? amount : '20');
    setActiveCurrency(currency !== undefined ? currency : 'USD');
    localStorage.setItem('subsession_id', sub_session_id !== undefined ? sub_session_id : '');
    localStorage.setItem('duration', duration !== undefined ? duration : '');
    localStorage.setItem('amount', amount !== undefined ? amount : '20');
    localStorage.setItem('activeCurrency', currency !== undefined ? currency : 'USD')
    // }
    setAppointmentTyepList(info)
    //   }
    // })
    // .catch(() => { });
  };


  const startProfessionalJourney = () => {
    setBookingType("professional");
    setSessionType("prof");
    setactivesession_id('5')
    auth.user.terms_session_status === 1
      ? startBookaSessionLaterQuestions()
      : startBookaSessionLaterVideo();
  }
  const bookStarterSession = () => {
    setactivesession_id('3')
    setActiveSubSessionId('33');
    localStorage.setItem('activesession_id', '3');
    localStorage.setItem('subsession_id', "33")
    // setRenderType("newappointment");
    setSessionType("later");

    if (auth.user.terms_session_starter_status === 1) {
      setRenderType("newappointment");
    }
    else {
      setRenderType("startBookaSessionLater");

    }

    // setActiveDurationId('');
    // setActiveAmount('');
    // setActiveCurrency('');
    // localStorage.setItem('subsession_id', '');
    // localStorage.setItem('duration', '');
    // localStorage.setItem('amount', '20');
    // localStorage.setItem('activeCurrency','');
    setBookingType('starter');
    localStorage.setItem("type", "newappointment");

  }
  const bookExtraServices = () => {
    let guest_id = localStorage.getItem('guest_id');
    // navigate(`/guest-booking/${guest_id}`);
    navigate(`/guest-booking/${process.env.REACT_APP_USER_ID}`);


    // getMediumServiceDetails(3711)
    // .then((data) => {
    //   if (data.data.status) {
    //     setMediumSessionInfo(data.data.data[0]);
    //     console.log('abc',mediumSessionInfo)
    //   }
    // })
    // .catch(() => { });
    // setactivesession_id('12')
    // setActiveSubSessionId('80');
    // localStorage.setItem('activesession_id', '12');
    // localStorage.setItem('subsession_id', "80")
    // setSessionType("later");

    // if (auth.user.terms_session_starter_status === 1) {
    //   setRenderType("newappointment");
    // }
    // else {
    //   setRenderType("startBookaSessionLater");

    // }
    // setBookingType('outreach');
    // localStorage.setItem("type", "newappointment");

  }
  return (
    <div>
      {renderType === "premium-session" && (
        <Practitioners
          specialtyData={specialtyData}
          languages={formLanguages}
          practitioners={practitioners}
          onClick={bookSession}
          back={() => setRenderType("select")}
          getPractitionarFiltersList={getPractitionarFiltersList}
          startBookaSessionLaterVideo={startBookaSessionLaterVideo}
          setBookInfo={setPractitionerInfo}
          startBookaSessionLaterQuestions={startBookaSessionLaterQuestions}
          saveterms={saveterms}
          getSubsessions={getSubsessions}
          setRenderType={setRenderType}
        />
      )}
      {renderType === "book-a-session" && (
        <PractitionerInfo
          PractitionerInfo={practitionerInfo}
          onClick={() => setRenderType("premium-session")}
          setRenderType={setRenderType}
          startBookaSessionLaterVideo={startBookaSessionLaterVideo}
          startBookaSessionLaterQuestions={startBookaSessionLaterQuestions}
          setBookInfo={setPractitionerInfo}
          getSubsessions={getSubsessions}
        />
      )}
      {renderType === "book-specific-professinal" && (
        <StepTwo
          onCancelQuestions={onCancelQuestions}
          // onCancelQuestions={() => setRenderType("premium-session")}
          practitionerInfo={practitionerInfo}
          setRenderType={setRenderType}
          bookInfo={bookInfo}
          setSaveTerms={setSaveTerms}
          getSessionTerms={getSessionTerms}
          bookingType={bookingType}
          getSubsessions={getSubsessions}
          activeSessionId={activeSessionId}
          getPractitionarsList={getPractitionarsList}
          activesession_id={activesession_id}
        />
      )}
      {renderType === "startBookaSessionLater" && (
        <StepOne
          onCancelQuestions={onCancelQuestions}
          startLoaderNow={startBookaSessionLaterVideo}
        />
      )}
      {renderType === "startBookaSessionLaterloader" && (
        <div>
          {/**true for live and false for staging */}
          <Loader
            showBtn={true}
            disabled={showBtn}
            backHandler={backHandler}
            onVideoEnd={startBookaSessionLaterQuestions}
            loaderType={loaderType}
            text="Please turn up your volume to watch this 3-minute introductory video"
            setRenderType={setRenderType}
            bookingType={bookingType}
            activesession_id={activesession_id}
          />
        </div>
      )}
      {renderType === "startBookaSessionLaterQuestions" && (
        <StepTwo
          onCancelQuestions={onCancelQuestions}
          setRenderType={setRenderType}
          getSessionTerms={getSessionTerms}
          url={checkOriganalType === "dummy" ? undefined : false}
          setSaveTerms={setSaveTerms}
          saveterms={auth.user.terms_session_status}
          bookingType={bookingType}
          activesession_id={activesession_id}
        />
      )}
      {console.log(renderType,"renderTyperenderTyperenderTyperenderType")}
      {renderType === "select" && (
        <div>
          <div className="select-appointment">

            <div className="container">
              <div className="back-btn-appointments mb-3 d-block width-unset">
                <div onClick={refreshpage} className="bck pointer">
                  <img src="images/black-erow.png" alt="back" />
                  <p className="margin-unset"> Back</p>
                </div>
              </div>
              <div className="select-appointment-inner width-unset">

                {/* <div  data-toggle="modal"

                data-target="#setPreferenceModal" className="edite">
                  <a href="">
                    Edit Preferences
                    <img src="images/pencil.png" />
                  </a>
                </div> */}
                {parseInt(bannerLimit) === 1 && (
                  <h2 className="banner-stop-text">{bannerDesc}</h2>
                )}
                {showSessionDone && (
                  <h2 className="banner-stop-text">
                    {
                      "We're sorry, you have already used your free sessions.  Contact Support if you need assistance."
                    }
                  </h2>
                )}
                <div className="appointment-area-white">

                  <div className="appointment-text-tab">
                    <p className="do-a-session-note do-a-session-note-new ">
                      Our mission is to ensure <span className="text-underline">everyone</span> has access
                      {/* Our mission is to help{" "}
                      <span className="text-underline">everyone</span> have
                      access to a meaningful session */}
                      <br />
                      {/* and so we offer donated sessions. */}
                      and so we offer Donated sessions.
                      <br />
                      {/* Consider choosing a Professional session if it fits your
                      budget. */}
                      Consider a Professional session if it fits your budget.
                    </p>
                  </div>
                  <h2>Select Appointment Type</h2>
                  <ul className="select-appointment-inner-1 starter-w mt-3">
                    {is_starter !== undefined && is_starter === 1 && <li onClick={bookStarterSession} className="session-tage _tbd ">
                      <a
                        className=""
                        href="javascript:void(0)"
                      // href="https://appointments.verysoul.com/reserve/Pro"
                      >
                        <div className="icon-select ">
                          {/* <span className="practioner-tab-appointment-tab-dummy-img"></span> */}
                          <img src="localImages/unnamed.png" alt="starter session" />
                          {/* <img src="localImages/p1.png" alt="do a session" /> */}
                        </div>
                        {/* <div className="_tb_parent">
                        <p className="_to_be_decided _coming_soon">Coming Soon</p>
                      </div> */}

                        <h5 className="">Starter Sessions</h5>
                        <div>
                          {/* <div className="practioner-tab-appointment-tab">
                            <span className="practioner-tab-appointment-tab-dummy">
                              Reasonable prices
                            </span>
                            <span className="practioner-tab-appointment-tab-dummy">
                              Payment options
                            </span>
                            <span className="practioner-tab-appointment-tab-dummy">
                              Select your Medium
                            </span>
                          </div> */}
                        </div>
                        <div className="practioner-tab-appointment-btns">
                          <Button
                            // onClick={bookStarterSession}
                            // onClick={() => getPractitionarsList()}
                            className={"practioner-tab-appointment-btn-1"}
                            title={"Book Session"}
                            status={0}
                          />
                        </div>
                      </a>
                    </li>}
                  </ul>

                  {/* //extra services */}

                  <ul className="select-appointment-inner-1 starter-w mt-3">
                    {/* {is_guest_client !== undefined && parseInt(is_guest_client) === 1 &&<li onClick={bookExtraServices} className="session-tage _tbd ">
                      <a
                        className=""
                        href="javascript:void(0)"
                      
                      >
                        <div className="icon-select ">
                         
                          <img src="localImages/unnamed.png" alt="extra services" />
                          
                        </div>
                        

                        <h5 className="">Extra Services</h5>
                        <div>
                        
                        </div>
                        <div className="practioner-tab-appointment-btns">
                          <Button
                          
                            className={"practioner-tab-appointment-btn-1"}
                            title={"Book Session"}
                            status={0}
                          />
                        </div>
                      </a>
                    </li>} */}
                  </ul>


                  {/* {is_starter !== undefined && is_starter === 1 && <button className="practioner-tab-appointment-btn-1 mt-4" onClick={bookStarterSession}>New Starter Appointment</button>} */}
                  <ul className="select-appointment-inner-1">
                    {/**************** */}
                    {/* <li
                      onClick={appointment_count ? startLoader : () => { }}
                      className={`session-tage ${appointment_count &&
                        "disabled-cursor"}`}
                    >
                      <a
                        className={`${appointment_count ? "" : "disabled-cursor"
                          }`}
                        href="javascript:void(0)"
                      >
                        <div
                          className={`icon-select ${appointment_count ? "" : "disabled-cursor blurred"
                            }`}
                        >
                          {appointment_count ? (
                            <img src="images/clock-big.png" alt="do a session" />
                          ) : (
                            <div className="seesion-error-message">
                              *Session limit reached
                            </div>
                          )}
                        </div>
                        <h5>
                          Do a Session <br />
                          Now (Free*)
                        </h5>
                      </a>

                    </li> */}
                    {/**************** */}
                    {test_mode === 1 && <li
                      // onClick={startLoader}
                      // onClick={() => selectAppointmentType("now")}

                      onClick={appointment_count ? startLoader : sessionLimitReachedHandler}
                      className={`session-tage`}
                    >
                      <a href="javascript:void(0)">
                        <div className={`icon-select`}>
                          {/* {appointment_count ? ( */}
                          <img src="images/clock-big.png" alt="do a session" />
                        </div>
                        <h5>Go On Demand</h5>
                        <div>
                          <div className="practioner-tab-appointment-tab">
                            <span className="practioner-tab-appointment">
                              Limit of 1 free
                            </span>
                            <span className="practioner-tab-appointment">
                              Up-&-coming Mediums
                            </span>
                            <span className="practioner-tab-appointment">
                              Medium assigned
                            </span>
                          </div>
                        </div>
                        <div className="practioner-tab-appointment-btns">


                          <div className={"practioner-tab-appointment-btn-1 bookin-new-btn"}>
                            <span

                              className="d-flex h-100 align-items-center justify-content-end w-0"><p className="first-child">Book</p></span>
                            <span

                              className="d-flex h-100 align-items-center justify-content-start w-100"><p className="second-child">Session</p></span>
                          </div>

                        </div>

                      </a>

                    </li>
                    }

                    {/***************** */}
                    <li
                      // onClick={startLoader}
                      // onClick={() => selectAppointmentType("now")}

                      onClick={
                        appointment_count
                          ? () => startBookaSessionLater("dummy")
                          : () => sessionLimitReachedHandler()
                      }
                      className={`session-tage`}
                    >
                      <a href="javascript:void(0)">
                        <div className={`icon-select`}>
                          {/* {appointment_count ? ( */}
                          <img src="localImages/p2.png" alt="do a session" />
                        </div>
                        <h5>Donated</h5>
                        <div>
                          <div className="practioner-tab-appointment-tab">
                            <span className="practioner-tab-appointment">
                              Limit of 1 free
                            </span>
                            <span className="practioner-tab-appointment">
                              Up-&-coming Mediums
                            </span>
                            <span className="practioner-tab-appointment">
                              Medium assigned
                            </span>
                          </div>
                        </div>
                        <div className="practioner-tab-appointment-btns">
                          {/**original button  staging*/}
                          {/* <Button
                            onClick={startLoader}
                            className={
                              "practioner-tab-appointment-btn-2 width-108px"
                            }
                            title={"Now"}
                            status={0}
                          />
                          <Button
                            onClick={() => startBookaSessionLater("original")}
                            className={
                              "practioner-tab-appointment-btn-1 width-108px"
                            }
                            title={"Later"}
                            status={0}
                          /> */}
                          {/**dummy buttons live */}
                          <div className={"practioner-tab-appointment-btn-1 bookin-new-btn"}>
                            <span
                              // onClick={() => startBookaSessionLater("original")}
                              className="d-flex h-100 align-items-center justify-content-end w-0"><p className="first-child">Book</p></span>
                            <span
                              // onClick={
                              //   appointment_count
                              //     ? () => startBookaSessionLater("dummy")
                              //     : () => sessionLimitReachedHandler()
                              // } 
                              className="d-flex h-100 align-items-center justify-content-start w-100"><p className="second-child">Session</p></span>
                          </div>
                          {/* <Button
                            onClick={() => startBookaSessionLater("original")}
                            className={"practioner-tab-appointment-btn-1"}
                            title={"Book Session"}
                            status={0}
                          /> */}
                        </div>
                        {/* <h5>
                        Do a Session <br />
                        Now (Free*)
                      </h5> */}
                      </a>
                      {/* {appointment_count ? (
                      ""
                    ) : (
                      <p>*Free session limit reached</p>
                    )} */}
                    </li>
                    <li onClick={startProfessionalJourney} className="session-tage _tbd ">
                      <a
                        className=""
                        href="javascript:void(0)"
                      // href="https://appointments.verysoul.com/reserve/Pro"
                      >
                        <div className="icon-select ">
                          <img src="localImages/p1.png" alt="do a session" />
                        </div>
                        {/* <div className="_tb_parent">
                        <p className="_to_be_decided _coming_soon">Coming Soon</p>
                      </div> */}

                        <h5 className="">Professional</h5>
                        <div>
                          <div className="practioner-tab-appointment-tab">
                            <span className="practioner-tab-appointment">
                              Reasonable prices
                            </span>
                            <span className="practioner-tab-appointment">
                              Payment options
                            </span>
                            <span className="practioner-tab-appointment">
                              Select your Medium
                            </span>
                          </div>
                        </div>
                        <div className="practioner-tab-appointment-btns">
                          <Button
                            // onClick={startProfessionalJourney}
                            // onClick={() => getPractitionarsList()}
                            className={"practioner-tab-appointment-btn-1"}
                            title={"Book Session"}
                            status={0}
                          />
                        </div>
                      </a>
                    </li>

                    {/****** starter client *******/}

                    {/****** starter client *******/}
                    {/******* TBD******/}
                    {/* <li className="session-tage _tbd disabled-cursor ">
                      <a className="disabled-cursor" href="javascript:void(0)">
                        <div className="icon-select disabled-cursor opacity-2">
                          <img src="localImages/p1.png" alt="do a session" />
                        </div>

                        <h5 className="opacity-2">Donated</h5>
                        <div className="opacity-2">
                          <div className="practioner-tab-appointment-tab">
                            <span className="practioner-tab-appointment">
                              {""}
                            </span>
                            <span className="practioner-tab-appointment">
                              {""}
                            </span>
                            <span className="practioner-tab-appointment">
                              {""}
                            </span>
                            <span className="practioner-tab-appointment">
                              {""}
                            </span>
                          </div>
                        </div>
                        <div className="tb-donated">TBD</div>
                        <div className="practioner-tab-appointment-btns">
                          <Button
                            onClick={
                              appointment_count
                                ? () => startBookaSessionLater("dummy")
                                : () => sessionLimitReachedHandler()
                            }
                            className={
                              "practioner-tab-appointment-btn-1 blurred opacity-2"
                            }
                            title={"Book Session"}
                            status={0}
                          />
                        </div>
                      </a>
                    </li> */}
                    {/******* TBD******/}
                  </ul>
                  {promotion_code !== null &&
                    promotion_code.toLowerCase() === "supportwga" && (
                      <p className="wga-members-text">
                        <span> WGA Members:</span> Please feel free to book a
                        free Donated Session regardless of your ability to pay
                      </p>
                    )}
                </div>

              </div>
            </div>
          </div>
          {/* <Progress
            loaderLine={time}
            setAppointments={setAppointments}
            changeRender={changeRender}
            getAvailabilites={() => {}}
            selectType={"loader"}
          /> */}
        </div>
      )}
      {renderType === "loader" && (
        <div>
          <Loader
           backHandler={()=>setRenderType("select")}
            onVideoEnd={onVideoEnd}
            loaderType={loaderType}
            text="Please turn up your volume to watch this 3-minute introductory video"
          />
        </div>
      )}
      {renderType === "questions" && (
        <Questions
          onCancelQuestions={onCancelQuestions}
          startLoaderNow={startLoaderNow}
        />
      )}
      {renderType === "render" && (
        <div className="select-appointment">
          <div className="container">
            <div className="book-practice-guest-saction">
              {auth.mediumdata.map((item) => (
                <div className="practice-guest-box">
                  <div className="profile-boxx">
                    <div className="profile-imgg">
                      <img src="images/pr-image.png" alt="profile" />
                    </div>
                    <div className="profile-contt">
                      <span>{item.first_name}</span>
                      <div className="rating">
                        <img src="images/star.png" alt="stars" />
                        <img src="images/star.png" alt="stars" />
                        <img src="images/star.png" alt="stars" />
                        <img src="images/star.png" alt="stars" />
                        <img src="images/star.png" alt="stars" />
                        <em>25 Reviews</em>
                      </div>
                    </div>
                  </div>

                  <div className="profile-boxx-lest">
                    <ul>
                      <li>
                        <div className="icon-gest">
                          <img src="images/badg-new.png" alt="mediums" />
                        </div>
                        Mediumship Specialities:<span>None in Particular</span>
                      </li>
                      <li>
                        <div className="icon-gest">
                          <img src="images/languag-new.png" alt="languages" />
                        </div>
                        Languages:<span>English,Catalan</span>
                      </li>
                      <li>
                        <div className="icon-gest">
                          <img src="images/globe-new.png" alt="country" />
                        </div>
                        Country:<span>United States</span>
                      </li>
                    </ul>
                    <div className="dont-have-acc gust-ppop">
                      <button
                        type="button"
                        className="btn btn-info btn-lg"
                        data-toggle="modal"
                        data-target="#myModal2"
                      >
                        Select
                      </button>
                      <div className="modal fade" id="myModal2" role="dialog">
                        <div className="modal-dialog modal-dialog-preview">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                          <div className="modal-content">
                            <div className="modal-body">
                              <div className="guest-popup">
                                {/* <img src="images/sadu.png" /> */}
                                <h3>
                                  We are sorry
                                  <br />
                                  there are no Mediums available at the moment.
                                </h3>
                                <a href="#">Book an advance session</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {renderType === "newappointment" && (
        <>
          {" "}
          <AdvanceAppointment
            practitionerInfo={practitionerInfo}
            sessionType={sessionType}
            appointmentTyepList={appointmentTyepList}
            activeSubSessionId={activeSubSessionId}
            activeDurationId={activeDurationId}
            getSubsessions={getSubsessions}
            setActiveSubSessionId={setActiveSubSessionId}
            setActiveDurationId={setActiveDurationId}
            setActiveAmount={setActiveAmount}
            setActiveCurrency={setActiveCurrency}
            activeAmount={activeAmount}
            activeCurrency={activeCurrency}
            activesession_id={activesession_id}
            bookingType={bookingType}
          // handleStart={handleStart}
          // handleReset={handleReset}
          />
          <Progress
            bookingType={bookingType}
            sessionType={sessionType}
            practitionerInfo={practitionerInfo}
            loaderLine={time}
            setAppointments={setAppointments}
            changeRender={changeRender}
            getAvailabilites={getAvailabilites}
            selectType={"newappointment"}
            bookAppointment={bookAppointment}
            activeSubSessionId={activeSubSessionId}
            activeAmount={activeAmount}
            activeDurationId={activeDurationId}
          />
        </>
      )}
      <div className="text-center for-support-text">
        <p className="mt-3 nav-link font-size-22 ">
          For Support &nbsp;
          <a href="mailto:Support@verysoul.com" target="_blank">
            Click Here
          </a>

        </p>
        {/* <p>Click here for <Link to={'/intro-video'}> introductory video</Link></p> */}
        <Footer />
      </div>
      <div className="dont-have-acc gust-ppop">
        <div
          className="modal fade"
          id="myModal2"
          role="dialog"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-preview">
            {/* <button type="button" className="close" data-dismiss="modal">
              &times;
            </button> */}
            <div className="modal-content">
              <div className="modal-body mt-3">
                <div className="guest-popup mt-5">
                  {/* <img src="images/sadu.png" /> */}
                  <h3>
                    Would you like to continue to wait for
                    <br />
                    a session?
                  </h3>

                  <div className="cancel-btn-check-popup-wraper">

                    <Button
                      className={"try-again-book-btn"}
                      status={0}
                      onClick={() => startBookingAgain()}
                      title="Yes, Continue to Wait"
                    />
                    <Button
                      className={"cancel-btn-check-popup"}
                      status={0}
                      onClick={() => bookNewAppointment()}
                      title="No, Try to book a different session"
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dont-have-acc gust-ppop">
        <div className="modal fade" id="noslotsfound" role="dialog">
          <div className="modal-dialog modal-dialog-preview">
            <button
              type="button"
              className="close pointer"
              data-dismiss="modal"
            >
              &times;
            </button>
            <div className="modal-content">
              <div className="modal-body">
                <div className="guest-popup">
                  {/* <img src="images/sadu.png" /> */}
                  <h3>
                    We are sorry
                    <br />
                    there are no slots available at the moment.
                  </h3>
                  <a
                    data-dismiss="modal"
                    onClick={() => {
                      navigate("/appointments");
                      window.location.reload();
                    }}
                    href="javascript:void(0);"
                  >
                    My Appointments
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="join-box-meet">
        <OpenUrlPopUp url={redirelUrl} />
      </div>

    </div>
  );
}
