import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { convertToLocal, removeQueryParams, removeString, setQueryParams } from "../../../../utils/helpers";
import "../calendar.css";
import { deleteSchedule, getSchedules, syncCalendar } from "../functions";
import { getTimeImage } from "../../../../utils/utils";
import { capatilizeLetter } from "../../../../utils/helpers";
// import { set } from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProfilePreferences } from "../../../../services/admin";
import { setScreenNameDetails } from "../../../../reducer/adminSlice";
import PreviewModal from "../previewModal";
import { toast } from "react-toastify";

export default function Schedules({
  type = "admin",
  openScheduleForm,
  medium_id,
  setScheduleId,
  setRedirectType,
}) {
  const { scheduleArray, screenNameDetails } = useSelector(
    (state) => state.users
  );
  const { timezone, google_calendar } = useSelector((state) => state.user.user);

  //query params
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("view");
  const searchVal = searchParams.get("search");
  const searchName = searchParams.get("searchName");
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');
  //query params


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [scheduleList, setScheduleList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [blockeddateList, setBlockedDateList] = useState([]);
  const [preferncesSection, setpreferncesSection] = useState(null);
  const [preview, setPreview] = useState(false);
  const [activesession_id, setActivesession_id] = useState("");
  const [activeSubSessionId, setActiveSubSessionId] = useState('');

  //query state
  const [queryValue, setQueryValue] = useState('');
  const [query1, setQuery1] = useState('');
  const [query2, setQuery2] = useState('');
  const [query3, setQuery3] = useState('');
  const [query4, setQuery4] = useState('');
  const [is_group_session, setis_group_session] = useState(null);

  useEffect(() => {


    getSchedules(medium_id, dispatch);
    let id = searchParams.get("id");
    if (
      type === "admin" &&
      (screenNameDetails === null || screenNameDetails.id !== id)
    )
      getPreferences(id);

    setQueryParams(setQueryValue, setQuery1, setQuery2, setQuery3, setQuery4, status, searchVal, searchName, page, rowperpage)
    setTimeout(() => {
      removeQueryParams()

    }, 2000);
  }, []);

  // const removeQueryParams = () => {
  //   const newUrl = new URL(window.location.href);
  //   newUrl.searchParams.delete("view");
  //   window.history.replaceState({}, document.title, newUrl.href);


  // };


  const getPreferences = (id) => {
    getProfilePreferences(id)
      .then((data) => {
        const { screen_name, time_zone } = data.data.data;
        dispatch(setScreenNameDetails({ id: id, screen_name: screen_name, time_zone: time_zone }));
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setpreferncesSection(screenNameDetails);
  }, [screenNameDetails]);
  useEffect(() => {
    let grouped = scheduleArray.map((item) => {
      return { ...item, slots: groupBy(item.slots, "day") };
    });
    // console.log("grouped", grouped);
    setScheduleList(grouped);
    let dategrouped = scheduleArray.map((item) => {
      return {
        ...item,
        slots: groupBy(item.slots, "date"),
      };
    });

    var sortedKeys = dategrouped.map((item) => {
      return Object.keys(item.slots)
        .sort()
        .reduce((objEntries, key) => {
          objEntries[key] = item.slots[key];

          return objEntries;
        }, {});
    });

    var specificdatesdata =
      Object.keys(sortedKeys).length > 0 &&
      Object.entries(sortedKeys).map(
        ([key, value]) =>
          // console.log('value..',value)
          Object.keys(value).length > 0 &&
          Object.entries(value).map(
            ([key1, value1]) =>
              // console.log('value1',value1)
              value1.length > 0 &&
              value1.filter((data, index) => {
                return data.status === 0 && data.date !== null;
              })
          )
      );
    setDateList(specificdatesdata);

    var blockeddatesdata =
      Object.keys(sortedKeys).length > 0 &&
      Object.entries(sortedKeys).map(
        ([key, value]) =>
          // console.log('value..',value)
          Object.keys(value).length > 0 &&
          Object.entries(value).map(
            ([key1, value1]) =>
              // console.log('value1',value1)
              value1.length > 0 &&
              value1.filter((data, index) => {
                return data.status === 1 && data.date !== null;
              })
          )
      );
    setBlockedDateList(blockeddatesdata);
  }, [scheduleArray]);

  const backtosessions = () => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete("schedule");
    newUrl.searchParams.delete("id");
    window.history.replaceState({}, document.title, newUrl.href);
    setRedirectType("Sessions-list");
  };
  function groupBy(objectArray, property) {
    let array = objectArray.map((item) => {
      return { ...item, day: item.day.toLowerCase() };

      // return { ...item, day: item.day.toTitleCase() };
    });
    return array.reduce(function (accumulator, currentObject) {
      let key = currentObject[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(currentObject);
      return accumulator;
    }, {});
  }
  const checkTime = (time) => {
    let newTime = typeof time === 'string' ? time.trim() : time
    if (newTime === "11:59 PM") return "12:00 AM";
    return time;
  };
  const pushValuewithoutdate = (value) => {
    let values = value.filter((data) => data.date === null);
    // console.log(values)
    return values;
  };
  const openPreviewModal = (item) => {
    setActiveSubSessionId(item.subsession_id);
    setis_group_session(item.is_group_session);
  
    setActivesession_id(item.session_id);
    setPreview(true);
    localStorage.removeItem('showpreview');
    localStorage.removeItem('showpreviewValue');
    window.loadModal2("#prvvvvv", "show");

  };
  const checkGoogleIntegration = () => {
    toast.error("Please Link you Google Calendar First", { position: "top-center" });
  };

  useEffect(() => {
    localStorage.removeItem('showpreview');
    localStorage.removeItem('showpreviewValue');
    // let check = localStorage.getItem('showpreview');
    // let id = localStorage.getItem('showpreviewValue');

    // if (check == "showpreview") {
    //   let body = {
    //     subsession_id: localStorage.getItem('showpreviewValue')
    //   }
    //   openPreviewModal(body);
    // }
  }, [])
  return (
    <>
      <div className="medium-schedules-all white-background">
        <div className="d-flex align-items-center justify-content-between  font-size- mt-2 mb-2 flex-wrap">
          {/* {" "}
          <select
            onChange={changeTimeZoneHandler}
            className="select-box-timezone pointer"
          >
            <option disabled selected value="">
              Change Timezone
            </option>
            {timezoneData.map((item) => (
              <option value={item.id}>{item.front_name}</option>
            ))}
          </select> */}
          <div className="d-flex align-items-center gap-10">
            <div className="right-last-td">
              <div className="btn-sedule">
                {/* {type !== "admin" && (
              <button
                className="synce mr-2"
                type="button"
                onClick={() => syncCalendar(navigate)}
              >
                <img src="images/web.png"></img>Sync Google Calendar
              </button>
            )} */}
                <button
                  className="margin-unset"
                  type="button"
                  onClick={() => openScheduleForm("")}
                // onClick={
                //   google_calendar === null
                //     ? checkGoogleIntegration
                //     : () => openScheduleForm("")
                // }
                >
                  <img src="images/web.png"></img>Add Schedule
                </button>
                {/* {type !== "admin" && (
              <button
                className="synce ml-2"
                type="button"
                onClick={() => syncCalendar(navigate)}
              >
                <img src="images/web.png"></img>Sync Now
              </button>
            )} */}
              </div>
            </div>
            <a target="_blank" className="font-size-14 ml-3 mr-3 pointer learn-more-btn height-50" href="https://vimeo.com/922236305/45a8a7bc1e">Learn more</a>
          </div>
          {type !== "admin" && <b>Timezone: {timezone !== undefined && timezone}&nbsp;</b>}
          {type === "admin" && <> <br /> <br /><b>Medium's Timezone: {preferncesSection !== null && preferncesSection.time_zone}&nbsp;</b></>}
        </div>
        <div className="right-last-td d-flex justify-content-between align-items-center tr-padding">
          {type === "admin" ? (
            <span
              onClick={type === "admin" ? () => navigate(`/mediums?view=${queryValue}&search=${query1}&searchName=${query2}&page=${query3}&rowperpage=${query4}&pagintion=${'change'}`) : backtosessions}
              className="pointer"
            >
              <span>

                <>
                  <img
                    src="images/arrow-back.png"
                    alt="arrow"
                    className="mt-3"
                  />
                  &nbsp; Back to Sessions
                </>

              </span>
            </span>
          )
            :
            <span></span>
          }
          <div className="middle-screen-name font-size-22">
            {" "}
            <b>
              {preferncesSection !== null && preferncesSection.screen_name}
              &nbsp; Schedule
            </b>
          </div>
          <div></div>
        </div>
        <div className="table-mideum-table tableclander tableclander-schedules">
          <table>
            <tbody>
              <tr>
                <th className="action-button-schedules-table-th">Actions</th>
                <th>Services</th>
                <th>Hours</th>
                <th>Specific Dates</th>
                <th>Blocked Dates</th>
                {/* <th> Schedule Info</th> */}

                {/* <th>Timezone</th> */}

              </tr>
              {scheduleList.map((item, dataIndex) => (
                <tr>
                  <td>
                    {/* <div className="left-last-td">{item.country}</div> */}
                    <div className="right-last-td action-button-schedules-table">
                      {/* <button type="button" onClick={() => openScheduleForm("")}>
                      <img src="images/web.png"></img>Add Schedule
                    </button> */}
                      <button
                        className="preview-button-availbility"
                        onClick={() => openPreviewModal(item)}
                        data-toggle="modal"
                      // data-target=".bd-example-modal-lg"
                      >
                        <img src="localImages/openeye-1.svg"></img>
                        <b>Preview</b>
                      </button>
                      <button
                        className="edit-button-availbility"
                        type="button"
                        onClick={() =>
                          openScheduleForm(item.medium_availability_id)
                        }
                      >
                        <img src="images/icon-feather-edit.png"></img>Edit
                      </button>
                      <button
                        className="pointer remove-button-availbility"
                        onClick={() =>
                          deleteSchedule(
                            item.medium_availability_id,
                            dispatch,
                            medium_id
                          )
                        }
                      >
                        <img src="images/close.svg"></img>Remove
                      </button>


                    </div>
                  </td>
                  <td>
                    <span className="hours_valid">
                      {item.start_date !== null && item.end_date !== null
                        ? `Valid: ${item.start_date} to ${item.end_date}`
                        : `Valid: ${item.start_date} and on`}
                    </span>
                    <span>{item.sessionName}</span>

                   {item.is_group_session !== 1 && <div className="schedule-additional-settings">
                      <p className="settings">Buffer Time: </p>
                      <span>
                        {item.buffer_time !== null
                          ? item.buffer_time + " minutes"
                          : ""}
                      </span>
                    </div>}

                    {item.is_group_session !== 1 && <div className="schedule-additional-settings">
                      <p className="settings">Appts/Day: </p>
                      <span>
                        {item.number_of_session_per_day !== null
                          ? item.number_of_session_per_day
                          : ""}
                      </span>
                    </div>}

                    {/* <div className="schedule-additional-settings"> */}
                    {/* <p className="settings">Hours Valid from:</p>
                          <span>Start Date:  {item.start_date} </span>
                          <span>End Date:  {item.end_date !== null ? item.end_date : ""} </span> */}
                    {/* <span> {`${item.start_date}${item.end_date !== null ? "-" : ""}${item.end_date !== null ? item.end_date : ""}`}</span> */}
                    {/* </div> */}

                    <div className="schedule-additional-settings">
                      <p className="settings">Min. Notice: </p>
                      <span>
                        {" "}
                        {item.min_book_time !== null &&
                          Math.floor(item.min_book_time / 24) >= 10
                          ? ""
                          : ""}

                        {Math.floor(item.min_book_time / 24) !== 0 ? Math.floor(item.min_book_time / 24) : ''}{Math.floor(item.min_book_time / 24) === 0 ? '' :
                          Math.floor(item.min_book_time / 24) > 1 ? ' days' : ' day'

                        }{"  "}

                        {Math.floor(item.min_book_time) % 24 !== 0
                          ? `${Math.floor(item.min_book_time) % 24 >= 10
                            ? ""
                            : ""
                          }${Math.floor(item.min_book_time) % 24} ${Math.floor(item.min_book_time) % 24 > 1 ? ' hours' : ' hour'}`
                          : ""}

                        {item.min_book_time.includes(".")
                          ? item.min_book_time
                            .toString()
                            .split(".")
                            .pop() === "00"
                            ? ""
                            : item.min_book_time
                              .toString()
                              .split(".")
                              .pop() + " minutes"
                          : ""}
                      </span>
                    </div>

                    <div className="schedule-additional-settings">
                      <p className="settings">Max Notice: </p>
                      {/* <span>{item.max_book_time !== null ? item.max_book_time : ""}</span> */}
                      <span>
                        {item.max_book_time !== null ||
                          item.max_book_time !== ""
                          ? parseInt(item.max_book_time) / 24 < 10
                            ? `0${parseInt(item.max_book_time) / 24}`
                            : `${parseInt(item.max_book_time) / 24}`
                          : null}{" "}
                        days
                      </span>
                    </div>
                  </td>
                  <td>
                    {item.slots !== undefined &&
                      Object.keys(item.slots).length > 0 &&
                      Object.entries(item.slots).map(([key, value]) => (
                        <>
                          {/* <div className="fehfuhrf"> */}{" "}
                          {value.length > 0 &&
                            pushValuewithoutdate(value).map((data, index) => (
                              <>
                                {data.date === null
                                  ? data.slot_starting_from.slice(0, 2) !==
                                  "00" &&
                                  data.slot_ending_to.slice(0, 2) !==
                                  "00" && (
                                    <>
                                      <div
                                        className={`d-flex align-items-center ${pushValuewithoutdate(value)
                                          .length === 1
                                          ? "hr-schedule-dated"
                                          : ""
                                          } 
                                          ${pushValuewithoutdate(value).length -
                                            1 ===
                                            index &&
                                            pushValuewithoutdate(value)
                                              .length !== 1
                                            ? "hr-schedule-dated"
                                            : ""
                                          } 
                                          `}
                                      >
                                        <p className="schedule-day-name">
                                          {" "}
                                          {index === 0 && (
                                            <>
                                              {capatilizeLetter(
                                                key
                                              ).substring(0, 3)}{" "}
                                              &nbsp;
                                            </>
                                          )}
                                        </p>
                                        <span className="schedule-time-from-to">
                                          {checkTime(data.slot_starting_from)}{' '}{getTimeImage(
                                            checkTime(
                                              data.slot_starting_from
                                            )
                                          ) ? (
                                            <img
                                              src="localImages/moon.png"
                                              className="star-imh-moon"
                                              alt="time-image"
                                            />
                                          ) : (
                                            ""
                                          )}
                                          &nbsp;- &nbsp;
                                          {checkTime(data.slot_ending_to)}{' '}{getTimeImage(
                                            checkTime(
                                              data.slot_ending_to
                                            )
                                          ) ? (
                                            <img
                                              src="localImages/moon.png"
                                              className="star-imh-moon"
                                              alt="time-image"
                                            />
                                          ) : (
                                            ""
                                          )}
                                          {/* &nbsp;
                                        {data.date !== null && `(${data.date})`} */}
                                        </span>
                                      </div>
                                    </>
                                  )
                                  : ""}
                              </>
                            ))}
                          {/* </div> */}
                        </>
                      ))}
                  </td>
                  <td>


                    {dateList[dataIndex] !== undefined &&
                      Object.keys(dateList[dataIndex]).length > 0 &&
                      Object.entries(dateList[dataIndex]).map(
                        ([key, value]) => (
                          <>
                            {" "}
                            {key !== null &&
                              value.length > 0 &&
                              value.map((data, index) => (
                                <>
                                  {data.date !== null && data.status === 0
                                    ? data.slot_starting_from.slice(0, 2) !==
                                    "00" &&
                                    data.slot_ending_to.slice(0, 2) !==
                                    "00" && (
                                      <>
                                        <div className="d-flex align-items-center">
                                          <p className="schedule-date-name">
                                            {" "}
                                            {index === 0
                                              ? `${moment(data.date).format(
                                                "Do MMM YYYY"
                                              )}`
                                              : ""}
                                          </p>
                                          <span className="schedule-time-from-to">
                                            {checkTime(
                                              data.slot_starting_from
                                            )}{" "}
                                            {getTimeImage(
                                              checkTime(
                                                data.slot_starting_from
                                              )
                                            ) ? (
                                              <img
                                                src="localImages/moon.png"
                                                className="star-imh-moon"
                                                alt="time-image"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            &nbsp;- &nbsp;
                                            {checkTime(data.slot_ending_to)}
                                            &nbsp;
                                            {getTimeImage(
                                              checkTime(data.slot_ending_to)
                                            ) ? (
                                              <img
                                                src="localImages/moon.png"
                                                className="star-imh-moon"
                                                alt="time-image"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {/* &nbsp;
                                     {data.date !== null && `(${data.date})`} */}
                                          </span>
                                        </div>
                                        {index === value.length - 1 && (
                                          <>
                                            <br />
                                          </>
                                        )}
                                      </>
                                    )
                                    : ""}
                                </>
                              ))}
                          </>
                        )
                      )}
                  </td>
                  <td>

                    {blockeddateList[dataIndex] !== undefined &&
                      Object.keys(blockeddateList[dataIndex]).length > 0 &&
                      Object.entries(blockeddateList[dataIndex]).map(
                        ([key, value]) => (
                          <>
                            {" "}
                            {key !== null &&
                              value.length > 0 &&
                              value.map((data, index) => (
                                <>
                                  {data.status === 1
                                    ? data.slot_starting_from.slice(0, 2) !==
                                    "00" &&
                                    data.slot_ending_to.slice(0, 2) !==
                                    "00" && (
                                      <>
                                        <div className="d-flex align-items-center">
                                          <p className="schedule-date-name">
                                            {" "}
                                            {index === 0
                                              ? `${moment(data.date).format(
                                                "Do MMM YYYY"
                                              )}`
                                              : ""}
                                          </p>
                                          <span className="schedule-time-from-to">
                                            {checkTime(
                                              data.slot_starting_from
                                            )}{" "}
                                            {getTimeImage(
                                              checkTime(
                                                data.slot_starting_from
                                              )
                                            ) ? (
                                              <img
                                                src="localImages/moon.png"
                                                className="star-imh-moon"
                                                alt="time-image"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            &nbsp;- &nbsp;
                                            {checkTime(data.slot_ending_to)}
                                            &nbsp;
                                            {getTimeImage(
                                              checkTime(data.slot_ending_to)
                                            ) ? (
                                              <img
                                                src="localImages/moon.png"
                                                className="star-imh-moon"
                                                alt="time-image"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {/* &nbsp;
                                     {data.date !== null && `(${data.date})`} */}
                                          </span>
                                        </div>
                                        {index === value.length - 1 && (
                                          <>
                                            <br />
                                          </>
                                        )}
                                      </>
                                    )
                                    : ""}
                                </>
                              ))}
                          </>
                        )
                      )}
                  </td>


                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {preview && (
        <PreviewModal
        is_group_session={is_group_session}
          setPreview={setPreview}
          activesession_id={activesession_id}
          setActivesession_id={setActivesession_id}
          type={type}
          preferncesSection={preferncesSection}
          activeSubSessionId={activeSubSessionId}
        />
      )}
    </>
  );
}
