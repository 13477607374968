import React, { useState } from "react";
import moment from "moment";
import Search from "../../../../components/search";
import { useSelector } from "react-redux";
import Pagination from "../../../../components/tablePagination";
import { UpcomingHeaders, filterList } from "../data";
import CopyComponent from "../../../../components/copyComponent";
import Button from "../../../../components/button";
import { copyToClipboard, emptyAllSelectBox, getLink, session_id_beg, session_id_end } from "../../../../utils/helpers";
import ConfirmCancel from "../../../admin/appointments/subcomponents/cancelpopup";
import Toggle from "../../../admin/appointments/subcomponents/toggle";
import ConfirmDeleteModal from "../../../../components/deleteConfirmModal";
import { confirmAppointmentstatus, getAppointmentsDetails } from "../../../../services/admin";
// import ReScheduleAppointment from "../../rescheduleappt";
import UpdatedReScheduleAppt from "../../updatedrescheduleappt";
import { toast } from "react-toastify";
import ConfirmReschedule from "../subcomponetnts/confirmreschedule";
import RescheduleAppointmentNotAllowModel from "../subcomponetnts/rescheduleAppointmentNotAllowModel";
import NoshowBox from "../../../admin/appointments/subcomponents/noshowbox";
import { cancelGroupAppointments, noshowGroupAppointments, noshowRemoveGroupAppointments } from "../../../../services/medium";

export default function Upcoming(props) {
  const {
    upcomingAppointments,
    changeHandler,
    onClickHandler,
    filterListAppointments,
    filterReset,
    getPaginationData,
    setFilterValues,
    setSearchValues,
    getAllAppointmentsList,
    activeTab,
    searchValue,
    filterValues,
    changeHandle1,
    setAppointments
  } = props;
  // const copyMeetingLink = (url) => {
  //   navigator.clipboard.writeText(url);
  // };
  // const handleChangeFilter = () =>{
  // console.log('ww')
  // }
  const { id } = useSelector(
    (state) => state.user.user
  );
  const {
    allUpcomingCount
  } = useSelector((state) => state.medium);
  const [active, setActive] = useState({});

  const [reschedeId, setReschedeId] = useState('');
  // const [reschedeData, setReschedeData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [activeUser, setActiveUser] = useState();
  const [itemReschedule, setItemReschedule] = useState();
  const [indexReschedule, setIndexReschedule] = useState();
  const [messageNotAllow, setMessageNotAllow] = useState();
  const [appType, setAppType] = useState("");
  const [attendeesList, setattendeesList] = useState([])
  const [noshowFrom, setNoShowFrom] = useState('');
  const [noShowActive, setNoShowActive] = useState();


  const handleNoShow = (e, appoinment) => {
    setAppType(appoinment.session_type);
    let value = e.target.value;
    if (value === 'me') {
      changeHandlerLocal(
        e,
        2,
        appoinment.appointmentId,
        appoinment.medium_id,
        'medium',
        'medium_id',
      )
      // changeHandler(
      //   e,
      //   2,
      //   appoinment.appointmentId,
      //   appoinment.medium_id,
      //   'medium',
      //   'medium_id',
      // );
    } else if (value === "ended-early") {
      changeHandlerLocal(
        e,
        4,
        appoinment.appointmentId,
        appoinment.medium_id,
        // appoinment.client_id,
        // 'medium',
        // 'client_id'
      )

    }
    else if (value === "restrict-client") {
      changeHandlerLocal(
        e,
        5,
        appoinment.appointmentId,
        appoinment.medium_id,
        // appoinment.client_id,
        // 'medium',
        // 'client_id'
      )

    }
    else if (value === "give-add-1") {
      changeHandlerLocal(
        e,
        6,
        appoinment.appointmentId,
        appoinment.medium_id,
        // appoinment.client_id,
        'medium',
        'medium_id'
        // 'client_id'
      )

    }
    else if (value === "other") {
      if (appoinment.session_type === "1" || appoinment.session_type === "2") {
        if (parseInt(id) === parseInt(appoinment.medium_id)) {
          changeHandlerLocal(
            e,
            2,
            appoinment.appointmentId,
            appoinment.client_id,
            'medium',
            'client_id'
          )

        }
        else {
          changeHandlerLocal(
            e,
            2,
            appoinment.appointmentId,
            appoinment.medium_id,
            'medium',
            'client_id'
          )

        }

      }
      else {


        changeHandlerLocal(
          e,
          2,
          appoinment.appointmentId,
          appoinment.client_id,
          'medium',
          'client_id'
        )
      }
    }

  }

  const changeHandlerLocal = (e, type, id, targetId, targetType, targetTypeID) => {
    const updatedItems = upcomingAppointments.map((item) => {
      return { ...item, status: false };
    });
    setAppointments(updatedItems)
    setActive({ e, type, id, targetId, targetType, targetTypeID })
    window.loadModal2('#ConfirmCancelupcomingmedium', 'show');
  }

  const cancelRequest = () => {
    window.loadModal2('#ConfirmCancelupcomingmedium', 'hide');
    emptyAllSelectBox()
  }
  const confirmRequest = () => {
    let { e, type, id, targetId, targetType, targetTypeID } = active
    if (parseInt(appType) === 1 || parseInt(appType) === 2) {
      changeHandle1(e, type, id, targetId, targetType, targetTypeID);
    } else {
      changeHandler(e, type, id, targetId, targetType, targetTypeID);
    }
  }

  const refreshScreen = () => {
    const data = {
      page: 1,
      rowsPerPage: 10,
    };
    getAllAppointmentsList(data, activeTab, "refresh");
  };
  const openConfirmAppointment = () => {
    window.loadModal2('#ConfirmAppointment', 'show');
  }
  const confirmAppointment = () => {
    console.log(activeUser, "cwkdn")
    confirmAppointmentstatus(activeUser.appointmentId)
      .then((data) => {
        if (data.data.status) {
          toast.success('Status Confirmed', { position: 'top-center' });
          const data = {
            page: 1,
            rowsPerPage: 10
          }
          getAllAppointmentsList(data, activeTab);
        }
        else {
          toast.error('Something went wrong', { position: 'top-center' });
        }
      })
      .catch(() => { });
  }
  const getTimeDiff = (appoinment) => {
    let appdate = moment(appoinment.date).format('YYYY/MM/DD');
    let now = moment().format();
    let datetimeB = moment(appdate + " " + appoinment.time);
    // console.log(appoinment.date,appoinment.time, "now",now)
    // console.log(datetimeB,"datetimeB")
    // var a = moment('2016-06-06T21:03:55');//now
    // var b = moment('2016-05-06T20:03:55');

    let final = datetimeB.diff(now, 'minutes');
    // console.log(final)
    let status;
    final > -5 ? status = true : status = false
    return status
  }

  const confirmRescheduleAppointmentpractice = (item, index) => {
    setItemReschedule(item)
    setIndexReschedule(index);
    window.loadModal2("#ConfirmRescheduleAppointmentpractice", "show");
  }

  const rescheduleAppointmentNotAllow = (item, index) => {
    let messageNotAllow = `We apologize but we do not allow rescheduling of appointments less than ${item.timeamount} before the start of a ${item.session_name}`;
    setMessageNotAllow(messageNotAllow);
    window.loadModal2("#RescheduleAppointmentNotAllowModel", "show");
  }
  const openReSchedulePopUp = () => {
    let index = indexReschedule
    let item = itemReschedule
    console.log(index)
    console.log(item, 'aaaa')
    // setAppointmentInfo
    setReschedeId(item)
    setSelectedIndex(index)
    window.loadModal2("#ReScheduleAppt", "show");

    // let body = {
    //   appointment_id: item.id
    // }
    // getAppointmentsDetails(body).then((data) => {

    //   setReschedeData(data.data.data)
    //   setReschedeId(item)
    //   setSelectedIndex(index)
    //   window.loadModal2("#ReScheduleAppt", "show");
    // }).catch(() => { })
  }
  const getTimeDiffTenMin = (appoinment) => {
    let appdate = moment(appoinment.date).format('YYYY/MM/DD');
    let now = moment().format();
    let datetimeB = moment(appdate + " " + appoinment.time);
    // console.log(appoinment.date,appoinment.time, "now",now)
    // console.log(datetimeB,"datetimeB")
    // var a = moment('2016-06-06T21:03:55');//now
    // var b = moment('2016-05-06T20:03:55');

    let final = datetimeB.diff(now, 'minutes');
    // console.log(final)
    let status;
    final > -10 ? status = true : status = false
    return status
  }

  const checkIfAppointmentStarted = (appoinment) => {
    let appdate = moment(appoinment.date).format('YYYY/MM/DD');
    let now = moment().format();
    let datetimeB = moment(appdate + " " + appoinment.time);

    var diff = datetimeB.diff(now, 'seconds');
    var diffmin = datetimeB.diff(now, 'minutes');
    console.log(diffmin, "diffmin");
    let status;
    diff > 0 ? status = true : status = false
    return status

  }
  const coptText = (value) => {
    copyToClipboard(value)
    toast.success('E-mail Copied', { position: "top-center", });
  }
  const openNoShowBox = (list, from, item) => {
    setNoShowActive(item);
    setNoShowFrom(from);
    setattendeesList(list)
    window.loadModal('#NoshowBox', 'show');
  }
  const noshowGroupAppointment = (item) => {
    console.log(item);
    let body = {
      appointment_id: noShowActive.id,
      user_id: item.user_id
    }
    noshowGroupAppointments(body).then((data) => {
      if (data.data.status) {
        toast.success("Status updated succesfully", {
          position: "top-center",
        });
        const data = {
          page: 1,
          rowsPerPage: 10,
        };
        getAllAppointmentsList(data, activeTab,);
      }
      else {
        toast.error("Something went wrong", { position: "top-center" });
      }
    })
  }
  const noshowRemoveGroupAppointment = (item) => {
    console.log(item);
    let body = {
      appointment_id: noShowActive.id,
      user_id: item.user_id
    }
    noshowRemoveGroupAppointments(body).then((data) => {
      if (data.data.status) {
        toast.success("Status updated succesfully", {
          position: "top-center",
        });
        const data = {
          page: 1,
          rowsPerPage: 10,
        };
        getAllAppointmentsList(data, activeTab,);
      }
      else {
        toast.error("Something went wrong", { position: "top-center" });
      }
    })
  }

  const cancelGroupAppointment = (id, app) => {
    const updatedItems = upcomingAppointments.map((item) => {
      return { ...item, status: false };
    });
    setAppointments(updatedItems)
    let attendee = app.attendees == undefined ? false : true
    let body = {
      appointment_id: id,

    }
    cancelGroupAppointments(body, attendee).then((data) => {
      if (data.data.status) {
        toast.success("Status updated succesfully", {
          position: "top-center",
        });
        const data = {
          page: 1,
          rowsPerPage: 10,
        };
        getAllAppointmentsList(data, activeTab,);
      }
      else {
        toast.error("Something went wrong", { position: "top-center" });
      }
    })
  }
  const checkAppId = (appoinment) => {
    if (appoinment.is_group_session === 0) {
      return appoinment.id
    }
    else if (appoinment.is_group_session === 1) {
      if (appoinment.attendees == undefined) {
        if (appoinment.parent_id == null) {
          return appoinment.id
        }
        else {
          return appoinment.parent_id
        }

      }
      else {
        return appoinment.id
      }

    }
    else {
      return appoinment.id
    }

  }
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        <Search callApi={filterListAppointments} list={filterList} setSearchValues={setSearchValues} setFilterValues={setFilterValues}
          searchValue={searchValue}
          filterValues={filterValues}
        />
        {/* <Button
          status={0}
          type="button"
          onClick={filterReset}
          className={`resend-btn  width-80px  reset-btn-123`}
          title="Reset"
        /> */}
      </div>
      <div className="table-box-medium admin-appoi">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              {React.Children.toArray(
                UpcomingHeaders.map((item) => <th>{item.title}</th>)
              )}
            </tr>
            {upcomingAppointments.length > 0 ? (
              upcomingAppointments.map((appoinment, index) => (
                <tr className="innr">
                  <td>{session_id_beg +
                    checkAppId(appoinment)

                    + session_id_end}{appoinment.give_add1_highlight ? <img className="ml-1 star-app-hightlight" src="../images/star.png" /> : ''}</td>
                  <td className="googl-link min-width-210">
                    {appoinment.meeting_link === "" ||
                      appoinment.meeting_link === null ||
                      appoinment.meeting_link === "NULL" ? (

                      <>
                        <span className="w-unset loading-regenrate">
                          {"Generating Zoom link"}
                        </span>
                        <img
                          title="Refresh"
                          onClick={(e) => refreshScreen("refresh")}
                          className="googl-link-copy-img copy-image-animate refresh-btn-app mt-4"
                          alt="copy"
                          src="images/refresh.png"
                        />
                      </>

                    ) : (
                      <>
                        {appoinment.meeting_link.substring(0, 20).concat("...")}
                        <CopyComponent value={appoinment.meeting_link} />
                      </>
                    )}
                    {/* <a href={appoinment.meeting_link}>{appoinment.meeting_link.substring(0, 20).concat("...")}</a>
                        <CopyComponent value={appoinment.meeting_link} /> */}
                    {/* <Button
                      className={`resend-btn w-unset height-35 min-width-130px`}
                      title="Click Here"
                      status={0}
                      onClick={() => getLink(appoinment.appointmentId)}
                    /> */}
                    {/* <a
                      className="appointment-links"
                      href={appoinment.meeting_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {appoinment.meeting_link}
                    </a>
                    <img
                      onClick={() => copyMeetingLink(appoinment.meeting_link)}
                      className="googl-link-copy-img "
                      src="images/copy.png"
                      alt="copy"
                    /> */}
                  </td>
                  {getTimeDiff(appoinment)}
                  <td>
                    <div className="min-width-70px ">
                      <Toggle
                        openConfirmAppointment={openConfirmAppointment}
                        item={appoinment}
                        setActiveUser={setActiveUser}
                      />
                    </div>
                  </td>
                  <td>
                    {appoinment.attendees == undefined ?
                      <>
                        <span className="capitalize">{appoinment.client_name},</span>
                        <br />
                        <span className="capitalize">{appoinment.medium_name}</span>
                      </>
                      :

                      <span className="text-underline  pointer p-1" onClick={() => openNoShowBox(appoinment.attendees, 'remove', appoinment)}>{appoinment.attendees.length}&nbsp;Attendee(s)</span>
                    }

                  </td>
                  <td>
                    {appoinment.date.split(' ')[0]}
                    {/* {moment
                      .utc(appoinment.date)
                      .local()
                      .format("LL")} */}
                  </td>
                  <td>
                    {" "}
                    {appoinment.time}
                    {/* {moment
                      .utc(appoinment.time, ["h:mm A"])
                      .local()
                      .format("hh:mm a")} */}
                  </td>
                  <td>{appoinment.session_name}</td>
                  <td>
                    {appoinment.attendees == undefined ?

                      <>
                        {appoinment.is_group_session === 0 ?

                          <select
                            // value={appoinment.statusValue}
                            onChange={(e) => handleNoShow(e, appoinment)}
                            className="appointment-select-box"
                          >
                            <option value="" selected disabled>
                              Select
                            </option>

                            {/* <option value={"me"}>{"By me"}</option> */}
                            <option disabled={getTimeDiff(appoinment)} value={"other"}>{appoinment.session_type === "1" || appoinment.session_type === "2" || appoinment.session_type === "11" ? "Other No show" : "Client no-show"}</option>
                            {appoinment.session_type !== "1" && appoinment.session_type !== "2" && appoinment.session_type !== "11" && <option disabled={getTimeDiffTenMin(appoinment)} value={"ended-early"}>{"Ended early"}</option>}
                            {appoinment.session_type !== "1" && appoinment.session_type !== "2" && appoinment.session_type !== "11" && <option value={"restrict-client"}>{"Restrict client"}</option>}
                            {appoinment.session_type === "5" ? <option disabled={getTimeDiff(appoinment)} value={"give-add-1"}>{"Give Add’l"}</option> : ''}
                          </select>
                          :
                          'N/A'
                        }
                      </>
                      :
                      <>
                        <Button
                          status={0}
                          onClick={() => openNoShowBox(appoinment.attendees, '', appoinment)}
                          type="button"
                          className={`resend-btn w-130`}
                          title="No Show"
                        />
                      </>
                    }
                  </td>
                  {/* <td>
                    <div>
                      <div className="d5a67b ml-1">
                        {appoinment.client_email}{" "}
                      </div>
                    </div>
                  </td> */}

                  <td className="dot-image">
                    <img
                      onClick={(e) =>
                        onClickHandler(e, appoinment.appointmentId, "upcoming")
                      }
                      src="../images/Unionblck.png"
                      className="pointer dot-image-inside"
                      alt="dots"
                    />
                    {appoinment.status && (
                      <div className="approve-btn-1 approve-btn z-index-2">
                        <span className="w-100 anchor-white">
                          <a onClick={() => coptText(((appoinment.session_type === "1" || appoinment.session_type === "2" || appoinment.session_type === "11") && parseInt(id) === parseInt(appoinment.client_id)) ? appoinment.medium_email : appoinment.client_email)}
                            href={`mailto:${((appoinment.session_type === "1" || appoinment.session_type === "2" || appoinment.session_type === "11") && parseInt(id) === parseInt(appoinment.client_id)) ? appoinment.medium_email : appoinment.client_email}`}>
                            Contact
                          </a>
                        </span>
                        {checkIfAppointmentStarted(appoinment) &&
                          <>
                            {appoinment.is_group_session == 0 ?
                              <span
                                className="w-100"
                                onClick={(e) => {
                                  changeHandlerLocal(
                                    e,
                                    3,
                                    appoinment.appointmentId,
                                    id,
                                  )
                                  // changeHandler(
                                  //   e,
                                  //   3,
                                  //   appoinment.appointmentId,
                                  //   appoinment.medium_id
                                  // );
                                }}
                              >
                                Cancel
                              </span>
                              :
                              <span
                                className="w-100"
                                onClick={(e) => cancelGroupAppointment(appoinment.id, appoinment)}
                              // changeHandler(
                              //   e,
                              //   3,
                              //   appoinment.appointmentId,
                              //   appoinment.medium_id
                              // );

                              >
                                Cancel
                              </span>
                            }
                          </>
                        }

                        {appoinment.is_reschedule == 1 && appoinment.show_reschedule == 1 ? <span
                          className="w-100"
                          onClick={() => confirmRescheduleAppointmentpractice(appoinment, index)}
                        >
                          Reschedule
                        </span> : (appoinment.is_reschedule == 0 && appoinment.show_reschedule == 1 ? <span
                          className="w-100"
                          onClick={() => rescheduleAppointmentNotAllow(appoinment, index)}
                        >
                          Reschedule
                        </span> : "")}
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Appointments Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <div className="pagination-box">
          <div className="left-pagi">
            <span>Showing 1 to 10 of 18 entries</span>
          </div>
          <div className="right-pagi">
            <ul>
              <li>Previous</li>
              <li className="active">
                <a href="#">1</a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>Next</li>
            </ul>
          </div>
        </div> */}
        <Pagination rowsPerPageOptions={[5, 10, 25]} activeTab={"mediumupcoming"} count={allUpcomingCount} getPaginationData={getPaginationData} />
        <ConfirmCancel
          deleteUser={confirmRequest}
          cancelRequest={cancelRequest}
          id={'ConfirmCancelupcomingmedium'}
        />
        <ConfirmDeleteModal
          deleteUser={confirmAppointment}
          id={'ConfirmAppointment'}
          label={"Are you sure ?"}
          btnLabel={"Continue"}
        />
        <ConfirmReschedule
          deleteUser={openReSchedulePopUp}
          setIndexReschedule={indexReschedule}
          setItemReschedule={itemReschedule}
          id={'ConfirmRescheduleAppointmentpractice'}
          label={"Please confirm that you have permission from your partner/client to schedule at this time?"}
          btnLabel={"Continue"}
        />
        <RescheduleAppointmentNotAllowModel

          setIndexReschedule={indexReschedule}
          setItemReschedule={itemReschedule}
          id={'RescheduleAppointmentNotAllowModel'}
          label={messageNotAllow}
          btnLabel={"Continue"}
        />

        <UpdatedReScheduleAppt
          // usersListMedium={usersListMedium}
          // usersListClient={usersListClient}
          reschedeId={reschedeId}
          // reschedeData={reschedeData}
          getAllAppointmentsList={getAllAppointmentsList}
          activeTab={activeTab}
          setReschedeId={setReschedeId}
          // setReschedeData={setReschedeData}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}

        />
        <NoshowBox
          noShowActive={noShowActive}
          list={attendeesList}
          noShowRequest={noshowGroupAppointment}
          noshowRemoveGroupAppointment={noshowRemoveGroupAppointment}
          noshowFrom={noshowFrom}
        />
      </div>
    </>
  );
}
