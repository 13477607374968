import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Tabs from '../../../components/tabs';
import TabPanel from "../../../components/tabs/tabpanel";
import { setLoading } from "../../../reducer/auth/auth";
import { list } from "./data";
import Professional from './professional';
import Coaching from './coaching';
import './style.css';

import {
    stepOneData,
    stepThreeData,
    stepTwoData,
    stepOneDataBackup,
    stepFourData,
} from "../../profile/data";
import {
    getProfileBasicInfo,
    getProfileLeagalAndPrivacy,
    getProfilePreferences,
    getProfileTechnology,
} from "../../../services/admin";
import PayItForward from './payitforward';
export default function Princing() {
    const dispatch = useDispatch();
    const { id } = useSelector((state) => state.user.user);
    const [transactionsList, setTransactionsList] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [profileSection, setProfileSection] = useState(null);
    const [profileLoaded, setProfileLoaded] = useState(false);

    useEffect(() => {
        if (profileSection === null) getProfile(id);
    }, []);
    const getProfile = (id) => {
        dispatch(setLoading(true))
        getProfileBasicInfo(id)
            .then((data) => {
                dispatch(setLoading(false))
                if (data.data.status) {
                    const {
                        country,
                        first_name,
                        last_name,
                        info,
                        languages,
                        mediumship_level_medium, //which users select
                        mediumship_level, //aprroved
                        phone,
                        specialities,
                        website,
                        whatsapp,
                        timezone,
                        when_do_i_pay,
                        session_length,
                        session_currency,
                        profile_image,
                        encoded_image,
                        zoom,
                        google,
                        medium_professional_category,
                        recommendation,
                        encrypted_id,
                        personal_booking_link,
                        is_square_integrated,
                        is_stripe_integrated,
                        is_paypal_integrated,
                        test_mode,
                        extra_services,
                        extra_services_data,
                        extra_services_list,
                        coaching_pricing,
                        hosting_circle_pricing,
                        hosting_qa_pricing,
                        comments,
                        payitforward_pricing,
                        payitforward_coaching_pricing,
                        coaching_info
                    } = data.data.data;
                    console.log(data.data.data,"anuj");
                    stepOneData.test_mode = test_mode
                    stepOneData.is_square_integrated = is_square_integrated !== undefined && is_square_integrated ? true : false
                    stepOneData.is_stripe_integrated = is_stripe_integrated !== undefined && is_stripe_integrated ? true : false
                    stepOneData.is_paypal_integrated = is_paypal_integrated !== undefined && is_paypal_integrated ? true : false
                    stepOneData.personal_booking_link = personal_booking_link;
                    stepOneData.encrypted_id = encrypted_id;
                    stepOneData.zoom = zoom !== null ? zoom : "";
                    stepOneData.google = google !== null ? google : "";
                    stepOneData.profile_image =
                        profile_image !== "" ? profile_image : null;
                    stepOneData.encoded_image = encoded_image;
                    stepOneData.firstName = first_name;
                    stepOneData.lastName = last_name;
                    stepOneData.website = website;
                    stepOneData.country = country;
                    stepOneData.biographicalInfo = info;
                    stepOneData.phone = phone;
                    stepOneData.mediumshipLevel = parseInt(mediumship_level_medium);
                    stepOneData.mediumship_level = parseInt(mediumship_level);

                    stepOneData.recommendation =
                        recommendation !== null ? recommendation : "";

                    stepOneData.medium_professional_category = medium_professional_category;
                    stepOneData.whatsapp = whatsapp;
                    stepOneData.when_do_i_pay =
                        when_do_i_pay !== undefined
                            ? when_do_i_pay.map((item) => {
                                return String(item);
                            })
                            : [];
                    stepOneData.session_currency =
                        session_currency !== null
                            ? Object.values(JSON.parse(session_currency))[0] !== "" ? Object.values(JSON.parse(session_currency))[0] : "USD"
                            : "USD";
                    stepOneData.session_length_30_currency =
                        session_currency !== null
                            ? Object.values(JSON.parse(session_currency))[0]
                            : "";
                    stepOneData.session_length_45_currency =
                        session_currency !== null
                            ? Object.values(JSON.parse(session_currency))[1]
                            : "";
                    stepOneData.session_length_60_currency =
                        session_currency !== null
                            ? Object.values(JSON.parse(session_currency))[2]
                            : "";
                    stepOneData.session_length_45_2_currency =
                        session_currency !== null
                            ? Object.values(JSON.parse(session_currency))[3]
                            : "";
                    stepOneData.session_length_60_2_currency =
                        session_currency !== null
                            ? Object.values(JSON.parse(session_currency))[4]
                            : "";

                    stepOneData.session_length_30 =
                        session_length !== null
                            ? Object.values(JSON.parse(session_length))[0]
                            : "";

                    stepOneData.session_length_45 =
                        session_length !== null
                            ? Object.values(JSON.parse(session_length))[1]
                            : "";
                    stepOneData.session_length_60 =
                        session_length !== null
                            ? Object.values(JSON.parse(session_length))[2]
                            : "";
                    stepOneData.session_length_45_2 =
                        session_length !== null
                            ? Object.values(JSON.parse(session_length))[3]
                            : "";
                    stepOneData.session_length_60_2 =
                        session_length !== null
                            ? Object.values(JSON.parse(session_length))[4]
                            : "";
                    stepOneData.extra_services = extra_services;
                    stepOneData.extra_services_data = extra_services_data;
                    stepOneData.extra_services_list = extra_services_list !== null ? extra_services_list.map((item) => {
                        return {
                            id: item.id,
                            amount: item.amount,
                            name: item.sub_session_name,
                        };
                    }) : "";
                    stepOneData.timezone = { ...timezone, value: timezone.id, label: timezone.front_name };
                    stepOneData.coaching_pricing_30 = coaching_pricing !== null && coaching_pricing !== undefined ? Object.values(JSON.parse(coaching_pricing))[0] : '';
                    stepOneData.hosting_circle_pricing_30 = hosting_circle_pricing !== null && hosting_circle_pricing !== undefined ? Object.values(JSON.parse(hosting_circle_pricing))[0] : '';
                    stepOneData.hosting_qa_pricing_30 = hosting_qa_pricing !== null && hosting_qa_pricing !== undefined ? Object.values(JSON.parse(hosting_qa_pricing))[0] : '';
                    stepOneData.comments = comments
                    stepOneData.coaching_info = coaching_info

                    stepOneData.payitforward_pricing_30= payitforward_pricing !== null && payitforward_pricing !== undefined ? Object.values(JSON.parse(payitforward_pricing))[0]
                    : "";
                    stepOneData.payitforward_pricing_45= payitforward_pricing !== null && payitforward_pricing !== undefined  ? Object.values(JSON.parse(payitforward_pricing))[1]
                    : "";
                    stepOneData.payitforward_pricing_60= payitforward_pricing !== null  && payitforward_pricing !== undefined ? Object.values(JSON.parse(payitforward_pricing))[2]
                    : "";
                    stepOneData.payitforward_pricing_45_2= payitforward_pricing !== null  && payitforward_pricing !== undefined ? Object.values(JSON.parse(payitforward_pricing))[3]
                    : "";
                    stepOneData.payitforward_pricing_60_2= payitforward_pricing !== null  && payitforward_pricing !== undefined ? Object.values(JSON.parse(payitforward_pricing))[4]
                    : "";
                    stepOneData.payitforward_coaching_pricing= payitforward_coaching_pricing !== null  && payitforward_coaching_pricing !== undefined ? payitforward_coaching_pricing
                    : "";
                    
                    dispatch(setLoading(false))
                    setProfileSection(data.data.data);
                    setProfileLoaded(true);
                }
            })
            .catch((err) => {
                dispatch(setLoading(false))
                console.log(err)
            });
    }


    return (
        <div className="all-medium-page">
            <Tabs active={activeTab} list={list} setActiveTab={setActiveTab} />
            <ul className="tabs-content">
                <TabPanel id={0} activeTab={activeTab}>
                    {profileLoaded && <Professional stepOneData={stepOneData} id={id} />}
                </TabPanel>
                <TabPanel id={1} activeTab={activeTab}>
                    <Coaching stepOneData={stepOneData} id={id} />
                </TabPanel>
                <TabPanel id={2} activeTab={activeTab}>
                    <PayItForward stepOneData={stepOneData} id={id} />
                </TabPanel>
            </ul>
        </div>
    )
}
