import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getUsers,
  approveUser,
  getAdminAppointmentCount,
} from "../../../services/admin";
import { setLoading } from "../../../reducer/auth/auth";
import { setUsers, setDashboardCount } from "../../../reducer/adminSlice";
import { getMediumRole } from "../../../utils/utils";
import ConfirmDelete from "../Subcomponents/deleteconfirm";
import CountStatusComponent from "./count";

export default function AdminDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = useSelector((state) => state.users.users);
  const { counts } = useSelector((state) => state.users);

  const [userData, setUserData] = useState([]);
  // const [open, setOpen] = useState(false);
  const [deleteInfo, setDeletInfo] = useState({});

  const [statuscounts, setStatusCounts] = useState({});
  // const [statusLiveCount, setStatusLiveCounts] = useState({});

  useEffect(() => {
    setStatusCounts(counts);
    console.log(counts, "cenjkn")
  }, [counts]);

  // useEffect(() => {
  //   setStatusLiveCounts(liveCount);
  // }, [liveCount]);
  useEffect(() => {
    if (counts === "") getCount();
    let token = localStorage.getItem("access_token");
    if (users.length === 0) {
      callUsers(token);
    }
  }, []);
  useEffect(() => {
    setUserData(JSON.parse(JSON.stringify(users)));
  }, [users]);

  const getCount = async () => {
    await getAdminAppointmentCount()
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          dispatch(setDashboardCount(data.data.data));
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const callUsers = async (token) => {
    dispatch(setLoading(true));
    const response = await getUsers()
      .then((data) => {
        dispatch(setLoading(false));
        const update = data.data.data.data.map((resp) => {
          return {
            ...resp,
            status: false,
          };
        });

        dispatch(setUsers(update));
        // setUserData(update);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });

    return response;
  };
  const updateUser = async (user, status, name) => {
    if (name === "delete") {
      window.loadModal2("#deleteConfirmation", "show");
      let data = { user, status };
      setDeletInfo(data);
    } else {
      deleteUser(user, status);
    }
  };
  const deleteUser = async (user, status) => {
    let token = localStorage.getItem("access_token");
    let body = {
      user_id: status !== undefined ? user.id : deleteInfo.user.id,
      medium_status:
        status !== undefined
          ? user.medium_status
          : deleteInfo.user.medium_status,
      status: status !== undefined ? status : deleteInfo.status,
    };
    console.log(body, "body");
    await approveUser(body)
      .then(() => {
        callUsers(token);
      })
      .catch(() => { });
  };

  const onClickHandler = (id) => {
    const response = userData.map((user) => {
      if (user.id === id) {
        user.status = !user.status;
      } else {
        user.status = false;
      }
      return user;
    });
    setUserData(response);
  };
  const navigateUser = (id) => {
    navigate(`/profile-view/${id.id}`);
  };
  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-md-12">
          <div className="quck-box">
            <h2>Quick Status</h2>
            <div className="quick-status-two admin-dashboard-page">
              <div
                onClick={() => navigate("/mediums?view=all")}
                className="upcoming-comlilated pointer"
              >
                <div className="content-left">
                  <div className="icon-left">
                    <img src="images/to-do-list.png" alt="count" />
                  </div>
                  <span>
                    {Object.keys(statuscounts).length > 0 &&
                      statuscounts.pendingApprovals}
                  </span>
                  Pending
                </div>
              </div>
              <div
                onClick={() => navigate("/mediums?view=allmediums")}
                className="upcoming-comlilated pointer"
              >
                <div className="content-left">
                  <div className="icon-left">
                    <img src="images/m.png" alt="count" />
                  </div>
                  <span>
                    {" "}
                    {Object.keys(statuscounts).length > 0 &&
                      statuscounts.mediums}
                  </span>
                  Mediums
                </div>
              </div>
              <div
                onClick={() => navigate("/clients")}
                className="upcoming-comlilated pointer"
              >
                <div className="content-left">
                  <div className="icon-left">
                    <img src="images/business-people.png" alt="count" />
                  </div>
                  <span>
                    {Object.keys(statuscounts).length > 0 &&
                      statuscounts.clients}
                  </span>
                  Clients{" "}
                </div>
              </div>

              {/* <div className="upcoming-comlilated">
                <div className="content-left">
                  <div className="icon-left">
                    <img src="images/novice.png" alt="count" />
                  </div>
                  <span>
                    {Object.keys(statuscounts).length > 0 &&
                      statuscounts.support}
                  </span>
                  Support
                </div>
              </div> */}

              {/* <div className="upcoming-comlilated">
                <div className="content-left">
                  <div className="icon-left">
                    <img src="images/testimonial.png" alt="" />
                  </div>
                  <span>
                    {Object.keys(statuscounts).length > 0 &&
                      statuscounts.inspirationalQoutes}
                  </span>
                  Inspiration <br />
                  Stories
                </div>
              </div> */}
            </div>
          </div>
          <div className="quck-box">
            <div className="quick-status-two admin-dashboard-page">
              <CountStatusComponent
                src={"images/business-people.png"}
                count={
                  Object.keys(statuscounts).length > 0 &&
                  statuscounts.loggedInMediums
                }
                title={"Logged In Mediums"}
              />
              <CountStatusComponent
                src={"images/business-people.png"}
                count={
                  Object.keys(statuscounts).length > 0 &&
                  statuscounts.LoggedInClients
                }
                title={"Logged In Clients"}
              />
              <CountStatusComponent
                src={"images/business-people.png"}
                count={
                  Object.keys(statuscounts).length > 0 &&
                  statuscounts.onDemandMediums
                }
                title={"Mediums on Demand"}
              />
              {/* <CountStatusComponent
                src={"images/business-people.png"}
                count={
                  Object.keys(statuscounts).length > 0 &&
                  statuscounts.onDemandClients
                }
                title={"Clients on Demand"}
              /> */}
            </div>
          </div>
          <div className="all-box-sally-saction">
            <div className="left-saly-table">
              <div className="table-my-appointments free-client-dashboard-tbale">
                <h2>Pending Approvals</h2>
                <div className="veiw-box">
                  <Link to="/mediums?view=all">View All</Link>
                </div>
                <div className="my-app-table">
                  <table className="caseTable">
                    <tbody>
                      <tr className="dark"> 
                        <th>&nbsp;</th>
                        <th>Screen Name</th>
                        <th>Email</th>
                        <th>Member Type</th>
                        <th>
                          Chosen Mediumship Level
                          {/* <a href="#" className="filre-image">
                            <img src="images/filtr.png" alt="" />
                          </a> */}
                        </th>

                        <th><div className="min-width-210">Preferred Sessions</div> </th>
                        <th className="text-right">Actions</th>
                      </tr>
                      {/* .slice(0,5) */}
                      {userData.length > 0 ? (
                        userData.slice(0, 5).map((user) => (
                          <tr className="innr min-height-65 ">
                            <td>
                              <img
                                class="img-profile rounded-circle"
                                src="/images/undraw_profile.svg"
                                alt="undraw_profile.svg"
                              />{" "}
                            </td>
                            <td className="capitalize">
                              {user.screen_name ? user.screen_name : "N/A"}
                              {/* {user.first_name ? user.first_name : "N/A"}{" "}
                              {user.last_name} */}
                            </td>
                            <td>{user.email}</td>
                            <td>Medium</td>
                            <td>
                              {getMediumRole(
                                user.current_mediumship_level_medium
                              )}
                            </td>

                            <td><div className="min-width-210">{JSON.parse(user.user_preferences.client_session_preferences).join(', ')}</div></td>

                            <td
                              onClick={() => onClickHandler(user.id)}
                              className="dott"
                            >
                              <a href="javascript:void(0);">
                                <img src="images/Unionblck.png" alt="count" />
                              </a>
                              {user.status && (
                                <div className="approve-btn z-index-1  mt-20">
                                  <span onClick={() => navigateUser(user)}>
                                    View
                                  </span>
                                  <span onClick={() => updateUser(user, 1)}>
                                    Approve
                                  </span>
                                  <span onClick={() => updateUser(user, 2)}>
                                    Decline
                                  </span>

                                  <span
                                    onClick={() =>
                                      updateUser(user, 3, "delete")
                                    }
                                  >
                                    Delete
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No Pending Approvals
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div className="right-saly-table">
              <div className="side-bar-right">
                <div className="chart-hading">
                  <div className="numbar-clint">Number of Mediums</div>
                  <div className="right-week">
                    <div className="dropdown">
                      <button>
                        Last Week <img src="images/dropdown.png" alt="" />
                      </button>
                      <ul>
                        <li>
                          <a href="#">Item 1</a>
                        </li>
                        <li>
                          <a href="#">Item 2</a>
                        </li>
                        <li>
                          <a href="#">Item 3</a>
                        </li>
                        <li>
                          <a href="#">Item 4</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="grabh">
                  <div className="chart">
                    <table
                      id="data-table"
                      summary="skillset"
                      className="js"
                      cellSpacing="0"
                      cellPadding="10"
                      border="1"
                    >
                      <thead>
                        <tr>
                          <td>&nbsp;</td>
                          <th scope="col">Mon</th>
                          <th scope="col">Tue</th>
                          <th scope="col">Wed</th>
                          <th scope="col">Thu</th>
                          <th scope="col">Fri</th>
                          <th scope="col">Sat</th>
                          <th scope="col">sun</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>90</td>
                          <td>50</td>
                          <td>80</td>
                          <td>95</td>
                          <td>80</td>
                          <td>80</td>
                          <td>80</td>
                        </tr>
                      </tbody>
                    </table>
                    <div id="figure">
                      <h4></h4>
                      <ul className="legend"></ul>
                      <div className="graph">
                        <ul className="x-axis">
                          <li>
                            <span>Mon</span>
                          </li>
                          <li>
                            <span>Tue</span>
                          </li>
                          <li>
                            <span>Wed</span>
                          </li>
                          <li>
                            <span>Thu</span>
                          </li>
                          <li>
                            <span>Fri</span>
                          </li>
                          <li>
                            <span>Sat</span>
                          </li>
                          <li>
                            <span>sun</span>
                          </li>
                        </ul>
                        <ul className="y-axis">
                          <li>
                            <span>100%</span>
                          </li>
                          <li>
                            <span>75%</span>
                          </li>
                          <li>
                            <span>50%</span>
                          </li>
                          <li>
                            <span>25%</span>
                          </li>
                          <li>
                            <span>0%</span>
                          </li>
                        </ul>
                        <div className="bars">
                          <div className="bar-group">
                            <div
                              className="bar fig0"
                              style={{ height: "90%", width: "60%" }}
                            >
                              <span>90%</span>
                            </div>
                          </div>
                          <div className="bar-group">
                            <div
                              className="bar fig0"
                              style={{ height: "50%", width: "60%" }}
                            >
                              <span>50%</span>
                            </div>
                          </div>
                          <div className="bar-group">
                            <div
                              className="bar fig0"
                              style={{ height: "80%", width: "60%" }}
                            >
                              <span>80%</span>
                            </div>
                          </div>
                          <div className="bar-group">
                            <div
                              className="bar fig0"
                              style={{ height: "95%", width: "60%" }}
                            >
                              <span>95%</span>
                            </div>
                          </div>
                          <div className="bar-group">
                            <div
                              className="bar fig0"
                              style={{ height: "80%", width: "60%" }}
                            >
                              <span>80%</span>
                            </div>
                          </div>
                          <div className="bar-group">
                            <div
                              className="bar fig0"
                              style={{ height: "80%", width: "60%" }}
                            >
                              <span>80%</span>
                            </div>
                          </div>
                          <div className="bar-group">
                            <div
                              className="bar fig0"
                              style={{ height: "80%", width: "60%" }}
                            >
                              <span>80%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <ConfirmDelete deleteUser={deleteUser} id="deleteConfirmation" />
    </div>
  );
}
