import React, { useCallback, useEffect, useMemo, useState } from "react";
// import BookingCalendar from "./calendar";
import moment from "moment-timezone";
import { getTimezones } from "../../../services/uidata";
import { getSlotsByDate, getTimeSlotsByDate } from "../../../services/client";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setLoadingContent, setLoadingText } from "../../../reducer/auth/auth";
import { toast } from "react-toastify";
import BookingCalendar from "./calendarscreen";
import { getAvailableMediumsForGroupCoaching, getCoachingMediumList, getDateCountMedium, getDateCountMediumGroupCoahing, getSlotsByDateMedium, getSlotsByDateMediumGroupCoaching } from "../../../services/medium";
import ProfileCard from "./componetnts/profilecard";
import PayOptionsPopUp from "./componetnts/paymentoptions";
import { el } from "date-fns/locale";
import Button from "../../../components/button";
import { setAllMediums } from "../../../reducer/adminSlice";
import PayItForwardPopUp from "../../client/appointments/components/payitforward";
import SelectedLimitReached from "./componetnts/selectedlimitreached";
import { doAPayItForwardPayment } from "../../../services/client";

export default function BookingPage({
    serviceType,
    setMediumVale, bookingDate, serviceValue, sessionLength,
    setSessionLength, sessionLengthList, openForm,
    setBookingDate, setBookingTime,
    setTimeZoneInfo, setsession_id,
    setsubsession_id, setTimeZoneName, setPrice,
    setDuration, setPaymentInfo, setCurrency,
    backHandler, mediumValue, bookingTime, mediumList, setMediumList,
    setPracticeMediums, setSupportMediums, coachingType, setCoachingType, activeIndex,
    setActiveIndex, coachingTypeBackup, setoldFlow }) {
    const { timezone } = useSelector(
        (state) => state.user.user
    );
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [pageType, setPageType] = useState('selection');
    const [timezoneData, setTimezoneData] = useState([]);
    const [timezon, setTimezone] = useState("");
    const [timeZoneValue, setTimeZoneValue] = useState("");
    const [appointmentTyepList, setAppointmentTyepList] = useState([]);
    const [dateCount, setDateCount] = useState({});
    const [activeSessionId, setActiveSessionId] = useState("");
    const [activeSubSessionId, setActiveSubSessionId] = useState("");


    //
    const [slots, setSlots] = useState([]);
    const [slotsupdated, setSlotsUpdated] = useState(false);
    const [active, setActive] = useState(false);
    const [count, setCount] = useState(0);
    const [loadCalcender, setLoadCalendar] = useState('');
    const [mediumLoaded, setMediumLoaded] = useState(false);
    const [value, setVlaue] = useState(true);
    const [allMediumList, seAllMediumList] = useState([])
    const [lastLoadedMedium, setLastLoadedMedium] = useState(7);
    const [checkTimes, setCheckTimes] = useState([]);
    const [resetCalander, setResetCalcendar] = useState(true);
    const [month_empty, setmonth_empty] = useState(false);


    const [activePay, setActivePay] = useState("");
    const [paymentstatus, setPaymentstatus] = useState(false);
  const [payItforwardValue, setpayItforwardValue] = useState('');
  const [payItforwardFixPrice ,setpayItforwardFixPrice] = useState("")
  const [counterStatus, setCounterStatus] = useState(false);
  const [paymentCurrency, setPaymentCurrency] = useState('USD');



    useEffect(() => {
        setmonth_empty(false)
        setTimezone(moment.tz.guess());
        // if (serviceValue === 2 && coachingType === 'free') {

        //     setLoadCalendar(Math.floor(Math.random()));
        // }
        console.log(parseInt(serviceType), serviceValue)
        if (parseInt(serviceType) == 1 && serviceValue === 2) {
            // let newArray = [
            //     {
            //         "id": 3457,
            //         "coaching_pricing": null,
            //         "profile_image": null,
            //         "screen_name": "NSVSTest 5",
            //         "encoded_image": null,
            //         "is_stripe": false,
            //         "is_paypal": false,
            //         "is_squareup": false

            //     },
            //     {
            //         "id": 3458,
            //         "coaching_pricing": null,
            //         "profile_image": null,
            //         "screen_name": "NSVSTest 5",
            //         "encoded_image": null,
            //         "is_stripe": false,
            //         "is_paypal": false,
            //         "is_squareup": false
            //     },
            //     {
            //         "id": 3459,
            //         "coaching_pricing": null,
            //         "profile_image": null,
            //         "screen_name": "NSVSTest 5",
            //         "encoded_image": null,
            //         "is_stripe": false,
            //         "is_paypal": false,
            //         "is_squareup": false
            //     }
            // ]
            // console.log(newArray, "newArray");
            // setMediumLoaded(true)
            // setCoachingType('paid')
            // setMediumList(newArray);
            getMediumListGroupCoahing();
            return;
        }
        if (serviceValue === 2 && coachingType === "paid") {

            let today = moment().format("YYYY-MM-DD")
            // getTimeSlots(today, "mediumlistnoupdate")
            getMediumList();
        }


        // dispatch(setLoading(true))
    }, []);
    useEffect(() => {
        // if (timezone !== "") {
        updateTimezones(timezone);
        // }
    }, []);

    // useEffect(() => {
    //     if (serviceValue === 2 && bookingTime !== "" && (mediumValue === undefined || mediumValue === "")) {
    //         getTimeSlots(bookingDate, "mediumlist", undefined, "effect")
    //     }
    // }, [bookingTime])


    const getMediumListGroupCoahing = () => {
        let body = {
            service: serviceValue,
            session_length: sessionLength,
        }
        getAvailableMediumsForGroupCoaching(body).then((data) => {
            dispatch(setLoading(false))

            if (data.data.status) {

                setMediumLoaded(true)
                setActiveIndex();
                console.log(data.data.data, "data.data.data")
                // let newArray = data.data.data.map((info) => {
                //     if (info.coaching_pricing === null) {
                //         return {
                //             ...info,
                //             whatTime: 'first'
                //         }

                //     }

                //     else if (Object.values(JSON.parse(JSON.parse(info.coaching_pricing)))[0] === '0') {
                //         return {
                //             ...info,
                //             coaching_pricing: null,
                //             whatTime: 'first'
                //         }
                //     }
                //     else {
                //         return {
                //             ...info,
                //             whatTime: 'first'
                //         }
                //     }


                // })
                localStorage.setItem('listmedium', JSON.stringify(data.data.data))
                seAllMediumList(data.data.data)
                setCoachingType('paid')
                setMediumList(data.data.data.slice(0, 7));
                setLastLoadedMedium(7)
            }
        }).catch(() => { })
    }
    const getMediumList = () => {
        let body = {
            service: serviceValue,
            session_length: sessionLength,
        }
        getCoachingMediumList(body).then((data) => {
            dispatch(setLoading(false))
            console.log(data.data.status, "data.data.status", data.data)
            if (data.data.status) {

                setMediumLoaded(true)
                setActiveIndex();
                let newArray = data.data.data.map((info) => {
                    if (info.coaching_pricing === null) {
                        return {
                            ...info,
                            whatTime: 'first'
                        }

                    }

                    else if (Object.values(JSON.parse(JSON.parse(info.coaching_pricing)))[0] === '0') {
                        return {
                            ...info,
                            coaching_pricing: null,
                            whatTime: 'first'
                        }
                    }
                    else {
                        return {
                            ...info,
                            whatTime: 'first'
                        }
                    }


                })
                localStorage.setItem('listmedium', JSON.stringify(newArray))
                seAllMediumList(newArray)
                setMediumList(newArray.slice(0, 7));
                setLastLoadedMedium(7)
            }
        }).catch(() => { })
    }
    const loadMoreMediums = () => {
        if (allMediumList.length > lastLoadedMedium) {
            setBookingDate('');
            setBookingTime('');
            setMediumVale('');
            setSlots([]);
            setSlotsUpdated(false);
            setActiveIndex();
            let today = moment().format("YYYY-MM-DD")
            setDateCount({ [today]: false })
            let newItem = allMediumList.slice(lastLoadedMedium, lastLoadedMedium + lastLoadedMedium)
            setMediumList(newItem);
            setLastLoadedMedium(lastLoadedMedium + lastLoadedMedium);
        }
    }
    const onChangeSessionHandler = (e) => {
        setSlotsUpdated(false);
        setActive(false);

        let value = e.target.value;
        console.log(value)
        console.log(sessionLengthList, "appointmentTyepList")
        let item = sessionLengthList.filter(
            (info) => parseInt(info.id) === parseInt(value)
        );
        setSessionLength(value);
        // setPageType('selection');
        // setActiveSubSessionId(item[0].subsession_id);
        // setActiveSessionId(item[0].session_id);

        // setsession_id(item[0].session_id);
        // setPrice(item[0].amount);
        // setDuration(item[0].duration);
        // setCurrency(item[0].currency)
        // setsubsession_id(item[0].subsession_id);

    };
    const changeTimeZoneHandler = (e) => {
        let id = e.target.value;
        let info = timezoneData.filter((time) => {
            return time.id === parseInt(id);
        });
        setTimeZoneName(info[0].front_name);
        setTimeZoneValue(id);
        setTimeZoneInfo(id);
        setSlotsUpdated(false);
        setActive(false);
    };
    const updateTimezones = (timezone) => {
        getTimezones()
            .then((data) => {
                setTimezoneData(data.data.data);

                let info = data.data.data.filter((time) => {
                    return time.front_name === timezone;
                });
                console.log(info);
                if (info.length) {
                    console.log(info[0].id)
                    // setTimeZoneName(info[0].front_name);
                    setTimeZoneValue(info[0].id);
                    // setTimeZoneInfo(info[0].id)
                }
            })
            .catch((err) => console.log(err));
    };

    const getDatesCountGroupCoaching = (dates) => {
        dispatch(setLoadingContent(`<div>Searching for availability... we'll have the results shortly! </div>`));
        dispatch(setLoadingText(true));

        let body = {
            medium_id: mediumValue !== null && mediumValue !== undefined ? mediumValue.id : "",
            startDate: dates.start,
            endDate: dates.end,
            service: serviceValue,
            session_length: sessionLength,
        };
        getDateCountMediumGroupCoahing(body)
            .then((data) => {


                if (data.data.status) {
                    setmonth_empty(data.data.externalInfo.month_empty)
                    if (data.data.externalInfo.month_empty) {
                        window.scrollTo({
                            left: 0,
                            top: 700,
                            behavior: "smooth",
                        });
                    }
                    if (JSON.parse(localStorage.getItem('listmedium')) == 0 && serviceValue == 2) {
                        let newObj = Object.keys(data.data.data).reduce((acc, key) => {
                            acc[key] = false;
                            return acc;
                        }, {});

                       
                        setDateCount(newObj)
                        setCount(count + 1)
                    }
                    else {
                        setDateCount(data.data.data);
                        setCount(count + 1)
                    }



                }
                else {
                    setmonth_empty(false)
                    toast.error(data.data.message, {
                        position: "top-center",
                    });
                }
                dispatch(setLoadingContent(""));
                dispatch(setLoadingText(false));
            })
            .catch(() => {
                dispatch(setLoadingContent(""));
                dispatch(setLoadingText(false));
            });
    }
    const getDatesCount = (dates) => {
        if (parseInt(serviceType) == 1 && serviceValue === 2 && loadCalcender !== "") {

            getDatesCountGroupCoaching(dates)


            return;
        }
        if (parseInt(serviceType) == 1 && serviceValue === 2 && loadCalcender == "") {

            let today = moment().format("YYYY-MM-DD")
            setDateCount({ [today]: false })
            return;
        }
        if (serviceValue === 2 && !mediumList.length && coachingType === "paid") {
            let today = moment().format("YYYY-MM-DD")
            setDateCount({ [today]: false })
            return;
        }
        dispatch(setLoadingContent(`<div>Searching for availability... we'll have the results shortly! </div>`));
        dispatch(setLoadingText(true));
        // dispatch(setLoading(true))
        let body = {
            medium_id: mediumValue !== null && mediumValue !== undefined ? mediumValue.id : "",
            startDate: dates.start,
            endDate: dates.end,
            service: serviceValue,
            session_length: sessionLength,
        };
        getDateCountMedium(body)
            .then((data) => {


                if (data.data.status) {
                    setmonth_empty(data.data.externalInfo.month_empty)
                    if (data.data.externalInfo.month_empty) {
                        window.scrollTo({
                            left: 0,
                            top: 700,
                            behavior: "smooth",
                        });
                    }
                    if (JSON.parse(localStorage.getItem('listmedium')) == 0 && serviceValue == 2) {
                        let newObj = Object.keys(data.data.data).reduce((acc, key) => {
                            acc[key] = false;
                            return acc;
                        }, {});
                       
                        setDateCount(newObj)
                        setCount(count + 1)
                    }
                    else {
                       
                        setDateCount(data.data.data);
                        setCount(count + 1)
                    }



                }
                else {
                    setmonth_empty(false)
                    toast.error(data.data.message, {
                        position: "top-center",
                    });
                }
                dispatch(setLoadingContent(""));
                dispatch(setLoadingText(false));
            })
            .catch(() => {
                dispatch(setLoadingContent(""));
                dispatch(setLoadingText(false));
            });
    };

    const getTimeSlots = (date, type, item = "", effect) => {
        if (parseInt(serviceType) == 1 && serviceValue === 2) {
            // setSlotsUpdated(true);
            // setActive(true);
            // window.scrollTo({
            //     left: 0,
            //     top: document.body.scrollHeight,
            //     behavior: "smooth",
            // });
            // let slots =
            //     [{ from: "02:00 AM", to: "04:00 AM", name: '30 Min Coaching Session', num: 2 },
            //     { from: "04:00 AM", to: "06:45 AM", name: '30 Min Coaching Session', num: 3 },
            //     { from: "08:00 AM", to: "09:00 AM", name: '30 Min Coaching Session', num: 8 },
            //     { from: "11:00 AM", to: "01:45 PM", name: '30 Min Coaching Session', num: 9 },
            //     { from: "02:00 PM", to: "06:45 PM", name: '30 Min Coaching Session', num: 6 },
            //     { from: "07:00 AM", to: "09:45 PM", name: '30 Min Coaching Session', num: 5 },
            //     ]
            // setSlots(
            //     slots.map((value) => {
            //         return { datetime: value, selectedStatus: false, status: false };
            //     })
            // );
            let body = {
                medium_id: type === "mediumlistnoupdate" ? item : mediumValue !== null && mediumValue !== undefined ? mediumValue.id !== undefined ? mediumValue.id : "" : "",
                date: date,
                timezone: timeZoneValue,
                session_id: serviceValue,
                subsession_id: sessionLength,
                is_medium_list: type === "mediumlist" ? 1 : 0,
                time: bookingTime
            }
            getSlotsByDateMediumGroupCoaching(body).then((data) => {

                setSlotsUpdated(true);
                setActive(true);
                window.scrollTo({
                    left: 0,
                    top: document.body.scrollHeight,
                    behavior: "smooth",
                });

                setSlots(
                    data.data.data.map((value) => {
                        return { datetime: value, selectedStatus: false, status: false };
                    })
                );
            }).catch(() => {

            })
            setBookingDate(date);
            return;
        }
        if (serviceValue == 2 && activeIndex == undefined && coachingType === "paid") {
            return;
        }
        if (effect !== "effect") {
            dispatch(setLoadingContent(`<div>Searching for availability... we'll have the results shortly! </div>`));
            dispatch(setLoadingText(true));
            // dispatch(setLoading(true))
            setSlotsUpdated(false);
            setActive(false);
        }
        setBookingDate(date);
        let body = {
            medium_id: type === "mediumlistnoupdate" ? item : mediumValue !== null && mediumValue !== undefined ? mediumValue.id !== undefined ? mediumValue.id : "" : "",
            date: date,
            timezone: timeZoneValue,
            session_id: serviceValue,
            subsession_id: sessionLength,
            is_medium_list: type === "mediumlist" ? 1 : 0,
            time: bookingTime
        }
        getSlotsByDateMedium(body)
            .then((data) => {


                if (effect !== "effect") {


                    if (data.data.status) {
                        if (serviceValue === 1) {
                            setPracticeMediums(data.data.data.mediums)
                        } else if (serviceValue === 11) {
                            setSupportMediums(data.data.data.mediums)
                        }
                        if (type !== "mediumlist") {

                            setSlotsUpdated(true);
                            setActive(true);
                            window.scrollTo({
                                left: 0,
                                top: document.body.scrollHeight,
                                behavior: "smooth",
                            });

                            setSlots(
                                data.data.data.slots.map((value) => {
                                    return { datetime: value, selectedStatus: false, status: false };
                                })
                            );
                        }
                        else if (type === "mediumlistnoupdate") {

                            setSlotsUpdated(true);
                            setActive(true);
                            window.scrollTo({
                                left: 0,
                                top: document.body.scrollHeight,
                                behavior: "smooth",
                            });

                            setSlots(
                                data.data.data.slots.map((value) => {
                                    return { datetime: value, selectedStatus: false, status: false };
                                })
                            );

                        }
                        else {
                            setActiveIndex();
                            let newArray = data.data.data.mediums.map((info) => {
                                if (info.coaching_pricing === null) {
                                    return info

                                }

                                else if (Object.values(JSON.parse(JSON.parse(info.coaching_pricing)))[0] === '0') {
                                    return {
                                        ...info,
                                        coaching_pricing: null
                                    }
                                }
                                else {
                                    return info
                                }


                            })
                            console.log(newArray, "newArray");
                            setMediumList(newArray);
                            setSlotsUpdated(true);
                            setActive(true);
                            window.scrollTo({
                                left: 0,
                                top: document.body.scrollHeight,
                                behavior: "smooth",
                            });

                            setSlots(
                                data.data.data.slots.map((value) => {
                                    return { datetime: value, selectedStatus: false, status: false };
                                })
                            );
                        }
                        // setTimeout(() => {
                        dispatch(setLoadingContent(""));
                        dispatch(setLoadingText(false));
                        // dispatch(setLoading(false))
                        // }, 6000)
                    }

                    else {
                        toast.error(data.data.message, {
                            position: "top-center",
                        });
                    }
                }
                else {
                    setActiveIndex();
                    let newArray = data.data.data.mediums.map((info) => {
                        if (info.coaching_pricing === null) {
                            return info

                        }

                        else if (Object.values(JSON.parse(JSON.parse(info.coaching_pricing)))[0] === '0') {
                            return {
                                ...info,
                                coaching_pricing: null
                            }
                        }
                        else {
                            return info
                        }

                    })

                    setMediumList(newArray);
                }
            })
            .catch(() => {
                dispatch(setLoadingContent(""));
                dispatch(setLoadingText(false));
            });
    };
    const openCalendar = () => {
        setPageType('calendar');
    }



    const getslotsFromMedium = (item) => {
        if (parseInt(serviceType) == 1 && serviceValue === 2) {

            // setMediumVale(item)

            // let dates = {

            //     '2024-06-17'
            //         :
            //         true,
            //     '2024-06-18'
            //         :
            //         true,
            //     '2024-06-19'
            //         :
            //         true,
            //     '2024-06-20'
            //         :
            //         true,
            //     '2024-06-21'
            //         :
            //         true,
            //     '2024-06-22'
            //         :
            //         true,
            //     '2024-06-23'
            //         :
            //         true,
            //     '2024-06-24'
            //         :
            //         true,
            //     '2024-06-25'
            //         :
            //         true,
            // }
            // setDateCount(dates);
            console.log(times, "times")
            setCheckTimes(times);
            setLoadCalendar(item.id);
            setMediumVale(item)
            setDateCount({})
            setBookingTime('');
            setBookingDate('');
            setSlots([]);
            setSlotsUpdated(false);
            return;
        }


        let times = checkTimes;
        if (times.length > 4) {

            window.loadModal2("#selectedlimitreached", "show");
            // window.removeBackdrop();
            setCheckTimes([]);
            setActiveIndex()
            let today = moment().format("YYYY-MM-DD")
            setDateCount({ [today]: false })
            return;
        }
        else {
            setResetCalcendar(false);


            setTimeout(() => {

                setResetCalcendar(true)
            }, 100);
        }
        times.push(item.id);
        console.log(times, "times")
        setCheckTimes(times);
        setLoadCalendar(item.id);
        setMediumVale(item)
        setDateCount({})
        setBookingTime('');
        setBookingDate('');
        setSlots([]);
        setSlotsUpdated(false);


    }


    const checkPyament = () => {

        if (bookingDate === "" || bookingTime === "") {

            toast.error("Please select both date and time slot", {
                position: "top-center",
            });
            return;

        }
        if (parseInt(serviceType) == 1 && serviceValue === 2) {
            openForm()
            return;
        }

        if (serviceValue === 2) {
            
            if (coachingTypeBackup === 'free' || coachingTypeBackup === 'paid' && coachingType === 'free') {
                let newbody = {
                    booking_type: 'free'
                }
                openForm(newbody);

            }

            else {
                let data = activeIndex !== undefined ? mediumList[activeIndex] : mediumList.length > 0 ? mediumList.filter((item) => item.coaching_pricing !== null)[0] : mediumValue;
                setActivePay(data);
                if(data.pay_forward == 1){
                    console.log(data,"sanish Kamboj");
                    setpayItforwardValue(data.amount);
                    setpayItforwardFixPrice(data.amount);
                    window.loadModal2("#PayItForwardPopUp", "show");
                }else{
                    window.loadModal2("#payoptionspopupmediumbooking", "show");
                    window.removeBackdrop();
                }
            }
        } else {
            openForm()
        }
    }
    const createBooking = (item) => {
        let i = mediumList.length ? mediumList.filter((item) => item.coaching_pricing !== null)[0] : mediumValue
        let newbody = {
            amount: activeIndex !== undefined ? Object.values(JSON.parse(JSON.parse(mediumList[activeIndex].coaching_pricing)))[0] : Object.values(JSON.parse(JSON.parse(i.coaching_pricing)))[0],
            appointment_type: 'paybefore',
            booking_type: 'paid',
            method: item.paymenttype
        }
        openForm(newbody)
    }
    const resetForm = () => {
        setmonth_empty(false)
        dispatch(setLoading(true))
        setMediumList([]);
        setBookingDate('');
        setBookingTime('');
        setMediumVale('');
        setLastLoadedMedium(7)
        seAllMediumList([]);
        setSlots([]);
        setSlotsUpdated(false);
        setActiveIndex();
        let today = moment().format("YYYY-MM-DD")
        setDateCount({ [today]: false })
        getMediumList();
        setResetCalcendar(false);


        setTimeout(() => {

            setResetCalcendar(true)
        }, 100);

    }
    const backRemoveStates = () => {
        setmonth_empty(false)
        setBookingDate('');
        setBookingTime('');
        setMediumVale('');
        setSlots([]);
        setSlotsUpdated(false);
        setActiveIndex();
        setMediumList([])
        seAllMediumList([]);
        setDateCount({})
        backHandler('SELECTION');

    }

    const showAllAvailability = () => {
        setmonth_empty(false)
        window.loadModal2("#selectedlimitreached", "hide");
        window.removeBackdrop();
        // if (coachingType === "free") {
        setCoachingType('free');
        setBookingDate('');
        setBookingTime('');
        setMediumVale('');
        setSlots([]);
        setSlotsUpdated(false);
        setActiveIndex();
        setMediumList([])
        seAllMediumList([]);
        setDateCount({})
        setLoadCalendar(Math.floor(Math.random()));
        setoldFlow(true)
        // }
    }
    const continueMediumSelection = () => {
        window.loadModal2("#selectedlimitreached", "hide");
        window.removeBackdrop();
    }
    const payItForwardHandler = () => {
        let body = {
            medium_id: activePay.id,
            amount: payItforwardValue,
            appointment_type: 'paybefore',
            currency: activePay.currency,
            session_type: activePay.session_type,
            method : "paypal",
            date: bookingDate,
            time: bookingTime,
            duration: activePay.duration,
            subsession_id: activePay.subsession_id
          }
        doAPayItForwardPayment(body).then((data) => {
            if (data.data.status) {
              window.open(data.data.data.approvedUrl, "_self");
              // openWaitingContainer();
            }
          }).catch(() => {
      
          })
    }
    return (
        <>

            <div className="container mt-5">

                {/* <div>
                    <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2  text-center">

                        <select
                            value={timeZoneValue}
                            onChange={changeTimeZoneHandler}
                            className="select-box-timezone pointer width-440px"
                        >
                            <option disabled selected value="">
                                Change Timezone
                            </option>
                            {timezoneData.map((item) => (
                                <option value={item.id}>{item.front_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2  text-center">
                        <select
                            onChange={onChangeSessionHandler}
                            className="subsession-box"
              
                        >
                            <option disabled value={""}>
                                Appoinment Type
                            </option>

                            {appointmentTyepList.map((item, index) => (
                                <option data-item={item} value={item.subsession_id}>
                                    {" "}
                                    {item.name}
                                </option>
                            ))}
                        </select>
                        <span className="d-inline-block p-2"> &nbsp;Price :${activeAmount}&nbsp;{activeCurrency}</span>
                    </div>
                </div> */}

            </div>

            <div>


                <div className="container mt-5">
                    <div className="d-flex align-items-center">
                        {serviceValue === 2 && <div className="width-430px">
                            <p>Step 1: Select coach</p>
                            <p>Step 2: Select available date (Shown in green)</p>
                            <p>Step 3: Select time (below)</p>
                            <p>Step 4: Press Book (at bottom)</p>
                        </div>}
                        <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2  text-center">
                            <select
                                onChange={onChangeSessionHandler}
                                className="subsession-box"
                                value={sessionLength}
                            >
                                <option disabled value={""}>
                                    Change Session Length
                                </option>

                                {sessionLengthList.map((item, index) => (
                                    <option selected={item.id === sessionLength} data-item={item} value={item.id}>
                                        {" "}
                                        {item.sub_session_name}
                                    </option>
                                ))}
                            </select>
                            {/* <span className="d-inline-block p-2"> &nbsp;Price :${activeAmount}&nbsp;{activeCurrency}</span> */}
                        </div>
                    </div>
                    {/* <h4 className='text-center'>Please select service type from dropdown above to get started.</h4> */}
                </div>
                <div className={`${pageType === "selection" ? 'd-none' : 'd-block'}`}>
                    <div className="p-5 m-5">

                    </div>
                    <div className="p-5 m-5">

                    </div>
                    <div className="p-5 m-5">

                    </div>
                </div>
                <div className={`${pageType === "selection" ? 'd-block' : 'd-none'}`}>
                    <div className="schedule-advance-saction margin-unset d-flex">
                        {serviceValue === 2 && coachingType === 'paid' && mediumList.length > 0 &&
                            <>
                                <div className="calenl-scn">

                                    <div className="ml-2 d-flex align-items-center justify-content-between mr-2"><span
                                        className="text-underline pointer min-width-120 d-inline-block"
                                        href="#"

                                    >Coach List</span>
                                        <img
                                            title="Refresh"
                                            onClick={resetForm}
                                            className="googl-link-copy-img copy-image-animate refresh-btn-app mt-4"
                                            alt="refresh"
                                            src="images/refresh.png"
                                        />
                                    </div>
                                    {mediumList.length > 0 && mediumList.map((item, index) => <ProfileCard onClick={getslotsFromMedium} item={item} index={index} activeIndex={activeIndex} setActiveIndex={setActiveIndex} bookingTime={bookingTime} serviceValue={serviceValue} />)}
                                    {allMediumList.length > lastLoadedMedium && <div className="d-flex align-items-center justify-content-center">

                                        <Button
                                            className={`resend-btn w-unset height-45 min-width-130px `}
                                            status={0}
                                            onClick={loadMoreMediums}
                                            title="Load More"
                                        />
                                    </div>}
                                </div>
                            </>
                        }
                        <div className="container">
                            <div className="row">
                                {resetCalander && <BookingCalendar

                                    getDatesCount={getDatesCount}
                                    timeZoneValue={timeZoneValue}
                                    dateCount={dateCount}
                                    activeSubSessionId={sessionLength}
                                    getTimeSlots={getTimeSlots}
                                    slots={slots}
                                    setSlots={setSlots}
                                    setBookingDate={setBookingDate}
                                    setBookingTime={setBookingTime}
                                    slotsupdated={slotsupdated}
                                    setSlotsUpdated={setSlotsUpdated}
                                    active={active}
                                    serviceValue={serviceValue}
                                    loadCalcender={loadCalcender}
                                    mediumList={mediumList}
                                    mediumValue={mediumValue}
                                    bookingTime={bookingTime}
                                    bookingDate={bookingDate}
                                    coachingType={coachingType}
                                    serviceType={serviceType}
                                    month_empty={month_empty}
                                />}
                            </div>
                        </div>

                    </div>
                    {/**********progress bar */}
                    <div className="progress-saction">
                        <div className="container">
                            <ul>
                                <li
                                    onClick={() => backRemoveStates()}
                                    className="back"
                                >
                                    <a href="#">
                                        <img src="images/black-erow.png" alt="back" />
                                        Back
                                    </a>
                                </li>

                                <li className="continue">
                                    <div className="dont-have-acc">
                                        {/* sessionType==="prof"?  () =>"": */}
                                        <button
                                            onClick={checkPyament}
                                            type="button"
                                            className="btn btn-info btn-lg width-unset-img"
                                        // data-toggle="modal"
                                        // data-target="#myModal"
                                        >
                                            Book Appointment
                                            <img
                                                className="width-unset-img"
                                                src="../images/wight-erow.png"
                                                alt="book appointment"
                                            />
                                        </button>

                                    </div>
                                </li>
                            </ul>
                        </div>
                        <PayOptionsPopUp createBooking={createBooking} paymentInfo={activeIndex !== undefined ? mediumList[activeIndex] : mediumList.length > 0 ? mediumList.filter((item) => item.coaching_pricing !== null)[0] : mediumValue} />
                        
                        <SelectedLimitReached
                            showAllAvailability={showAllAvailability}
                            continueMediumSelection={continueMediumSelection} />

                    </div>
                </div>
            </div>
            <PayItForwardPopUp
                activePay={activePay}
                paymentMethodHandler={payItForwardHandler}
                setpayItforwardValue={setpayItforwardValue}
                setPaymentCurrency={setPaymentCurrency}
                payItforwardFixPrice={payItforwardFixPrice}
                paymentstatus={paymentstatus}
                payItforwardValue={payItforwardValue}
            />
        </>
    );
}
